import { useEffect, useState } from 'react'
import { Field } from 'formik'
import { supervisionService } from '../../services/supervisionService'
import { capitalizeFirstLetter, formatDate, isNullOrUndefined, openPDF } from '../../helpers/utils'
import { Accordion, Card, Button } from 'react-bootstrap'
import Checkbox from '@material-ui/core/Checkbox'
import { Table } from 'react-bootstrap'
import { ReactComponent as OK } from './../../res/checked.svg'
import { ReactComponent as Erreur } from './../../res/siren.svg'
import { ReactComponent as SO } from './../../res/erreur.svg'
import { Tabs } from 'antd'
import jsPDF from 'jspdf'
import logo from './../../res/logo.png'
import souspdf from './../../assets/sous-pdf.png'
import manuel from './../../assets/manuel.png'
import { IoMdClose } from "react-icons/io"
import { useSaisieContext } from '../Intervention/Saisie/SaisieContext'
import useCanvas from '../Canvas/useCanvas'
import { ReactComponent as Intervention } from './../../res/alerte.svg'
import { useDataContext } from '../../providers/DataContextProvider'
import { storage } from '../../helpers/storageHelper'

const { TabPane } = Tabs

const DetailAutresPopup = (props) => {

    const [marques, setMarques] = useState([])
    const [modeles, setModeles] = useState([])
    const [vlogiciels, setvlogiciels] = useState([])
    const [modelesLog, setModelesLog] = useState([])
    const [revision, setRevision] = useState("")
    const [revisionFile, setRevisionFile] = useState("")
    const [couleurs, setCouleurs] = useState([])
    const [montagesA, setMontagesA] = useState([])
    // Collapse 
    const [isOpen, setIsOpen] = useState(false)
    // Collapse 
    const [ahoui, setahoui] = useState(false)

    const [scellement, setScellement] = useState(null)
    const [isControleOk, setIsControleOk] = useState(false)
    const { setFormMateriel, formVerif, setFormVerif, formMateriel, setScellementTarifTaximetre, setScellementTarifAfficheur } = useSaisieContext()
    const { interventionInfoGeneral } = useDataContext()

    const { setCoordinate, setCoordinate2, canvasRef, canvasWidthInt, canvasHeightInt } = useCanvas()

    const handleCanvasClick = async (event) => {
        let canvas = document.getElementById("canvas1")
        let rect = canvas.getBoundingClientRect()
        let scaleX = canvas.width / rect.width
        let scaleY = canvas.height / rect.height
        // si cercle pas de offset
        // si rect on ajoute offset rect.left + offset
        // Coordonnées reelles
        let x = Math.round((event.clientX - (rect.left)) * scaleX)
        let y = Math.round((event.clientY - (rect.top)) * scaleY)

        const currentCoord = { x, y }
        setCoordinate(currentCoord)

        // Coordonnées pourcentage
        x = Math.round((x / canvasWidthInt) * 1000) / 1000
        y = Math.round((y / canvasHeightInt) * 1000) / 1000

        // Mise à jour de l'état global
        let l_materiels = await storage.get('materiel')
        const indexMat = l_materiels.findIndex(m => m.numero_type_interne === props.data.numero_type_interne)
        l_materiels[indexMat].emplacement1 = x
        l_materiels[indexMat].emplacement2 = y
        setFormMateriel(l_materiels)
        await storage.set("materiel", l_materiels)
        let newSet = new Map(props.values.set)
        let l_materiel = props.values.set.get(props.data.numero_type_interne)
        l_materiel.emplacement1 = x
        l_materiel.emplacement2 = y
        newSet.set(props.data.numero_type_interne, l_materiel)
        props.setFieldValue("set", newSet)
        await storage.set("liaisons", Array.from(newSet.entries()))
    }

    const handleCanvasClick2 = async (event) => {
        let canvas = document.getElementById("canvas2")
        let rect = canvas.getBoundingClientRect()
        let scaleX = canvas.width / rect.width
        let scaleY = canvas.height / rect.height
        // si cercle pas de offset
        // si rect on ajoute offset rect.left + offset
        // Coordonnées reelles
        let x = Math.round((event.clientX - (rect.left)) * scaleX)
        let y = Math.round((event.clientY - (rect.top)) * scaleY)

        const currentCoord = { x, y }
        setCoordinate2(currentCoord)

        // Coordonnées pourcentage
        x = Math.round((x / canvasWidthInt) * 1000) / 1000
        y = Math.round((y / canvasHeightInt) * 1000) / 1000

        // Mise à jour de l'état global
        let l_materiels = await storage.get('materiel')
        const indexMat = l_materiels.findIndex(m => m.numero_type_interne === props.data.numero_type_interne)
        l_materiels[indexMat].emplacement3 = x
        l_materiels[indexMat].emplacement4 = y
        setFormMateriel(l_materiels)
        await storage.set("materiel", l_materiels)
        let newSet = new Map(props.values.set)
        let l_materiel = props.values.set.get(props.data.numero_type_interne)
        l_materiel.emplacement3 = x
        l_materiel.emplacement4 = y
        newSet.set(props.data.numero_type_interne, l_materiel)
        props.setFieldValue("set", newSet)
        await storage.set("liaisons", Array.from(newSet.entries()))
    }

    const handleClearCanvas = async (event) => {
        setCoordinate(null)
        // Mettre a jour l'etta global
        let l_materiels = await storage.get('materiel')
        const indexMat = l_materiels.findIndex(m => m.numero_type_interne === props.data.numero_type_interne)
        l_materiels[indexMat].emplacement1 = 0
        l_materiels[indexMat].emplacement2 = 0
        setFormMateriel(l_materiels)
        await storage.set("materiel", l_materiels)
        let newSet = new Map(props.values.set)
        let l_materiel = props.values.set.get(props.data.numero_type_interne)
        l_materiel.emplacement1 = 0
        l_materiel.emplacement2 = 0
        newSet.set(props.data.numero_type_interne, l_materiel)
        props.setFieldValue("set", newSet)
        await storage.set("liaisons", Array.from(newSet.entries()))
    }

    const handleClearCanvas2 = async (event) => {
        setCoordinate2(null)
        // Mettre a jour l'etta global
        let l_materiels = await storage.get('materiel')
        const indexMat = l_materiels.findIndex(m => m.numero_type_interne === props.data.numero_type_interne)
        l_materiels[indexMat].emplacement3 = 0
        l_materiels[indexMat].emplacement4 = 0
        setFormMateriel(l_materiels)
        await storage.set("materiel", l_materiels)
        let newSet = new Map(props.values.set)
        let l_materiel = props.values.set.get(props.data.numero_type_interne)
        l_materiel.emplacement3 = 0
        l_materiel.emplacement4 = 0
        newSet.set(props.data.numero_type_interne, l_materiel)
        props.setFieldValue("set", newSet)
        await storage.set("liaisons", Array.from(newSet.entries()))
    }

    // Initialisation du 1er emplacement
    useEffect(() => {
        let materiel = props.data
        //console.log(materiel)
        if (materiel.emplacement1 !== 0 && materiel.emplacement2) {
            let x = Math.round(materiel.emplacement1 * canvasWidthInt)
            let y = Math.round(materiel.emplacement2 * canvasHeightInt)

            const currentCoord = { x, y }
            setCoordinate(currentCoord)
        }
    }, [])

    // Initialisation du 2eme emplacement
    useEffect(() => {
        let materiel = props.data
        if (materiel.emplacement3 !== 0 && materiel.emplacement4) {
            let x = Math.round(materiel.emplacement3 * canvasWidthInt)
            let y = Math.round(materiel.emplacement4 * canvasHeightInt)

            const currentCoord = { x, y }
            setCoordinate2(currentCoord)
        }
    }, [])

    useEffect(() => {
        getMarqueAppareil(props.data.numero_type_interne)
        props.data.marque_appareil !== 0 && getModeleAppareil(props.data.numero_type_interne, props.data.marque_appareil)
            .then(response =>
                getModelesLog(props.data.marque_appareil, props.data.modele_appareil)
                    .then(result => {
                        if (props.data.numero_type_interne === 2) {
                            let texteRevision = ""

                            if (props.data.numero_cet !== 0) {
                                texteRevision = result.find(m => m.clef_logiciel === props.data.numero_cet).autres
                                // index - 1 pour ne pas prendre en compte le 1er index des options et respecter la taille du tableau de modelesLog
                            }
                            setRevision(texteRevision)
                        }
                    })
            )
        getAllCouleur()
        if (props.data.numero_type_interne !== 2) {
            const data = {
                marque: props.data.marque_appareil,
                modele: props.data.modele_appareil
            }
            getAllMontageAutorise(data)
            if (props.data.modeleTousAppareils) {
                setRevision(props.data.modeleTousAppareils.ref_dam)
            }
        }
    }, [])

    useEffect(() => {
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)
        let formAppareilData = newSet.get(type)
        if (formAppareilData.marque_appareil && formAppareilData.modele_appareil) {
            getVersionLogiciel(formAppareilData.marque_appareil, formAppareilData.modele_appareil)
        }
    }, [props.data.numero_type_interne, props.values.set])

    useEffect(() => {
        controle_autres()
    }, [formVerif, formMateriel])

    // Récupérer toutes les marques de ce type de materiel
    const getMarqueAppareil = async (type) => {
        await supervisionService.getMarqueAppareil(type)
            .then(response => setMarques(response))
            .catch(error => console.log(error))
    }

    const getModeleAppareil = async (type, marque) => {
        await supervisionService.getModeleAppareil(type, marque)
            .then(response => { process.env.NODE_ENV === "development" && console.log(response); setModeles(response) })
            .catch(error => console.log(error))
    }

    const getModelesLog = async (marque, modele) => {
        return await supervisionService.getModelesLog(marque, modele)
            .then(response => {
                setModelesLog(response)
                if (props.data.cet) {
                    const modele = response.filter(m => m.dam_cet === props.data.cet)[0]
                    modele && setRevision(modele.autres)
                }
                return response
            })
            .catch(error => console.log(error))
    }

    const getVersionLogiciel = async (marque, modele) => {
        await supervisionService.getModelesLog(marque, modele)
            .then(response => setvlogiciels(response))
            .catch(error => console.log(error))
    }

    const getAllCouleur = async () => {
        await supervisionService.getAllCouleur()
            .then(response => setCouleurs(response))
            .catch(error => console.log(error))
    }

    const getAllMontageAutorise = async (data) => {
        await supervisionService.getAllMontageAutorise(data)
            .then(response => { setMontagesA(response) })
            .catch(error => console.log(error))
    }

    // Chargement du document de la signature
    const loadFile = async (numero) => {
        await supervisionService.getDocumentListeByNumeroDocument(numero)
            .then(response => setRevisionFile(response))
            .catch(error => setRevisionFile(null))
    }

    const handleChangeMarque = async (e, setFieldValue) => {
        // Marque selectionne
        let marque = parseInt(e.target.value)
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)

        // Mise à jour de l'etat global
        let l_materiel = await storage.get('materiel')

        let formAppareilData = newSet.get(type)
        formAppareilData.marque_appareil = marque

        // Mise à jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)
        l_materiel[indexMat].marque_appareil = marque

        getModeleAppareil(type, marque)
        let modeleTousAppareils = { modele_nom_appareil: "" }
        l_materiel[indexMat].modeleTousAppareils = modeleTousAppareils
        formAppareilData.modeleTousAppareils = modeleTousAppareils
        newSet.set(type, formAppareilData)
        setFieldValue("set", newSet)
        props.setValues({ ...props.values, newSet })

        setModelesLog([])
        setRevisionFile("")

        // Mise a jour de l'etat global
        await storage.set('liaisons', Array.from(newSet.entries()))
        setFormMateriel(l_materiel)
        await storage.set('materiel', l_materiel)
    }

    const handleChangeModele = async (e, setFieldValue) => {
        // Marque selectionne
        let modele = parseInt(e.target.value)
        // Marque selectionne
        let marque = props.values.set.get(props.data.numero_type_interne).marque_appareil
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)

        // Mise à jour de l'etat global
        let l_materiel = await storage.get('materiel')

        let formAppareilData = newSet.get(props.data.numero_type_interne)
        formAppareilData.modele_appareil = modele

        // Mise à jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)
        l_materiel[indexMat].modele_appareil = modele

        const getModelesTousAppareils = (marque, modele) => {
            supervisionService.getModeleTousAppareils(marque, modele)
                .then(async response => {
                    formAppareilData.modeleTousAppareils = response
                    l_materiel[indexMat].modeleTousAppareils = response
                    setRevision(response.ref_dam)

                    // Mise à jour dans Sanctions
                    if (props.data.numero_type_interne === 1) {
                        setScellementTarifTaximetre(response.scellement_tarif)
                    }
                    if (props.data.numero_type_interne === 10) {
                        setScellementTarifAfficheur(response.scellement_tarif)
                    }
                    /*setScellementTarifAfficheur()
                    setScellementTarifAfficheur()*/

                    getModelesLog(marque, modele)
                    newSet.set(type, formAppareilData)
                    setFieldValue("set", newSet)
                    // Mise a jour de l'etat global
                    await storage.set('liaisons', Array.from(newSet.entries()))
                    setFormMateriel(l_materiel)
                    await storage.set('materiel', l_materiel)
                })
                .catch(error => console.log(error))
        }

        if (modele !== 0) {
            getModelesTousAppareils(marque, modele)
            if (props.data.numero_type_interne) {
                const data = {
                    marque: marque,
                    modele: modele
                }
                getAllMontageAutorise(data)
            }
        }
    }

    const handleChangeCET = async (e, setFieldValue) => {
        var index = e.nativeEvent.target.selectedIndex
        const selectedOption = e.target.options[index]
        // Marque selectionne
        let cet = e.target.value
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)
        let l_materiel = await storage.get('materiel')

        let formAppareilData = newSet.get(type)
        // console.log("formAppareilData", formAppareilData)
        formAppareilData.cet = selectedOption.innerText.replace(/\//g, " ")
        formAppareilData.numero_cet = parseInt(cet)

        // Mise a jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)
        // console.log("l_materiel", l_materiel)
        l_materiel[indexMat].cet = selectedOption.innerText.replace(/\//g, " ")
        l_materiel[indexMat].numero_cet = parseInt(cet)

        newSet.set(type, formAppareilData)
        setFieldValue("set", newSet)

        // Nous permet de prendre le numero cet pour pouvoir afficher le document
        const modele = modelesLog.filter(m => m.clef_logiciel === cet)[0]

        let texteRevision = ""

        if (index !== 0) {
            texteRevision = modelesLog[index - 1].autres
            // index - 1 pour ne pas prendre en compte le 1er index des options et respecter la taille du tableau de modelesLog
        }
        setRevision(texteRevision)
        if (!isNullOrUndefined(modele)) {
            loadFile(modele.numero_document_cet)
        }
        else {
            setRevisionFile(null)
        }

        // Mise a jour de l'etat global
        await storage.set('liaisons', Array.from(newSet.entries()))
        setFormMateriel(l_materiel)
        await storage.set('materiel', l_materiel)
    }

    const handleChangeSerie = async (e, setFieldValue) => {
        // Marque selectionne
        let serie = e.target.value
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)

        // Mise à jour de l'etat global
        let l_materiel = await storage.get('materiel')

        let formAppareilData = newSet.get(type)
        formAppareilData.serie_appareil = serie

        // Mise à jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)
        l_materiel[indexMat].serie_appareil = serie

        newSet.set(type, formAppareilData)
        setFieldValue("set", newSet)

        // Mise a jour de l'etat global
        await storage.set('liaisons', Array.from(newSet.entries()))
        setFormMateriel(l_materiel)
        await storage.set('materiel', l_materiel)
    }

    const handleChangeCouleur = async (e, setFieldValue) => {
        // couleur selectionne
        let couleur = parseInt(e.target.value)
        // Map des values de la form
        let newTaximetre = props.values.taximetre

        newTaximetre.couleur_lumineux = couleur

        setFieldValue("taximetre", newTaximetre)
        setFormVerif(newTaximetre)
        await storage.set("taximetre", newTaximetre)
    }

    const handleChangeScellement = (e) => {
        setScellement(e.target.value)
    }

    const addScellement = async (e, setFieldValue) => {
        if (scellement !== "") {
            let compteur = 1
            let dispo = 1
            if (props.values.set.get(props.data.numero_type_interne).modeleTousAppareils) {
                for (let i = 1; i <= 5; i++) {
                    if (props.values.set.get(props.data.numero_type_interne).modeleTousAppareils["marque_" + i] || props.values.set.get(props.data.numero_type_interne)["marque_" + i]) {
                        compteur++
                    }
                }
                /*for (let j = 1; j <= 4; j++) {
                    if (props.values.set.get(props.data.numero_type_interne).modeleTousAppareils["marque_option_" + j]) {
                        compteur++
                    } else {
                        dispo = j
                    }
                }*/
            }
            if (compteur <= 5) {
                // Type de l'appareil
                let type = props.data.numero_type_interne
                // Map des values de la form
                let newSet = new Map(props.values.set)
                let l_materiel = await storage.get('materiel')

                const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)

                let formAppareilData = newSet.get(type)
                formAppareilData.modeleTousAppareils["marque_" + compteur] = scellement
                formAppareilData["marque_" + compteur] = scellement
                l_materiel[indexMat]["marque_" + compteur] = scellement

                newSet.set(type, formAppareilData)
                setFieldValue("set", newSet)

                await storage.set("liaisons", Array.from(newSet.entries()))
                setFormMateriel(l_materiel)
                await storage.set("materiel", l_materiel)

                setScellement("")
            }
        }
    }

    const createPDF = () => {
        const appareil = props.values.set.get(props.data.numero_type_interne)

        // Default export is a4 paper, portrait, using millimeters for units
        const doc = new jsPDF()

        // PDF dimensions
        const pageWidth = doc.internal.pageSize.getWidth()
        const pageHeight = doc.internal.pageSize.getHeight()
        const marginLeft = 20
        const marginTop = 10
        const espacementExtraLarge = 15
        const espacementLarge = 10
        const espacementSmall = 5
        const espacementExtraSmall = 1
        const defaultFontSize = 12

        /* Header de la page */
        // Logo
        const logoSize = { w: 10, h: 10 }
        doc.cell(marginLeft, marginTop, logoSize.w, logoSize.h, " ", 1, "center")
        doc.addImage(logo, 'PNG', marginLeft + 1, marginTop + 1, 8, 8)
        // Titre
        doc.setFontSize(defaultFontSize)
        const titre = "FICHE RECAPITULATIF TAXIMETRES"
        const titreDimension = doc.getTextDimensions(titre)
        doc.cell(marginLeft, marginTop, titreDimension.w + espacementLarge, 10, titre, 1, "center")
        // Infos Tax et App
        doc.setFontSize(8)
        doc.cell(marginLeft, marginTop, 20, 10, " ", 1, "center")
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2) - (20) - (titreDimension.w + espacementLarge) - logoSize.w, 10, "N° : Tax-F350\nCoMet App", 1, "center")

        /* SubHeader de la page */
        doc.setFontSize(defaultFontSize)
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2), logoSize.h, " ", 2, "center")
        // MarqueTitre
        const marqueTitre = "Marque : "
        const marqueTitreMargins = { l: marginLeft + espacementSmall, t: logoSize.h * 2 + 7 }
        doc.text(marqueTitre, marqueTitreMargins.l, marqueTitreMargins.t)
        // Marque
        const marque = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_nom_appareil
        const marqueMargins = { l: marqueTitreMargins.l + doc.getTextDimensions(marqueTitre).w + espacementExtraSmall, t: marqueTitreMargins.t }
        doc.text(marque, marqueMargins.l, marqueMargins.t)
        // ModeleTitre
        const modeleTitre = "Modèle : "
        const modeleTitreMargins = { l: marqueMargins.l + doc.getTextDimensions(marque).w + espacementExtraLarge + 10, t: marqueTitreMargins.t }
        doc.text(modeleTitre, modeleTitreMargins.l, modeleTitreMargins.t)
        // Modele
        const modele = appareil.modeleTousAppareils && appareil.modeleTousAppareils.modele_nom_appareil
        const modeleMargins = { l: modeleTitreMargins.l + doc.getTextDimensions(modeleTitre).w + espacementExtraSmall, t: marqueTitreMargins.t }
        doc.text(modele, modeleMargins.l, modeleMargins.t)
        // TypeTitre
        const typeTitre = "Type : "
        const typeTitreMargins = { l: modeleMargins.l + doc.getTextDimensions(modele).w + espacementExtraLarge + 10, t: marqueTitreMargins.t }
        doc.text(typeTitre, typeTitreMargins.l, typeTitreMargins.t)
        // Type
        const type = props.title
        const typeMargins = { l: typeTitreMargins.l + doc.getTextDimensions(typeTitre).w + espacementExtraSmall, t: marqueTitreMargins.t }
        doc.text(type, typeMargins.l, typeMargins.t)

        /* Corps de la page */
        // General
        doc.setFontSize(10)
        doc.setFont(undefined, undefined, 700)
        const generalTitre = "GENERAL"
        const generalTitreMargins = { l: (pageWidth / 2), t: marqueTitreMargins.t + doc.getTextDimensions(marqueTitre).h + espacementSmall }
        doc.setFillColor(220, 220, 220)
        doc.rect(marginLeft, generalTitreMargins.t - 4, pageWidth - (marginLeft * 2), doc.getTextDimensions(generalTitre).h + 1.5, "F")
        doc.text(generalTitre, generalTitreMargins.l, generalTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // CETTitre
        const cetTitre = "CET : "
        const cetTitreMargins = { l: marqueTitreMargins.l, t: generalTitreMargins.t + espacementSmall + 2 }
        doc.text(cetTitre, cetTitreMargins.l, cetTitreMargins.t)
        // CET
        const cet = appareil.modeleTousAppareils && appareil.modeleTousAppareils.ref_dam
        const cetMargins = { l: cetTitreMargins.l + doc.getTextDimensions(cetTitre).w + espacementSmall, t: cetTitreMargins.t }
        doc.text(cet, cetMargins.l, cetMargins.t)
        // DateTitre
        const dateTitre = "Date : "
        const dateTitreMargins = { l: cetTitreMargins.l, t: cetTitreMargins.t + espacementSmall }
        doc.text(dateTitre, dateTitreMargins.l, dateTitreMargins.t)
        // Date
        const date = appareil.modeleTousAppareils && formatDate(appareil.modeleTousAppareils.date_debut_dam)
        const dateMargins = { l: cetMargins.l, t: dateTitreMargins.t }
        doc.text(date, dateMargins.l, dateMargins.t)
        // k mini Titre
        const kminiTitre = "k mini : "
        const kminiTitreMargins = { l: dateTitreMargins.l, t: dateTitreMargins.t + espacementSmall }
        doc.text(kminiTitre, kminiTitreMargins.l, kminiTitreMargins.t)
        // k mini
        const kmini = appareil.modeleTousAppareils && (appareil.modeleTousAppareils.etendue_debut === 0 || typeof appareil.modeleTousAppareils.etendue_debut === 'undefined') ? "Inconnu" : (appareil.modeleTousAppareils.etendue_debut).toString()
        const kminiMargins = { l: dateMargins.l, t: kminiTitreMargins.t }
        doc.text(kmini, kminiMargins.l, kminiMargins.t)
        // k maxi Titre
        const kmaxiTitre = "k maxi : "
        const kmaxiTitreMargins = { l: kminiTitreMargins.l, t: kminiTitreMargins.t + espacementSmall }
        doc.text(kmaxiTitre, kmaxiTitreMargins.l, kmaxiTitreMargins.t)
        // k maxi
        const kmaxi = appareil.modeleTousAppareils && (appareil.modeleTousAppareils.etendue_fin === 0 || typeof appareil.modeleTousAppareils.etendue_debut === 'undefined') ? "Inconnu" : (appareil.modeleTousAppareils.etendue_fin).toString()
        const kmaxiMargins = { l: kminiMargins.l, t: kmaxiTitreMargins.t }
        doc.text(kmaxi, kmaxiMargins.l, kmaxiMargins.t)
        // Première image
        let picture1 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.photo_1 && 'data:image/jpeg;base64,' + appareil.modeleTousAppareils.photo_1
        const firstImageMargins = { l: modeleTitreMargins.l, t: cetTitreMargins.t - doc.getTextDimensions(cetTitre).h }
        const firstImageSize = { w: 50, h: 40 }
        doc.rect(firstImageMargins.l, firstImageMargins.t, firstImageSize.w, firstImageSize.h, 'S')
        picture1 && doc.addImage(picture1, 'JPEG', firstImageMargins.l + 1, firstImageMargins.t + 1, firstImageSize.w - 2, firstImageSize.h - 2)
        // Deuxième image
        const secondImageMargins = { l: modeleTitreMargins.l + 50 + 5, t: cetTitreMargins.t - doc.getTextDimensions(cetTitre).h }
        const secondImageSize = { w: 50, h: 40 }
        let picture2 = appareil.modeleTousAppareils && 'data:image/jpeg;base64,' + appareil.modeleTousAppareils.photo_2
        doc.rect(secondImageMargins.l, secondImageMargins.t, secondImageSize.w, secondImageSize.h, 'S')
        appareil.modeleTousAppareils && appareil.modeleTousAppareils.photo_2 && doc.addImage(picture2, 'JPEG', secondImageMargins.l + 1, secondImageMargins.t + 1, secondImageSize.w - 2, secondImageSize.h - 2)
        // Observations Titre
        const observationsTitre = "Observations : "
        const observationsTitreMargins = { l: kmaxiTitreMargins.l, t: firstImageMargins.t + firstImageSize.h + espacementSmall }
        doc.text(observationsTitre, observationsTitreMargins.l, observationsTitreMargins.t)
        // Observations
        const observations = appareil.modeleTousAppareils && appareil.modeleTousAppareils.observations
        const observationsMargins = { l: observationsTitreMargins.l + doc.getTextDimensions(observationsTitre).w + espacementSmall, t: observationsTitreMargins.t }
        doc.text(observations, observationsMargins.l, observationsMargins.t)

        /* 2e corps de la page */
        // Montage
        doc.setFontSize(10)
        doc.setFont(undefined, undefined, 700)
        const montageTitre = "MONTAGE"
        const montageTitreMargins = { l: (pageWidth / 3), t: observationsTitreMargins.t + doc.getTextDimensions(observationsTitre).h + espacementLarge }
        doc.setFillColor(220, 220, 220)
        doc.rect(marginLeft, montageTitreMargins.t - 4, pageWidth / 2, doc.getTextDimensions(montageTitre).h + 1.5, "F")
        doc.text(montageTitre, montageTitreMargins.l, montageTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // Première image
        let picture3 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.photo_montage && 'data:image/jpeg;base64,' + appareil.modeleTousAppareils.photo_montage
        const montageImage1Margins = { l: marginLeft, t: montageTitreMargins.t + doc.getTextDimensions(montageTitre).h }
        const montageImage1Size = { w: (pageWidth / 2), h: 70 }
        doc.rect(montageImage1Margins.l, montageImage1Margins.t, montageImage1Size.w, montageImage1Size.h, 'S')
        picture3 && doc.addImage(picture3, 'JPEG', montageImage1Margins.l + 1, montageImage1Margins.t + 1, montageImage1Size.w - 2, montageImage1Size.h - 2)
        // Deuxième image
        const montageImage2Margins = { l: montageImage1Margins.l + montageImage1Size.w + 2, t: montageTitreMargins.t - doc.getTextDimensions(montageTitre).h }
        const montageImage2Size = { w: pageWidth - (2 * marginLeft) - montageImage1Size.w, h: montageImage1Size.h * 1.25 / 2 }
        doc.rect(montageImage2Margins.l, montageImage2Margins.t, montageImage2Size.w, montageImage2Size.h, 'S')
        doc.addImage(manuel, 'JPEG', montageImage2Margins.l + 1, montageImage2Margins.t + 1, montageImage2Size.w - 2, montageImage2Size.h - 2)
        // Position vignette titre
        const positionVignetteTitre = "Position Vignette : "
        const positionVignetteTitreMargins = { l: observationsTitreMargins.l, t: montageImage1Margins.t + montageImage1Size.h + espacementSmall }
        doc.text(positionVignetteTitre, positionVignetteTitreMargins.l, positionVignetteTitreMargins.t)
        // Position vignette
        const positionVignette = appareil.modeleTousAppareils && appareil.modeleTousAppareils.situation_vignette
        const positionVignetteMargins = { l: positionVignetteTitreMargins.l + doc.getTextDimensions(positionVignetteTitre).w + 2, t: positionVignetteTitreMargins.t }
        doc.text(positionVignette, positionVignetteMargins.l, positionVignetteMargins.t)
        // Pour le montage général
        const montageGeneral = appareil.modeleTousAppareils && appareil.modeleTousAppareils.detail_montage ? appareil.modeleTousAppareils.detail_montage : ""
        const montageGeneralMargins = { l: montageImage2Margins.l, t: montageImage2Margins.t + montageImage2Size.h + espacementSmall }
        doc.text(montageGeneral, montageGeneralMargins.l, montageGeneralMargins.t, { maxWidth: 65 })

        /* 3e corps de la page */
        // Navigation
        doc.setFontSize(10)
        doc.setFont(undefined, undefined, 700)
        const navigationTitre = "NAVIGATION"
        const navigationTitreMargins = { l: (pageWidth / 2), t: positionVignetteTitreMargins.t + doc.getTextDimensions(positionVignetteTitre).h + espacementSmall }
        doc.setFillColor(220, 220, 220)
        doc.rect(marginLeft, navigationTitreMargins.t - 4, pageWidth - (marginLeft * 2), doc.getTextDimensions(navigationTitre).h + 1.5, "F")
        doc.text(navigationTitre, navigationTitreMargins.l, navigationTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // Logiciel titre
        const logicielTitre = "Logiciel : "
        const logicielTitreMargins = { l: positionVignetteTitreMargins.l, t: navigationTitreMargins.t + doc.getTextDimensions(navigationTitre).h + espacementSmall }
        doc.text(logicielTitre, logicielTitreMargins.l, logicielTitreMargins.t)
        // Logiciel 
        doc.setFontSize(8)
        const logiciel = appareil.modeleTousAppareils && appareil.modeleTousAppareils.manip_logiciel ? appareil.modeleTousAppareils.manip_logiciel : ""
        const logicielMargins = { l: logicielTitreMargins.l + doc.getTextDimensions(logicielTitre).w + espacementLarge, t: logicielTitreMargins.t }
        doc.text(logiciel, logicielMargins.l, logicielMargins.t, {
            maxWidth: (pageWidth / 2) - (2 * marginLeft) - espacementLarge
        })
        // Essai titre
        doc.setFontSize(defaultFontSize)
        const essaiTitre = "Essai : "
        const essaiTitreMargins = { l: (pageWidth / 2) + espacementLarge, t: logicielTitreMargins.t }
        doc.text(essaiTitre, essaiTitreMargins.l, essaiTitreMargins.t)
        // Essai 
        doc.setFontSize(8)
        const essai = appareil.modeleTousAppareils && appareil.modeleTousAppareils.essai_kilometrique ? appareil.modeleTousAppareils.essai_kilometrique : ""
        const essaiMargins = { l: essaiTitreMargins.l + doc.getTextDimensions(essaiTitre).w + espacementLarge, t: essaiTitreMargins.t }
        doc.text(essai, essaiMargins.l, essaiMargins.t, {
            maxWidth: (pageWidth / 2) - (2 * marginLeft) - espacementLarge
        })
        // Tarif titre
        doc.setFontSize(defaultFontSize)
        const tarifTitre = "Tarif : "
        const tarifTitreMargins = { l: logicielTitreMargins.l, t: logicielMargins.t + (logiciel !== "" ? doc.getTextDimensions(logiciel, { maxWidth: (pageWidth / 2) - (2 * marginLeft) - espacementLarge }).h : doc.getTextDimensions(logicielTitre).h + espacementLarge) }
        doc.text(tarifTitre, tarifTitreMargins.l, tarifTitreMargins.t)
        // Tarif
        const tarif = appareil.modeleTousAppareils && appareil.modeleTousAppareils.manip_tarifs ? appareil.modeleTousAppareils.manip_tarifs : ""
        const tarifMargins = { l: logicielMargins.l, t: tarifTitreMargins.t }
        doc.text(tarif, tarifMargins.l, tarifMargins.t)
        // Prog titre
        doc.setFontSize(defaultFontSize)
        const progTitre = "Prog : "
        const progTitreMargins = { l: essaiTitreMargins.l, t: tarifTitreMargins.t }
        doc.text(progTitre, progTitreMargins.l, progTitreMargins.t)
        // Prog
        const prog = appareil.modeleTousAppareils && appareil.modeleTousAppareils.programmation ? appareil.modeleTousAppareils.programmation : ""
        const progMargins = { l: essaiMargins.l, t: tarifTitreMargins.t }
        doc.text(prog, progMargins.l, progMargins.t)
        /* 4e corps de la page */
        // Scellements installateurs
        doc.setFontSize(8)
        doc.setFont(undefined, undefined, 700)
        const scellementTitre = "SCELLEMENTS INSTALLATEURS"
        const scellementTitreMargins = { l: (pageWidth / 4), t: tarifTitreMargins.t + doc.getTextDimensions(tarifTitre).h + espacementLarge }
        doc.setFillColor(220, 220, 220)
        doc.rect(marginLeft, scellementTitreMargins.t - 4, pageWidth - (marginLeft * 2), doc.getTextDimensions(scellementTitre).h + 2.5, "F")
        doc.text(scellementTitre, scellementTitreMargins.l, scellementTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // Marquages fabricants
        doc.setFontSize(8)
        doc.setFont(undefined, undefined, 700)
        const marquageTitre = "MARQUAGES FABRICANTS"
        const marquageTitreMargins = { l: (pageWidth * 2.375 / 4), t: scellementTitreMargins.t }
        doc.text(marquageTitre, marquageTitreMargins.l, marquageTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // Acces k
        doc.setFontSize(8)
        doc.setFont(undefined, undefined, 700)
        const acceskTitre = "ACCES K"
        const acceskTitreMargins = { l: ((pageWidth * 3.25) / 4), t: scellementTitreMargins.t }
        doc.text(acceskTitre, acceskTitreMargins.l, acceskTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)
        // Marque 1 Titre
        doc.setFontSize(10)
        const marque1Titre = "Marque 1 : "
        const marque1TitreMargins = { l: tarifTitreMargins.l, t: scellementTitreMargins.t + doc.getTextDimensions(scellementTitre).h + 2 }
        doc.text(marque1Titre, marque1TitreMargins.l, marque1TitreMargins.t)
        // Marque 1
        const marque1 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_1
        const marque1Margins = { l: marque1TitreMargins.l + doc.getTextDimensions(marque1Titre).w + espacementExtraSmall, t: scellementTitreMargins.t + doc.getTextDimensions(scellementTitre).h + 2 }
        doc.text(marque1, marque1Margins.l, marque1Margins.t)
        // Marque 2 Titre
        const marque2Titre = "Marque 2 : "
        const marque2TitreMargins = { l: marque1TitreMargins.l, t: marque1TitreMargins.t + doc.getTextDimensions(marque1Titre).h + 2 }
        doc.text(marque2Titre, marque2TitreMargins.l, marque2TitreMargins.t)
        // Marque 2
        const marque2 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_2
        const marque2Margins = { l: marque2TitreMargins.l + doc.getTextDimensions(marque2Titre).w + espacementSmall, t: marque2TitreMargins.t }
        doc.text(marque2, marque2Margins.l, marque2Margins.t)
        // Marque 3 Titre
        const marque3Titre = "Marque 3 : "
        const marque3TitreMargins = { l: marque1TitreMargins.l, t: marque2TitreMargins.t + doc.getTextDimensions(marque2Titre).h + 2 }
        doc.text(marque3Titre, marque3TitreMargins.l, marque3TitreMargins.t)
        // Marque 3
        const marque3 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_3
        const marque3Margins = { l: marque3TitreMargins.l + doc.getTextDimensions(marque3Titre).w + espacementSmall, t: marque3TitreMargins.t }
        doc.text(marque3, marque3Margins.l, marque3Margins.t)
        // Marque 4 Titre
        const marque4Titre = "Marque 4 : "
        const marque4TitreMargins = { l: marque1TitreMargins.l, t: marque3TitreMargins.t + doc.getTextDimensions(marque3Titre).h + 2 }
        doc.text(marque4Titre, marque4TitreMargins.l, marque4TitreMargins.t)
        // Marque 4
        const marque4 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_4
        const marque4Margins = { l: marque4TitreMargins.l + doc.getTextDimensions(marque4Titre).w + espacementSmall, t: marque4TitreMargins.t }
        doc.text(marque4, marque4Margins.l, marque4Margins.t)
        // Marque 5 Titre
        const marque5Titre = "Marque 5 : "
        const marque5TitreMargins = { l: marque1TitreMargins.l, t: marque4TitreMargins.t + doc.getTextDimensions(marque4Titre).h + 2 }
        doc.text(marque5Titre, marque5TitreMargins.l, marque5TitreMargins.t)
        // Marque 5
        const marque5 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.marque_5
        const marque5Margins = { l: marque5TitreMargins.l + doc.getTextDimensions(marque5Titre).w + espacementSmall, t: marque5TitreMargins.t }
        doc.text(marque5, marque5Margins.l, marque5Margins.t)
        // Marquage
        const marquage = appareil.modeleTousAppareils && "CE MXX " + (appareil.modeleTousAppareils.organisme_notifie ? appareil.modeleTousAppareils.organisme_notifie : "")
        const marquageMargins = { l: (pageWidth / 2) - 15, t: marque1Margins.t }
        doc.text(marquage, marquageMargins.l, marquageMargins.t, { maxWidth: 30 })
        // Marquage Image
        let picture4 = appareil.modeleTousAppareils && appareil.modeleTousAppareils.photo_fabrication && 'data:image/jpeg;base64,' + appareil.modeleTousAppareils.photo_fabrication
        const marquageImageMargins = { l: marquageMargins.l + doc.getTextDimensions(marquage, { maxWidth: 30 }).w + 2, t: marquageMargins.t - doc.getTextDimensions(marquage).h }
        const marquageImageSize = { w: 35, h: 35 }
        doc.rect(marquageImageMargins.l, marquageImageMargins.t, marquageImageSize.w, marquageImageSize.h, "S")
        picture4 && doc.addImage(picture4, 'JPEG', marquageImageMargins.l + 1, marquageImageMargins.t + 1, marquageImageSize.w - 2, marquageImageSize.h - 2)
        // Acces k
        const accesk = appareil.modeleTousAppareils && appareil.modeleTousAppareils.manip_k ? appareil.modeleTousAppareils.manip_k : ""
        const acceskMargins = { l: marquageImageMargins.l + marquageImageSize.w + espacementLarge, t: marquageMargins.t }
        doc.text(accesk, acceskMargins.l, acceskMargins.t, { maxWidth: 30 })

        // Sous PDF
        // Image
        const sousPDFMargins = { l: marginLeft, t: marquageImageMargins.t + marquageImageSize.h + espacementLarge }
        const sousPDFSize = { w: ((pageWidth - (marginLeft * 2)) * 3.25 / 4) - espacementSmall, h: marginTop + 2 }
        doc.rect(sousPDFMargins.l, sousPDFMargins.t, sousPDFSize.w, sousPDFSize.h, "S")
        doc.addImage(souspdf, 'PNG', sousPDFMargins.l, sousPDFMargins.t, sousPDFSize.w, sousPDFSize.h)
        // Date Section
        const todaySectionMargins = { l: sousPDFMargins.l + sousPDFSize.w + espacementSmall, t: sousPDFMargins.t }
        const todaySectionSize = { w: pageWidth - (marginLeft * 2) - sousPDFSize.w - espacementSmall, h: (sousPDFSize.h / 2) - 1 }
        doc.rect(todaySectionMargins.l, todaySectionMargins.t, todaySectionSize.w, todaySectionSize.h, "S")
        // Date
        const today = formatDate(new Date())
        const todayMargins = { l: todaySectionMargins.l + (todaySectionSize.w / 2), t: todaySectionMargins.t + 4 }
        doc.text(today, todayMargins.l, todayMargins.t, "center")
        // Page Section
        const pageSectionMargins = { l: todaySectionMargins.l, t: todaySectionMargins.t + todaySectionSize.h + 1 }
        const pageSectionSize = { w: todaySectionSize.w, h: todaySectionSize.h }
        doc.rect(pageSectionMargins.l, pageSectionMargins.t, pageSectionSize.w, pageSectionSize.h, "S")
        // Page
        const page = "Page 1 / 2"
        const pageMargins = { l: todayMargins.l, t: todayMargins.t + doc.getTextDimensions(today).h + 2 }
        doc.text(page, pageMargins.l, pageMargins.t, "center")

        // 2e page
        doc.cellAddPage()

        /* Header de la page */
        // Logo
        doc.cell(marginLeft, marginTop, logoSize.w, logoSize.h, " ", 1, "center")
        doc.addImage(logo, 'PNG', marginLeft + 1, marginTop + 1, 8, 8)
        // Titre
        doc.setFontSize(defaultFontSize)
        doc.cell(marginLeft, marginTop, titreDimension.w + espacementLarge, 10, titre, 1, "center")
        // Infos Tax et App
        doc.setFontSize(8)
        doc.cell(marginLeft, marginTop, 20, 10, " ", 1, "center")
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2) - (20) - (titreDimension.w + espacementLarge) - logoSize.w, 10, "N° : Tax-F350\nCoMet App", 1, "center")

        /* SubHeader de la page */
        doc.setFontSize(defaultFontSize)
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2), logoSize.h, " ", 2, "center")
        // MarqueTitre
        doc.text(marqueTitre, marqueTitreMargins.l, marqueTitreMargins.t)
        // Marque
        doc.text(marque, marqueMargins.l, marqueMargins.t)
        // ModeleTitre
        doc.text(modeleTitre, modeleTitreMargins.l, modeleTitreMargins.t)
        // Modele
        doc.text(modele, modeleMargins.l, modeleMargins.t)
        // TypeTitre
        doc.text(typeTitre, typeTitreMargins.l, typeTitreMargins.t)
        // Type
        doc.text(type, typeMargins.l, typeMargins.t)

        /* Corps de la page */
        // Historique CETs
        doc.setFontSize(10)
        doc.setFont(undefined, undefined, 700)
        const historiqueTitre = "HISTORIQUE CETs"
        const historiqueTitreMargins = { l: (pageWidth / 2), t: marqueTitreMargins.t + doc.getTextDimensions(marqueTitre).h + espacementSmall }
        doc.setFillColor(220, 220, 220)
        doc.rect(marginLeft, historiqueTitreMargins.t - 4, pageWidth - (marginLeft * 2), doc.getTextDimensions(historiqueTitre).h + 1.5, "F")
        doc.text(historiqueTitre, historiqueTitreMargins.l, historiqueTitreMargins.t, "center")
        doc.setFontSize(defaultFontSize)
        doc.setFont(undefined, undefined, 400)

        let variables = {}
        let cetTitreNb = 1
        let modelesLogLength = modelesLog && modelesLog.length

        for (let i = 1; i <= modelesLogLength; i++) {
            if (i < 2) {
                // CET Titre
                variables["cet" + (cetTitreNb + i) + "Titre"] = "CET : "
                variables["cet" + (cetTitreNb + i) + "TitreMargins"] = { l: marqueTitreMargins.l - 2, t: generalTitreMargins.t + espacementSmall + 2 }
            } else {
                // CET Titre
                variables["cet" + (cetTitreNb + i) + "Titre"] = "CET : "
                variables["cet" + (cetTitreNb + i) + "TitreMargins"] = { l: marqueTitreMargins.l - 2, t: variables["historique" + i + "Margins"].t + doc.getTextDimensions(variables["historique" + i], { fontSize: 8, maxWidth: (pageWidth - (marginLeft * 2)) - variables["historique" + i + "TitreMargins"].l + doc.getTextDimensions(variables["historique" + i + "Titre"]).w + espacementExtraSmall }).h + espacementSmall }
            }
            // CET
            variables["cet" + (cetTitreNb + i)] = modelesLog[i - 1].dam_cet
            variables["cet" + (cetTitreNb + i) + "Margins"] = { l: variables["cet" + (cetTitreNb + i) + "TitreMargins"].l + doc.getTextDimensions(variables["cet" + (cetTitreNb + i) + "Titre"]).w + espacementExtraSmall, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Date CET
            variables["dateCet" + (cetTitreNb + i)] = formatDate(modelesLog[i - 1].acceptation)
            variables["dateCet" + (cetTitreNb + i) + "Margins"] = { l: variables["cet" + (cetTitreNb + i) + "Margins"].l + doc.getTextDimensions(variables["cet" + (cetTitreNb + i)]).w, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Logiciel Titre
            variables["logiciel" + (cetTitreNb + i) + "Titre"] = "Logiciel : "
            variables["logiciel" + (cetTitreNb + i) + "TitreMargins"] = { l: variables["dateCet" + (cetTitreNb + i) + "Margins"].l + doc.getTextDimensions(variables["dateCet" + (cetTitreNb + i)]).w + espacementSmall, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Logiciel
            variables["logiciel" + (cetTitreNb + i)] = modelesLog[i - 1].version_logiciel
            variables["logiciel" + (cetTitreNb + i) + "Margins"] = { l: variables["logiciel" + (cetTitreNb + i) + "TitreMargins"].l + doc.getTextDimensions(variables["logiciel" + (cetTitreNb + i) + "Titre"]).w + espacementExtraSmall, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Checksum Titre
            variables["checksum" + (cetTitreNb + i) + "Titre"] = "Checksum : "
            variables["checksum" + (cetTitreNb + i) + "TitreMargins"] = { l: variables["logiciel" + (cetTitreNb + i) + "Margins"].l + doc.getTextDimensions(variables["logiciel" + (cetTitreNb + i)]).w + espacementSmall, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Checksum
            variables["checksum" + (cetTitreNb + i)] = modelesLog[i - 1].signature
            variables["checksum" + (cetTitreNb + i) + "Margins"] = { l: variables["checksum" + (cetTitreNb + i) + "TitreMargins"].l + doc.getTextDimensions(variables["checksum" + (cetTitreNb + i) + "Titre"]).w + espacementExtraSmall, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t }
            // Historique Titre
            variables["historique" + (cetTitreNb + i) + "Titre"] = "Historique : "
            variables["historique" + (cetTitreNb + i) + "TitreMargins"] = { l: variables["cet" + (cetTitreNb + i) + "TitreMargins"].l, t: variables["cet" + (cetTitreNb + i) + "TitreMargins"].t + espacementSmall }
            // Historique
            variables["historique" + (cetTitreNb + i)] = modelesLog[i - 1].autres
            variables["historique" + (cetTitreNb + i) + "Margins"] = { l: variables["historique" + (cetTitreNb + i) + "TitreMargins"].l + doc.getTextDimensions(variables["historique" + (cetTitreNb + i) + "Titre"]).w + espacementExtraLarge, t: variables["historique" + (cetTitreNb + i) + "TitreMargins"].t }

            // CET Titre
            doc.text(variables["cet" + (cetTitreNb + i) + "Titre"], variables["cet" + (cetTitreNb + i) + "TitreMargins"].l, variables["cet" + (cetTitreNb + i) + "TitreMargins"].t)
            // CET
            doc.setFontSize(10)
            doc.text(variables["cet" + (cetTitreNb + i)], variables["cet" + (cetTitreNb + i) + "Margins"].l, variables["cet" + (cetTitreNb + i) + "Margins"].t)
            doc.setFontSize(defaultFontSize)
            // Date CET
            doc.setFontSize(defaultFontSize)
            doc.setFont(undefined, undefined, 700)
            doc.text(variables["dateCet" + (cetTitreNb + i)], variables["dateCet" + (cetTitreNb + i) + "Margins"].l, variables["dateCet" + (cetTitreNb + i) + "Margins"].t)
            doc.setFont(undefined, undefined, 400)
            // Logiciel Titre
            doc.text(variables["logiciel" + (cetTitreNb + i) + "Titre"], variables["logiciel" + (cetTitreNb + i) + "TitreMargins"].l, variables["logiciel" + (cetTitreNb + i) + "TitreMargins"].t)
            // Logiciel
            doc.text(variables["logiciel" + (cetTitreNb + i)], variables["logiciel" + (cetTitreNb + i) + "Margins"].l, variables["logiciel" + (cetTitreNb + i) + "Margins"].t)
            // Checksum Titre
            doc.text(variables["checksum" + (cetTitreNb + i) + "Titre"], variables["checksum" + (cetTitreNb + i) + "TitreMargins"].l, variables["checksum" + (cetTitreNb + i) + "TitreMargins"].t)
            // Checksum
            doc.text(variables["checksum" + (cetTitreNb + i)], variables["checksum" + (cetTitreNb + i) + "Margins"].l, variables["checksum" + (cetTitreNb + i) + "Margins"].t)
            // Historique Titre
            doc.text(variables["historique" + (cetTitreNb + i) + "Titre"], variables["historique" + (cetTitreNb + i) + "TitreMargins"].l, variables["historique" + (cetTitreNb + i) + "TitreMargins"].t)
            // Historique
            doc.setFontSize(8)
            doc.text(variables["historique" + (cetTitreNb + i)], variables["historique" + (cetTitreNb + i) + "Margins"].l, variables["historique" + (cetTitreNb + i) + "Margins"].t, { maxWidth: (pageWidth - (marginLeft * 2)) - variables["historique" + (cetTitreNb + i) + "TitreMargins"].l - doc.getTextDimensions(variables["historique" + (cetTitreNb + i) + "Titre"]).w - espacementExtraSmall })
            doc.setFontSize(defaultFontSize)
        }

        /* 2e corps de la page */
        // Montages autorisés
        if (montagesA && montagesA.length !== 0) {

            doc.setFontSize(10)
            doc.setFont(undefined, undefined, 700)
            const montagesTitre = "MONTAGES AUTORISES"
            const montagesTitreMargins = { l: (pageWidth / 2), t: variables["historique" + (cetTitreNb + modelesLogLength) + "Margins"].t + doc.getTextDimensions(variables["historique" + (cetTitreNb + modelesLogLength)]).h + espacementLarge }
            doc.setFillColor(220, 220, 220)
            doc.rect(marginLeft, montagesTitreMargins.t - 4, pageWidth - (marginLeft * 2), doc.getTextDimensions(montagesTitre).h + 1.5, "F")
            doc.text(montagesTitre, montagesTitreMargins.l, montagesTitreMargins.t, "center")
            doc.setFontSize(defaultFontSize)
            doc.setFont(undefined, undefined, 400)

            // Autorisation
            doc.setFontSize(9)
            const autorisationTitre = "Autorisation : "
            const autorisationTitreMargins = { l: marqueTitreMargins.l - 2, t: montagesTitreMargins.t + doc.getTextDimensions(montagesTitre).h + espacementSmall }
            doc.text(autorisationTitre, autorisationTitreMargins.l, autorisationTitreMargins.t)

            // Type
            const typeMaterielTitre = "Type : "
            const typeMaterielTitreMargins = { l: autorisationTitreMargins.l + doc.getTextDimensions(autorisationTitre).w + espacementExtraLarge, t: autorisationTitreMargins.t }
            doc.text(typeMaterielTitre, typeMaterielTitreMargins.l, typeMaterielTitreMargins.t)

            // Marque / Modele
            const marqueModeleTitre = "Marque / modèle : "
            const marqueModeleTitreMargins = { l: (pageWidth * 2.25 / 4), t: autorisationTitreMargins.t }
            doc.text(marqueModeleTitre, marqueModeleTitreMargins.l, marqueModeleTitreMargins.t)

            let montages = {}
            let montagesLength = montagesA && montagesA.length
            for (let a = 1; a <= montagesLength; a++) {
                if (a < 2) {
                    // Autorisation
                    montages["autorisation" + a] = formatDate(montagesA[a - 1].i.date_fin)
                    montages["autorisation" + a + "Margins"] = { l: autorisationTitreMargins.l, t: autorisationTitreMargins.t + doc.getTextDimensions(autorisationTitre).h + espacementSmall }
                } else {
                    // Autorisation
                    montages["autorisation" + a] = formatDate(montagesA[a - 1].i.date_fin)
                    montages["autorisation" + a + "Margins"] = { l: autorisationTitreMargins.l, t: montages["autorisation" + (a - 1) + "Margins"].t + doc.getTextDimensions(montages["autorisation" + (a - 1)]).h + 3 }
                }

                // Type
                montages["typeMateriel" + a] = montagesA[a - 1].m.typeTousAppareils.nom_type
                montages["typeMateriel" + a + "Margins"] = { l: typeMaterielTitreMargins.l, t: montages["autorisation" + a + "Margins"].t }

                // Marque / Modele
                montages["marqueModele" + a] = montagesA[a - 1].m.marque_nom_appareil + " " + montagesA[a - 1].m.modele_nom_appareil
                montages["marqueModele" + a + "Margins"] = { l: marqueModeleTitreMargins.l, t: montages["autorisation" + a + "Margins"].t }

                doc.text(montages["autorisation" + a], montages["autorisation" + a + "Margins"].l, montages["autorisation" + a + "Margins"].t)
                doc.text(montages["typeMateriel" + a], montages["typeMateriel" + a + "Margins"].l, montages["typeMateriel" + a + "Margins"].t)
                doc.text(montages["marqueModele" + a], montages["marqueModele" + a + "Margins"].l, montages["marqueModele" + a + "Margins"].t)
            }
        }

        // Sous PDF
        // Image
        const sousPDF2Size = { w: ((pageWidth - (marginLeft * 2)) * 3.25 / 4) - espacementSmall, h: marginTop + 2 }
        const sousPDF2Margins = { l: marginLeft, t: pageHeight - marginTop - sousPDF2Size.h }
        doc.rect(sousPDF2Margins.l, sousPDF2Margins.t, sousPDF2Size.w, sousPDF2Size.h, "S")
        doc.addImage(souspdf, 'PNG', sousPDF2Margins.l, sousPDF2Margins.t, sousPDF2Size.w, sousPDF2Size.h)
        // Date Section
        const todaySection2Margins = { l: sousPDF2Margins.l + sousPDF2Size.w + espacementSmall, t: sousPDF2Margins.t }
        const todaySection2Size = { w: pageWidth - (marginLeft * 2) - sousPDF2Size.w - espacementSmall, h: (sousPDF2Size.h / 2) - 1 }
        doc.rect(todaySection2Margins.l, todaySection2Margins.t, todaySection2Size.w, todaySection2Size.h, "S")
        // Date
        const today2 = today
        const today2Margins = { l: todaySection2Margins.l + (todaySection2Size.w / 2), t: todaySection2Margins.t + 4 }
        doc.text(today2, today2Margins.l, today2Margins.t, "center")
        // Page Section
        const pageSection2Margins = { l: todaySection2Margins.l, t: todaySection2Margins.t + todaySection2Size.h + 1 }
        const pageSection2Size = { w: todaySection2Size.w, h: todaySection2Size.h }
        doc.rect(pageSection2Margins.l, pageSection2Margins.t, pageSection2Size.w, pageSection2Size.h, "S")
        // Page
        const page2 = "Page 2 / 2"
        const page2Margins = { l: today2Margins.l, t: today2Margins.t + doc.getTextDimensions(today2).h + 2 }
        doc.text(page2, page2Margins.l, page2Margins.t, "center")

        doc.save("test.pdf")
    }

    const handleChangeEtat = async (e) => {
        // Marque selectionne
        let etat = parseInt(e.target.value)
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)
        let l_materiel = await storage.get('materiel')

        let formAppareilData = newSet.get(type)
        formAppareilData.etat = etat

        // Mise a jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)
        l_materiel[indexMat].etat = etat

        newSet.set(type, formAppareilData)
        props.setFieldValue("set", newSet)

        // Mise a jour de l'etat global
        await storage.set('liaisons', Array.from(newSet.entries()))
        setFormMateriel(l_materiel)
        await storage.set('materiel', l_materiel)
    }

    const handleChangeVLogiciel = async (e, setFieldValue) => {
        // Amovible selectionné
        let valeur = e.target.value
        // Map des values de la form
        let newTaximetre = props.values.taximetre
        newTaximetre[e.target.name] = valeur
        setFieldValue("taximetre", newTaximetre)
        await storage.set("taximetre", newTaximetre)
    }

    const handleChangeCheckboxScellement = async (e, setFieldValue) => {
        let name = e.target.name
        let checked = e.target.checked
        // Type de l'appareil
        let type = props.data.numero_type_interne
        // Map des values de la form
        let newSet = new Map(props.values.set)
        let l_materiel = await storage.get('materiel')
        let formAppareilData = newSet.get(type)

        // Mise a jour de l'etat global
        const indexMat = l_materiel.findIndex(m => m.numero_type_interne === type)

        if (checked === false) {
            formAppareilData[name] = ""
            l_materiel[indexMat][name] = ""
        }
        else {
            formAppareilData[name] = formAppareilData.modeleTousAppareils[name]
            l_materiel[indexMat][name] = l_materiel[indexMat].modeleTousAppareils[name]
        }

        newSet.set(type, formAppareilData)
        setFieldValue("set", newSet)

        //console.log(l_materiel)

        // Mise a jour de l'etat global
        await storage.set('liaisons', Array.from(newSet.entries()))
        setFormMateriel(l_materiel)
        await storage.set('materiel', l_materiel)
    }

    const controle_autres = () => {
        let currentAppareil = props.values.set.get(props.data.numero_type_interne)
        let currentTaximetre = props.values.taximetre
        let scellements_ok = true

        if (currentAppareil.modeleTousAppareils && currentAppareil.modeleTousAppareils.marque_1 !== "") {
            for (var i = 1; i <= 5; i++) {
                if ((currentAppareil.modeleTousAppareils["marque_" + i] !== "" && currentAppareil.modeleTousAppareils["marque_" + i] !== null) && currentAppareil["marque_" + i] === "" && currentAppareil["marque_" + i + "_optionnel"] !== true) {
                    scellements_ok = false
                }
            }
        }

        if ((currentAppareil.marque_appareil !== -1 && currentAppareil.marque_appareil !== 0 && currentAppareil.modele_appareil !== -1 && currentAppareil.modele_appareil !== 0)
            && (currentAppareil.numero_type_interne !== 2 || (currentTaximetre.couleur_lumineux !== -1 && currentTaximetre.couleur_lumineux !== -2))
            && (currentAppareil.numero_type_interne !== 2 || (currentTaximetre.c_autoblocage !== 0 && ((currentTaximetre.c_ampoules !== 0 && (currentAppareil.modeleTousAppareils.ampoules !== "" || currentAppareil.modeleTousAppareils.ampoules !== " ")) || (currentTaximetre.c_ampoules === 0 && (currentAppareil.modeleTousAppareils.ampoules === "" && currentAppareil.modeleTousAppareils.ampoules === " ")))))
            && ((currentAppareil.emplacement1 !== 0 && currentAppareil.emplacement1 !== null) || (currentAppareil.emplacement2 !== 0 && currentAppareil.emplacement2 !== null) || (currentAppareil.emplacement3 !== 0 && currentAppareil.emplacement3 !== null) || (currentAppareil.emplacement4 !== 0 && currentAppareil.emplacement4 !== null))
            && (scellements_ok === true)
            && (parseInt(currentTaximetre.logiciel_afficheur) !== 0)
            && (currentAppareil.etat !== 0)
            && (currentAppareil.numero_type_interne === 1 || props.data.numero_type_interne === 10 || modelesLog.length === 0 || currentAppareil.cet !== "")) {
            setIsControleOk(true)
        }
        else {
            setIsControleOk(false)
        }
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title + " : Détail du matériel"}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className={"popup-body" + (isControleOk ? " isok" : "")}>
                    {/* Marque du Taximètre */}
                    <div className="form-group">
                        <Field className="form-control"
                            name="marque_appareil"
                            value={props.values.set.get(props.data.numero_type_interne).marque_appareil}
                            as="select"
                            disabled={props.data.numero_type_interne === 1 ? true : false}
                            onChange={e => handleChangeMarque(e, props.setFieldValue)}
                        >
                            <option value="0"></option>
                            {marques && marques.map((m, n) =>
                                <option key={n} value={m.marque_appareil}>{m.marque_nom_appareil}</option>
                            )}
                        </Field>
                    </div>
                    {/* Modele du Taximètre */}
                    <div className="form-group">
                        <Field
                            className="form-control input-infos"
                            name="modele_appareil"
                            value={props.values.set.get(props.data.numero_type_interne).modele_appareil}
                            as="select"
                            disabled={props.data.numero_type_interne === 1 ? true : false}
                            onChange={e => handleChangeModele(e, props.setFieldValue)}
                        >
                            <option value="0"></option>
                            {modeles && modeles.map((m, n) =>
                                <option key={n} value={m.modele_appareil}>{m.modele_nom_appareil}</option>
                            )}
                        </Field>
                    </div>
                    {/* CET */}
                    <div className="form-group">
                        <label htmlFor="cet">CET</label>
                        <Field
                            className="form-control input-infos"
                            name="cet"
                            value={props.data.numero_cet}
                            as="select"
                            disabled={(props.data.numero_type_interne === 1 || props.data.numero_type_interne === 10) ? true : false}
                            onChange={e => handleChangeCET(e, props.setFieldValue)}
                        >
                            <option value="0"></option>
                            {modelesLog && modelesLog.map((m, n) =>
                                <option value={m.clef_logiciel} key={n}>{m.dam_cet + " : " + formatDate(m.acceptation)}</option>
                            )}
                        </Field>
                        {revisionFile && revisionFile.fichier_contenu &&
                            <div className="form-cell-input">
                                <div className="form-input">
                                    <div className="popup-action-open">
                                        <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, revisionFile)}>
                                            <span>Ouvrir</span>
                                            <i className="fas fa-eye"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* Révision */}
                    <div className="form-group">
                        <label htmlFor="cet">Révision</label>
                        <Field
                            className="form-control"
                            name="details"
                            value={revision}
                            as="textarea"
                            disabled
                        />
                    </div>
                    {/* Numéro de Série */}
                    <div className="form-group">
                        <label htmlFor="cet" className='font-weight-bold'>N° Série</label>
                        <Field
                            className="form-control"
                            name="serie_appareil"
                            value={props.values.set.get(props.data.numero_type_interne).serie_appareil}
                            type="text"
                            disabled={props.data.numero_type_interne === 1 ? true : false}
                            onChange={e => handleChangeSerie(e, props.setFieldValue)}
                        />
                    </div>
                    {/* Option V.Logiciel */}
                    {props.data.numero_type_interne === 10 &&
                        <div className="form-group">
                            <label htmlFor="logiciel_afficheur">V. Logiciel</label>
                            <Field
                                className="form-control input-infos"
                                name="logiciel_afficheur"
                                value={props.values.taximetre.logiciel_afficheur}
                                as="select"
                                onChange={e => handleChangeVLogiciel(e, props.setFieldValue)}
                            >
                                <option value="0"></option>
                                {vlogiciels && vlogiciels.map((m, n) =>
                                    <option key={n} value={(!m.version_logiciel ? "" : "Log : " + m.version_logiciel) + (m.signature ? " Signature : " + m.signature : "") + " (" + formatDate(m.acceptation) + " N° : " + m.dam_cet + ")"}>{(!m.version_logiciel ? "" : "Log : " + m.version_logiciel + " ") + (m.signature ? "Signature : " + m.signature : "") + " (" + formatDate(m.acceptation) + " N° : " + m.dam_cet + ")"}</option>
                                )}
                            </Field>
                        </div>}
                    {/* Options supplémentaire Lumineux */}
                    {props.data.numero_type_interne === 2 &&
                        <div className="form-group">
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} onClick={() => setIsOpen(!isOpen)} className="comet-collapse" eventKey="0">
                                        <label>Options Lumineux</label>

                                        <Button
                                            onClick={() => setIsOpen(!isOpen)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={isOpen}
                                        >
                                            {isOpen === false ?
                                                <i className="fas fa-arrow-down"></i> :
                                                <i className="fas fa-arrow-up"></i>
                                            }
                                        </Button>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className="form-group checkbox-group">

                                                <label htmlFor="cet" className="mr-4">Couleur</label>
                                                <Field
                                                    className="form-control input-infos"
                                                    name="couleur_lumineux"
                                                    value={props.values.taximetre.couleur_lumineux}
                                                    as="select"
                                                    disabled={props.data.numero_type_interne === 1 ? true : false}
                                                    onChange={e => handleChangeCouleur(e, props.setFieldValue)}
                                                >
                                                    <option value="-2"></option>
                                                    {couleurs && couleurs.map((c, n) =>
                                                        <option value={c.numero_couleur} key={n}>{capitalizeFirstLetter(c.nom_couleur)}</option>
                                                    )}
                                                </Field>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    }
                    <div className="form-group d-flex">
                        <Field className="form-control input-small mr-4 m-w-50" type="text" name="scellement" value={scellement} onChange={handleChangeScellement} placeholder="Saisir scellement..." />
                        <button className="btn cbtn-primary c-text-normal" type="button" onClick={e => addScellement(e, props.setFieldValue)}>Ajouter scellement</button>
                    </div>
                    <Table striped hover size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Situation</th>
                                <th>N° Stick</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.values.set.get(props.data.numero_type_interne)
                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                && (props.values.set.get(props.data.numero_type_interne).marque_1 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_1) &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_1"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_1 !== "" /*props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_1*/ ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).marque_1 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_1}</td>
                                    <td></td>
                                    <td>{!props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_1_optionnel ? "Obligatoire" : "Optionnel"}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne)
                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                && (props.values.set.get(props.data.numero_type_interne).marque_2 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_2) &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_2"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_2 !== "" /*props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_2*/ ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_2 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_2}</td>
                                    <td></td>
                                    <td>{!props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_2_optionnel ? "Obligatoire" : "Optionnel"}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne)
                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                && (props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_3 || props.values.set.get(props.data.numero_type_interne).marque_3) &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_3"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_3 !== "" ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{(props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_3 || props.values.set.get(props.data.numero_type_interne).marque_3)}</td>
                                    <td></td>
                                    <td>{!props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_3_optionnel ? "Obligatoire" : "Optionnel"}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne)
                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                && (props.values.set.get(props.data.numero_type_interne).marque_4 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_4) &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_4"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_4 !== "" /*props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_4*/ ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).marque_4 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_4}</td>
                                    <td></td>
                                    <td>{!props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_4_optionnel ? "Obligatoire" : "Optionnel"}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne)
                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                && (props.values.set.get(props.data.numero_type_interne).marque_5 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_5) &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_5"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_5 !== "" /*props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_5*/ ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).marque_5 || props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_5}</td>
                                    <td></td>
                                    <td>{!props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_5_optionnel ? "Obligatoire" : "Optionnel"}</td>
                                </tr>
                            }
                            {/* Optionnel */}
                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_1 &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_option_1"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_option_1 === props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_1 ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_1}</td>
                                    <td></td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_1 ? "Optionnel" : undefined}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_2 &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_option_2"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_option_2 === props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_2 ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_2}</td>
                                    <td></td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_2 ? "Optionnel" : undefined}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_3 &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_option_3"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_option_3 === props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_3 ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_3}</td>
                                    <td></td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_3 ? "Optionnel" : undefined}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_4 &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_option_4"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_option_4 === props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_4 ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_4}</td>
                                    <td></td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_4 ? "Optionnel" : undefined}</td>
                                </tr>
                            }
                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_5 &&
                                <tr>
                                    <td>
                                        <Checkbox
                                            name="marque_option_5"
                                            checked={props.values.set.get(props.data.numero_type_interne).marque_option_5 === props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_5 ? true : false}
                                            onChange={e => handleChangeCheckboxScellement(e, props.setFieldValue)}
                                            inputProps={{
                                                'aria-label': 'primary checkbox'
                                            }} />
                                    </td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_5}</td>
                                    <td></td>
                                    <td>{props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.marque_option_5 ? "Optionnel" : undefined}</td>
                                </tr>
                            }
                            {/* Evite d'avoir l'erreur React validateDomNesting */}
                            <tr></tr>
                        </tbody>
                    </Table>
                    {/* Details refus */}
                    {props.data.numero_type_interne !== 2 &&
                        <div className="form-group">
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} onClick={() => setIsOpen(!isOpen)} className="comet-collapse" eventKey="0">
                                        <label>Détails refus</label>
                                        <Button
                                            onClick={() => setIsOpen(!isOpen)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={isOpen}
                                        >
                                            {isOpen === false ?
                                                <i className="fas fa-arrow-down"></i> :
                                                <i className="fas fa-arrow-up"></i>
                                            }
                                        </Button>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            checked={props.values.taximetre.c_r30}
                                                            onChange={e => {
                                                                props.values.taximetre.r30 = ""
                                                                props.values.taximetre.c_r30 = !props.values.taximetre.c_r30
                                                                props.setValues(props.values)
                                                            }}
                                                            name="c_r30"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            R30 - Autre motif :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <Field className="form-control" type="text" name="taximetre.r30" disabled={props.values.taximetre.c_r30 ? undefined : true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            checked={props.values.taximetre.c_r31}
                                                            onChange={e => {
                                                                props.values.taximetre.r31 = ""
                                                                props.values.taximetre.c_r31 = !props.values.taximetre.c_r31
                                                                props.setValues(props.values)
                                                            }}
                                                            name="c_r31"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            R31 - Indication :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <Field className="form-control" type="text" name="taximetre.r31" disabled={props.values.taximetre.c_r31 ? undefined : true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            checked={props.values.taximetre.c_r35}
                                                            onChange={e => {
                                                                props.values.taximetre.r35 = ""
                                                                props.values.taximetre.c_r35 = !props.values.taximetre.c_r35
                                                                props.setValues(props.values)
                                                            }}
                                                            name="c_r35"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            R35 - Défaut scellement (ou différent du carnet) :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <Field className="form-control" type="text" name="taximetre.r35" disabled={props.values.taximetre.c_r35 ? undefined : true} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row m-m-0">
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            checked={props.values.taximetre.c_r38}
                                                            onChange={e => {
                                                                props.values.taximetre.r38 = ""
                                                                props.values.taximetre.c_r38 = !props.values.taximetre.c_r38
                                                                props.setValues(props.values)
                                                            }}
                                                            name="c_r38"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            R38 - Non approuvé / non vérifié :
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input m-w-100">
                                                    <div className="form-input">
                                                        <Field className="form-control" type="text" name="taximetre.r38" disabled={props.values.taximetre.c_r38 ? undefined : true} />
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    }
                    {props.data.numero_type_interne === 2 &&
                        <div className="form-group">

                            <Tabs defaultActiveKey="1" tabPosition={'top'} style={{ height: 220, width: '100%' }}>
                                <TabPane tab={'Controle'} key={1}>
                                    <>
                                        {/* Autoblocage Taximetre */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Autoblocage Taximètre
                                                    </div>
                                                    <div className="indication-details">
                                                        <span className="details-item">
                                                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                                                && (props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.lumineux_autoblocage !== "" &&
                                                                    !isNullOrUndefined(props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.lumineux_autoblocage)) ?
                                                                props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.lumineux_autoblocage :
                                                                "Pas d'indication"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_autoblocage"
                                                                id="c_autoblocage-1"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_autoblocage', 1)}
                                                                checked={props.values.taximetre.c_autoblocage === 1 ? true : false} />
                                                            <label htmlFor="c_autoblocage-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                OK
                                                            </label>
                                                        </div>
                                                    </li>
                                                    {interventionInfoGeneral.que_vp === false &&
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="c_autoblocage"
                                                                    id="c_autoblocage-4"
                                                                    className="sr-only"
                                                                    onChange={() => props.setFieldValue('taximetre.c_autoblocage', 3)}
                                                                    checked={props.values.taximetre.c_autoblocage === 3 ? true : false} />
                                                                <label htmlFor="c_autoblocage-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Intervention />
                                                                    I17
                                                                </label>
                                                            </div>
                                                        </li>
                                                    }
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_autoblocage"
                                                                id="c_autoblocage-2"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_autoblocage', 2)}
                                                                checked={props.values.taximetre.c_autoblocage === 2 ? true : false} />
                                                            <label htmlFor="c_autoblocage-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R17
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_autoblocage"
                                                                id="c_autoblocage-3"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_autoblocage', 0)}
                                                                checked={props.values.taximetre.c_autoblocage === 0 ? true : false} />
                                                            <label htmlFor="c_autoblocage-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Ampoules */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Ampoules
                                                    </div>
                                                    <div className="indication-details">
                                                        <span className="details-item">
                                                            A FAIRE SUR TOUTES :
                                                            {props.values.set.get(props.data.numero_type_interne).modeleTousAppareils
                                                                && props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.ampoules !== "" &&
                                                                !isNullOrUndefined(props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.lumineux_autoblocage) ?
                                                                " " + props.values.set.get(props.data.numero_type_interne).modeleTousAppareils.ampoules :
                                                                " Pas d'indication"
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ampoules"
                                                                id="c_ampoules-1"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_ampoules', 1)}
                                                                checked={props.values.taximetre.c_ampoules === 1 ? true : false} />
                                                            <label htmlFor="c_ampoules-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                OK
                                                            </label>
                                                        </div>
                                                    </li>
                                                    {interventionInfoGeneral.que_vp === false &&
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="c_ampoules"
                                                                    id="c_ampoules-4"
                                                                    className="sr-only"
                                                                    onChange={() => props.setFieldValue('taximetre.c_ampoules', 3)}
                                                                    checked={props.values.taximetre.c_ampoules === 3 ? true : false} />
                                                                <label htmlFor="c_ampoules-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Intervention />
                                                                    I49
                                                                </label>
                                                            </div>
                                                        </li>
                                                    }
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ampoules"
                                                                id="c_ampoules-2"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_ampoules', 2)}
                                                                checked={props.values.taximetre.c_ampoules === 2 ? true : false} />
                                                            <label htmlFor="c_ampoules-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R49
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ampoules"
                                                                id="c_ampoules-3"
                                                                className="sr-only"
                                                                onChange={() => props.setFieldValue('taximetre.c_ampoules', 0)}
                                                                checked={props.values.taximetre.c_ampoules === 0 ? true : false} />
                                                            <label htmlFor="c_ampoules-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                </TabPane>
                                <TabPane tab={'Refus'} key={2}>

                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r40}
                                                    onChange={e => {
                                                        props.values.taximetre.r40 = ""
                                                        props.values.taximetre.c_r40 = !props.values.taximetre.c_r40
                                                        props.setValues(props.values)
                                                    }}
                                                    name="c_r40"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R40 - Lumineux :
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field className="form-control" type="text" name="taximetre.r40" disabled={props.values.taximetre.c_r40 ? undefined : true} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r41}
                                                    onChange={e => { props.setFieldValue('taximetre.c_r41', !props.values.taximetre.c_r41) }}
                                                    name="c_r41"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R41 - Indication / Agrément Lumineux :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r42}
                                                    onChange={e => { props.setFieldValue('taximetre.c_r42', !props.values.taximetre.c_r42) }}
                                                    name="c_r42"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R42 - Traditionnel en débrochable :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r45}
                                                    onChange={e => { props.setFieldValue('taximetre.c_r45', !props.values.taximetre.c_r45) }}
                                                    name="c_r45"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R45 - défaut de scellement :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r46}
                                                    onChange={e => { props.setFieldValue('taximetre.c_r46', !props.values.taximetre.c_r46) }}
                                                    name="c_r46"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R46 - défaut de scellement constructeur (amovible) :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input checkbox-container">
                                            <div className="form-input">
                                                <Checkbox
                                                    checked={props.values.taximetre.c_r47}
                                                    onChange={e => { props.setFieldValue('taximetre.c_r47', !props.values.taximetre.c_r47) }}
                                                    name="c_r47"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    R47 - Lumineux de couleur sans autorisation :
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </div>
                    }
                    {/* Conformité appareil */}
                    <div className="form-row">
                        <div className="form-cell-label">
                            <div className="form-text">
                                <div className="indication-title">
                                    Conformité appareil
                                </div>
                            </div>
                        </div>
                        <div className="form-cell-input">
                            <ul>
                                <li>
                                    <div className="input-radio-item">
                                        <Field
                                            type="radio"
                                            name="etat"
                                            id="etat-1"
                                            className="sr-only"
                                            onChange={handleChangeEtat}
                                            value="1"
                                            checked={props.values.set.get(props.data.numero_type_interne).etat === 1 ? true : false} />
                                        <label htmlFor="etat-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                            <OK />
                                            OK
                                        </label>
                                    </div>
                                </li>
                                {interventionInfoGeneral.que_vp === false &&
                                    <li>
                                        <div className="input-radio-item">
                                            <Field
                                                type="radio"
                                                name="etat"
                                                id="etat-4"
                                                className="sr-only"
                                                onChange={handleChangeEtat}
                                                value="3"
                                                checked={props.values.set.get(props.data.numero_type_interne).etat === 3 ? true : false} />
                                            <label htmlFor="etat-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Intervention />
                                                I33
                                            </label>
                                        </div>
                                    </li>
                                }
                                <li>
                                    <div className="input-radio-item">
                                        <Field
                                            type="radio"
                                            name="etat"
                                            id="etat-2"
                                            className="sr-only"
                                            onChange={handleChangeEtat}
                                            value="2"
                                            checked={props.values.set.get(props.data.numero_type_interne).etat === 2 ? true : false} />
                                        <label htmlFor="etat-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                            <Erreur />
                                            R33
                                        </label>
                                    </div>
                                </li>
                                <li>
                                    <div className="input-radio-item">
                                        <Field
                                            type="radio"
                                            name="etat"
                                            id="etat-3"
                                            className="sr-only"
                                            onChange={handleChangeEtat}
                                            value="0"
                                            checked={props.values.set.get(props.data.numero_type_interne).etat === 0 ? true : false} />
                                        <label htmlFor="etat-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                            <SO />
                                            SO
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="button-document-container cw-150">
                        <div className="rapport-sup-button">
                            <button type="button" className="btn btn-document" disabled={props.data.modeleTousAppareils ? false : true} onClick={createPDF}>
                                <span>Récap</span>
                                <i className="fas fa-eye"></i>
                            </button>
                        </div>
                    </div>
                    <div className="canva">
                        <div className="canva-container m-canva-container">
                            <canvas
                                id="canvas1"
                                className="App-canvas App-canvas1"
                                ref={canvasRef}
                                onClick={handleCanvasClick}>
                            </canvas>
                        </div>
                        <div className="footer-canva mb-4">
                            <div className="button">
                                <button onClick={handleClearCanvas} type="button" className="comet-btn comet-btn-danger">Effacer</button>
                            </div>
                            <label className={'m-0' + ((props.data.emplacement1 !== 0 && props.data.emplacement1 !== null) || (props.data.emplacement2 !== 0 && props.data.emplacement2 !== null) ? " text-success" : " text-warning")}>
                                {(props.data.emplacement1 !== 0 && props.data.emplacement1 !== null) || (props.data.emplacement2 !== 0 && props.data.emplacement2 !== null) ? "Posé" : "Pas de pose"}
                            </label>
                        </div>
                    </div>
                    <div className="canva">
                        <div className="canva-container m-canva-container">
                            <canvas
                                id="canvas2"
                                className="App-canvas App-canvas2"
                                onClick={handleCanvasClick2}>
                            </canvas>
                        </div>
                        <div className="footer-canva">
                            <div className="button">
                                <button onClick={handleClearCanvas2} type="button" className="comet-btn comet-btn-danger">Effacer</button>
                            </div>
                            <label className={'m-0' + ((props.data.emplacement3 !== 0 && props.data.emplacement3 !== null) || (props.data.emplacement4 !== 0 && props.data.emplacement4 !== null) ? " text-success" : " text-warning")}>
                                {(props.data.emplacement3 !== 0 && props.data.emplacement3 !== null) || (props.data.emplacement4 !== 0 && props.data.emplacement4 !== null) ? "Posé" : "Pas de pose"}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailAutresPopup