import React, { useState } from 'react'
import { Field } from 'formik'
import { IoMdClose } from "react-icons/io"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getImageMateriel, getNomMateriel } from '../../helpers/utils'
import { useSaisieContext } from '../Intervention/Saisie/SaisieContext'
import { storage } from '../../helpers/storageHelper'

const LiaisonPopup = (props) => {

    const [appareils] = useState(props.data)
    const [appareilsLiables1, setAppareilsLiables1] = useState([])
    const [appareilsLiables2, setAppareilsLiables2] = useState([])
    const [showSecondForm, setShowSecondForm] = useState(false)
    const [checkedIndex1, setCheckedIndex1] = useState(0)
    const [checkedIndex2, setCheckedIndex2] = useState(0)
    const [checkedIndex3, setCheckedIndex3] = useState(0)
    const [showThirdForm, setShowThirdForm] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const { setFormVerif } = useSaisieContext()

    // 1er formulaire
    const handleChange1 = (e, setFieldValue) => {
        // Matériel selectionné
        let materiel = parseInt(e.target.value)
        // Liaisons existants
        const { liaisons } = props.values
        // Taximètre
        if (materiel === 1) {
            setAppareilsLiables1(appareils.filter(appareil => (
                ((liaisons.c_liaisons7 === 0 || (appareils.filter(a => a === 6).length !== 0 && liaisons.c_liaisons9 === 0)) ? (appareil === 2) : undefined) ||
                (liaisons.c_liaisons4 === 0 ? (appareil === 3) : undefined) ||
                (liaisons.c_liaisons9 === 0 ? appareil === 6 : undefined) ||
                (liaisons.c_liaisons11 === 0 ? appareil === 7 : undefined) ||
                (liaisons.c_liaisons10 === 0 ? appareil === 10 : undefined) ||
                (liaisons.c_liaisons15 === 0 ? appareil === 13 : undefined) ||
                (liaisons.c_liaisons13 === 0 ? appareil === 20 : undefined) ||
                (liaisons.c_liaisons1 === 0 ? appareil === 21 : undefined)
            )))
        }
        // Lumineux
        else if (materiel === 2) {
            setAppareilsLiables1(appareils.filter(appareil => (
                ((liaisons.c_liaisons7 === 0 || (appareils.filter(a => a === 6).length !== 0 && liaisons.c_liaisons9 === 0)) ? appareil === 1 : undefined) ||
                (((appareils.filter(a => a === 6).length !== 0 && liaisons.c_liaisons3 === 0) || liaisons.c_liaisons6 === 0) ? appareil === 3 : undefined) ||
                (((appareils.filter(a => a === 3).length !== 0 && liaisons.c_liaisons3 === 0) || (appareils.filter(a => a === 1).length !== 0 && liaisons.c_liaisons9 === 0)) ? appareil === 6 : undefined) ||
                (liaisons.c_liaisons12 === 0 || (appareils.filter(a => a === 6).length !== 0 && liaisons.c_liaisons20 === 0) ? appareil === 13 : undefined)
            )))
        }
        // Boîtier
        else if (materiel === 3) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons4 === 0 ? appareil === 1 : undefined) ||
                ((liaisons.c_liaisons14 === 0 || liaisons.c_liaisons6 === 0) ? appareil === 2 : undefined) ||
                (liaisons.c_liaisons3 === 0 ? appareil === 6 : undefined) ||
                (liaisons.c_liaisons17 === 0 ? appareil === 7 : undefined) ||
                (liaisons.c_liaisons16 === 0 ? appareil === 13 : undefined) ||
                (liaisons.c_liaisons5 === 0 ? appareil === 20 : undefined) ||
                (liaisons.c_liaisons2 === 0 ? appareil === 21 : undefined)
            )))
        }
        // Boîte à eau
        else if (materiel === 6) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons9 === 0 ? appareil === 1 : undefined) ||
                (liaisons.c_liaisons9 === 0 || (liaisons.c_liaisons20 === 0 && appareils.includes(13)) ? appareil === 2 : undefined) ||
                (liaisons.c_liaisons3 === 0 ? appareil === 3 : undefined)
            )))
        }
        // Imprimante
        else if (materiel === 7) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons11 === 0 ? appareil === 1 : undefined) ||
                (liaisons.c_liaisons17 === 0 ? appareil === 3 : undefined) ||
                (liaisons.c_liaisons18 === 0 ? appareil === 20 : undefined)
            )))
        }
        // Afficheur
        else if (materiel === 10) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons10 === 0 ? appareil === 1 : undefined)
            )))
        }
        // Boîtier Lumineux
        else if (materiel === 13) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons15 === 0 ? appareil === 1 : undefined) ||
                (liaisons.c_liaisons14 === 0 || (liaisons.c_liaisons20 === 0 && appareils.includes(6)) ? appareil === 2 : undefined) ||
                (liaisons.c_liaisons16 === 0 ? appareil === 3 : undefined) ||
                (liaisons.c_liaisons19 === 0 ? appareil === 20 : undefined)
            )))
        }
        // Batterie
        else if (materiel === 20) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons13 === 0 ? appareil === 1 : undefined) ||
                (liaisons.c_liaisons5 === 0 ? appareil === 3 : undefined) ||
                (liaisons.c_liaisons18 === 0 ? appareil === 7 : undefined) ||
                (liaisons.c_liaisons19 === 0 ? appareil === 13 : undefined)
            )))
        }
        // Capteur/fil
        else if (materiel === 21) {
            setAppareilsLiables1(appareils.filter(appareil => (
                (liaisons.c_liaisons1 === 0 ? appareil === 1 : undefined) ||
                (liaisons.c_liaisons2 === 0 ? appareil === 3 : undefined)
            )))
        }
        else {
            setAppareilsLiables2([])
            setShowThirdForm(false)
        }
        setShowSecondForm(true)
        // Mise à jour du formulaire avec le matériel selectionné
        setCheckedIndex1(materiel)
        // Mise à jour de l'état du formulaire avec le matériel selectionné
        setFieldValue("appareil1", materiel)
        // Réinitialisation de la valeur du 2e et 3e formulaire
        setCheckedIndex2(0)
        setCheckedIndex3(0)
        // Réinitialisation dans l'état du 2e et 3e formulaire
        setFieldValue("appareil2", 0)
        setFieldValue("appareil3", 0)
        // Réinitialisation du tableau d'appareils liables 3
        setAppareilsLiables2([])
        setDisabled(true)
    }

    // 2eme formulaire
    const handleChange2 = (e, setFieldValue) => {
        // Materiel sélectionné
        let materiel = parseInt(e.target.value)
        // Liaisons existants
        const { liaisons } = props.values
        // ---------- Liaisons à 3 possible entre un taximètre, un lumineux et une boîte à eau
        // ---------- ou un Lumineux, un Boîtier et une Boîte à eau
        // Taximètre ou Lumineux selectionné en premier
        if (checkedIndex1 === 1 || checkedIndex1 === 2) {
            if (materiel === (checkedIndex1 === 1 ? 2 : 1) && (appareils.filter(appareil => appareil === 6)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil =>
                    liaisons.c_liaisons9 === 0 ? appareil === 6 : undefined
                ))
                setShowThirdForm(true)
                liaisons.c_liaisons7 === 0 ? setDisabled(false) : setDisabled(true)
            }
            else if (materiel === 6 && (appareils.filter(appareil => appareil === (checkedIndex1 === 1 ? 2 : 1))) !== null) {
                process.env.NODE_ENV === "development" && console.log(checkedIndex1)
                setAppareilsLiables2(appareils.filter(appareil => (checkedIndex1 === 1 ? (liaisons.c_liaisons9 === 0 ? appareil === 2 : undefined) :
                    ((liaisons.c_liaisons9 === 0 ? appareil === 1 : undefined) || (liaisons.c_liaisons3 === 0 ? appareil === 3 : undefined) || (liaisons.c_liaisons20 === 0 ? appareil === 13 : undefined)))))
                setShowThirdForm(true)
                setDisabled(true)
            }
            else if (checkedIndex1 === 2 && materiel === 3 && (appareils.filter(appareil => appareil === 6)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil =>
                    (liaisons.c_liaisons3 === 0 ? appareil === 6 : undefined)
                ))
                setShowThirdForm(true)
                liaisons.c_liaisons6 === 0 ? setDisabled(false) : setDisabled(true)
            }
            else if (checkedIndex1 === 2 && materiel === 13 && (appareils.filter(appareil => appareil === 6)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil =>
                    (liaisons.c_liaisons20 === 0 ? appareil === 6 : undefined)
                ))
                setShowThirdForm(true)
                liaisons.c_liaisons12 === 0 ? setDisabled(false) : setDisabled(true)
            }
            else {
                setAppareilsLiables2([])
                setDisabled(false)
                setShowThirdForm(false)
            }
        }
        // Boîte à eau selectionné en premier
        else if (checkedIndex1 === 6) {
            if (materiel === 2 && (appareils.filter(appareil => appareil === 1 || appareil === 13)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil => (liaisons.c_liaisons9 === 0 ? appareil === 1 : undefined) || (liaisons.c_liaisons20 === 0 ? appareil === 13 : undefined)))
                setShowThirdForm(true)
                setDisabled(true)
            } else if ((materiel === 1 || materiel === 3) && (appareils.filter(appareil => appareil === 2)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil => appareil === 2))
                setShowThirdForm(true)
                setDisabled(true)
            }
            else {
                setAppareilsLiables2([])
                setShowThirdForm(false)
                setDisabled(false)
            }
        }
        // ---------- Liaisons à 3 possibles entre un Lumineux, Boîte à eau et Boîtier
        // On sélectionne en premier un boîtier
        else if (checkedIndex1 === 3) {
            if (materiel === 2 && (appareils.filter(appareil => appareil === 6)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil =>
                    (liaisons.c_liaisons3 === 0 ? appareil === 6 : undefined)
                ))
                liaisons.c_liaisons6 === 0 ? setDisabled(false) : setDisabled(true)
                setShowThirdForm(true)
            } else if (materiel === 6 && (appareils.filter(appareil => appareil === 2)) !== null) {
                setAppareilsLiables2(appareils.filter(appareil => appareil === 2))
                setDisabled(true)
                setShowThirdForm(true)
            } else {
                setAppareilsLiables2([])
                setDisabled(false)
                setShowThirdForm(false)
            }
        } else if (checkedIndex1 === 13) {
            if (materiel === 2 && appareils.filter(appareil => appareil === 6) !== null) {
                setAppareilsLiables2(appareils.filter(appareil =>
                    (liaisons.c_liaisons20 === 0 ? appareil === 6 : undefined)
                ))
                liaisons.c_liaisons14 === 0 ? setDisabled(false) : setDisabled(true)
                setShowThirdForm(true)
            }

        }
        // On sélectionne en premier un boîtier lumineux
        else {
            setAppareilsLiables2([])
            setShowThirdForm(false)
            setDisabled(false)
        }
        setCheckedIndex2(materiel)
        setFieldValue("appareil2", materiel)
        setCheckedIndex3(0)
        setFieldValue("appareil3", 0)
    }

    const handleChange3 = (e, setFieldValue) => {
        let materiel = parseInt(e.target.value)
        setCheckedIndex3(materiel)
        setFieldValue("appareil3", materiel)
        setDisabled(false)
    }

    const handleSubmit = async (e) => {
        const { appareil1, appareil2, appareil3 } = props.values
        let liaisons = await storage.get('taximetre')
        const defaultLiaisonValue = 1
        const defaultBlindeValue = 2
        // On vérifie quand même que les 2 premiers
        // appareils ont bien été sélectionnés
        if (appareil1 === 0 || appareil2 === 0) {
            toast.error("Veuillez bien sélectionner au moins les deux premiers champs.")
        }
        // ---------- 2 liaisons
        // Capteur/fil et Taximetre
        else if ((appareil1 === 21 && appareil2 === 1) || (appareil1 === 1 && appareil2 === 21)) {
            props.setFieldValue("liaisons.c_liaisons1", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde1", defaultBlindeValue)
            liaisons.c_liaisons1 = defaultLiaisonValue
            liaisons.c_blinde1 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Capteur/fil et Boîtier
        else if ((appareil1 === 21 && appareil2 === 3) || (appareil1 === 3 && appareil2 === 21)) {
            props.setFieldValue("liaisons.c_liaisons2", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde2", defaultBlindeValue)
            liaisons.c_liaisons2 = defaultLiaisonValue
            liaisons.c_blinde2 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Taximetre et Boîtier
        else if ((appareil1 === 1 && appareil2 === 3) || (appareil1 === 3 && appareil2 === 1)) {
            props.setFieldValue("liaisons.c_liaisons4", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde4", defaultBlindeValue)
            liaisons.c_liaisons4 = defaultLiaisonValue
            liaisons.c_blinde4 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Batterie et Boîtier
        else if ((appareil1 === 20 && appareil2 === 3) || (appareil1 === 3 && appareil2 === 20)) {
            props.setFieldValue("liaisons.c_liaisons5", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde5", defaultBlindeValue)
            liaisons.c_liaisons5 = defaultLiaisonValue
            liaisons.c_blinde5 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Lumineux et Boîtier
        else if ((appareil1 === 2 && appareil2 === 3 && appareil3 === 0) || (appareil1 === 3 && appareil2 === 2 && appareil3 === 0)) {
            props.setFieldValue("liaisons.c_liaisons6", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde6", defaultBlindeValue)
            liaisons.c_liaisons6 = defaultLiaisonValue
            liaisons.c_blinde6 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Taximetre et Lumineux
        else if ((appareil1 === 1 && appareil2 === 2 && appareil3 === 0) || (appareil1 === 2 && appareil2 === 1 && appareil3 === 0)) {
            props.setFieldValue("liaisons.c_liaisons7", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde7", defaultBlindeValue)
            liaisons.c_liaisons7 = defaultLiaisonValue
            liaisons.c_blinde7 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Afficheur et Taximetre
        else if ((appareil1 === 1 && appareil2 === 10) || (appareil1 === 10 && appareil2 === 1)) {
            props.setFieldValue("liaisons.c_liaisons10", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde10", defaultBlindeValue)
            liaisons.c_liaisons10 = defaultLiaisonValue
            liaisons.c_blinde10 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Imprimante et Taximetre
        else if ((appareil1 === 1 && appareil2 === 7) || (appareil1 === 7 && appareil2 === 1)) {
            props.setFieldValue("liaisons.c_liaisons11", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde11", defaultBlindeValue)
            liaisons.c_liaisons11 = defaultLiaisonValue
            liaisons.c_blinde11 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Lumineux et Boîtier Lumineux
        else if (appareil1 === 2 && appareil2 === 13 && appareil3 === 0) {
            props.setFieldValue("liaisons.c_liaisons12", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde12", defaultBlindeValue)
            liaisons.c_liaisons12 = defaultLiaisonValue
            liaisons.c_blinde12 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Taximetre et Batterie
        else if ((appareil1 === 1 && appareil2 === 20) || (appareil1 === 20 && appareil2 === 1)) {
            props.setFieldValue("liaisons.c_liaisons13", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde13", defaultBlindeValue)
            liaisons.c_liaisons13 = defaultLiaisonValue
            liaisons.c_blinde13 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Boîtier Lumineux et Lumineux
        else if (appareil1 === 13 && appareil2 === 2 && appareil3 === 0) {
            props.setFieldValue("liaisons.c_liaisons14", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde14", defaultBlindeValue)
            liaisons.c_liaisons14 = defaultLiaisonValue
            liaisons.c_blinde14 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Boîtier Lumineux et Taximetre
        else if ((appareil1 === 1 && appareil2 === 13) || (appareil1 === 13 && appareil2 === 1)) {
            props.setFieldValue("liaisons.c_liaisons15", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde15", defaultBlindeValue)
            liaisons.c_liaisons15 = defaultLiaisonValue
            liaisons.c_blinde15 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Boîtier Lumineux et Boit. Raccord
        else if ((appareil1 === 3 && appareil2 === 13) || (appareil1 === 13 && appareil2 === 3)) {
            props.setFieldValue("liaisons.c_liaisons16", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde16", defaultBlindeValue)
            liaisons.c_liaisons16 = defaultLiaisonValue
            liaisons.c_blinde16 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Imprimante et Boit. Raccord
        else if ((appareil1 === 3 && appareil2 === 7) || (appareil1 === 7 && appareil2 === 3)) {
            props.setFieldValue("liaisons.c_liaisons17", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde17", defaultBlindeValue)
            liaisons.c_liaisons17 = defaultLiaisonValue
            liaisons.c_blinde17 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Imprimante et Batterie
        else if ((appareil1 === 20 && appareil2 === 7) || (appareil1 === 7 && appareil2 === 20)) {
            props.setFieldValue("liaisons.c_liaisons18", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde18", defaultBlindeValue)
            liaisons.c_liaisons18 = defaultLiaisonValue
            liaisons.c_blinde18 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Boîtier Lumineux et Batterie
        else if ((appareil1 === 20 && appareil2 === 13) || (appareil1 === 13 && appareil2 === 20)) {
            props.setFieldValue("liaisons.c_liaisons19", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde19", defaultBlindeValue)
            liaisons.c_liaisons19 = defaultLiaisonValue
            liaisons.c_blinde19 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // -------------- 3 liaisons
        // Lumineux, Boîte à eau et Boîtier
        else if ((appareil1 === 2 || appareil1 === 6 || appareil1 === 3)
            && (appareil2 === 2 || appareil2 === 6 || appareil2 === 3)
            && (appareil3 === 2 || appareil3 === 6 || appareil3 === 3)) {
            props.setFieldValue("liaisons.c_liaisons3", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde3", defaultBlindeValue)
            liaisons.c_liaisons3 = defaultLiaisonValue
            liaisons.c_blinde3 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Lumineux, Boîte à eau et Taximètre
        else if ((appareil1 === 2 || appareil1 === 6 || appareil1 === 1)
            && (appareil2 === 2 || appareil2 === 6 || appareil2 === 1)
            && (appareil3 === 2 || appareil3 === 6 || appareil3 === 1)) {
            props.setFieldValue("liaisons.c_liaisons9", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde9", defaultBlindeValue)
            liaisons.c_liaisons9 = defaultLiaisonValue
            liaisons.c_blinde9 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        // Boitier Lumineux, Boîte à eau et Lumineux
        else if ((appareil1 === 2 || appareil1 === 6 || appareil1 === 13)
            && (appareil2 === 2 || appareil2 === 6 || appareil2 === 13)
            && (appareil3 === 2 || appareil3 === 6 || appareil3 === 13)) {
            props.setFieldValue("liaisons.c_liaisons20", defaultLiaisonValue)
            props.setFieldValue("blindes.c_blinde20", defaultBlindeValue)
            liaisons.c_liaisons20 = defaultLiaisonValue
            liaisons.c_blinde20 = defaultBlindeValue
            await storage.set('taximetre', liaisons)
        }
        setFormVerif(liaisons)
        props.close()
    }

    return (
        <div className="popup-cover">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={props.close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body popup-liaison-body">
                    <div className="liaisons-wrapper">
                        <span className="mb-2">Sélectionner l'appareil 1</span>
                        <div className="appareils">
                            {appareils && appareils.map((materiel, n) =>
                                <div className="appareil-wrapper" key={n}>
                                    <Field className="checkbox-appareil" id={"appareil1-" + (n + 1)} type="radio" name="appareil1" onChange={e => handleChange1(e, props.setFieldValue)} value={materiel} checked={materiel === checkedIndex1} />
                                    <label htmlFor={"appareil1-" + (n + 1)} className="for-checkbox-appareil">
                                        <div className="materiel-image">
                                            <img src={getImageMateriel(materiel)} alt={"materiel" + materiel} />
                                        </div>
                                        <span>
                                            {getNomMateriel(materiel)}
                                        </span>
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    {showSecondForm && appareilsLiables1 && appareilsLiables1.length !== 0 &&
                        <div className="liaisons-wrapper">
                            <span className="mb-2">Sélectionner l'appareil 2 (à lier avec 1)</span>
                            <div className="appareils">
                                {appareilsLiables1.map((materiel, n) =>
                                    <div className="appareil-wrapper" key={n}>
                                        <Field className="checkbox-appareil" id={"appareil2-" + (n + 1)} type="radio" name="appareil2" onChange={e => handleChange2(e, props.setFieldValue)} value={materiel} checked={materiel === checkedIndex2} />
                                        <label htmlFor={"appareil2-" + (n + 1)} className="for-checkbox-appareil for-checkbox-2">
                                            <div className="materiel-image">
                                                <img src={getImageMateriel(materiel)} alt={"materiel" + materiel} />
                                            </div>
                                            <span>
                                                {getNomMateriel(materiel)}
                                            </span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {showThirdForm && appareilsLiables2 && appareilsLiables2.length !== 0 &&
                        <div className="liaisons-wrapper">
                            <span className="mb-2">Sélectionner appareil 3 (à lier avec 2 et 1)</span>
                            <div className="appareils">
                                {appareilsLiables2.map((materiel, n) =>
                                    <div className="appareil-wrapper" key={n}>
                                        <Field className="checkbox-appareil" id={"appareil3-" + (n + 1)} type="radio" name="appareil3" onChange={e => handleChange3(e, props.setFieldValue)} value={materiel} checked={materiel === checkedIndex3} />
                                        <label htmlFor={"appareil3-" + (n + 1)} className="for-checkbox-appareil for-checkbox-3">
                                            <div className="materiel-image">
                                                <img src={getImageMateriel(materiel)} alt={"materiel" + materiel} />
                                            </div>
                                            <span>
                                                {getNomMateriel(materiel)}
                                            </span>
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button type="button" disabled={disabled} className={"upload-file-btn" + (disabled ? " disabled" : "")} onClick={handleSubmit}>
                            <span>Ajouter</span>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default LiaisonPopup