import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { IoMdCloseCircle } from "react-icons/io"
import { CSSTransition } from 'react-transition-group'
/* ------------------------------------------- */
import { Formik, Form } from "formik"
import { useSaisieContext } from "../SaisieContext"
import localForage from 'localforage'
import { supervisionService } from "../../../../services/supervisionService"
import { toast } from "react-toastify"
import { isNullOrUndefined } from "../../../../helpers/utils"
import imageCompression from 'browser-image-compression'
import { useDataContext } from "../../../../providers/DataContextProvider"
import { storage } from "../../../../helpers/storageHelper"
import Skeleton from 'react-loading-skeleton'
/* ------------------------------------------- */

const Photos = (props) => {

    const [intervention, setIntervention] = useState(null)
    const [photo, setPhoto] = useState({ open: false })
    const [photos, setPhotos] = useState({})
    const [isLoading, setIsLoading] = useState({})
    const formikRef = useRef()
    const { setFormPhotos } = useSaisieContext()
    const { selectedIntervention } = useDataContext()

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des données depuis localforage
                const [interventionData, photosData] = await Promise.all([
                    storage.get('intervention'),
                    storage.get('photos')
                ])

                if (interventionData) {
                    setIntervention(interventionData)
                }

                if (!photosData || !photosData.length) {
                    // Pas de photos dans la base
                    const initialPhotos = {}
                    for (let i = 1; i <= 6; i++) {
                        initialPhotos["photo_" + i] = null
                    }
                    setPhotos(initialPhotos)
                    setFormPhotos([])
                } else {
                    // Photos existantes dans la base
                    const existingPhotos = {}
                    photosData.forEach((photo) => {
                        if (photo.numero_photo !== 20) {
                            existingPhotos["photo_" + photo.numero_photo] = photo.photo_detail
                        }
                    })
                    setPhotos(existingPhotos)
                    setFormPhotos(photosData)
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
                toast.error('Erreur lors du chargement des photos')
            }
        }

        loadInitialData()
    }, [])

    useEffect(() => {
        return () => {
            // Cleanup function
            setIsLoading({})
        }
    }, [])

    const openImage = (event, index) => {
        setPhoto({
            open: true,
            image: photos["photo_" + index]
        })
    }

    const closeImage = () => {
        setPhoto({
            open: false
        })
    }

    const compressImage = async (file, setFieldValue, index) => {
        try {
            setIsLoading(prev => ({ ...prev, [index]: true }));

            const options = {
                maxSizeMB: 0.8,            // Réduire légèrement la taille maximale
                maxWidthOrHeight: 1024,    // Réduire la résolution maximale
                initialQuality: 0.6,       // Réduire la qualité initiale
                useWebWorker: true,
                fileType: 'image/jpeg',    // Forcer le format JPEG
                alwaysKeepResolution: false // Permettre la réduction de résolution
            }

            // Pré-traitement de l'image si elle est très grande
            if (file.size > 3 * 1024 * 1024) { // Si plus de 3MB
                options.maxWidthOrHeight = 800;  // Réduire davantage la résolution
                options.initialQuality = 0.5;    // Réduire davantage la qualité
            }

            const compressedFile = await imageCompression(file, options);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = async () => {
                const base64String = reader.result.split(',')[1];
                setPhotos(prev => ({
                    ...prev,
                    ["photo_" + index]: base64String
                }));
                setFieldValue("photo_" + index, base64String);

                try {
                    const photosArray = await storage.get('photos') || [];
                    let photoEdited = photosArray.find(photo => photo.numero_photo === index);

                    if (photoEdited) {
                        photoEdited.photo_detail = base64String;
                        const newPhotosArray = photosArray
                            .filter(photo => photo.numero_photo !== index)
                            .concat(photoEdited);
                        await storage.set('photos', newPhotosArray);
                        setFormPhotos(newPhotosArray);
                    } else {
                        const currentIntervention = selectedIntervention.data;
                        const newPhoto = {
                            clef_lien: currentIntervention.clef_lien,
                            clef_stationnement: currentIntervention.clef_stationnement,
                            numero_adherent: currentIntervention.numero_adherent,
                            numero_client: currentIntervention.numero_client,
                            numero_intervention: currentIntervention.numero_intervention,
                            numero_photo: index,
                            photo_detail: base64String
                        };
                        const newPhotosArray = [...photosArray, newPhoto];
                        await storage.set('photos', newPhotosArray);
                        setFormPhotos(newPhotosArray);
                    }
                } catch (error) {
                    console.error('Erreur lors de la sauvegarde de la photo:', error);
                    toast.error('Erreur lors de la sauvegarde de la photo');
                } finally {
                    setIsLoading(prev => ({ ...prev, [index]: false }));
                }
            };
        } catch (error) {
            console.error('Erreur lors de la compression de l\'image:', error);
            toast.error('Erreur lors de la compression de l\'image');
            setIsLoading(prev => ({ ...prev, [index]: false }));
        }
    }

    const uploadPhoto = async (event, setFieldValue, index) => {
        const file = event.currentTarget.files[0]
        if (file) {
            await compressImage(file, setFieldValue, index)
        }
    }

    // Supprimer une photo
    const handleDelete = async (event, setFieldValue, index) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer la photo' + index + ' ?')) {
            try {
                setIsLoading(prev => ({ ...prev, [index]: true }));
                
                const photosArray = await storage.get('photos') || [];
                const newPhotosArray = photosArray.filter(photo => photo.numero_photo !== index);
                await storage.set('photos', newPhotosArray);
                
                setPhotos(prev => {
                    const newPhotos = { ...prev };
                    delete newPhotos["photo_" + index];
                    return newPhotos;
                });
                
                setFieldValue("photo_" + index, "");
                setFormPhotos(newPhotosArray);
                toast.success('Photo supprimée avec succès');
            } catch (error) {
                console.error('Erreur lors de la suppression de la photo:', error);
                toast.error('Erreur lors de la suppression de la photo');
            } finally {
                setIsLoading(prev => ({ ...prev, [index]: false }));
            }
        }
    }

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    const handleEgalPrecedent = async (e, setValues, setFieldValue) => {
        try {
            const data = {
                numero_adherent: intervention.numero_adherent,
                numero_client: intervention.numero_client,
                clef_stationnement: intervention.clef_stationnement,
                clef_lien: intervention.clef_lien,
                numero_intervention: intervention.numero_intervention,
                date_intervention: intervention.date_intervention,
            }

            const response = await supervisionService.egalePrecedentPhotos(data)

            // Mise à jour des photos
            const newPhotos = {}
            const photosArray = []

            for (let i = 1; i <= 6; i++) {
                const photo = response.find(element => element.numero_photo === i)
                newPhotos["photo_" + i] = photo ? photo.photo_detail : null
                setFieldValue("photo_" + i, photo ? photo.photo_detail : null)

                if (photo) {
                    photo.numero_intervention = intervention.numero_intervention
                    photosArray.push(photo)
                }
            }

            setPhotos(newPhotos)

            // Mise à jour du storage
            await storage.set('photos', photosArray)
            setFormPhotos(photosArray)

            toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
        } catch (error) {
            console.error('Erreur lors de la récupération des photos précédentes:', error)
            toast.info(error, { containerId: 'PRE' })
        }
    }

    if (!intervention) {
        return <div>Chargement...</div>
    }

    return (
        <div className="infos-section">
            <div className="infos-section-container">
                <div>
                    <CSSTransition
                        in={photo.open}
                        timeout={300}
                        onExit={closeImage}
                        classNames="exemple"
                        unmountOnExit
                    >
                        <div className={"show-image" + (photo.open === true ? " show" : "")}>
                            <button className="btn" onClick={closeImage}>
                                <IoMdCloseCircle />
                            </button>
                            <div className="photo-saisie">
                                <input type="checkbox" className="zoomCheck" id="zoomCheck1" />
                                <label htmlFor="zoomCheck1">
                                    <img src={photo && photo.image ? "data:image/png;base64," + photo.image : ""} alt="Pas de photo1 pour cette saisie" />
                                </label>
                            </div>
                        </div>
                    </CSSTransition>
                    <div className="saisie-photo">
                        <div className="table-container">
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize
                                initialValues={photos}
                                validateOnMount={true}
                                validateOnChange={true}
                                validateOnBlur={true}
                            >
                                {({ values, setFieldValue, setValues }) => (
                                    <Form>
                                        {/* Egale precedent */}
                                        <div className="section-precedent">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalPrecedent(e, setValues, setFieldValue)}>Egal précédent</button>
                                        </div>
                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Photos</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            <div className="button-document-container">
                                                                {isLoading[1] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) :
                                                                    (values && values.photo_1 ?
                                                                        <button id="photo1" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                                                            Photo 1
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo1 pour cette saisie")
                                                                }
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_1"
                                                                    id="photo_1"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => uploadPhoto(e, setFieldValue, 1)}
                                                                    key={Date.now() + 1}
                                                                />
                                                                <label htmlFor="photo_1">Ajouter ou modifier</label>
                                                                <button id="del1" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 1)} disabled={!values.photo_1}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_1 && !values.photo_2 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {isLoading[2] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) : (
                                                                    values && values.photo_2 ?
                                                                        <button id="photo2" type="button" className="btn btn-document" onClick={(e) => openImage(e, 2)}>
                                                                            Photo 2
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo2 pour cette saisie"
                                                                )}
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_2"
                                                                    id="photo_2"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 2) }}
                                                                    key={Date.now() + 2}
                                                                    disabled={!values.photo_1 && !values.photo_2 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_2">Ajouter ou modifier</label>
                                                                <button id="del2" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 2)} disabled={!values.photo_2}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_2 && !values.photo_3 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {isLoading[3] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) : (
                                                                    values && values.photo_3 ?
                                                                        <button id="photo3" type="button" className="btn btn-document" onClick={(e) => openImage(e, 3)}>
                                                                            Photo 3
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo3 pour cette saisie"
                                                                )}
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_3"
                                                                    id="photo_3"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 3) }}
                                                                    key={Date.now() + 3}
                                                                    disabled={!values.photo_2 && !values.photo_3 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_3">Ajouter ou modifier</label>
                                                                <button id="del3" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 3)} disabled={!values.photo_3}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_3 && !values.photo_4 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {isLoading[4] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) : (
                                                                    values && values.photo_4 ?
                                                                        <button id="photo4" type="button" className="btn btn-document" onClick={(e) => openImage(e, 4)}>
                                                                            Photo 4
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo4 pour cette saisie"
                                                                )}
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_4"
                                                                    id="photo_4"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 4) }}
                                                                    key={Date.now() + 4}
                                                                    disabled={!values.photo_3 && !values.photo_4 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_4">Ajouter ou modifier</label>
                                                                <button id="del4" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 4)} disabled={!values.photo_4}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_4 && !values.photo_5 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {isLoading[5] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) : (
                                                                    values && values.photo_5 ?
                                                                        <button id="photo5" type="button" className="btn btn-document" onClick={(e) => openImage(e, 5)}>
                                                                            Photo 5
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo5 pour cette saisie"
                                                                )}
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_5"
                                                                    id="photo_5"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 5) }}
                                                                    key={Date.now() + 5}
                                                                    disabled={!values.photo_4 && !values.photo_5 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_5">Ajouter ou modifier</label>
                                                                <button id="del5" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 5)} disabled={!values.photo_5}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={!values.photo_5 && !values.photo_6 ? "c-disabled" : undefined}>
                                                            <div className="button-document-container">
                                                                {isLoading[6] ? (
                                                                    <div className="position-relative" style={{ height: '45px', width: '100%' }}>
                                                                        <Skeleton height={45} />
                                                                    </div>
                                                                ) : (
                                                                    values && values.photo_6 ?
                                                                        <button id="photo6" type="button" className="btn btn-document" onClick={(e) => openImage(e, 6)}>
                                                                            Photo 6
                                                                            <i className="fas fa-eye"></i>
                                                                        </button>
                                                                        :
                                                                        "Pas de photo6 pour cette saisie"
                                                                )}
                                                            </div>
                                                            <div className="action-button-container">
                                                                <input
                                                                    type="file"
                                                                    name="photo_6"
                                                                    id="photo_6"
                                                                    className="photo-input"
                                                                    accept="image/x-png,image/gif,image/jpeg"
                                                                    onChange={e => { uploadPhoto(e, setFieldValue, 6) }}
                                                                    key={Date.now() + 6}
                                                                    disabled={!values.photo_5 && !values.photo_6 ? true : undefined}
                                                                />
                                                                <label htmlFor="photo_6">Ajouter ou modifier</label>
                                                                <button id="del6" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue, 6)} disabled={!values.photo_6}>
                                                                    <i className="fas fa-trash-alt"></i>
                                                                </button>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Photos