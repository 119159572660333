import { Formik, Field, Form, ErrorMessage } from 'formik'
import { IoMdClose } from "react-icons/io"
import { ReactComponent as OK } from './../../../res/checked.svg'
import { ReactComponent as Alerte } from './../../../res/alerte.svg'
import { useRef, useState } from 'react'
import { useEffect } from 'react'
import { supervisionService } from '../../../services/supervisionService'
import { useAuth } from "../../../providers/authProvider"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { toast } from 'react-toastify'
import { formatDate } from '../../../helpers/utils'
import useConfirm from '../../../hooks/useConfirm'

const VehiculePopup = ({ vehicule, newVehicule, ...props }) => {
    let auth = useAuth()
    const userGroup = auth.userGroup
    const [saving, setSaving] = useState(false)
    const formikRef = useRef()
    const [marques, setMarques] = useState([])
    const [modeles, setModeles] = useState([])
    const numero_client = parseInt(props.client.id.split("_")[1])
    const { isConfirmedDeblocage } = useConfirm()

    useEffect(() => {
        supervisionService.getAllMarqueVehicule(userGroup)
            .then(response => setMarques(response))
            .catch(error => { toast.error(error, { containerId: 'App' }); console.log(error) })

        if (!newVehicule) {
            if (vehicule.marque_vehicule !== -1) {
                supervisionService.getAllModeleVehicule(vehicule.numero_adherent, vehicule.marque_vehicule)
                    .then(response => setModeles(response))
                    .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_vehicule", -1) })
            }
            else {
                formikRef.current.setFieldValue("modele_vehicule", -1)
            }
        }
    }, [])

    const handleSaveClient = e => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const haveErrors = async (data) => {
        try {
            let message = ""
            let error = true

            if (data.immatriculation_vehicule === "" && data.numero_serie.length < 17) {
                message = "Vous devez identifier l'immatriculation du véhicule."
                return { error: true, message }
            }

            if (newVehicule === false && data.immatriculation_vehicule !== vehicule.immatriculation_vehicule && data.immatriculation_vehicule.indexOf("WW") === -1) {
                const request = {
                    numero_adherent: data.numero_adherent,
                    numero_client: data.numero_client,
                    numero_vehicule: vehicule.numero_vehicule,
                }

                try {
                    const intervention = await supervisionService.testVehiculeIntervention(request)

                    if (intervention) {
                        toast.error("Changement de numéro d'immatriculation d'un véhicule alors que ce véhicule a déjà une intervention finalisée le " + formatDate(intervention.date_effectuee), { autoClose: 5000, containerId: "App" })
                        let show_modal_deblocage = true
                        
                        while (show_modal_deblocage) {
                            try {
                                let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { 
                                    message: "Changement Immatriculation Véhicule : " + data.immatriculation_vehicule, 
                                    option: 2, 
                                    activite: 1 
                                })

                                if (confirmCodeDeblocage === "code_bon") {
                                    show_modal_deblocage = false
                                    error = false
                                } else if (confirmCodeDeblocage === "code_incorrect") {
                                    toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                                } else {
                                    toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                                    return { error: true, message: "Arrêt..." }
                                }
                            } catch (err) {
                                console.error("Erreur lors du déblocage:", err)
                                toast.error("Erreur lors du déblocage.", { autoClose: 5000, containerId: "App" })
                                return { error: true, message: "Erreur lors du déblocage" }
                            }
                        }
                    } else {
                        error = false
                    }
                } catch (err) {
                    console.error("Erreur lors de la vérification du véhicule:", err)
                    toast.error("Erreur lors de la vérification du véhicule", { containerId: "App" })
                    return { error: true, message: "Erreur lors de la vérification du véhicule" }
                }
            } else {
                error = false
            }

            return { error, message }
        } catch (err) {
            console.error("Erreur inattendue lors de la validation:", err)
            toast.error("Une erreur inattendue est survenue", { containerId: "App" })
            return { error: true, message: "Une erreur inattendue est survenue" }
        }
    }

    const saveVehicule = async (request) => {
        try {
            const vehicule = await supervisionService.saveVehicule(request)
            toast.success('Véhicule enregistré avec succès')
            props.close(vehicule)
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement du véhicule:', error)
            toast.error(error.message || 'Erreur lors de l\'enregistrement du véhicule', { containerId: 'App' })
        } finally {
            setSaving(false)
        }
    }

    const updateVehicule = async (request) => {
        try {
            const vehicule = await supervisionService.updateVehicule(request)
            toast.success('Véhicule mis à jour avec succès')
            props.close(vehicule)
        } catch (error) {
            console.error('Erreur lors de la mise à jour du véhicule:', error)
            toast.error(error.message || 'Erreur lors de la mise à jour du véhicule', { containerId: 'App' })
        } finally {
            setSaving(false)
        }
    }

    const handleChangeMarque = (e) => {
        const value = e.target.value
        formikRef.current.setFieldValue("marque_vehicule", value)
        formikRef.current.setFieldValue("modele_vehicule", -1)
        if (value !== -1) {
            supervisionService.getAllModeleVehicule(userGroup, value)
                .then(response => setModeles(response))
                .catch(error => { toast.error(error, { containerId: 'App' }); formikRef.current.setFieldValue("modele_vehicule", -1) })
        }
        else {
            formikRef.current.setFieldValue("modele_vehicule", -1)
        }
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {props.title}
                    </h3>
                    <div className="popup-close-button" onClick={e => props.close(null)}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-body pt-0 popup-liaison-body">
                    <div className="form-saisie-container">
                        <Formik
                            innerRef={formikRef}
                            enableReinitialize
                            initialValues={{
                                numero_client: !newVehicule ? vehicule.numero_client : numero_client,
                                etat_vehicule: !newVehicule ? (vehicule.actif_vehicule ? 0 : 1) : 0,
                                marque_vehicule: !newVehicule ? vehicule.marque_vehicule : -1,
                                modele_vehicule: !newVehicule ? vehicule.modele_vehicule : -1,
                                particularite: !newVehicule ? vehicule.particularite : "",
                                immatriculation_vehicule: !newVehicule ? vehicule.immatriculation_vehicule : "",
                                numero_serie: !newVehicule ? vehicule.numero_serie : "",
                                observations: !newVehicule ? vehicule.observations : "",
                            }}
                            validate={values => {
                                const errors = {}

                                if (values.marque_vehicule === -1) {
                                    errors.marque_vehicule = "Vous devez renseigner une marque."
                                }
                                if (values.modele_vehicule === -1) {
                                    errors.modele_vehicule = "Vous devez renseigner un modele."
                                }

                                return errors
                            }}
                            onSubmit={async (values, errors) => {
                                setSaving(true)
                                let data = values
                                data.numero_adherent = userGroup
                                data.immatriculation_vehicule = data.immatriculation_vehicule.replace(" ", "")
                                data.numero_serie = data.numero_serie.replace(" ", "")
                                data.marque_vehicule = parseInt(data.marque_vehicule)
                                data.modele_vehicule = parseInt(data.modele_vehicule)
                                setSaving(false)
                                const isError = await haveErrors(data)
                                if (isError.error) {
                                    toast.error(isError.message, { containerId: 'App' })
                                    setSaving(false)
                                    return
                                }
                                else {
                                    data.clef_stationnement = parseInt(props.selected.split("_")[2])
                                    if (newVehicule) {
                                        saveVehicule(data)
                                    }
                                    else {
                                        data.numero_vehicule = vehicule.numero_vehicule
                                        updateVehicule(data)
                                    }
                                }
                            }}
                        >
                            {({ values, setFieldValue }) => (
                                <Form>
                                    {/* Actif */}
                                    <div className="form-group">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="etat_vehicule"
                                                            id="etat_vehicule-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('etat_vehicule', 0)}
                                                            checked={values.etat_vehicule === 0 ? true : false} />
                                                        <label htmlFor="etat_vehicule-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Actif
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="etat_vehicule"
                                                            id="etat_vehicule-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('etat_vehicule', 1)}
                                                            checked={values.etat_vehicule === 1 ? true : false} />
                                                        <label htmlFor="etat_vehicule-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Alerte />
                                                            Inactif
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Marque */}
                                    <div className="form-group">
                                        <label>Marque</label>
                                        <Field name="marque_vehicule" className="form-control" as="select" onChange={e => { handleChangeMarque(e) }}>
                                            <option value="empty"></option>
                                            {marques && marques.map((marque, index) =>
                                                <option key={index} value={marque.marque_vehicule}>{marque.marque_nom_vehicule}</option>
                                            )}
                                        </Field>
                                        <ErrorMessage name="marque_vehicule" component="div" className="text-danger" />
                                    </div>
                                    {/* Modèle */}
                                    <div className="form-group">
                                        <label htmlFor="modele_vehicule">Modèle</label>
                                        <Field name="modele_vehicule" className="form-control" as="select">
                                            <option value="-1"></option>
                                            {modeles && modeles.map((modele, index) =>
                                                <option key={index} value={modele.modele_vehicule}>{modele.modele_nom_vehicule}</option>
                                            )}
                                        </Field>
                                        <ErrorMessage name="modele_vehicule" component="div" className="text-danger" />
                                    </div>
                                    {/* Particularités */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="particularite">Particularités</label>
                                                <Field className="form-control" type="text" name="particularite" />
                                                <ErrorMessage name="particularite" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Immatriculation */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="immatriculation_vehicule">Immat</label>
                                                <Field className="form-control" type="text" name="immatriculation_vehicule" autoComplete="off" />
                                                <ErrorMessage name="immatriculation_vehicule" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Numéro de série */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="numero_serie">Numéro de série</label>
                                                <Field className="form-control" type="text" name="numero_serie" autoComplete="off" />
                                                <ErrorMessage name="numero_serie" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Observations */}
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label htmlFor="observations">Observations</label>
                                                <Field className="form-control" as="textarea" name="observations" />
                                                <ErrorMessage name="observations" component="div" className="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div >
                <div className="popup-actions popup-actions-liaisons">
                    <div className="popup-action-open">
                        <button disabled={saving} type="button" className={"upload-file-btn" + (saving ? " disabled" : "")} onClick={handleSaveClient}>
                            <span> {saving ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrer"}</span>
                        </button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default VehiculePopup