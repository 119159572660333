import React, { useEffect } from "react"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import history from "./helpers/history"
import { HomePage } from "./pages/HomePage"
import { PrivateRoute } from "./components/PrivateRoute"
import DeblocagePage from "./pages/DeblocagePage"
import UnauthorizedPage from "./pages/UnauthorizedPage"
import PreSupervisionPage from "./pages/PreSupervisionPage"
import SupervisionPage from "./pages/SupervisionPage"
import ChooseAdherentPage from "./pages/Siege/ChooseAdherentPage"
import 'react-toastify/dist/ReactToastify.css'
import AdherentPage from "./pages/Siege/AdherentPage"
import IntervenantPage from "./pages/Siege/IntervenantPage"
import DocQualitePage from "./pages/DocQualitePage"
import InterventionPage from "./pages/InterventionPage"
import TextePage from "./pages/Texte/TextePage"
import { AuthProvider } from "./providers/authProvider"
import { Logout } from "./pages/Logout"
import { SilentRenew } from "./pages/SilentRenew"
import { Callback } from "./pages/Callback"
import DashboardPage from "./pages/DashboardPage"
import InterventionPageTachy from "./pages/InterventionPageTachy"
import ConfirmContextProvider from "./providers/ConfirmContextProvider"
import DeblocageContextProvider from "./providers/DeblocageContextProvider"
import ConfirmModal from "./components/Modal/ConfirmModal"
import DataContextProvider from "./providers/DataContextProvider"
import { ToastContainer } from "react-toastify"
import { isNullOrUndefined, unlockBody } from "./helpers/utils"
import { Role } from "./helpers/Role"
import PreInterventionPage from "./pages/Intervention/PreInterventionPage"
import AdherentContextProvider from "./providers/AdherentContextProvider"
import IndicateurPage from "./pages/Siege/IndicateurPage"

export const App = () => {

    useEffect(() => {
        history.listen((location) => {
            window.scrollTo(0, 0)
            document.body.style.paddingTop = '0'
            if (location.pathname.indexOf("/intervention") === -1 && location.pathname !== "/supervision") {
                window.onbeforeunload = undefined
            }
            if (!isNullOrUndefined(location.state) && location.state !== location.pathname) {
                unlockBody()
            }
        })
    }, [])

    return (
        <div>
            <AdherentContextProvider>
                <DataContextProvider>
                    <ConfirmContextProvider>
                        <DeblocageContextProvider>
                            <AuthProvider>
                                <ToastContainer
                                    position="top-center"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={true}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    containerId={"App"}
                                />
                                <Router history={history}>
                                    <Switch>
                                        <PrivateRoute path="/" exact component={HomePage} />
                                        {/*<Route path="/register" component={RegisterPage} />*/}
                                        {/*<Route path="/login" component={LoginPage} />*/}
                                        {/*<PrivateRoute path="/users" component={UsersPage} />*/}
                                        <PrivateRoute path="/deblocage" component={DeblocagePage} for={[Role.Admin, Role.Siege]} />
                                        {/*<PrivateRoute path="/update" component={UpdatePage} />*/}
                                        <PrivateRoute path="/presupervision" component={PreSupervisionPage} for={[Role.Admin, Role.Siege]} />
                                        <PrivateRoute path="/supervision" component={SupervisionPage} for={[Role.Admin, Role.Siege]} />
                                        <PrivateRoute path="/dashboard" component={DashboardPage} for={[Role.Admin, Role.Siege, Role.Adherent]} />
                                        <Route path="/callback" component={Callback} />
                                        <Route path="/silentRenew" component={SilentRenew} />
                                        <Route path="/logout" component={Logout} />
                                        <Route path="/404" component={UnauthorizedPage} />
                                        <Route path="/adherents" render={({ match: { path } }) => (
                                            <>
                                                <PrivateRoute path={`${path}/`} component={ChooseAdherentPage} exact for={[Role.Admin, Role.Siege]} />
                                                <Route path={`${path}/:id`} render={({ match: { path } }) => (
                                                    <>
                                                        <PrivateRoute path={`${path}/`} component={AdherentPage} exact for={[Role.Admin, Role.Siege]} />
                                                        <PrivateRoute path={`${path}/intervenant`} component={IntervenantPage} for={[Role.Admin, Role.Siege]} />
                                                    </>
                                                )} />
                                            </>
                                        )}
                                        />
                                        <PrivateRoute path="/documents" component={DocQualitePage} for={[Role.Admin, Role.Siege, Role.Externe]} />
                                        {/* <PrivateRoute path="/intervention" component={InterventionPage} for={[Role.Admin, Role.Siege, Role.Adherent]} /> */}
                                        <PrivateRoute path="/intervention-tachy" component={InterventionPageTachy} for={[Role.Admin, Role.Siege, Role.Adherent]} />
                                        <PrivateRoute path="/intervention-v2" component={PreInterventionPage} for={[Role.Admin, Role.Siege, Role.Adherent]} />
                                        <PrivateRoute path="/indicateur" component={IndicateurPage} for={[Role.Admin, Role.Siege]} />
                                        <PrivateRoute path="/texte" component={TextePage} for={[Role.Admin, Role.Siege]} />
                                        <Redirect from="*" to="/" />
                                    </Switch>
                                </Router>
                                <ConfirmModal />
                            </AuthProvider>
                        </DeblocageContextProvider>
                    </ConfirmContextProvider>
                </DataContextProvider>
            </AdherentContextProvider>
        </div>
    )
}