import { mgr } from "../utils/authConst"
import jwtDecode from "jwt-decode"
import history from "../helpers/history"
import { oidclink } from "../utils/oidclink"
import { isNullOrUndefined } from "../helpers/utils"

/**
 * --------------------------------
 * -------- Initialisation --------
 * --------------------------------
*/

mgr.events.addUserSignedOut(() => {
    window.location.reload(true)
})

mgr.events.addSilentRenewError((e) => {
    process.env.NODE_ENV === "development" && console.log("silent renew error", e.message)
})

mgr.events.addAccessTokenExpired(() => {
    process.env.NODE_ENV === "development" && console.log("token expired")
    signInSilent()
})

let User = null

mgr.getUser().then(user => {
    User = user
})

/**
 * ----------------------------
 * -------- Fonctions --------
 * ----------------------------
*/

const login = (pathname = null) => {
    localStorage.avatar && localStorage.removeItem("avatar")
    if (pathname === null || pathname === "") {
        mgr.signinRedirect()
    } else {
        localStorage.setItem("redirectURI", pathname)
        mgr.signinRedirect()
    }
}

const signinRedirectCallback = () => {
    mgr.signinRedirectCallback()
        .then(() => {
            window.history.replaceState({},
                window.document.title,
                window.location.origin + window.location.pathname)
            localStorage.redirectURI ? history.push(localStorage.redirectURI) : history.push('/')
        })
        .catch(function (e) {
            // A voir si bonne solution
            history.push('/')
            console.error(e)
        })
}

const isAuthenticated = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(oidclink))
    return (!!oidcStorage && !!oidcStorage.access_token)
}

const isLoggedIn = () => {
    process.env.NODE_ENV === "development" && console.log(User)
    return User != null && !User.expired
}

const getUser = async () => {
    const user = await mgr.getUser()
    if (!user) {
        try {
            return await mgr.signinRedirectCallback()
        } catch (err) {
            console.error(err)
        }
    }
    return user
}

const getLocalUser = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(oidclink))
    if (isNullOrUndefined(oidcStorage)) {
        login()
    }
    return jwtDecode(oidcStorage.access_token)
}

const getAccessToken = () => {
    const oidcStorage = JSON.parse(sessionStorage.getItem(oidclink))
    return oidcStorage.access_token
}

const getUserRole = () => {
    return parseInt(getLocalUser().role)
}

const getUserGroup = () => {
    return parseInt(getLocalUser().group)
}

const getUserId = () => {
    return parseInt(getLocalUser().sub)
}

const logout = () => {
    mgr.signoutRedirect({
        post_logout_redirect_uri: process.env.NODE_ENV === "development" ? "http://localhost:3000" : "https://cometoptima.com"
    })
    mgr.clearStaleState()
    localStorage.clear()
}

const logoutRedirectCallback = () => {
    mgr.signoutRedirectCallback()
        .then(() => {
            localStorage.clear()
            history.push('/')
        })
    mgr.clearStaleState()
}

const signInSilent = () => {
    mgr.signinSilent()
        .then((user) => {
            process.env.NODE_ENV === "development" && console.log("signed in", user)
            User = user
        })
        .catch((err) => {
            console.log(err)
        })
}

const signInSilentCallback = () => {
    mgr.signinSilentCallback()
}

export const authService = {
    login,
    signinRedirectCallback,
    getUser,
    isAuthenticated,
    logout,
    logoutRedirectCallback,
    getLocalUser,
    signInSilentCallback,
    signInSilent,
    isLoggedIn,
    get accessToken() { return getAccessToken() },
    get userRole() { return getUserRole() },
    get userGroup() { return getUserGroup() },
    get userId() { return getUserId() },
}