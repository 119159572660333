import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { Accordion, Card, Button } from 'react-bootstrap'
import './receptionP.scss'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { useSaisieContext } from '../SaisieContext'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { fr } from "date-fns/locale"
import { supervisionService } from '../../../../services/supervisionService'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'
import { formatDate, isNullOrUndefined, isValidDate } from '../../../../helpers/utils'
import { useDataContext } from '../../../../providers/DataContextProvider'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { storage } from '../../../../helpers/storageHelper'

const ReceptionP = (props) => {

    const [receptionP, setReceptionP] = useState(null)
    const [receptionP_intervention, setReceptionP_intervention] = useState(null)
    const [receptionP_verif, setReceptionP_verif] = useState(null)
    const [aide_pneu_av, setAide_pneu_av] = useState(null)
    const [aide_pneu_ar, setAide_pneu_ar] = useState(null)
    const [manometres, setManometres] = useState([])
    const [loadingAidePneuAv, setLoadingAidePneuAv] = useState(true)
    const [loadingAidePneuAr, setLoadingAidePneuAr] = useState(true)
    const { setFormIntervention, setFormVerif } = useSaisieContext()
    const { interventionInfoGeneral } = useDataContext()
    // Collapse 
    const [isOpenAv, setIsOpenAv] = useState(true)
    const [isOpenAr, setIsOpenAr] = useState(false)
    const [errors, setErrors] = useState(null)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const infos = await storage.get('infos')
                const intervention = await storage.get('intervention')
                const taximetre = await storage.get('taximetre')

                if (infos) setReceptionP(infos)
                if (intervention) setReceptionP_intervention(intervention)
                if (taximetre) setReceptionP_verif(taximetre)

                // Charger les données des manomètres et aides pneus
                if (intervention) {
                    await Promise.all([
                        getAidePneuAvant(intervention.numero_adherent),
                        getAidePneuArriere(intervention.numero_adherent),
                        getManometres(intervention)
                    ])
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
                setErrors(error)
            }
        }
        loadInitialData()
    }, [])

    const getAidePneuAvant = async (adherent) => {
        setLoadingAidePneuAv(true)
        try {
            const response = await supervisionService.getAidePneuAvant(adherent)
            setAide_pneu_av(response)
        } catch (error) {
            console.error('Erreur lors de la récupération des aides pneus avant:', error)
        } finally {
            setLoadingAidePneuAv(false)
        }
    }

    const getAidePneuArriere = async (adherent) => {
        setLoadingAidePneuAr(true)
        try {
            const response = await supervisionService.getAidePneuArriere(adherent)
            setAide_pneu_ar(response)
        } catch (error) {
            console.error('Erreur lors de la récupération des aides pneus arrière:', error)
        } finally {
            setLoadingAidePneuAr(false)
        }
    }

    const getManometres = async (intervention) => {
        try {
            const manometresRequest1 = intervention ? {
                numero_adherent: intervention.numero_adherent,
                activite: 0,
                type_appareil: 82,
                y_compris_inactif: !isNullOrUndefined(intervention.date_effectuee) &&
                    new Date(intervention.date_effectuee) > new Date("1900-01-01")
            } : {}

            const manometresRequest2 = intervention ? {
                numero_adherent: intervention.numero_adherent,
                activite: parseInt(await storage.get('activite')),
                type_appareil: 2,
                y_compris_inactif: !isNullOrUndefined(intervention.date_effectuee) &&
                    new Date(intervention.date_effectuee) > new Date("1900-01-01")
            } : {}

            const [response1, response2] = await Promise.all([
                supervisionService.getManometre(manometresRequest1),
                supervisionService.getManometre(manometresRequest2)
            ])
            setManometres([...response1, ...response2])
        } catch (error) {
            console.error("Erreur lors de la récupération des manomètres", error)
        }
    }

    const AequalA = (values, setValues) => {
        setValues(prev => ({
            ...prev,
            pneu_dim4: values.pneu_dim1,
            pneu_dim5: values.pneu_dim2,
            pneu_dim6: values.pneu_dim3,
            pneu_pression_ar: values.pneu_pression,
        }))
    }

    const handleChangeAide = async (e, setFieldValue, option) => {
        try {
            setFieldValue("aide_pneu_av", e.target.value)
            if (option === 1 && e.target.value !== "0") {
                let pneu = e.target.options[e.nativeEvent.target.selectedIndex].text.split("/")
                process.env.NODE_ENV === "development" && console.log(pneu)

                setFieldValue("pneu_dim1", parseInt(pneu[0]))
                setFieldValue("pneu_dim2", parseInt(pneu[1]))
                setFieldValue("pneu_dim3", parseInt(pneu[2]))

                const intervention = await storage.get('intervention')
                intervention.pneu_dim1 = parseInt(pneu[0])
                intervention.pneu_dim2 = parseInt(pneu[1])
                intervention.pneu_dim3 = parseInt(pneu[2])

                await storage.set('intervention', intervention)
                setFormIntervention(intervention)
            }
            if (option === 2 && e.target.value !== "0") {
                let pneu = e.target.options[e.nativeEvent.target.selectedIndex].text.split("/")
                process.env.NODE_ENV === "development" && console.log(pneu)

                setFieldValue("pneu_dim4", parseInt(pneu[0]))
                setFieldValue("pneu_dim5", parseInt(pneu[1]))
                setFieldValue("pneu_dim6", parseInt(pneu[2]))

                const intervention = await storage.get('intervention')
                intervention.pneu_dim4 = parseInt(pneu[0])
                intervention.pneu_dim5 = parseInt(pneu[1])
                intervention.pneu_dim6 = parseInt(pneu[2])

                await storage.set('intervention', intervention)
                setFormIntervention(intervention)
            }
        } catch (error) {
            console.error('Erreur lors du changement d\'aide:', error)
            toast.error("Erreur lors de la sauvegarde des données")
        }
    }

    const handleEgalPrecedent = (e, setValues) => {

        const data = receptionP_intervention ? {
            numero_adherent: receptionP_intervention.numero_adherent,
            numero_client: receptionP_intervention.numero_client,
            clef_stationnement: receptionP_intervention.clef_stationnement,
            clef_lien: receptionP_intervention.clef_lien,
            numero_intervention: receptionP_intervention.numero_intervention,
            date_intervention: receptionP_intervention.date_intervention,
        } : {}

        supervisionService.egalPrecedentPression(data)
            .then(response => {
                setValues(prev => ({
                    ...prev,
                    pneu_dim1: response.pneu_dim1,
                    pneu_dim2: response.pneu_dim2,
                    pneu_dim3: response.pneu_dim3,
                    pneu_pression: response.pneu_pression,
                    pneu_dim4: response.pneu_dim4,
                    pneu_dim5: response.pneu_dim5,
                    pneu_dim6: response.pneu_dim6,
                    pneu_pression_ar: response.pneu_pression_ar,
                    usure_pneumatique: response.usure_pneumatique,
                    pneu_ajuste: response.pneu_ajuste,
                }))
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => toast.info(error, { containerId: 'PRE' }))
    }

    const handleEgalBase = (e, setValues) => {

        const data = receptionP_intervention ? {
            numero_adherent: receptionP_intervention.numero_adherent,
            numero_client: receptionP_intervention.numero_client,
            clef_stationnement: receptionP_intervention.clef_stationnement,
            clef_lien: receptionP_intervention.clef_lien,
            numero_intervention: receptionP_intervention.numero_intervention,
            date_intervention: receptionP_intervention.date_intervention,
        } : {}

        supervisionService.egalePrecedent2(data)
            .then(response => {
                setValues(prev => ({
                    ...prev,
                    presence_carnet: response.presence_carnet,
                    fourniture_carnet: response.fourniture_carnet,
                }))
                if (isValidDate(response.date_k) && new Date(response.date_k).getFullYear() > 1000) {
                    setValues(prev => ({
                        ...prev,
                        date_k: moment(formatDate(response.date_k, 2)).utcOffset(0, true).format("YYYY-MM-DD[T]HH:mm:ss"),
                    }))
                }
                if (isValidDate(response.date_installation) && new Date(response.date_installation).getFullYear() > 1000) {
                    setValues(prev => ({
                        ...prev,
                        date_installation: moment(formatDate(response.date_installation, 2)).utcOffset(0, true).format("YYYY-MM-DD[T]HH:mm:ss"),
                    }))
                }
                if (isValidDate(response.date_derniere_vp) && new Date(response.date_derniere_vp).getFullYear() > 1000) {
                    setValues(prev => ({
                        ...prev,
                        date_derniere_vp: moment(formatDate(response.date_derniere_vp, 2)).utcOffset(0, true).format("YYYY-MM-DD[T]HH:mm:ss"),
                    }))
                }
                //console.log(response)
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => toast.info(error, { containerId: 'PRE' }))
    }

    if (!receptionP_intervention || !receptionP_verif) return null // ou un loader

    return (
        <div className="receptionp-section">
            <Formik
                initialValues={{
                    pneu_dim1: receptionP_intervention.pneu_dim1,
                    pneu_dim2: receptionP_intervention.pneu_dim2,
                    pneu_dim3: receptionP_intervention.pneu_dim3,
                    pneu_pression: receptionP_intervention.pneu_pression,
                    pneu_dim4: receptionP_intervention.pneu_dim4,
                    pneu_dim5: receptionP_intervention.pneu_dim5,
                    pneu_dim6: receptionP_intervention.pneu_dim6,
                    pneu_pression_ar: receptionP_intervention.pneu_pression_ar,
                    usure_pneumatique: receptionP_intervention.usure_pneumatique,
                    pneu_ajuste: receptionP_intervention.pneu_ajuste,
                    date_installation: receptionP_intervention.date_installation ? receptionP_intervention.date_installation : null,
                    date_k: receptionP_intervention.date_k ? receptionP_intervention.date_k : null,
                    date_derniere_vp: receptionP_intervention.date_derniere_vp ? receptionP_intervention.date_derniere_vp : null,
                    presence_carnet: (interventionInfoGeneral.vp || interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement) ? receptionP_verif.c_carnet1 : receptionP_intervention.presence_carnet,
                    fourniture_carnet: (interventionInfoGeneral.vp || interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement) ? receptionP_verif.c_carnet2 : receptionP_intervention.fourniture_carnet,
                    pneu_egal_carnet: receptionP_intervention.pneu_egal_carnet,
                    autorisation_stat_immatriculation: receptionP_intervention.autorisation_stat_immatriculation,
                    util_mano: receptionP_intervention.util_mano,
                }}
                validate={async values => {
                    const errors = {}
                    try {
                        const intervention = await storage.get('intervention')
                        const taximetre = await storage.get('taximetre')

                        intervention.pneu_dim1 = parseInt(values.pneu_dim1)
                        intervention.pneu_dim2 = parseInt(values.pneu_dim2)
                        intervention.pneu_dim3 = parseInt(values.pneu_dim3)
                        intervention.pneu_pression = parseFloat(values.pneu_pression)
                        intervention.pneu_dim4 = parseInt(values.pneu_dim4)
                        intervention.pneu_dim5 = parseInt(values.pneu_dim5)
                        intervention.pneu_dim6 = parseInt(values.pneu_dim6)
                        intervention.pneu_pression_ar = parseFloat(values.pneu_pression_ar)
                        intervention.usure_pneumatique = values.usure_pneumatique
                        intervention.pneu_ajuste = values.pneu_ajuste
                        intervention.date_installation = values.date_installation ? values.date_installation : null
                        intervention.date_k = values.date_k ? values.date_k : null
                        intervention.date_derniere_vp = values.date_derniere_vp ? values.date_derniere_vp : null
                        if (interventionInfoGeneral.vp || interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement) {
                            taximetre.c_carnet1 = values.presence_carnet
                            taximetre.c_carnet2 = values.fourniture_carnet
                        }
                        intervention.presence_carnet = values.presence_carnet
                        intervention.fourniture_carnet = values.fourniture_carnet
                        intervention.pneu_egal_carnet = values.pneu_egal_carnet
                        intervention.autorisation_stat_immatriculation = values.autorisation_stat_immatriculation
                        intervention.util_mano = parseInt(values.util_mano)
                        // Partage des données
                        setFormIntervention(intervention)
                        setFormVerif(taximetre)
                        setErrors({ ...errors })

                        await storage.set('intervention', intervention)
                        await storage.set('taximetre', taximetre)
                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }
                }}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/*Pneumatique*/}
                            <div className="section-one">
                                <div className="reception-accordion">
                                    <Accordion defaultActiveKey="0">
                                        {/* Pneumatique Avant */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="0"
                                                onClick={() => { setIsOpenAv(!isOpenAv); setIsOpenAr(false) }}
                                            >
                                                <label>Pneumatique avant</label>
                                                <Button
                                                    onClick={() => { setIsOpenAv(!isOpenAv); setIsOpenAr(false) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={isOpenAv}
                                                    className="font-weight-bold"
                                                >
                                                    {isOpenAv === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Dimensions avant
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim1" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="pneumatique-separateur">
                                                                <span>X</span>
                                                            </div>
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim2" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="pneumatique-separateur">
                                                                <span>X</span>
                                                            </div>
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim3" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Aide pneus avant
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    {(!loadingAidePneuAv &&
                                                                        <Field name="aide_pneu_av" className="form-control" as="select" onChange={e => handleChangeAide(e, setFieldValue, 1)}>
                                                                            <option value="0"></option>
                                                                            {aide_pneu_av && aide_pneu_av.map((aide, n) =>
                                                                                <option key={n} value={aide.compte}>{aide.pneu_dim1 + "/" + aide.pneu_dim2 + "/" + aide.pneu_dim3}</option>
                                                                            )}
                                                                        </Field>) ||
                                                                        <Skeleton height={34} width={120} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Pression avant
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_pression" className="form-control input-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        bars
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        {/* Pneumatique Arrière */}
                                        <Card>
                                            <Accordion.Toggle
                                                className="comet-collapse supervision-collapse card-header font-weight-bold"
                                                as={Card.Header}
                                                eventKey="1"
                                                onClick={() => { setIsOpenAr(!isOpenAr); setIsOpenAv(false) }}
                                            >
                                                <label>Pneumatique arrière</label>
                                                <Button
                                                    onClick={() => { setIsOpenAr(!isOpenAr); setIsOpenAv(false) }}
                                                    aria-controls="example-collapse-text"
                                                    aria-expanded={isOpenAr}
                                                    className="font-weight-bold"
                                                >
                                                    {isOpenAr === false ?
                                                        <>Ouvrir <i className="fas fa-arrow-down"></i></> :
                                                        <>Fermer <i className="fas fa-arrow-up"></i> </>
                                                    }
                                                </Button>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => AequalA(values, setValues)}>
                                                        Dimension arrière = Dimension avant
                                                    </button>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Dimensions arrière
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim4" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="pneumatique-separateur">
                                                                <span>X</span>
                                                            </div>
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim5" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="pneumatique-separateur">
                                                                <span>X</span>
                                                            </div>
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_dim6" className="form-control input-extra-small" type="text" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Aide pneus arrière
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    {(!loadingAidePneuAr &&
                                                                        <Field name="aide_pneu_ar" className="form-control input-small" as="select" onChange={e => handleChangeAide(e, setFieldValue, 2)}>
                                                                            <option value="0"></option>
                                                                            {aide_pneu_ar && aide_pneu_ar.map((aide, n) =>
                                                                                <option key={n} value={aide.compte}>{aide.pneu_dim4 + "/" + aide.pneu_dim5 + "/" + aide.pneu_dim6}</option>
                                                                            )}
                                                                        </Field>) ||
                                                                        <Skeleton height={34} width={120} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-col">
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">Pression arrière</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-col">
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field name="pneu_pression_ar" className="form-control input-small" type="text" />
                                                                </div>
                                                            </div>
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">bars</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>
                            </div>

                            <div className="reception-body">
                                <div className="section-one">
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Manomètre</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-col m-w-100">
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_mano" className="form-control" as="select">
                                                        <option value="0"></option>
                                                        {manometres && manometres.map((mano, n) =>
                                                            <option key={n} value={mano.numero_appareil}>
                                                                {/*mano.marque + " " +*/ mano.modele + " " + mano.identification_interne /*+ " " + mano.numero_appareil*/}
                                                            </option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Usure */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Usure
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 1)}
                                                            checked={values.usure_pneumatique === 1 ? true : false} />
                                                        <label htmlFor="usure_pneumatique-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 2)}
                                                            checked={values.usure_pneumatique === 2 ? true : false} />
                                                        <label htmlFor="usure_pneumatique-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            Usé
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="usure_pneumatique"
                                                            id="usure_pneumatique-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('usure_pneumatique', 0)}
                                                            checked={values.usure_pneumatique === 0 ? true : false} />
                                                        <label htmlFor="usure_pneumatique-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* Ajusté */}
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Ajustement pression
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="pneu_ajuste"
                                                            id="pneu_ajuste-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('pneu_ajuste', 2)}
                                                            checked={values.pneu_ajuste === 2 ? true : false} />
                                                        <label htmlFor="pneu_ajuste-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow comet-choice-input">
                                                            OK
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="pneu_ajuste"
                                                            id="pneu_ajuste-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('pneu_ajuste', 1)}
                                                            checked={values.pneu_ajuste === 1 ? true : false} />
                                                        <label htmlFor="pneu_ajuste-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow comet-choice-input">
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="pneu_ajuste"
                                                            id="pneu_ajuste-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('pneu_ajuste', 0)}
                                                            checked={values.pneu_ajuste === 0 ? true : false} />
                                                        <label htmlFor="pneu_ajuste-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            SO
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="section-precedent">
                                        <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalPrecedent(e, setValues)}>Egal précédent</button>
                                    </div>
                                </div>
                                {/* Date d'installation */}
                                <div className="form-row">
                                    <div className="form-col">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">Date d'installation</div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} className="m-w-100">
                                                    <KeyboardDatePicker
                                                        id="date-picker-dialog"
                                                        label="Date d'installation"
                                                        inputVariant="outlined"
                                                        format="dd/MM/yyyy"
                                                        value={values.date_installation}
                                                        onChange={value => setFieldValue("date_installation", value)}
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date"
                                                        }}
                                                        maxDate={new Date()}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                    </div>
                                    {interventionInfoGeneral.installation === false &&
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Détermination 'k'</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} className="m-w-100">
                                                        <KeyboardDatePicker
                                                            id="date-picker-dialog"
                                                            label="Détermination 'k'"
                                                            inputVariant="outlined"
                                                            format="dd/MM/yyyy"
                                                            value={values.date_k}
                                                            onChange={value => setFieldValue("date_k", value)}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            maxDate={new Date()}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {interventionInfoGeneral.vignette === false &&
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">Dernière VP</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} className="m-w-100">
                                                        <KeyboardDatePicker
                                                            id="date-picker-dialog"
                                                            label="Dernière VP"
                                                            inputVariant="outlined"
                                                            format="dd/MM/yyyy"
                                                            value={values.date_derniere_vp}
                                                            onChange={value => setFieldValue("date_derniere_vp", value)}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date"
                                                            }}
                                                            maxDate={new Date()}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="section-precedent">
                                    <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalBase(e, setValues)}>Egal base</button>
                                </div>
                                {/* Carnet présent */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Carnet présent
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="presence_carnet"
                                                        id="presence_carnet-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('presence_carnet', 1)}
                                                        checked={values.presence_carnet === 1 ? true : false} />
                                                    <label htmlFor="presence_carnet-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            {interventionInfoGeneral.que_vp === false &&
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="presence_carnet"
                                                            id="presence_carnet-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('presence_carnet', 3)}
                                                            checked={values.presence_carnet === 3 ? true : false} />
                                                        <label htmlFor="presence_carnet-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            I01
                                                        </label>
                                                    </div>
                                                </li>
                                            }
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="presence_carnet"
                                                        id="presence_carnet-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('presence_carnet', 2)}
                                                        checked={values.presence_carnet === 2 ? true : false} />
                                                    <label htmlFor="presence_carnet-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R01
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="presence_carnet"
                                                        id="presence_carnet-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('presence_carnet', 0)}
                                                        checked={values.presence_carnet === 0 ? true : false} />
                                                    <label htmlFor="presence_carnet-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Carnet conforme */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Carnet conforme
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="fourniture_carnet"
                                                        id="fourniture_carnet-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('fourniture_carnet', 1)}
                                                        checked={values.fourniture_carnet === 1 ? true : false} />
                                                    <label htmlFor="fourniture_carnet-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            {interventionInfoGeneral.que_vp === false &&
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="fourniture_carnet"
                                                            id="fourniture_carnet-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('fourniture_carnet', 3)}
                                                            checked={values.fourniture_carnet === 3 ? true : false} />
                                                        <label htmlFor="fourniture_carnet-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            I02
                                                        </label>
                                                    </div>
                                                </li>
                                            }
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="fourniture_carnet"
                                                        id="fourniture_carnet-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('fourniture_carnet', 2)}
                                                        checked={values.fourniture_carnet === 2 ? true : false} />
                                                    <label htmlFor="fourniture_carnet-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R02
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="fourniture_carnet"
                                                        id="fourniture_carnet-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('fourniture_carnet', 0)}
                                                        checked={values.fourniture_carnet === 0 ? true : false} />
                                                    <label htmlFor="fourniture_carnet-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Pneu = carnet */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Pneu = carnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="pneu_egal_carnet"
                                                        id="pneu_egal_carnet-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('pneu_egal_carnet', 1)}
                                                        checked={values.pneu_egal_carnet === 1 ? true : false} />
                                                    <label htmlFor="pneu_egal_carnet-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            {interventionInfoGeneral.que_vp === false &&
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="pneu_egal_carnet"
                                                            id="pneu_egal_carnet-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('pneu_egal_carnet', 3)}
                                                            checked={values.pneu_egal_carnet === 3 ? true : false} />
                                                        <label htmlFor="pneu_egal_carnet-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            I03
                                                        </label>
                                                    </div>
                                                </li>
                                            }
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="pneu_egal_carnet"
                                                        id="pneu_egal_carnet-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('pneu_egal_carnet', 2)}
                                                        checked={values.pneu_egal_carnet === 2 ? true : false} />
                                                    <label htmlFor="pneu_egal_carnet-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R03
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="pneu_egal_carnet"
                                                        id="pneu_egal_carnet-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('pneu_egal_carnet', 4)}
                                                        checked={values.pneu_egal_carnet === 4 ? true : false} />
                                                    <label htmlFor="pneu_egal_carnet-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Paris */}
                                <div className="form-row bg-cgray">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Paris : Autorisation de stationnement avec immatriculation
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field disabled name="numero_stationnement" className="form-control input-small" type="text" value={receptionP.stationnements[0].numero_stationnement} />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field disabled name="immatriculation_vehicule" className="form-control input-small" type="text" value={receptionP.stationnements[0].vehiculeTaximetres[0].vehicule.immatriculation_vehicule} />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        disabled
                                                        type="radio"
                                                        name="autorisation_stat_immatriculation"
                                                        id="autorisation_stat_immatriculation-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('autorisation_stat_immatriculation', 1)}
                                                        checked={values.autorisation_stat_immatriculation === 1 ? true : false} />
                                                    <label htmlFor="autorisation_stat_immatriculation-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        disabled
                                                        type="radio"
                                                        name="autorisation_stat_immatriculation"
                                                        id="autorisation_stat_immatriculation-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('autorisation_stat_immatriculation', 2)}
                                                        checked={values.autorisation_stat_immatriculation === 2 ? true : false} />
                                                    <label htmlFor="autorisation_stat_immatriculation-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R54
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        disabled
                                                        type="radio"
                                                        name="autorisation_stat_immatriculation"
                                                        id="autorisation_stat_immatriculation-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('autorisation_stat_immatriculation', 4)}
                                                        checked={values.autorisation_stat_immatriculation === 4 ? true : false} />
                                                    <label htmlFor="autorisation_stat_immatriculation-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    )

}

export default ReceptionP