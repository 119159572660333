import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from 'formik'
import { Tabs } from 'antd'
import "antd/dist/antd.css"
import "./modele.scss"
import "./responsive.scss"
import { supervisionService } from "../../../../services/supervisionService"
import Checkbox from '@material-ui/core/Checkbox'
import { formatDate, isNullOrUndefined, lockBody, unlockBody } from '../../../../helpers/utils'
import CetsLogiciel from "./CetsLogiciel"
import Etiquettek from "./Etiquettek"
import Montage from "./../../../../assets/montage.png"
import AutorisationCouleurPopup from "../../../Popup/AutorisationCouleurPopup"
import { storage } from "../../../../helpers/storageHelper"

const { TabPane } = Tabs

const Modele = (props) => {

    const [modele, setModele] = useState(null)
    const [typeAppareils, setTypeAppareils] = useState(null)
    const [docUE, setDocUE] = useState(null)
    const [docUtilisateur, setDocUtilisateur] = useState(null)
    const [docVerificateur1, setDocVerificateur1] = useState(null)
    const [docVerificateur2, setDocVerificateur2] = useState(null)
    const [showCetsLogiciel, setShowCetsLogiciel] = useState(false)
    const [showEtiquettek, setShowEtiquettek] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des données depuis localforage
                const infos = await storage.get('infos')
                if (infos) {
                    setModele(infos)
                    await loadAllData(infos)
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            } finally {
                setLoading(false)
            }
        }

        loadInitialData()
    }, [])

    const loadAllData = async (infos) => {
        try {
            // Chargement des types d'appareils
            const typeAppareilsResponse = await supervisionService.getTypeTousAppareils()
            setTypeAppareils(typeAppareilsResponse)

            // Chargement des documents si nécessaire
            const modeleTousAppareils = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils
            await Promise.all([
                loadDocument(modeleTousAppareils.numero_document_attestation_UE, setDocUE),
                loadDocument(modeleTousAppareils.numero_document_utilisateur, setDocUtilisateur),
                loadDocument(modeleTousAppareils.numero_document_verificateur_1, setDocVerificateur1),
                loadDocument(modeleTousAppareils.numero_document_verificateur_2, setDocVerificateur2)
            ])
        } catch (error) {
            console.error('Erreur lors du chargement des données:', error)
        }
    }

    const loadDocument = async (numero, setterFunction) => {
        if (numero !== 0 && !isNullOrUndefined(numero)) {
            try {
                const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                setterFunction(response)
            } catch (error) {
                console.error('Erreur lors du chargement du document:', error)
            }
        }
    }

    const openDocument = (e, afile) => {
        try {
            var byteCharacters = atob(afile.fichier_contenu)
            var byteNumbers = new Array(byteCharacters.length)
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            var byteArray = new Uint8Array(byteNumbers)
            var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
            var fileURL = URL.createObjectURL(file)

            var element = document.createElement('a')
            element.setAttribute('href', fileURL)
            element.setAttribute('download', afile.fichier_original)
            document.body.appendChild(element)
            element.click()
            setTimeout(function () {
                window.URL.revokeObjectURL(fileURL)
            }, 100)
            document.body.removeChild(element)
        } catch (error) {
            console.error('Erreur lors de l\'ouverture du document:', error)
        }
    }

    const toggleCetsLogiciel = () => {
        setShowCetsLogiciel(!showCetsLogiciel)
        showCetsLogiciel ? unlockBody() : lockBody()
    }

    const toggleEtiquettek = () => {
        setShowEtiquettek(!showEtiquettek)
        showEtiquettek ? unlockBody() : lockBody()
    }

    if (loading) {
        return <div>Chargement...</div>
    }

    if (!modele) {
        return <div>Aucune donnée disponible</div>
    }

    return (
        <div className="infos-section">
            <div className="infos-section-container">
                <Formik
                    initialValues={modele}
                    validate={values => {
                        const errors = {}
                        return errors
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            alert(JSON.stringify(values, null, 2));
                            setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ isSubmitting, setFieldValue }) => (
                        <Form>
                            <div className="modele-header">
                                <div className="modele-actions mb-3">
                                    <button type="button" className="modele-btn cbtn-primary" onClick={toggleCetsLogiciel}>
                                        Cets/logiciels
                                        <i className="fas fa-external-link-alt ml-2"></i>
                                    </button>
                                    <button type="button" className="modele-btn cbtn-primary" onClick={toggleEtiquettek}>
                                        Etiquette k
                                        <i className="fas fa-external-link-alt ml-2"></i>
                                    </button>
                                </div>
                                {showCetsLogiciel &&
                                    <CetsLogiciel title="Cets/Logiciel" infos={modele} close={toggleCetsLogiciel} setFieldValue={setFieldValue} />
                                }
                                {showEtiquettek &&
                                    <Etiquettek title="Etiquette K" infos={modele} close={toggleEtiquettek} setFieldValue={setFieldValue} />
                                }
                                <div className="modele-general">
                                    <div className="fabricant-tax">
                                        <label>Fabricant / Marque : </label>
                                        <span className="details-item">
                                            {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.ftaxi.nom_ftaxi} / {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil}
                                        </span>
                                    </div>
                                    <div className="selection-tax">
                                        <label>Sélection / Modèle : </label>
                                        <span className="details-item">
                                            {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil}
                                        </span>
                                    </div>
                                    <div className="type-tax">
                                        <label>Type : </label>
                                        <Field as="select" disabled name="stationnements[0].vehicule.vehiculeTaximetres[0].taximetre.modeleTousAppareils.numero_type" className="details-item">
                                            {typeAppareils && typeAppareils.map((app, n) =>
                                                <option key={n} value={app.numero_type}>{app.nom_type}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="modele-body">
                                <Tabs defaultActiveKey="1" tabPosition={'top'} style={{ height: 220, width: '100%' }}>
                                    <TabPane tab={'Photo'} key={1}>
                                        <div className="form-row">
                                            <div className="form-photo">
                                                {
                                                    !isNullOrUndefined(modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_1) &&
                                                    <div className="form-photo-1">
                                                        <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_1}`} alt="Taximètre 1" />
                                                    </div>
                                                }
                                                {
                                                    !isNullOrUndefined(modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_2) &&
                                                    <div className="form-photo-2">
                                                        <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_2}`} alt="Taximètre 2" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <span className="details-item">
                                                Rapport Longueur/Hauteur : 4.5/6
                                            </span>
                                        </div>
                                    </TabPane>
                                    <TabPane tab={'Général'} key={2}>
                                        <div className="divers-header">
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            N° de CET initial
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="ref_dam" className="form-control input-small" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.ref_dam} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            w de
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="etendue_debut" className="form-control input-small" disabled type="number" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.etendue_debut} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            à
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="etendue_fin" className="form-control input-small" disabled type="number" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.etendue_fin} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Date CET initial
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="date_debut_dam" className="form-control form-small" disabled type="text" value={formatDate(modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.date_debut_dam)} />
                                                    </div>
                                                </div>
                                                <div className="form-cell-input checkbox-container">
                                                    <div className="form-input">
                                                        <Checkbox
                                                            checked={modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.france_mid === 1 ? true : false}
                                                            name="france_mid"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Appareil MID
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Date fin CET
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="date_fin_dam" className="form-control form-small" disabled type="text" value={formatDate(modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.date_fin_dam)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Observations
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="observations" className="form-control form-infos" disabled as="textarea" value={modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.observations} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Position vignette verte
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="situation_vignette" className="form-control form-infos" disabled type="text" value={modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.situation_vignette} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="divers-body">
                                            {/* 1er fichier PDF */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">Attestattion UE</div>
                                                    </div>
                                                </div>
                                                <div className="button-document-container">
                                                    <div className="rapport-sup-button">
                                                        <button disabled={(docUE && docUE.nom_fichier) ? false : true} type="button" className="btn btn-document" onClick={e => openDocument(e, docUE)}>
                                                            <span>{docUE ? docUE.nom_fichier : "Non renseigné"}</span>
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 2e fichier PDF */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">Notice Utilisateur</div>
                                                    </div>
                                                </div>
                                                <div className="button-document-container">
                                                    <div className="rapport-sup-button">
                                                        <button disabled={(docUtilisateur && docUtilisateur.nom_fichier) ? false : true} type="button" className="btn btn-document" onClick={e => openDocument(e, docUtilisateur)}>
                                                            <span>{docUtilisateur ? docUtilisateur.nom_fichier : "Non renseigné"}</span>
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 3e fichier PDF */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">Notice Vérificateur 1</div>
                                                    </div>
                                                </div>
                                                <div className="button-document-container">
                                                    <div className="rapport-sup-button">
                                                        <button disabled={(docVerificateur1 && docVerificateur1.nom_fichier) ? false : true} type="button" className="btn btn-document" onClick={e => openDocument(e, docVerificateur1)}>
                                                            <span>{docVerificateur1 ? docVerificateur1.nom_fichier : "Non renseigné"}</span>
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* 4e fichier PDF */}
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">Notice Verificateur 2</div>
                                                    </div>
                                                </div>
                                                <div className="button-document-container">
                                                    <div className="rapport-sup-button">
                                                        <button disabled={(docVerificateur2 && docVerificateur2.nom_fichier) ? false : true} type="button" className="btn btn-document" onClick={e => openDocument(e, docVerificateur2)}>
                                                            <span>{docVerificateur2 ? docVerificateur2.nom_fichier : "Non renseigné"}</span>
                                                            <i className="fas fa-eye"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab={'Montage'} key={3}>
                                        <>
                                            <div className="form-row">
                                                <div className="form-photo montage-form-photo">
                                                    {
                                                        modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_montage !== undefined &&
                                                        <div className="form-photo-1">
                                                            <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_montage}`} alt="Montage" />
                                                        </div>
                                                    }
                                                    <div className="form-photo-2 montage-photo">
                                                        <img src={Montage} alt="Fabrication" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Détail Montage
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="detail_montage" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.detail_montage} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <span className="details-item">
                                                    Rapport Longueur/Hauteur : 11.5/8.5
                                                </span>
                                            </div>
                                        </>
                                    </TabPane>
                                    <TabPane tab={'Navigation'} key={4}>
                                        <>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Accès Logiciel
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="manip_logiciel" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_logiciel} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Essai kilométrique
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="essai_kilometrique" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.essai_kilometrique} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Accès Tarifs
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="manip_tarifs" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_tarifs} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Programmation
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="programmation" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.programmation} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </TabPane>
                                    <TabPane tab={'Fabricant'} key={5}>
                                        <div className="modele-container">
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Marques Fabricant
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_fabricant_1" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_fabricant_1} />
                                                        </div>
                                                        <div className="form-input">
                                                            <Field name="marque_fabricant_2" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_fabricant_2} />
                                                        </div>
                                                        <div className="form-input">
                                                            <Field name="marque_fabricant_3" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_fabricant_3} />
                                                        </div>
                                                        <div className="form-input">
                                                            <Field name="marque_fabricant_4" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_fabricant_4} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Organisme(s) notifié(s)
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="organisme_notifie" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.organisme_notifie} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Marquage Fabricant
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-photo p-2">
                                                        {
                                                            modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_fabrication !== undefined &&
                                                            <div className="form-photo-1">
                                                                <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.photo_fabrication}`} alt="Marquage" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <span className="details-item">
                                                        Rapport Longueur/Hauteur : 11.5/8.5
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab={'Installateur'} key={6}>
                                        <>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Scellements Installateur obligatoires
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="marque_1" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_1} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_2" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_2} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_3" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_3} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_4" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_4} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_5" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_5} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Scellements Installateur optionnels
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="marque_option_1" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_option_1} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_option_2" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_option_2} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_option_3" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_option_3} />
                                                    </div>
                                                    <div className="form-input">
                                                        <Field name="marque_option_4" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_option_4} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </TabPane>
                                    {/* ------------ Divers ------------ */}
                                    <TabPane tab={'Divers'} key={7}>
                                        <div className="divers-header">
                                            <div className="divers-item checkbox-group">
                                                <label>Afficheur en plus : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                            <div className="divers-item checkbox-group">
                                                <label>Numéro de série obligatoire : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.serie_obligatoire ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.serie_obligatoire ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                            <div className="divers-item checkbox-group">
                                                <label>Afficheur avec CE : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marquage_ce_afficheur ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marquage_ce_afficheur ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                            <div className="divers-item checkbox-group">
                                                <label>Imprimante intégrée : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.imprimante_integree ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.imprimante_integree ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                            <div className="divers-item checkbox-group">
                                                <label>Lumineux amovible : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.amovible ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.amovible ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                            <div className="divers-item checkbox-group">
                                                <label>Logiciel obligatoire : </label>
                                                <span
                                                    className={"details-item" + (modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logiciel_obligatoire ? " option-yes" : "")}
                                                >
                                                    {modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logiciel_obligatoire ? "Oui" : "Non"}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="divers-body">
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Essai Extinction
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="taximetre_extinction" className="form-control form-infos" disabled as="textarea" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.taximetre_extinction} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Scellement Tarif
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="scellement_tarif" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.scellement_tarif} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Ampoules
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="ampoules" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.ampoules} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Test autoblocage
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="lumineux_autoblocage" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.lumineux_autoblocage} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Lié au Taximètre
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="liaison_taximetre" className="form-control form-infos" disabled as="select" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.liaison_taximetre} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-cell-label">
                                                    <div className="form-text">
                                                        <div className="indication-title">
                                                            Lien Net
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="lien_net" className="form-control form-infos" disabled type="text" value={modele && modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.lien_net} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    {/* ------------ Etiquette k ------------ */}
                                    <TabPane tab={'Etiquette k'} key={8}>
                                        <div className="modele-container">
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Etiquette k
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-photo">
                                                        {
                                                            modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logo_k !== undefined &&
                                                            <div className="form-photo-1">
                                                                <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logo_k}`} alt="Marquage" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Acces k
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="marque_fabricant_1" className="form-control form-infos" disabled as="textarea" value={modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_k} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab={'Etalonnage'} key={10}>
                                        <div className="modele-container">
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Détermination k
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input">
                                                            <Field name="determination_k" className="form-control form-infos" disabled as="textarea" value={modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.determination_k} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-col">
                                                <div className="form-row">
                                                    <div className="form-photo">
                                                        {
                                                            !isNullOrUndefined(modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.etalonnage_manipulation) &&
                                                            <div className="form-photo-1">
                                                                <img src={`data:image/jpeg;base64,${modele.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.etalonnage_manipulation}`} alt="Etalonnage" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )

}

export default Modele