import React, { useEffect, useState } from "react"
import { Table } from 'react-bootstrap'
import { supervisionService } from "../../../../services/supervisionService"
import Checkbox from '@material-ui/core/Checkbox'
import { Formik, Form } from 'formik'
import DetailAutresPopup from "../../../Popup/DetailAutresPopup"
import { useSaisieContext } from "../SaisieContext"
import { dataService } from "../../../../helpers/dataService"
import { toast } from "react-toastify"
import { lockBody, unlockBody } from "../../../../helpers/utils"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { storage } from "../../../../helpers/storageHelper"

const Autres = (props) => {

    const [typeAppareils, setTypeAppareils] = useState(null)
    const [autres, setAutres] = useState(null)
    const [autres_intervention, setAutres_intervention] = useState(null)
    const [selectedAppareils, setSelectedAppareils] = useState(new Map())
    const [popupOpened, setPopupOpened] = useState(false)
    const [selectedMateriel, setSelectedMateriel] = useState(null)
    const [selectedMaterielText, setSelectedMaterielText] = useState("")
    const [taximetre, setTaximetre] = useState(null)
    const { setFormVerif, setFormMateriel, okList } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const [loadingButton, setLoadingButton] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initializeData = async () => {
            try {
                // Chargement parallèle des données
                const [materielData, interventionData, taximetreData] = await Promise.all([
                    storage.get('materiel'),
                    storage.get('intervention'),
                    storage.get('taximetre')
                ])

                if (!materielData || !interventionData || !taximetreData) {
                    console.error('Données manquantes')
                    return
                }

                setAutres(materielData)
                setAutres_intervention(interventionData)
                setTaximetre(taximetreData)

                // Chargement des types d'appareils
                await getTypeAppareils()

                // Initialisation des appareils sélectionnés
                await initializeSelectedAppareils(materielData)

            } catch (error) {
                console.error('Erreur lors de l\'initialisation:', error)
                toast.error("Erreur lors du chargement des données", { containerId: 'App' })
            } finally {
                setLoading(false)
            }
        }

        initializeData()
    }, [])

    const getTypeAppareils = async () => {
        try {
            const response = await supervisionService.getTypeTousAppareils()
            const filteredAppareils = response.filter(appareil =>
                appareil.numero_type !== 5 &&
                appareil.numero_type !== 8 &&
                appareil.numero_type !== 15
            )
            setTypeAppareils(filteredAppareils)
        } catch (error) {
            console.error('Erreur lors du chargement des types d\'appareils:', error)
            toast.error("Erreur lors du chargement des types d'appareils", { containerId: 'App' })
        }
    }

    const initializeSelectedAppareils = async (materielData) => {
        try {
            const liaisons = await storage.get('liaisons')
            const newSelectedAppareils = new Map()

            if (liaisons) {
                liaisons.forEach(([key, value]) => {
                    newSelectedAppareils.set(key, value)
                })
            } else {
                if (materielData.length === 1) {
                    materielData[0].numero_materiel = 1
                    materielData[0].numero_type_interne = 1
                    newSelectedAppareils.set(materielData[0].numero_type_interne, materielData[0])
                } else {
                    materielData.forEach(materiel => {
                        newSelectedAppareils.set(materiel.numero_type_interne, materiel)
                    })
                }
                await storage.set('liaisons', Array.from(newSelectedAppareils.entries()))
            }

            setSelectedAppareils(newSelectedAppareils)
        } catch (error) {
            console.error('Erreur lors de l\'initialisation des appareils:', error)
            toast.error("Erreur lors de l'initialisation des appareils", { containerId: 'App' })
        }
    }

    // useEffect(() => {

    //     const getTypeAppareils = async () => {
    //         await supervisionService.getTypeTousAppareils()
    //             .then(response => {
    //                 let _typeAppareils = response.filter(appareil => appareil.numero_type !== 5 && appareil.numero_type !== 8 && appareil.numero_type !== 15)
    //                 setTypeAppareils(_typeAppareils)
    //             })
    //             .catch(error => console.log(error))
    //     }

    //     const saveAppareils = () => {
    //         if (localStorage.liaisons) {
    //             let liaisons = JSON.parse(localStorage.liaisons)
    //             for (let i = 0; i < liaisons.length; i++) {
    //                 setSelectedAppareils(selectedAppareils.set(liaisons[i][0], liaisons[i][1]))
    //             }
    //         } else {
    //             if (autres.length === 1) {
    //                 autres[0].numero_materiel = 1
    //                 autres[0]["numero_type_interne"] = 1
    //                 setSelectedAppareils(selectedAppareils.set(autres[0].numero_type_interne, autres[0]))
    //             } else {
    //                 for (let i = 0; i < autres.length; i++) {
    //                     setSelectedAppareils(selectedAppareils.set(autres[i].numero_type_interne, autres[i]))
    //                 }
    //             }
    //             localStorage.setItem("liaisons", JSON.stringify(Array.from(selectedAppareils.entries())))
    //         }
    //     }

    //     getTypeAppareils()
    //     saveAppareils()
    // }, [selectedAppareils, autres])

    const handleChange = async (e, values, setFieldValue) => {
        try {
            const numero = parseInt(e.target.value)
            const newSet = new Map(values.set)

            // Récupération du matériel actuel
            const materiel = await storage.get('materiel')
            if (!materiel) {
                throw new Error('Données matériel non disponibles')
            }

            if (numero !== 1 && !values.set.has(numero)) {
                // Ajout d'un nouvel appareil
                const store = await new Promise(resolve => {
                    dataService.getIntervention().subscribe(store => {
                        resolve(store)
                    })
                })

                if (store.currentInt) {
                    const newAppareil = {
                        cet: "",
                        clef_lien: store.currentInt.clef_lien,
                        clef_stationnement: store.currentInt.clef_stationnement,
                        emplacement1: 0,
                        emplacement2: 0,
                        emplacement3: 0,
                        emplacement4: 0,
                        etat: 0,
                        marque_1: "",
                        marque_1_optionnel: false,
                        marque_2: "",
                        marque_2_optionnel: false,
                        marque_3: "",
                        marque_3_optionnel: false,
                        marque_4: "",
                        marque_4_optionnel: false,
                        marque_5: "",
                        marque_5_optionnel: false,
                        marque_appareil: 0,
                        modele_appareil: 0,
                        numero_adherent: store.currentInt.numero_adherent,
                        numero_cet: 0,
                        numero_client: store.currentInt.numero_client,
                        numero_intervention: store.currentInt.numero_intervention,
                        numero_marque_1: 0,
                        numero_marque_2: 0,
                        numero_marque_3: 0,
                        numero_marque_4: 0,
                        numero_marque_5: 0,
                        numero_type_interne: numero,
                        scelles_fabricant: 0,
                        scelles_installateur: 0,
                        serie_appareil: "",
                    }
                    newSet.set(numero, newAppareil)
                    materiel.push(newAppareil)
                }
            } else if (numero !== 1 && values.set.has(numero)) {
                // Suppression d'un appareil
                newSet.delete(numero)
                const updatedMateriel = materiel.filter(mat => mat.numero_type_interne !== numero)
                await storage.set('materiel', updatedMateriel)
                setFormMateriel(updatedMateriel)
            }

            // Mise à jour des états et du storage
            setFieldValue("set", newSet)
            await storage.set('liaisons', Array.from(newSet.entries()))
            await storage.set('materiel', materiel)
            setFormMateriel(materiel)
        } catch (error) {
            console.error('Erreur lors de la modification des appareils:', error)
            toast.error("Erreur lors de la modification des appareils", { containerId: 'App' })
        }
    }

    const openDetail = (e, values, numero) => {
        e.preventDefault()
        // Evite d'ouvir le popup lors du click sur le Checkbox
        //console.log(values.set)
        if (e.target.type !== "checkbox" && values.set.has(numero)) {
            setSelectedMateriel(values.set.get(numero))
            setPopupOpened(true)
            lockBody()
        }
    }

    const closeDetail = () => {
        setPopupOpened(false)
        unlockBody()
    }

    const setMaterielText = (appareil) => {
        // Réduit la taille de texte des types des appareils
        if (appareil.numero_type === 3
            || appareil.numero_type === 4) {
            setSelectedMaterielText((appareil.nom_type).split(" ")[0])
        } else {
            setSelectedMaterielText((appareil.nom_type))
        }
    }

    const handleEgalPrecedentAutres = async (e, values, setFieldValue, setValues) => {
        setLoadingButton(true)
        try {
            const data = {
                numero_adherent: autres_intervention.numero_adherent,
                numero_client: autres_intervention.numero_client,
                clef_stationnement: autres_intervention.clef_stationnement,
                clef_lien: autres_intervention.clef_lien,
                numero_intervention: autres_intervention.numero_intervention,
                date_intervention: autres_intervention.date_intervention,
            }

            const response = await supervisionService.egalePrecedentAutres(data)

            let appareils = new Map()
            let materiels = []

            // Gestion taximetre
            const taximetreData = await storage.get('taximetre')
            if (taximetreData) {
                taximetreData.couleur_lumineux = response.couleur_lumineux
                setFieldValue("taximetre", taximetreData)
                setFormVerif(taximetreData)
                await storage.set('taximetre', taximetreData)
            }

            if (response.materiels.length === 1) {
                // Gestion d'un seul matériel
                await handleSingleMateriel(response, values, appareils, materiels, data)
            } else {
                // Gestion de plusieurs matériels
                await handleMultipleMateriel(response, values, appareils, materiels, data)
            }

            // Mise à jour finale
            await storage.set('liaisons', Array.from(appareils.entries()))
            setValues(prev => ({
                ...prev,
                set: appareils
            }))
            setFieldValue("set", appareils)
            setFormMateriel(materiels)
            setSelectedAppareils(appareils)
            await storage.set('materiel', materiels)

            toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'App' })
        } catch (error) {
            console.error('Erreur lors de la récupération des données précédentes:', error)
            toast.info(error, { containerId: 'App' })
        } finally {
            setLoadingButton(false)
        }
    }

    const handleSingleMateriel = async (response, values, appareils, materiels, data) => {
        response.materiels[0].numero_materiel = 1
        response.materiels[0].numero_type_interne = 1

        let selectedAppareil = values.set.get(response.materiels[0].numero_type_interne)

        // Mise à jour des données de l'intervention actuelle
        selectedAppareil.numero_adherent = data.numero_adherent
        selectedAppareil.numero_client = data.numero_client
        selectedAppareil.clef_stationnement = data.clef_stationnement
        selectedAppareil.clef_lien = data.clef_lien
        selectedAppareil.numero_intervention = data.numero_intervention
        /* Fin */
        selectedAppareil.marque_appareil = response.materiels[0].marque_appareil
        selectedAppareil.modele_appareil = response.materiels[0].modele_appareil
        selectedAppareil.modeleTousAppareils.marque_appareil = response.materiels[0].marque_appareil
        selectedAppareil.modeleTousAppareils.modele_appareil = response.materiels[0].modele_appareil
        selectedAppareil.serie_appareil = response.materiels[0].serie_appareil
        selectedAppareil.modeleTousAppareils.marque_nom_appareil = response.materiels[0].marque_nom_appareil
        selectedAppareil.modeleTousAppareils.modele_nom_appareil = response.materiels[0].modele_nom_appareil
        selectedAppareil.etat = response.materiels[0].etat
        selectedAppareil.emplacement1 = response.materiels[0].emplacement1
        selectedAppareil.emplacement2 = response.materiels[0].emplacement2
        selectedAppareil.emplacement3 = response.materiels[0].emplacement3
        selectedAppareil.emplacement4 = response.materiels[0].emplacement4
        selectedAppareil.modeleTousAppareils.serie_obligatoire = response.materiels[0].serie_obligatoire

        // Mise à jour des marques
        for (let i = 1; i <= 5; i++) {
            if (response.materiels[0][`marque_${i}`] !== "") {
                selectedAppareil[`marque_${i}`] = response.materiels[0][`marque_${i}`]
                selectedAppareil[`numero_marque_${i}`] = response.materiels[0][`numero_marque_${i}`]
            }
        }

        appareils.set(response.materiels[0].numero_type_interne, selectedAppareil)
        materiels.push(selectedAppareil)
    }

    const handleMultipleMateriel = async (response, values, appareils, materiels, data) => {
        // Ajouter cette vérification
        if (!response?.materiels?.[0]) {
            console.error('Pas de matériels ou données disponible')
        }

        // Traitement du taximètre si présent
        if (response.materiels[0].numero_type_interne === 1) {
            const infos = await storage.get('infos')
            if (!infos) throw new Error('Données infos non disponibles')

            let selectedTaximetre = values.set.get(1)

            /* On remet les données de l'intervention actuelle */
            selectedTaximetre.numero_adherent = data.numero_adherent
            selectedTaximetre.numero_client = data.numero_client
            selectedTaximetre.clef_stationnement = data.clef_stationnement
            selectedTaximetre.clef_lien = data.clef_lien
            selectedTaximetre.numero_intervention = data.numero_intervention
            /* Fin */
            selectedTaximetre.marque_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
            selectedTaximetre.modele_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil
            selectedTaximetre.modeleTousAppareils.marque_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
            selectedTaximetre.modeleTousAppareils.modele_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil
            selectedTaximetre.modeleTousAppareils.marque_nom_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil
            selectedTaximetre.modeleTousAppareils.modele_nom_appareil = infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil
            selectedTaximetre.emplacement1 = response.materiels[0].emplacement1
            selectedTaximetre.emplacement2 = response.materiels[0].emplacement2
            selectedTaximetre.emplacement3 = response.materiels[0].emplacement3
            selectedTaximetre.emplacement4 = response.materiels[0].emplacement4

            // Mise à jour des marques pour le taximètre
            for (let i = 1; i <= 5; i++) {
                if (response.materiels[0][`marque_${i}`] !== "") {
                    selectedTaximetre[`marque_${i}`] = response.materiels[0][`marque_${i}`]
                    selectedTaximetre[`numero_marque_${i}`] = response.materiels[0][`numero_marque_${i}`]
                }
            }

            appareils.set(1, selectedTaximetre)
            materiels.push(selectedTaximetre)
        }

        // Traitement des autres matériels
        for (let i = 1; i < response.materiels.length; i++) {
            let selectedMateriel = response.materiels[i]
            /* On remet les données de l'intervention actuelle */
            selectedMateriel.numero_adherent = data.numero_adherent
            selectedMateriel.numero_client = data.numero_client
            selectedMateriel.clef_stationnement = data.clef_stationnement
            selectedMateriel.clef_lien = data.clef_lien
            selectedMateriel.numero_intervention = data.numero_intervention
            /* Fin */
            appareils.set(response.materiels[i].numero_type_interne, selectedMateriel)
            materiels.push(selectedMateriel)
        }
    }

    if (loading || !autres || !autres_intervention || !taximetre) {
        return <div>Chargement...</div>
    }

    return (
        <div className="autres">
            <div className="infos-section-container">
                <h1>Sélection d'appareils</h1>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ set: selectedAppareils, taximetre: taximetre }}
                    validate={async (values) => {
                        const errors = {}
                        try {
                            let taximetre = await storage.get('taximetre')

                            // Mise à jour des interventions
                            taximetre.c_autoblocage = values.taximetre.c_autoblocage
                            taximetre.c_ampoules = values.taximetre.c_ampoules
                            taximetre.c_lumineux_amovible = values.taximetre.c_lumineux_amovible
                            taximetre.logiciel_afficheur = values.taximetre.logiciel_afficheur
                            taximetre.c_r40 = values.taximetre.c_r40
                            taximetre.r40 = values.taximetre.r40
                            taximetre.c_r41 = values.taximetre.c_r41
                            taximetre.c_r42 = values.taximetre.c_r42
                            taximetre.c_r45 = values.taximetre.c_r45
                            taximetre.c_r46 = values.taximetre.c_r46
                            taximetre.c_r47 = values.taximetre.c_r47
                            taximetre.c_r30 = values.taximetre.c_r30
                            taximetre.r30 = values.taximetre.r30
                            taximetre.c_r31 = values.taximetre.c_r31
                            taximetre.r31 = values.taximetre.r31
                            taximetre.c_r35 = values.taximetre.c_r35
                            taximetre.r35 = values.taximetre.r35
                            taximetre.c_r38 = values.taximetre.c_r38
                            taximetre.r38 = values.taximetre.r38
                            // Partage des données
                            setFormVerif(taximetre)
                            setErrors({ ...errors })
                            // Mise à jour de localStorage
                            await storage.set('taximetre', taximetre)

                        } catch (error) {
                            console.error('Erreur lors de la validation:', error)
                            errors.submit = "Erreur lors de la sauvegarde"
                        }
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            alert(JSON.stringify(values, null, 2))
                            setSubmitting(false)
                        }, 400)
                    }}
                >
                    {({ isSubmitting, values, setFieldValue, setValues }) => (
                        <>
                            <div className="section-precedent">
                                <button type="button" className="cbtn cbtn-success mt-0 mb-2 c-egal-base-right c-absolute" onClick={e => handleEgalPrecedentAutres(e, values, setFieldValue, setValues)}>
                                    {loadingButton ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /> : "Egal Base"}
                                </button>
                            </div>
                            <div className="appareils-liste">
                                <Form autoComplete="off">
                                    <Table hover size="sm">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Type d'appareils</th>
                                                <th>N° Série</th>
                                                <th>Marque</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {typeAppareils && typeAppareils.map((appareil, index) =>
                                                <tr key={index} onClick={e => { openDetail(e, values, appareil.numero_type); setMaterielText(appareil) }} className={(okList.indexOf("A" + appareil.numero_type) !== -1 ? "isok" : undefined)}>
                                                    <td>
                                                        <Checkbox
                                                            checked={values.set.has(appareil.numero_type)}
                                                            name="appareil"
                                                            onChange={e => handleChange(e, values, setFieldValue)}
                                                            value={appareil.numero_type}
                                                            inputProps={{
                                                                'aria-label': 'primary checkbox'
                                                            }} />
                                                    </td>
                                                    <td>{appareil.nom_type}</td>
                                                    <td>{values.set.has(appareil.numero_type) && values.set.get(appareil.numero_type).serie_appareil}</td>
                                                    <td>{values.set.has(appareil.numero_type) && values.set.get(appareil.numero_type).modeleTousAppareils && values.set.get(appareil.numero_type).modeleTousAppareils.marque_nom_appareil}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                    {popupOpened && <DetailAutresPopup setValues={setValues} title={selectedMaterielText} close={closeDetail} data={selectedMateriel} setFieldValue={setFieldValue} values={values} setFormMateriel={setFormMateriel} />}
                                </Form>
                            </div>
                        </>
                    )}
                </Formik>

            </div>
        </div>
    )

}

export default Autres