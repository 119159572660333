import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { supervisionService } from '../../../services/supervisionService'
import { BiArrowFromRight } from "react-icons/bi"
import { UserCase, UserCaseV2 } from './constant/UserCase'
import history from "./../../../helpers/history"
import { Router, Route, Switch, Link, Redirect, Prompt } from "react-router-dom"
import Infos from "./Infos/Infos"
import Modele from "./Modele/Modele"
import Procedure from "./Procedure/Procedure"
import Sanction from "./Sanction/Sanction"
import Photos from "./Photos/Photos"
import Liaisons from "./Liaisons/Liaisons"
import Lumineux from "./Lumineux/Lumineux"
import Autres from "./Autres/Autres"
import Taximetre from "./Taximetre/Taximetre"
import ReceptionV from "./ReceptionV/ReceptionV"
import ReceptionP from "./ReceptionP/ReceptionP"
import Metrologie from "./Metrologie/Metrologie"
import { toast } from 'react-toastify'
import { useSaisieContext } from './SaisieContext'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { defaultTaximetre, formatDate, getDegatVignette, getImprimanteLabel, getNomLiaisonTax, getNomMateriel, getSaisieTitleName, getTextInterventionDetail, isCorrectDate, isNullOrUndefined, isRadioButtonValid, isValidDate, lockBody, loggerDev, redirectSaisie, renderHistoriqueAfficheur, showVignetteStatus, unlockBody } from '../../../helpers/utils'
import localForage from 'localforage'
import { useDataContext } from '../../../providers/DataContextProvider'
import useConfirm from './../../../hooks/useConfirm'
import { useAuth } from '../../../providers/authProvider'
import Imprimante from './Imprimante/Imprimante'
import SignaturePopup from '../../Popup/SignaturePopup'
import Etalonnage from './Etalonnage/Etalonnage'
import Installateur from './Installateur/Installateur'
import { storage } from '../../../helpers/storageHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const SaisieIntervention = (props) => {

    const classes = useStyles()
    const [initial, setinitial] = useState(null)
    const [intervenants, setintervenants] = useState([])
    const [showMenu, setshowMenu] = useState(true)
    const [disableSave, setDisableSave] = useState(true)
    const { formVerif, setFormVerif,
        formIntervention, setFormIntervention,
        formPhotos, setFormPhotos,
        formMateriel, setFormMateriel,
        formVignette, setFormVignette,
        okList, setOkList, isReelle, isUniv, isBanc,
        vpVisible, vaiVisible, nbChutes,
        emtMoins, emtPlus, historiqueAfficheur, currentSaisieTitle, setCurrentSaisieTitle, stopAll, setStopAll, calculK, setScellementTarifTaximetre, setScellementTarifAfficheur, setIsUniv, setIsReelle, setIsBanc,
        activeKeyTab, handleChangeTab } = useSaisieContext()
    const { interventionStationnement, setInterventionStationnement, interventionInfoGeneral, selectedIntervention } = useDataContext()
    // On garde ici les valeurs du formulaire pour comparer
    // avec la nouvelle afin de griser ou non les boutons de sauvegardes
    const [initialFormVerif, setInitialFormVerif] = useState(null)
    const [initialFormIntervention, setInitialFormIntervention] = useState(null)
    const [initialFormPhotos, setInitialFormPhotos] = useState(null)
    const [initialFormMateriel, setInitialFormMateriel] = useState(null)
    const [initialFormVignette, setInitialFormVignette] = useState(null)
    const [isSavingPartially, setIsSavingPartially] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [cets, setCets] = useState([])
    const [signatures, setSignatures] = useState([])
    const toastSavingId = useRef(null)
    const { isConfirmedDeblocage } = useConfirm()
    const auth = useAuth()
    const userId = auth.userId
    const userGroup = parseInt(auth.userGroup)
    const [shouldBlockNavigation] = useState(true)
    const [pageList, setPageList] = useState(["infos", "reception-vehicule", "reception-pneumatique", "taximetre", "autres", "lumineux", "liaisons", "metrologie", "imprimante", "photos", "sanction"])
    const [stickySave, setStickySave] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const { blockSaveMetrologie } = useSaisieContext()

    useEffect(() => {
        window.addEventListener('scroll', stickySaveFunction)
        return () => {
            window.removeEventListener('scroll', stickySaveFunction)
        }
    }, [])

    // Gestion de l'ajout des sections etalonnage
    // dans la pagination automatique
    useEffect(() => {
        if (!(interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false)) {
            let _pageList = pageList
            let index = 3
            if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                _pageList.splice(index, 0, "etalonnage")
                index += 1
            }
            _pageList.splice(index, 0, "installateur")
            setPageList(_pageList)
        }
    }, [])

    const stickySaveFunction = () => {
        if (window !== undefined) {
            if (window.matchMedia('only screen and (max-width: 767px)').matches) {
                if (window.scrollY > 60) {
                    setStickySave(true)
                    document.body.style.paddingTop = '55px'
                }
                else {
                    setStickySave(false)
                    document.body.style.paddingTop = '0'
                }
            }
        }
    }

    // Gestion de l'affichage du titre de la section
    useEffect(() => {
        let currentPage = history.location.pathname.replace(history.location.pathname.includes("intervention-v2") ? "/intervention-v2/" : "/intervention/", "")
        setCurrentSaisieTitle(getSaisieTitleName(currentPage))
        saveAutomatically(currentPage)
    }, [history.location.pathname])

    /**
     * Sauvegarde automatiquement la saisie au changement d'une page
     * @param {String} currentPage 
     */
    const saveAutomatically = (currentPage) => {
        if (process.env.NODE_ENV !== 'development' || userGroup !== 0) {
            if (history.location.state && currentPage !== history.location.state.prevPath && disableSave === false) {
                if (window.confirm('Souhaitez-vous sauvegarder partiellement la saisie ?')) {
                    handleSubmitPartially()
                }
            }
        }
    }

    // Boutons de changement de pages/section de la saisie
    const handleChangePage = e => {
        let currentButton = e.currentTarget.id
        let currentPage = history.location.pathname.replace(history.location.pathname.includes("intervention-v2") ? "/intervention-v2/" : "/intervention/", "")
        let currentTaximetrePage = activeKeyTab
        let indexCurrentPage = pageList.indexOf(currentPage)
        // Bouton précédent
        if (currentButton === "b1") {
            if (pageList[indexCurrentPage - 1]) {
                if (pageList[indexCurrentPage] === "taximetre") {
                    if (currentTaximetrePage === "1") {
                        // On redirige à la page suivante
                        history.push(pageList[indexCurrentPage - 1], { prevPath: currentPage })
                    }
                    else {
                        let newTaximetrePage = (parseInt(currentTaximetrePage) - 1)
                        handleChangeTab(newTaximetrePage.toString())
                    }
                }
                else {
                    history.push(pageList[indexCurrentPage - 1], { prevPath: currentPage })
                }
            }
            else {
                // On redirige à la page du début
                history.push(pageList[pageList.length - 1], { prevPath: currentPage })
            }
        }
        // Bouton suivant
        else if (currentButton === "b2") {
            if (pageList[indexCurrentPage + 1]) {
                // Dans taximetre 
                if (pageList[indexCurrentPage] === "taximetre") {
                    if (currentTaximetrePage === "7") {
                        // On redirige à la page suivante
                        history.push(pageList[indexCurrentPage + 1], { prevPath: currentPage })
                    }
                    else {
                        let newTaximetrePage = (parseInt(currentTaximetrePage) + 1)
                        handleChangeTab(newTaximetrePage.toString())
                    }
                }
                else {
                    loggerDev("Pas taximetre")
                    history.push(pageList[indexCurrentPage + 1], { prevPath: currentPage })
                }
            }
            else {
                // On redirige à la page du début
                history.push(pageList[0], { prevPath: currentPage })
            }
        }
    }

    useEffect(() => {
        const loadSanctionData = async () => {
            try {
                const [taximetreData, interventionData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention')

                ]);
                if (taximetreData && interventionData) {
                    controle_saisie();
                }
            } catch (error) {
                console.error('Error loading sanction data:', error);
            }
        };

        loadSanctionData();
    }, []); // Empty dependency array means this runs once when component mounts

    /**
     * Récupération des données nécessaires à la saisie
     */
    useEffect(() => {

        let data = {}
        let taxData = {}
        const currentIntervention = selectedIntervention.data

        const initializeData = async () => {

            const initial = {
                sign1: currentIntervention?.sign1,
                revision_rapport: currentIntervention?.revision_rapport,
                numero_oi_interne: currentIntervention?.numero_oi_interne,
                numero_commande: !currentIntervention?.numero_commande ? "" : currentIntervention?.numero_commande,
                notes: currentIntervention?.notes == null ? "" : currentIntervention?.notes,
                numero_intervenant: await storage.get('taximetre') ?
                    (await storage.get('taximetre')).numero_intervenant : 0
            }
            setinitial(initial)
            setInitialFormIntervention(await storage.get('intervention'))
        }

        initializeData()

        data = {
            numero_adherent: currentIntervention?.numero_adherent,
            numero_client: currentIntervention?.numero_client,
            clef_stationnement: currentIntervention?.clef_stationnement,
            clef_lien: currentIntervention?.clef_lien
        }
        taxData = {
            numero_adherent: currentIntervention?.numero_adherent,
            numero_client: currentIntervention?.numero_client,
            clef_stationnement: currentIntervention?.clef_stationnement,
            clef_lien: currentIntervention?.clef_lien,
            numero_intervention: currentIntervention?.numero_intervention
        }
        const getCetRequest = {
            marque_taximetre: currentIntervention?.vehiculeTaximetre?.taximetre?.marque_taximetre,
            modele_taximetre: currentIntervention?.vehiculeTaximetre?.taximetre?.modele_taximetre,
        }

        async function getInfos(data) {
            setStopAll(true)
            return await supervisionService.getInfos(data)
                .then(async response => {
                    // localStorage.setItem("infos", JSON.stringify(response))
                    await storage.set('infos', response)
                    //process.env.NODE_ENV === "development" && console.log(response)
                    return response
                })
                .finally(() => setStopAll(false))
        }

        const getInterventionTaximetre = async (data, infos) => {
            setStopAll(true)
            await supervisionService.getInterventionTaximetre(data)
                .then(async response => {
                    // Gestion de la métrologie fenetre piste
                    if (response.position_kilo >= 99) {
                        setIsUniv(true)
                        setIsBanc(false)
                        setIsReelle(false)
                    }
                    else if (response.position_kilo <= -1000) {
                        setIsBanc(true)
                        setIsUniv(false)
                        setIsReelle(false)
                    }
                    else {
                        setIsReelle(true)
                        setIsUniv(false)
                        setIsBanc(false)
                    }
                    // localStorage.setItem("taximetre", JSON.stringify(response))
                    await storage.set('taximetre', response)
                    setFormVerif(response)
                    setInitialFormVerif(response)
                    setinitial(prev => {
                        return {
                            ...prev,
                            anomalies: response.anomalies,
                            numero_intervenant: response.numero_intervenant
                        }
                    })
                })
                .catch(async error => {
                    defaultTaximetre.numero_adherent = infos.numero_adherent
                    defaultTaximetre.numero_client = infos.numero_client
                    defaultTaximetre.clef_stationnement = infos.stationnements[0].clef_stationnement
                    defaultTaximetre.clef_lien = infos.stationnements[0].vehiculeTaximetres[0].clef_lien
                    defaultTaximetre.numero_intervention = currentIntervention.numero_intervention
                    defaultTaximetre.numero_intervenant = currentIntervention.numero_intervenant_prevu
                    // localStorage.setItem("taximetre", JSON.stringify(defaultTaximetre))
                    await storage.set('taximetre', defaultTaximetre)
                    setFormVerif(defaultTaximetre)
                    setInitialFormVerif(defaultTaximetre)
                    setinitial(prev => {
                        return {
                            ...prev,
                            anomalies: defaultTaximetre.anomalies,
                            numero_intervenant: defaultTaximetre.numero_intervenant
                        }
                    })
                })
                .finally(() => setStopAll(false))
        }

        const getTaximetreVignette = async (taxData, infos) => {
            setStopAll(true)
            await supervisionService.getVehiculeTaximetreVignettes(taxData)
                .then(async response => {
                    if (response.numero_vignette === 0 && response.validite_vignette === -1) {
                        response.etat_vignette = 3
                    }
                    else if (response.numero_vignette !== 0 && response.validite_vignette === 0) {
                        response.etat_vignette = 2
                    }
                    else if ((response.validite_vignette !== 0 && response.validite_vignette !== -1)) {
                        response.etat_vignette = 1
                    }
                    response.annee_vignette = response.validite_vignette
                    // localStorage.setItem("taxvignette", JSON.stringify(response))
                    await storage.set('taxvignette', response)
                    setFormVignette(response)
                    setInitialFormVignette(response)
                })
                .catch(async error => {
                    let defaultVignette = {}
                    defaultVignette.numero_adherent = infos.numero_adherent
                    defaultVignette.numero_client = infos.numero_client
                    defaultVignette.clef_stationnement = infos.stationnements[0].clef_stationnement
                    defaultVignette.clef_lien = infos.stationnements[0].vehiculeTaximetres[0].clef_lien
                    defaultVignette.numero_intervention = currentIntervention.numero_intervention
                    defaultVignette.numero_vignette = 0
                    defaultVignette.validite_vignette = 0
                    defaultVignette.etat_vignette = 0
                    defaultVignette.annee_vignette = 0
                    // localStorage.setItem("taxvignette", JSON.stringify(defaultVignette))
                    await storage.set('taxvignette', defaultVignette)
                    setFormVignette(defaultVignette)
                    setInitialFormVignette(defaultVignette)
                    //process.env.NODE_ENV === "development" && console.log(defaultVignette)
                })
                .finally(() => setStopAll(false))
        }

        const getCets = async (request) => {
            setStopAll(true)
            await supervisionService.getModelesLog(request.marque_taximetre, request.modele_taximetre)
                .then(response => setCets(response))
                .finally(() => setStopAll(false))
        }

        const getSignaturesLogiciels = async (request) => {
            setStopAll(true)
            await supervisionService.getSignaturesLogiciel(request.marque_taximetre, request.modele_taximetre)
                .then(response => setSignatures(response))
                .finally(() => setStopAll(false))
        }

        const getIntervenants = async (infos) => {
            setStopAll(true)
            if (userGroup === 0) {
                await supervisionService.getAllIntervenant(infos.numero_adherent, false)
                    .then(response => setintervenants(response))
                    .finally(() => setStopAll(false))
            }
            else {
                await supervisionService.getAllIntervenant(userGroup, true)
                    .then(response => setintervenants(response))
                    .finally(() => setStopAll(false))
            }
        }

        const getVehiculeTaxPhotos = async (taxData) => {
            setStopAll(true)
            await supervisionService.getVehiculeTaxPhotos(taxData)
                .then(async response => {
                    // localStorage.setItem('photos', JSON.stringify(response))
                    await storage.set('photos', response)
                    setFormPhotos(response)
                    setInitialFormPhotos(response)
                })
                .finally(() => setStopAll(false))
        }

        const getVehiculeTaxMateriel = async (taxData, infos) => {
            await supervisionService.getVehiculeTaxMateriel(taxData)
                .then(async response => {
                    // localStorage.removeItem("materiel")
                    // localStorage.removeItem("liaisons")
                    await storage.remove('materiel')
                    await storage.remove('liaisons')
                    //process.env.NODE_ENV === "development" && console.log(response)
                    // localStorage.setItem("materiel", JSON.stringify(response))
                    await storage.set('materiel', response)
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].numero_type_interne === 10) {
                            setScellementTarifAfficheur(response[i].modeleTousAppareils.scellement_tarif)
                        }
                        if (response[i].numero_type_interne === 1) {
                            setScellementTarifTaximetre(response[i].modeleTousAppareils.scellement_tarif)
                        }
                    }
                    setFormMateriel(response)
                    setInitialFormMateriel(response)
                })
                .catch(async error => {
                    // localStorage.removeItem("materiel")
                    // localStorage.removeItem("liaisons")
                    await storage.remove('materiel')
                    await storage.remove('liaisons')
                    //process.env.NODE_ENV === "development" && console.log(infos)
                    let defaultMateriel = [{
                        numero_adherent: currentIntervention.numero_adherent,
                        numero_client: currentIntervention.numero_client,
                        clef_stationnement: currentIntervention.clef_stationnement,
                        clef_lien: currentIntervention.clef_lien,
                        numero_intervention: currentIntervention.numero_intervention,
                        marque_appareil: infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.marque_taximetre,
                        modele_appareil: infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modele_taximetre,
                        serie_appareil: infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_taximetre,
                        etat: 0,
                        cet: "",
                        numero_cet: 0,
                        numero_type_interne: 1,
                        marque_1: infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_1,
                        marque_1_optionnel: false,
                        modeleTousAppareils: {
                            marque_nom_appareil: infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil,
                        }
                    }]
                    // localStorage.setItem("materiel", JSON.stringify(defaultMateriel))
                    await storage.set('materiel', defaultMateriel)
                    setFormMateriel(defaultMateriel)
                    setInitialFormMateriel(defaultMateriel)
                    //process.env.NODE_ENV === "development" && console.log(defaultMateriel)
                })
        }

        getInfos(data)
            .then(response => {
                getIntervenants(response)
                getVehiculeTaxMateriel(taxData, response)
                getInterventionTaximetre(taxData, response)
                getTaximetreVignette(taxData, response)
                setInterventionStationnement(response.stationnements[0])
            })

        getVehiculeTaxPhotos(taxData)
        // setInitialFormIntervention(JSON.parse(localStorage.intervention))
        // Le remplacement dans la fonction initializeData au dessus

        getCets(getCetRequest)
        getSignaturesLogiciels(getCetRequest)
        /*return subscription.unsubscribe*/
    }, [])

    const toggleMenu = () => {
        setshowMenu(!showMenu)
    }

    /**
     * @param {Object} initial 
     * @param {Object} actual 
     */
    const haveChanges = (initial, actual) => {
        if (initial !== null && actual !== null) {
            if (JSON.stringify(initial) !== JSON.stringify(actual)) {
                return true
            } else {
                return false
            }
        }
    }

    const controle_saisie = async () => {
        const i = formIntervention
        const ve = formVerif
        const vi = formVignette
        const p = formPhotos
        const m = formMateriel
        let currentOkList = okList
        // let infos = JSON.parse(localStorage.getItem("infos"))
        let infos = await storage.get('infos')
        let pas_ok = false

        /**
         * Contrôle Imprimante
         */
        if (i && ve) {
            if (i.controle_imprimante) {
                for (var n = 0; n < Array.from(i.controle_imprimante).length; n++) {
                    if (parseInt(Array.from(i.controle_imprimante)[n]) === 0 || Array.from(i.controle_imprimante)[n] === "") {
                        pas_ok = true
                    }
                }
            }
            if ((pas_ok === false && (ve.c_r91 === 1 || ve.c_r91 === 2 || ve.c_r91 === 3)) || interventionInfoGeneral.verification === false) {
                if (currentOkList.indexOf('Imprimante') === -1) {
                    currentOkList.push('Imprimante')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Imprimante')
                setOkList(currentOkList)
            }
        }

        /**
         * Contrôle Vehicule
        */
        // A voir deuxieme derniere partie
        if (i) {
            if ((i.kilometrage !== 0 && i.kilometrage !== "")
                && (interventionInfoGeneral.installation === true || (i.pas_vignette_presente === true || (isCorrectDate(i.date_validite) && i.marque_vignette !== "" && (i.numero_vignette !== null && i.numero_vignette !== ""))))
                && (isCorrectDate(i.date_effectuee))
                && ((interventionInfoGeneral.installation === false && i.coefficient_k_avant !== 0) || interventionInfoGeneral.installation === true)) {
                if (currentOkList.indexOf('Réception Véhicule') === -1) {
                    currentOkList.push('Réception Véhicule')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Réception Véhicule')
                setOkList(currentOkList)
            }
        }
        /**
         * Contrôle Pneumatique
        */
        if (i) {
            if ((i.pneu_dim1 !== 0 && i.pneu_dim2 !== 0 && i.pneu_dim3 !== 0 && i.pneu_pression !== 0)
                && (i.pneu_dim4 !== 0 && i.pneu_dim5 !== 0 && i.pneu_dim6 !== 0 && i.pneu_pression_ar !== 0)
                && (i.util_mano !== 0)
                && (i.usure_pneumatique !== 0 && i.pneu_ajuste !== 0)
                && (isCorrectDate(i.date_installation) && (interventionInfoGeneral.installation === true || isCorrectDate(i.date_k)))
                && (i.fourniture_carnet !== 0 && ((i.pneu_egal_carnet !== 0 && i.pneu_egal_carnet !== 4) || ((i.pneu_egal_carnet === 0 || i.pneu_egal_carnet === 4) && interventionInfoGeneral.installation === true)))
                && (i.presence_carnet !== 0 || interventionInfoGeneral.que_tarif === true)
                && (interventionStationnement.clef_dpt !== 75 || i.autorisation_stat_immatriculation !== null)) {
                if (currentOkList.indexOf('Réception Pneumatiques') === -1) {
                    currentOkList.push('Réception Pneumatiques')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Réception Pneumatiques')
                setOkList(currentOkList)
            }
        }
        /**
         * Contrôle Etalonnage
         */
        if (i) {
            if ((i.etalonnage_type !== 0 || interventionInfoGeneral.installation === false)
                && (i.instrument_etalonnage !== 0 || interventionInfoGeneral.installation === false)
                && (i.etalonnage_distance !== 0 || interventionInfoGeneral.installation === false)
                && (i.etalonnage_impulsions !== 0 || interventionInfoGeneral.installation === false)
                && (i.etalonnage_k !== 0 || interventionInfoGeneral.installation === false)) {
                if (currentOkList.indexOf('Etalonnage') === -1) {
                    currentOkList.push('Etalonnage')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Etalonnage')
                setOkList(currentOkList)
            }
        }
        /**
         * Contrôle Installateur
         */
        if (i) {
            if (i.numero_intervenant !== 0 || interventionInfoGeneral.installation === false) {
                if (currentOkList.indexOf('Installateur') === -1) {
                    currentOkList.push('Installateur')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Installateur')
                setOkList(currentOkList)
            }
        }
        /**
         * Contrôle Taximètre
        */
        let afficheur = infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur
        let france_mid = infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.france_mid
        let marquage_ce_afficheur = infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marquage_ce_afficheur

        if (ve && i) {
            let c_cet = -1
            let c_signature = -1
            let text_cet = ve.dam_cet ? ve.dam_cet : ""
            let text_signature = ""
            if (signatures && signatures.length > 0) {
                const signatureIndex = signatures.findIndex(signature => {
                    const text = signature.signature
                    return ve.logiciel.includes(text) || text.includes(ve.logiciel)
                })
                if (signatureIndex !== -1) {
                    c_signature = signatureIndex
                    text_signature = signatures[signatureIndex].signature
                }
            }
            if (cets && cets.length > 0) {
                cets.forEach((cet, index) => {
                    // Vérifie si l'item contient damCetValue ou vice versa
                    let text = cet.dam_cet /*+ (cet.acceptation !== "" ? " du " : "")*/
                    if ((ve.dam_cet).includes(text) || text.includes(ve.dam_cet)) {
                        c_cet = index // Mettre à jour l'index sélectionné
                        text_cet = text
                    }
                })
            }
            // console.log("signatures", signatures)
            // console.log(ve.logiciel)
            // console.log(text_signature)
            // console.log("cets", cets)
            // console.log(ve.dam_cet)
            // console.log(text_cet)
            // console.log((!isNullOrUndefined(ve.logiciel) && text_signature !== ""))
            // console.log((!isNullOrUndefined(ve.dam_cet) && text_cet !== ""))
            // console.log((afficheur === false || (ve.detail_acces_centrale !== "" && !isNullOrUndefined(ve.detail_acces_centrale)) || interventionInfoGeneral.que_tarif === true))
            // console.log(isRadioButtonValid(ve.c_marque_reparateur) || interventionInfoGeneral.que_tarif === true)
            // console.log(isRadioButtonValid(ve.c_elements_accessibles) || interventionInfoGeneral.que_tarif === true)
            // console.log((((france_mid === 0) || (!isNullOrUndefined(i.millesime_mid) && i.millesime_mid !== 0)) && (marquage_ce_afficheur === false || i.pas_marquage_mid_afficheur === true || (!isNullOrUndefined(i.millesime_mid_afficheur) && i.millesime_mid_afficheur !== 0))) || interventionInfoGeneral.que_tarif === true)
            // console.log(i.numero_cet_avant !== 0 || interventionInfoGeneral.que_tarif === true)
            // console.log(i.numero_cet_new !== 0 || interventionInfoGeneral.d2 === true)
            // console.log((isRadioButtonValid(ve.c_affichagea) && isRadioButtonValid(ve.c_affichageb) && isRadioButtonValid(ve.c_affichagec) && (isRadioButtonValid(ve.c_affichaged) || interventionStationnement.clef_dpt === 75)) || interventionInfoGeneral.que_tarif === true)
            // console.log((isRadioButtonValid(ve.c_affichagelumineux) && isRadioButtonValid(ve.c_mention1) && isRadioButtonValid(ve.c_mention2) && isRadioButtonValid(ve.c_mention3)) || interventionInfoGeneral.que_tarif === true)
            // console.log(((isRadioButtonValid(ve.c_cet) && isRadioButtonValid(ve.c_logiciel) && (!isNullOrUndefined(ve.dam_cet) && text_cet !== "")) || interventionInfoGeneral.que_tarif === true) && (!isNullOrUndefined(ve.logiciel) && text_signature !== ""))
            // console.log((isRadioButtonValid(ve.c_indication1) && isRadioButtonValid(ve.c_indication2) && isRadioButtonValid(ve.c_indication3) && isRadioButtonValid(ve.c_indication4)) || interventionInfoGeneral.que_tarif === true)
            // console.log(((!isNullOrUndefined(ve.etiquettek) && ve.etiquettek !== 0 && ve.etiquettek !== "") && isRadioButtonValid(ve.c_etiquettek1) && isRadioButtonValid(ve.c_etiquettek2) && isRadioButtonValid(ve.c_etiquettek3)) || interventionInfoGeneral.que_tarif === true)
            // console.log((isRadioButtonValid(ve.c_scelles) && (isRadioButtonValid(ve.c_marque_ce) || france_mid === 0)) || interventionInfoGeneral.que_tarif === true)
            // console.log(isRadioButtonValid(ve.c_ANO_maj_ml_sans_vprim) || interventionInfoGeneral.que_tarif === true)
            // console.log(isRadioButtonValid(ve.c_REF_maj_ml_sans_vprim) || interventionInfoGeneral.que_tarif === true)
            // console.log(afficheur === false || isRadioButtonValid(ve.c_ANO_afficheur_depareille) || interventionInfoGeneral.que_tarif === true)
            // console.log(afficheur === false || isRadioButtonValid(ve.c_REF_afficheur_depareille) || interventionInfoGeneral.que_tarif === true)
            // console.log(afficheur === false || isRadioButtonValid(ve.c_ANO_double_marquage_CE) || isRadioButtonValid(ve.c_REF_double_marquage_CE) || i.pas_marquage_mid_afficheur === true || marquage_ce_afficheur === false || interventionInfoGeneral.que_tarif === true)
            // console.log((!isNullOrUndefined(ve.detail_fixation) && ve.detail_fixation !== "" && isRadioButtonValid(ve.fixation) && isRadioButtonValid(ve.positionnement) && (isRadioButtonValid(ve.temporisation) || france_mid === 0)) || interventionInfoGeneral.que_tarif === true)
            // console.log((france_mid === 1 || (isRadioButtonValid(ve.c_inter_1) && isRadioButtonValid(ve.c_inter_2)) || (interventionInfoGeneral.verification === false && ve.etiquettek !== 0 && ve.etiquettek !== "" && !isNullOrUndefined(ve.etiquettek))) || interventionInfoGeneral.que_tarif === true)
            // console.log(interventionStationnement.clef_dpt !== 75 || isRadioButtonValid(i.conformite_gabarit) || interventionInfoGeneral.que_tarif === true)
            if (ve && i && (
                (afficheur === false || (ve.detail_acces_centrale !== "" && !isNullOrUndefined(ve.detail_acces_centrale)) || interventionInfoGeneral.que_tarif === true)
                && (isRadioButtonValid(ve.c_marque_reparateur) || interventionInfoGeneral.que_tarif === true)
                && (isRadioButtonValid(ve.c_elements_accessibles) || interventionInfoGeneral.que_tarif === true)
                && ((((france_mid === 0) || (!isNullOrUndefined(i.millesime_mid) && i.millesime_mid !== 0)) && (marquage_ce_afficheur === false || i.pas_marquage_mid_afficheur === true || (!isNullOrUndefined(i.millesime_mid_afficheur) && i.millesime_mid_afficheur !== 0))) || interventionInfoGeneral.que_tarif === true)
                && (i.numero_cet_avant !== 0 || interventionInfoGeneral.que_tarif === true)
                && (i.numero_cet_new !== 0 || interventionInfoGeneral.d2 === true)
                && ((isRadioButtonValid(ve.c_affichagea) && isRadioButtonValid(ve.c_affichageb) && isRadioButtonValid(ve.c_affichagec) && (isRadioButtonValid(ve.c_affichaged) || interventionStationnement.clef_dpt === 75)) || interventionInfoGeneral.que_tarif === true)
                && ((isRadioButtonValid(ve.c_affichagelumineux) && isRadioButtonValid(ve.c_mention1) && isRadioButtonValid(ve.c_mention2) && isRadioButtonValid(ve.c_mention3)) || interventionInfoGeneral.que_tarif === true)
                && (((isRadioButtonValid(ve.c_cet) && isRadioButtonValid(ve.c_logiciel) && (!isNullOrUndefined(ve.dam_cet) && text_cet !== "")) || interventionInfoGeneral.que_tarif === true) && (!isNullOrUndefined(ve.logiciel) && text_signature !== ""))
                && ((isRadioButtonValid(ve.c_indication1) && isRadioButtonValid(ve.c_indication2) && isRadioButtonValid(ve.c_indication3) && isRadioButtonValid(ve.c_indication4)) || interventionInfoGeneral.que_tarif === true)
                && (((!isNullOrUndefined(ve.etiquettek) && ve.etiquettek !== 0 && ve.etiquettek !== "") && isRadioButtonValid(ve.c_etiquettek1) && isRadioButtonValid(ve.c_etiquettek2) && isRadioButtonValid(ve.c_etiquettek3)) || interventionInfoGeneral.que_tarif === true)
                && ((isRadioButtonValid(ve.c_scelles) && (isRadioButtonValid(ve.c_marque_ce) || france_mid === 0)) || interventionInfoGeneral.que_tarif === true)
                && (isRadioButtonValid(ve.c_ANO_maj_ml_sans_vprim) || interventionInfoGeneral.que_tarif === true)
                && (isRadioButtonValid(ve.c_REF_maj_ml_sans_vprim) || interventionInfoGeneral.que_tarif === true)
                && (afficheur === false || isRadioButtonValid(ve.c_ANO_afficheur_depareille) || interventionInfoGeneral.que_tarif === true)
                && (afficheur === false || isRadioButtonValid(ve.c_REF_afficheur_depareille) || interventionInfoGeneral.que_tarif === true)
                && (afficheur === false || isRadioButtonValid(ve.c_ANO_double_marquage_CE) || isRadioButtonValid(ve.c_REF_double_marquage_CE) || i.pas_marquage_mid_afficheur === true || marquage_ce_afficheur === false || interventionInfoGeneral.que_tarif === true)
                && ((!isNullOrUndefined(ve.detail_fixation) && ve.detail_fixation !== "" && isRadioButtonValid(ve.fixation) && isRadioButtonValid(ve.positionnement) && (isRadioButtonValid(ve.temporisation) || france_mid === 0)) || interventionInfoGeneral.que_tarif === true)
                && ((france_mid === 1 || (isRadioButtonValid(ve.c_inter_1) && isRadioButtonValid(ve.c_inter_2)) || (interventionInfoGeneral.verification === false && ve.etiquettek !== 0 && ve.etiquettek !== "" && !isNullOrUndefined(ve.etiquettek))) || interventionInfoGeneral.que_tarif === true)
                && (interventionStationnement.clef_dpt !== 75 || isRadioButtonValid(i.conformite_gabarit) || interventionInfoGeneral.que_tarif === true)
            )) {
                if (currentOkList.indexOf('Taximètre') === -1) {
                    currentOkList.push('Taximètre')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Taximètre')
                setOkList(currentOkList)
            }
        }
        // Affichage
        if (ve && (isRadioButtonValid(ve.c_affichagea) && isRadioButtonValid(ve.c_affichageb) && isRadioButtonValid(ve.c_affichagec) && (isRadioButtonValid(ve.c_affichaged) || interventionStationnement.clef_dpt === 75)
            && isRadioButtonValid(ve.c_affichagelumineux) && isRadioButtonValid(ve.c_mention1) && isRadioButtonValid(ve.c_mention2) && isRadioButtonValid(ve.c_mention3))) {
            if (currentOkList.indexOf('Affichage') === -1) {
                currentOkList.push('Affichage')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Affichage')
            setOkList(currentOkList)
        }
        // Logiciel
        if (ve && i && ((isRadioButtonValid(ve.c_cet) && isRadioButtonValid(ve.c_logiciel) && !isNullOrUndefined(i.numero_cet_avant) && i.numero_cet_avant !== 0 && !isNullOrUndefined(i.numero_cet_new) && i.numero_cet_new !== 0) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('Logiciel') === -1) {
                currentOkList.push('Logiciel')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Logiciel')
            setOkList(currentOkList)
        }
        // Indication
        if (ve && ((isRadioButtonValid(ve.c_indication1) && isRadioButtonValid(ve.c_indication2) && isRadioButtonValid(ve.c_indication3) && isRadioButtonValid(ve.c_indication4)) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('Indication') === -1) {
                currentOkList.push('Indication')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Indication')
            setOkList(currentOkList)
        }
        // Etiquette k
        if (ve && (((ve.etiquettek !== 0 && ve.etiquettek !== "") && isRadioButtonValid(ve.c_etiquettek1) && isRadioButtonValid(ve.c_etiquettek2) && isRadioButtonValid(ve.c_etiquettek3)) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('Etiquettek') === -1) {
                currentOkList.push('Etiquettek')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Etiquettek')
            setOkList(currentOkList)
        }
        // Marquages
        if (ve && i && (((ve.c_marque_reparateur !== undefined && ve.c_marque_reparateur !== null) && isRadioButtonValid(ve.c_scelles)
            && (isRadioButtonValid(ve.c_marque_ce) || (infos && france_mid === 0))
            && ((i.millesime_mid !== 0 && isNaN(i.millesime_mid) === false) || (infos && france_mid === 0))) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('Marquages') === -1) {
                currentOkList.push('Marquages')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Marquages')
            setOkList(currentOkList)
        }
        // Position
        if (ve && i && (((isRadioButtonValid(i.conformite_gabarit) || interventionStationnement.clef_dpt !== 75) && ve.detail_fixation !== "" && isRadioButtonValid(ve.fixation) && isRadioButtonValid(ve.positionnement) && isRadioButtonValid(ve.temporisation)) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('Position') === -1) {
                currentOkList.push('Position')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Position')
            setOkList(currentOkList)
        }
        // Autres
        if (ve && (((afficheur === false || (ve.detail_acces_centrale !== "" && isRadioButtonValid(ve.c_ANO_afficheur_depareille) && isRadioButtonValid(ve.c_REF_afficheur_depareille))) && isRadioButtonValid(ve.c_elements_accessibles)) || interventionInfoGeneral.que_tarif === true)) {
            if (currentOkList.indexOf('TAutres') === -1) {
                currentOkList.push('TAutres')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'TAutres')
            setOkList(currentOkList)
        }
        /**
         * Contrôle Autres // Selection d'appareils
        */
        if (m && ve) {
            let afficheur_logiciel_ok = true
            let appareils = m
            let materiel = appareils.filter(materiel => materiel.numero_type_interne === 10)[0]
            let un_ok = appareils.length
            let marque_modele = false
            let pas_de_scelles = false
            let num_serie_ok = true
            let nbe_pas_ok = 0
            let info_vitesse = false
            let cet_lumineux = false

            if (materiel) {
                // if(ve.logiciel_afficheur !== 0 && ){

                // }
                if ((isNaN(materiel.marque_appareil) === false && materiel.marque_appareil !== 0 && isNaN(materiel.modele_appareil) === false && materiel.modele_appareil !== 0 && ve.logiciel_afficheur === 0)) {
                    if (interventionInfoGeneral.verification || interventionInfoGeneral.tarif === true) {
                        afficheur_logiciel_ok = false
                    }
                }
            }
            for (var a = 0; a < appareils.length; a++) {
                pas_de_scelles = false
                num_serie_ok = true
                marque_modele = false
                if (appareils[a].marque_appareil !== 0 && appareils[a].modele_appareil !== 0) {
                    marque_modele = true
                }
                if (appareils[a].modeleTousAppareils && appareils[a].modeleTousAppareils.serie_obligatoire === true /* a voir avec stephan*/) {
                    let data = { marque: appareils[a].marque_appareil, modele: appareils[a].modele_appareil }
                    supervisionService.getNumTaxSerieObligatoire(data)
                        // eslint-disable-next-line no-loop-func
                        .then(response => {
                            if (response === true) {
                                num_serie_ok = false
                            }
                        })
                }
                if (appareils[a].marque_1 === "" && appareils[a].numero_type_interne !== 12 && appareils[a].numero_type_interne !== 9 && appareils[a].numero_type_interne !== 10 && appareils[a].numero_type_interne !== 7) {
                    pas_de_scelles = true
                }

                /*console.log("---------appareils[a].marque_1--------")
                console.log(appareils[a].marque_1)
                console.log("---------pas_de_scelles--------")
                console.log(pas_de_scelles)*/

                let nomAutre = 'A' + appareils[a].numero_type_interne
                // Lumineux
                if (appareils[a].numero_type_interne === 2) {
                    if (((appareils[a].marque_1 === "" || pas_de_scelles === false) || interventionInfoGeneral.verification === false)
                        && num_serie_ok === true && marque_modele === true
                        && (ve.c_autoblocage !== 0 || interventionInfoGeneral.verification === false)
                        && (ve.c_ampoules !== 0 || interventionInfoGeneral.verification === false || (ve.c_ampoules === 0 && appareils[a].modeleTousAppareils.ampoules === ""))) {
                        if (currentOkList.indexOf(nomAutre) === -1) {
                            currentOkList.push(nomAutre)
                        }
                        setOkList(currentOkList)
                    }
                    else {
                        currentOkList = currentOkList.filter(name => name !== nomAutre)
                        setOkList(currentOkList)
                        nbe_pas_ok += 1
                    }
                }
                else {
                    if (((appareils[a].marque_1 === "" || pas_de_scelles === false) || interventionInfoGeneral.verification === false)
                        && num_serie_ok === true && marque_modele === true) {
                        if (currentOkList.indexOf(nomAutre) === -1) {
                            currentOkList.push(nomAutre)
                        }
                        setOkList(currentOkList)
                    }
                    else {
                        currentOkList = currentOkList.filter(name => name !== nomAutre)
                        setOkList(currentOkList)
                        nbe_pas_ok += 1
                    }
                }

                if (appareils[a].numero_type_interne === 4 || appareils[a].numero_type_interne === 9) {
                    info_vitesse = true
                }
                if (appareils[a].numero_type_interne === 2 || appareils[a].cet !== "") {
                    cet_lumineux = true
                }
            }
            if (afficheur_logiciel_ok === true && nbe_pas_ok === 0 && un_ok > 2 && (cet_lumineux === true || interventionInfoGeneral.que_tarif === true) && info_vitesse === true) {
                if (currentOkList.indexOf("Autres") === -1) {
                    currentOkList.push("Autres")
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Autres')
                setOkList(currentOkList)
            }
        }
        /**
         * Contrôle Lumineux
        */
        if (ve && ((isRadioButtonValid(ve.c_lum_non_deplacable) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.c_lum_taxi_visible) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.c_lum_commune_lisible) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.c_lum_commune_taille) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.c_lum_non_cache) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.c_lum_couleur_autorise) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.lumineux_toit_ouvrant) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.lumineux_kit_rehausse) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.lumineux_amovible) || interventionInfoGeneral.que_tarif === true)
            && (isRadioButtonValid(ve.lumineux_barre_de_toit) || interventionInfoGeneral.que_tarif === true))) {
            if (currentOkList.indexOf('Lumineux') === -1) {
                currentOkList.push('Lumineux')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Lumineux')
            setOkList(currentOkList)
        }
        /**
         * Contrôle Liaisons
        */
        let nb_liaisons = 0
        let nb_pas_blinde = 0
        if (ve) {
            for (let a = 1; a < 21; a++) {
                if (ve['c_liaisons' + a] !== 0) {
                    nb_liaisons += 1
                    // Vérification en passant s'il est blinde
                    if (ve['c_blinde' + a] === 0) {
                        nb_pas_blinde += 1
                    }
                }
            }
        }
        if ((nb_liaisons > 2 && nb_pas_blinde === 0) || interventionInfoGeneral.verification === false) {
            if (currentOkList.indexOf('Liaisons') === -1) {
                currentOkList.push('Liaisons')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Liaisons')
            setOkList(currentOkList)
        }

        /**
         * Contrôle Métrologie
        */
        if (ve && i && (((ve.clef_arrete_national !== -1 && ve.clef_arrete_national !== "" && isNullOrUndefined(ve.clef_arrete_national) === false)
            && ((ve.c_correspondance !== 0 && ve.c_correspondance !== 4) && (ve.c_conformite_lettre !== 0 && ve.c_conformite_lettre !== 4))
            && (((i.util_piste !== 0 && isNaN.util_piste !== "empty" && isNaN(i.util_piste) === false) && (ve.position_kilo !== 0 || (ve.c_point_lu !== "empty" && ve.c_point_lu !== -1))) || ve.c_r71 === true)
            && (((i.util_palm !== 0 && isNaN(i.util_palm) === false) && ve.tarif !== 0 && ve.lue_secondes > 0) || ve.c_r81 === true)) || interventionInfoGeneral.verification === false)) {
            if (currentOkList.indexOf('Métrologie') === -1) {
                currentOkList.push('Métrologie')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Métrologie')
            setOkList(currentOkList)
        }

        /**
         * Contrôle Photos
        */
        if (p && (p.length > 1 || (p.length > 0 && isNullOrUndefined(p.find(e => e.numero_photo === 20))))) {
            if (currentOkList.indexOf('Photos') === -1) {
                currentOkList.push('Photos')
            }
            setOkList(currentOkList)
        }
        else {
            currentOkList = currentOkList.filter(name => name !== 'Photos')
            setOkList(currentOkList)
        }
        /**
         * Contrôle Sanctions
        */
        // if (ve && vi) {
        //     console.log((ve.c_taximetre_mid_neuf === 3 || ((ve.c_taximetre_mid_notice === 1 || ve.c_taximetre_mid_notice === 3) && (ve.c_taximetre_mid_attestation === 1 || ve.c_taximetre_mid_attestation === 3))))
        //     console.log(((vi.numero_vignette !== 0 && isNaN(vi.numero_vignette) === false) || interventionInfoGeneral.vignette === false))
        //     console.log((vi.etat_vignette === 0 || vi.etat_vignette === 1 || vi.etat_vignette === 3 || vi.etat_vignette === 2))
        //     console.log(((interventionInfoGeneral.que_tarif === false) || (vi.scellement_tarif !== "" && (vi.scellement_tarif_quel_appareil === 1 || vi.scellement_tarif_quel_appareil === 10))))
        // }
        if (ve && vi) {
            if ((ve.c_taximetre_mid_neuf === 3 || ((ve.c_taximetre_mid_notice === 1 || ve.c_taximetre_mid_notice === 3) && (ve.c_taximetre_mid_attestation === 1 || ve.c_taximetre_mid_attestation === 3)))
                && ((vi.numero_vignette !== 0 && isNaN(vi.numero_vignette) === false) || interventionInfoGeneral.vignette === false)
                && ((vi.etat_vignette === 1 || vi.etat_vignette === 3 || vi.etat_vignette === 2) || interventionInfoGeneral.vignette === false)
                && (vi.etat_vignette === 0 || vi.etat_vignette === 1 || vi.etat_vignette === 3 || vi.etat_vignette === 2)
                && ((interventionInfoGeneral.que_tarif === false) || (vi.scellement_tarif !== "" && (vi.scellement_tarif_quel_appareil === 1 || vi.scellement_tarif_quel_appareil === 10)))) {
                if (currentOkList.indexOf('Sanction Vérification') === -1) {
                    currentOkList.push('Sanction Vérification')
                }
                setOkList(currentOkList)
            }
            else {
                currentOkList = currentOkList.filter(name => name !== 'Sanction Vérification')
                setOkList(currentOkList)
            }
        }
    }

    // On vérifie ici s'il y a des changements sur le formulaire
    useEffect(() => {
        controle_saisie()
        //process.env.NODE_ENV === "development" && console.log(initialFormVerif)
        //process.env.NODE_ENV === "development" && console.log(formVerif)
        //process.env.NODE_ENV === "development" && console.log(haveChanges(initialFormVerif, formVerif))
        //process.env.NODE_ENV === "development" && console.log(initialFormIntervention)
        //process.env.NODE_ENV === "development" && console.log(formIntervention)
        //process.env.NODE_ENV === "development" && console.log(haveChanges(initialFormIntervention, formIntervention))
        /*process.env.NODE_ENV === "development" && console.log(initialFormPhotos)
        process.env.NODE_ENV === "development" && console.log(formPhotos)
        process.env.NODE_ENV === "development" && console.log(haveChanges(initialFormPhotos, formPhotos))*/
        //process.env.NODE_ENV === "development" && console.log(initialFormMateriel)
        //process.env.NODE_ENV === "development" && console.log(formMateriel)
        //process.env.NODE_ENV === "development" && console.log(haveChanges(initialFormMateriel, formMateriel))
        //process.env.NODE_ENV === "development" && console.log(initialFormVignette)
        //process.env.NODE_ENV === "development" && console.log(formVignette)
        //process.env.NODE_ENV === "development" && console.log(haveChanges(initialFormVignette, formVignette))
        if (haveChanges(initialFormVerif, formVerif) === true
            || haveChanges(initialFormIntervention, formIntervention) === true
            || haveChanges(initialFormPhotos, formPhotos) === true
            || haveChanges(initialFormMateriel, formMateriel) === true
            || haveChanges(initialFormVignette, formVignette) === true) {
            if (blockSaveMetrologie === false) {
                setDisableSave(false)
            }
        } else {
            setDisableSave(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formVerif, formIntervention, formPhotos, formMateriel, formVignette])

    // Afficher toast en fonction de l'état de l'enregistrement partiel
    useEffect(() => {
        isSavingPartially ? notifyPartialSavingLoading() : notifySavingLoadingDone()
    }, [isSavingPartially])

    // Afficher toast en fonction de l'état de l'enregistrement
    useEffect(() => {
        isSaving ? notifySavingLoading() : notifySavingLoadingDone()
    }, [isSaving])

    const hasControlePoseError = async (numero_vignette, etat_vignette) => {

        const data = {
            numero_vignette: parseInt(numero_vignette),
            numero_adherent: formIntervention.numero_adherent,
            validite_vignette: (new Date()).getFullYear() + 1,
            numero_client: formIntervention.numero_client,
            clef_stationnement: formIntervention.clef_stationnement,
            clef_lien: formIntervention.clef_lien,
            numero_intervention: formIntervention.numero_intervention
        }

        let result = false
        let type = etat_vignette === 1 ? "verte" : etat_vignette === 2 ? "rouge" : undefined

        await supervisionService.controlePose(data, type)
            .then(response => {
                if (response.type !== 2) {
                    const cp_message = response.message + (response.degat ? getDegatVignette(response.degat) : "") + (response.infos ?
                        " " + response.infos.nom_client + " " + response.infos.cp_client + " " + response.infos.ville_client + "\n" +
                        "Stationnement : " + response.infos.stationnements[0].numero_stationnement + "\n" +
                        response.infos.stationnements[0].cp + " " + response.infos.stationnements[0].ville + "\n" +
                        "Date : " + formatDate(response.date) : "")
                    showVignetteStatus(response.type, cp_message)
                    // Erreur
                    result = true
                }
                else {
                    result = false
                }
            })

        return result
    }

    const haveErrors = async (saisie) => {
        const { vignette, verif, intervention, photos, materiels } = saisie
        /**
         * Variables et Initialisation
         */
        let infosData = await storage.get('infos')
        let dpt = infosData.stationnements[0].clef_dpt

        let data = {
            numero_adherent: intervention.numero_adherent,
            numero_client: intervention.numero_client,
            clef_stationnement: intervention.clef_stationnement,
            clef_lien: intervention.clef_lien,
            numero_intervention: intervention.numero_intervention,
            numero_vignette: vignette.numero_vignette,
            validite_vignette: interventionInfoGeneral.change_vignette === true ? parseInt(vignette.annee_vignette) : new Date(intervention.date_effectuee).getFullYear() + 1
        }
        let dataI = {
            numero_adherent: intervention.numero_adherent,
            numero_client: intervention.numero_client,
            clef_stationnement: intervention.clef_stationnement,
            clef_lien: intervention.clef_lien,
            numero_intervention: intervention.numero_intervention,
        }
        let vignette_unique = await supervisionService.isVignetteUnique(data)
        let date_effectuee = await supervisionService.getDateEffectueeIntervention(dataI)
        let refus = false
        let date_controle = new Date()
        date_controle.setHours(0, 0, 0, 0)
        let date_effectuee_avant_enregistrement = null
        if (vignette.etat_vignette === 2 || vignette.etat_vignette === 3) {
            refus = true
        }
        if (date_effectuee !== null && date_effectuee !== "") {
            date_effectuee_avant_enregistrement = date_effectuee
        }
        if (isValidDate(intervention.date_effectuee) === true) {
            date_controle = intervention.date_effectuee
        }
        if (isValidDate(intervention.date_effectuee) === false) {
            toast.error("Vous devez identifier une date d'intervention.", { containerId: "App" })
            redirectSaisie("reception-vehicule")
            return true
        }
        if (isValidDate(intervention.date_effectuee) === true) {
            if ((new Date(intervention.date_effectuee)).getFullYear() < 2000) {
                toast.error("Vous devez identifier une date d'intervention valide.", { containerId: "App" })
                redirectSaisie("reception-vehicule")
                return true
            }
        }
        let date = new Date()
        date.setHours(0, 0, 0, 0)
        date.setHours(date.getHours() + 23)
        date.setMinutes(date.getMinutes() + 59)
        date.setSeconds(date.getSeconds() + 59)
        if (date_controle > date) {
            toast.error("La date ne peut pas être postérieure à la date du jour.", { containerId: "App" })
            redirectSaisie("reception-vehicule")
            return true
        }
        date_controle = new Date(date_controle)
        date_controle.setDate(date_controle.getDate() + 1)
        if (/*isValidDate(initialFormIntervention.date_effectuee) === false*/date_effectuee_avant_enregistrement === null && date_controle < new Date()) {
            toast.error("La date ne peut pas être antérieure à la date du jour, en cas de reprise se rapprocher du siège pour code.", { containerId: "App" })
            let show_modal_deblocage = true
            while (show_modal_deblocage) {
                let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Heure de saisie antérieur : " + formatDate(date_controle), option: 2 })
                if (confirmCodeDeblocage === "code_bon") {
                    show_modal_deblocage = false
                }
                else if (confirmCodeDeblocage === "code_incorrect") {
                    toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: "App" })
                }
                else {
                    toast.error("Arrêt...", { autoClose: 5000, containerId: "App" })
                    show_modal_deblocage = false
                    return true
                }
            }
        }

        // REFUS
        if (refus === true) {
            if (verif.commentaires === "") {
                toast.error("Dans le cas d'un refus : la case commentaires doit être rempli en détaillant le problème.", { containerId: "App" })
                return true
            }
        }

        // PAS DE REFUS
        if (refus === false) {
            if (verif.c_affichaged === 2) {
                toast.error("Vous ne pouvez pas cocher R13 (Tarif D) sans refuser l'intervention.", { containerId: "App" })
                return true
            }
            if (verif.pneu_egal_carnet === 2) {
                toast.error("Vous ne pouvez pas cocher R03 (Carnet/pneus) sans refuser l'intervention.", { containerId: "App" })
                return true
            }
            if (verif.c_scelles === 2) {
                toast.error("Vous ne pouvez pas cocher R05 sans refuser l'intervention.", { containerId: "App" })
                return true
            }
            if (verif.c_marque_reparateur === 2) {
                toast.error("Vous ne pouvez pas cocher R05 sans refuser l'intervention.", { containerId: "App" })
                return true
            }
            if (verif.c_r99 === true) {
                toast.error("Vous ne pouvez pas cocher R99 sans refuser l'intervention.", { containerId: "App" })
                return true
            }
            if (verif.c_REF_maj_ml_sans_vprim === 3) {
                toast.error("Si logiciel non conforme après le 01/06/2021, vous ne pouvez pas valider l'intervention en acceptation.", { containerId: "App" })
                return true
            }
            if (verif.c_REF_maj_ml_sans_vprim === 3 && verif.c_logiciel === 1) {
                toast.error("Si logiciel non conforme après le 01/06/2021, vous ne pouvez pas cocher l'accord sur le logiciel.", { containerId: "App" })
                return true
            }
            if (interventionInfoGeneral.tarif === true && interventionInfoGeneral.installation === false && intervention.scellement_tarif === "") {
                toast.error("Dans le cas d'un changement de tarif sans bris de scellement autre, il faut indiquer quel est le scellement apposé.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }
            if (interventionInfoGeneral.tarif === true && interventionInfoGeneral.installation === false && (intervention.scellement_tarif).length < 4) {
                toast.error("Dans le cas d'un changement de tarif sans bris de scellement autre, il faut indiquer quel est le scellement apposé et ne pas se contenter d'un OK ou du nombre.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }
            if (interventionInfoGeneral.tarif === true && interventionInfoGeneral.installation === false && intervention.scellement_tarif_quel_appareil !== 1 && verif.scellement_tarif_quel_appareil !== 10) {
                toast.error("Dans le cas d'un changement de tarif sans bris de scellement autre, il faut indiquer sur quel appareil le scellement apposé.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }

            if ((interventionInfoGeneral.a5 === true || interventionInfoGeneral.a5_bis === true) && (verif.commentaires).length < 10) {
                toast.error("Dans le cas d'une remise en conformité, vous devez indiquer dans les commentaires la raison exacte de la remise en conformité.", { containerId: "App" })
                return true
            }
            if (isValidDate(intervention.date_installation) === false) {
                toast.error("Vous devez identifier une date d'installation du montage valide.", { containerId: "App" })
                redirectSaisie("reception-pneumatique")
                return true
            }
            if (new Date(intervention.date_installation).getFullYear() < 2000) {
                toast.error("Vous devez identifier une date d'installation du montage valide.", { containerId: "App" })
                redirectSaisie("reception-pneumatique")
                return true
            }
            if (interventionInfoGeneral.installation === false) {
                if (isValidDate(intervention.date_k) === false) {
                    toast.error("Vous devez identifier une date de détermination du 'k'.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (new Date(intervention.date_k).getFullYear() < 2000) {
                    toast.error("Vous devez identifier une date de détermination du 'k' valide.", { containerId: "App" })
                    return true
                }
            }
            let visibilite_installeur = (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) ? false : true

            if (visibilite_installeur === true && (intervention.numero_intervenant === "" || intervention.numero_intervenant === 0) && (interventionInfoGeneral.installation === true || interventionInfoGeneral.c1 === true || interventionInfoGeneral.d1 === true || interventionInfoGeneral.d2 === true)) {
                toast.error("Vous devez identifier un installateur.", { containerId: "App" })
                redirectSaisie("installateur")
                return true
            }
            if ((intervention.numero_intervenant !== "" && intervention.numero_intervenant !== 0) && (isNullOrUndefined(intervention.sign2) === true || intervention.sign2 === "") && interventionInfoGeneral.que_tarif === false) {
                toast.error("L'installateur n'a pas signé.", { containerId: "App" })
                return true
            }
            if ((intervention.numero_intervenant !== "" && intervention.numero_intervenant !== 0) && (isNullOrUndefined(intervention.sign2) === true || intervention.sign2 === "") && interventionInfoGeneral.que_tarif === true) {
                toast.warn("L'installateur n'a pas signé, vous pouvez enregistrer car il s'agit d'un tarif, mais le rapport papier devra être signé.", { containerId: "App" })
                // Pas de return true ici car il peut enregistrer malgre cela
            }
            if ((verif.numero_intervenant === "" || verif.numero_intervenant === 0) && interventionInfoGeneral.verification === true) {
                toast.error("Vous devez identifier un vérificateur.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }
            if ((verif.numero_intervenant !== "" || verif.numero_intervenant !== 0) && (isNullOrUndefined(intervention.sign3) === true || intervention.sign3 === "") && interventionInfoGeneral.verification === true) {
                toast.error("Le vérificateur n'a pas signé.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }
            /**
             * Autres Appareils
             */
            if (verif.c_r30 === true) {
                toast.error("Vous avez coché un refus R30 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r31 === true) {
                toast.error("Vous avez coché un refus R31 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r35 === true) {
                toast.error("Vous avez coché un refus R35 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r38 === true) {
                toast.error("Vous avez coché un refus R38 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r40 === true) {
                toast.error("Vous avez coché un refus R40 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r41 === true) {
                toast.error("Vous avez coché un refus R41 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r42 === true) {
                toast.error("Vous avez coché un refus R42 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r45 === true) {
                toast.error("Vous avez coché un refus R45 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r46 === true) {
                toast.error("Vous avez coché un refus R46 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r47 === true) {
                toast.error("Vous avez coché un refus R47 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            if (verif.c_r48 === true) {
                toast.error("Vous avez coché un refus R48 alors que l'appareil est accepté.", { containerId: "App" })
                return true
            }
            /**
             * Vehicule
             */
            if (isValidDate(intervention.date_effectuee) === false) {
                toast.error("Vous devez identifier une date de réalisation valide.", { containerId: "App" })
                return true
            }
            if (new Date(intervention.date_effectuee).getFullYear() < 2000) {
                toast.error("Vous devez identifier une date de réalisation valide.", { containerId: "App" })
                return true
            }
            if (parseInt(intervention.kilometrage) === 0) {
                toast.error("Vous devez identifier un kilométrage.", { containerId: "App" })
                redirectSaisie("reception-vehicule")
                return true
            }
            if (interventionInfoGeneral.installation === false && interventionInfoGeneral.verification === true) {
                if (parseInt(intervention.coefficient_k_avant) === 0) {
                    toast.error("Vous devez identifier le 'k' présent avant intervention.", { containerId: "App" })
                    redirectSaisie("reception-vehicule")
                    return true
                }
                if (intervention.pas_vignette_presente === false) {
                    if (isValidDate(intervention.date_validite) === false) {
                        toast.error("Vous devez identifier une année de pose de la vignette précédente.", { containerId: "App" })
                        redirectSaisie("reception-vehicule")
                        return true
                    }
                    if (new Date(intervention.date_validite).getFullYear() < 2000) {
                        toast.error("Vous devez identifier une année de pose de la vignette précédente.", { containerId: "App" })
                        redirectSaisie("reception-vehicule")
                        return true
                    }
                }
            }
            /**
             * Imprimante
             */
            let imprimante_integree = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.imprimante_integree
            let presence_imprimante = materiels.find(element => element.numero_type_interne === 7)
            if (imprimante_integree === false && isNullOrUndefined(presence_imprimante) && (new Date(intervention.date_effectuee)).getFullYear() >= 2017 && (interventionInfoGeneral.verification === true || interventionInfoGeneral.tarif === true)) {
                toast.error("L'imprimante est obligatoire après le 01/01/2017.", { containerId: "App" })
                return true
            }
            if (presence_imprimante === true && interventionInfoGeneral.verification === true) {
                for (var n = 0; n < Array.from(intervention.controle_imprimante).length; n++) {
                    if (parseInt(Array.from(intervention.controle_imprimante)[n]) === 0 || Array.from(intervention.controle_imprimante)[n] === "") {
                        toast.error("Vous devez identifier un résultat de contrôle imprimante pour " + getImprimanteLabel(n) + ".", { containerId: "App" })
                        return true
                    }
                }
                if (verif.c_r91 === 0) {
                    toast.error("Vous devez valider ou non la note d'impression.", { containerId: "App" })
                    return true
                }
                if (verif.c_r91 === 2) {
                    toast.error("Vous avez coché un refus R91 alors que l'appareil est accepté.", { containerId: "App" })
                    return true
                }
            }
            /**
             * Liaisons
             */
            if (interventionInfoGeneral.verification === true || interventionInfoGeneral.tarif === true) {
                let nb_liaisons = 0
                // Calcul du nombre de liaisons
                for (let a = 1; a < 21; a++) {
                    if (verif['c_liaisons' + a] !== 0) {
                        nb_liaisons += 1
                    }
                }
                if (nb_liaisons < 3) {
                    toast.error("Pas assez de liaisons identifiées.", { containerId: "App" })
                    redirectSaisie("liaisons")
                    return true
                }
                for (let a = 1; a < 21; a++) {
                    if (verif['c_liaisons' + a] === "" || isNullOrUndefined(verif['c_liaisons' + a])) {
                        toast.error("Pas de résultat pour la liaison " + getNomLiaisonTax(a) + ".", { containerId: "App" })
                        return true
                    }
                    if (verif['c_blinde' + a] === "") {
                        toast.error("Pas de type de blindage pour la liaison " + getNomLiaisonTax(a) + ".", { containerId: "App" })
                        return true
                    }
                }
                if (verif['c_liaisons5'] === 0 && verif['c_liaisons13'] === 0 && verif['c_liaisons19'] === 0) {
                    toast.error("Il manque la liaison à la batterie.", { containerId: "App" })
                    return true
                }
            }
            /**
             * Métrologie
             */
            if (interventionInfoGeneral.vignette || interventionInfoGeneral.bris_scellement) {
                if (verif.clef_arrete_national === "" || verif.clef_arrete_national === 0 || verif.clef_arrete_national === -1) {
                    toast.error("Vous devez identifier un arrêté national.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (verif.c_correspondance === 0) {
                    toast.error("Vous devez identifier un état le choix de l'arrêté.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (verif.c_conformite_lettre === 0) {
                    toast.error("Vous devez identifier un état pour la lettre de l'arrêté.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (userGroup !== 0) {
                    if (intervention.util_piste === 0 || intervention.util_piste === "empty" || intervention.util_piste === -1) {
                        toast.error("Vous devez identifier un instrument de mesure pour l'essai en base kilométrique.", { containerId: "App" })
                        redirectSaisie("metrologie")
                        return true
                    }
                }
                /*if (intervention.util_piste === 0 || intervention.util_piste === "empty") {
                    toast.error("Vous devez identifier un instrument de mesure pour l'essai en base kilométrique.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }*/
                if (isReelle === false && isUniv === false && isBanc === false) {
                    toast.error("Vous devez choisir un type de contrôle pour la base kilométrique (Piste universelle, réelle ou banc).", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (isReelle === true && verif.position_kilo !== 3 && verif.position_kilo !== 4 && interventionInfoGeneral.installation === true) {
                    toast.error("Piste Réelle sélectionnée en installation : refusée sans refus de l'intervention.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (isReelle === true && verif.position_kilo !== 2 && verif.position_kilo !== 3 && verif.position_kilo !== 4 && verif.position_kilo !== 5 && interventionInfoGeneral.installation === false && interventionInfoGeneral.vp === true) {
                    toast.error("Piste Réelle sélectionnée en VP : refusée sans refus de l'intervention.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (refus === false) {
                    if (isReelle === true && (verif.position_kilo === 0 || verif.position_kilo === "" || verif.position_kilo === "empty" || isNullOrUndefined(verif.position_kilo))) {
                        toast.error("Piste Réelle sélectionnée mais pas de position indiquée.", { containerId: "App" })
                        redirectSaisie("metrologie")
                        return true
                    }
                }
                if (isUniv === true && (verif.c_point_lu === "empty" || verif.c_point_lu === -1)) {
                    toast.error("Piste Universelle sélectionnée : Vous devez indiquer le point de chute.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (isUniv === true && vaiVisible === false && interventionInfoGeneral.installation === true) {
                    toast.error("Piste Universelle en installation : refusée sans refus de l'intervention.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (isUniv === true && vpVisible === false && interventionInfoGeneral.installation === true && interventionInfoGeneral.vp === true) {
                    toast.error("Piste Universelle en installation : refusée sans refus de l'intervention.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (userGroup !== 0) {
                    if (intervention.util_palm === 0 || intervention.util_palm === "empty") {
                        toast.error("Vous devez identifier un chronomètre pour l'essai en base horaire.", { containerId: "App" })
                        return true
                    }
                }
                /*if (intervention.util_palm === 0 || intervention.util_palm === "empty") {
                    toast.error("Vous devez identifier un chronomètre pour l'essai en base horaire.", { containerId: "App" })
                    return true
                }*/
                if (verif.tarif !== 0 && verif.tarif === "") {
                    toast.error("Vous devez identifier un tarif horaire pour l'essai en base horaire.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (nbChutes === "" || nbChutes === 0) {
                    toast.error("Vous devez identifier un nombre de chutes pour l'essai en base horaire.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if (verif.lue_secondes === "" || verif.lue_secondes === 0) {
                    toast.error("Vous devez identifier le temps écoulé pour l'essai en base horaire.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
                if ((verif.lue_secondes < emtMoins || verif.lue_secondes > emtPlus) && interventionInfoGeneral.verification === true) {
                    toast.error("Base horaire : essai faux mais appareil non refusé.", { containerId: "App" })
                    redirectSaisie("metrologie")
                    return true
                }
            }

            /**
             * Pneumatiques
             */
            if (interventionInfoGeneral.verification === true) {
                if (dpt === 75 && intervention.autorisation_stat_immatriculation !== 0 && intervention.autorisation_stat_immatriculation !== 1 && intervention.autorisation_stat_immatriculation !== 2 && intervention.autorisation_stat_immatriculation !== 3 && intervention.autorisation_stat_immatriculation !== 4) {
                    toast.error("Pour Paris vous devez contrôler la présence de l'autorisation de stationnement, idéalement même une photo.", { containerId: "App" })
                    return true
                }
                if (parseInt(intervention.pneu_dim1) === 0) {
                    toast.error("Vous devez identifier La dimension 1, pneumatique avant.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_dim2) === 0) {
                    toast.error("Vous devez identifier La dimension 2, pneumatique avant.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_dim3) === 0) {
                    toast.error("Vous devez identifier La dimension 3, pneumatique avant.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_pression) < 1) {
                    toast.error("Vous devez identifier la pression, pneumatique avant.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_dim4) === 0) {
                    toast.error("Vous devez identifier La dimension 1, pneumatique arrière.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_dim5) === 0) {
                    toast.error("Vous devez identifier La dimension 2, pneumatique arrière.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_dim6) === 0) {
                    toast.error("Vous devez identifier La dimension 3, pneumatique arrière.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (parseInt(intervention.pneu_pression_ar) < 1) {
                    toast.error("Vous devez identifier la pression, pneumatique arrière.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (userGroup !== 0) {
                    if (intervention.util_mano === "" || intervention.util_mano === 0 || intervention.util_mano === -1) {
                        toast.error("Vous devez identifier un manomètre pour le gonflage.", { containerId: "App" })
                        return true
                    }
                }
                /*if (intervention.util_mano === "" || intervention.util_mano === 0 || intervention.util_mano === -1) {
                    toast.error("Vous devez identifier un manomètre pour le gonflage.", { containerId: "App" })
                    return true
                }*/
                if (intervention.pneu_ajuste === 0) {
                    toast.error("Vous devez identifier si les pneumatiques ont été ajustés.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (intervention.usure_pneumatique === 0) {
                    toast.error("Vous devez identifier si les pneumatiques sont usés.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (intervention.usure_pneumatique === 2) {
                    toast.error("Si l'usure des pneumatiques n'est pas bonne vous devez annuler l'intervention.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (intervention.fourniture_carnet === 0) {
                    toast.error("Vous devez identifier si un nouveau carnet a été fourni.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (intervention.presence_carnet === 0) {
                    toast.error("Vous devez identifier si le carnet était bien présent.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
                if (intervention.pneu_egal_carnet === 0) {
                    toast.error("Vous devez identifier si les dimensions de pneus dans le carnet sont bien les mêmes que celles sur le véhicule.", { containerId: "App" })
                    redirectSaisie("reception-pneumatique")
                    return true
                }
            }
            /**
             * Sanctions
             */
            if (interventionInfoGeneral.installation === true) {
                if (verif.c_taximetre_mid_neuf !== 1 && verif.c_taximetre_mid_neuf !== 3) {
                    toast.error("Vous devez identifier si le taximètre MID était neuf au moment de l'intervention.", { containerId: "App" })
                    redirectSaisie("sanction")
                    return true
                }
                if (verif.c_taximetre_mid_neuf === 1) {
                    if (verif.c_taximetre_mid_notice !== 1 && verif.c_taximetre_mid_notice !== 3) {
                        toast.error("Si appareil neuf vous devez valider la remise de la notice utilisateur.", { containerId: "App" })
                        redirectSaisie("sanction")
                        return true
                    }
                    if (verif.c_taximetre_mid_attestation !== 1 && verif.c_taximetre_mid_attestation !== 3) {
                        toast.error("Si appareil neuf vous devez valider la remise de la notice de l'attestation UE.", { containerId: "App" })
                        redirectSaisie("sanction")
                        return true
                    }
                }
            }
            if (interventionInfoGeneral.vignette === true || interventionInfoGeneral.change_vignette === true) {
                if (interventionInfoGeneral.vignette === true && vignette.etat_vignette !== 1 && vignette.etat_vignette !== 3 && vignette.etat_vignette !== 2) {
                    toast.error("Vous devez identifier une sanction pose vignette ou intervention car l'intervention sélectionné présuppose une vignette.", { containerId: "App" })
                    redirectSaisie("sanction")
                    return true
                }
                if ((vignette.etat_vignette === 1 || vignette.etat_vignette === 2) && parseInt(vignette.numero_vignette) === 0) {
                    toast.error("Vous devez identifier un numéro de vignette posée.", { containerId: "App" })
                    redirectSaisie("sanction")
                    return true
                }
                if (userGroup !== 0) {
                    if (vignette_unique.isUnique === false) {
                        toast.error(vignette_unique.message, { containerId: "App" })
                        redirectSaisie("sanction")
                        return true
                    }
                }
                /*if (vignette_unique.isUnique === false) {
                    toast.error(vignette_unique.message, { containerId: "App" })
                    redirectSaisie("sanction")
                    return true
                }*/
            }
            if (interventionInfoGeneral.change_vignette === true) {
                if (parseInt(vignette.annee_vignette) === 0) {
                    toast.error("Vous devez identifier un numéro d'année de vignette pour le remplacement.", { containerId: "App" })
                    redirectSaisie("sanction")
                    return true
                }
            }
            if (vignette.etat_vignette !== 1 && vignette.etat_vignette !== 3 && vignette.etat_vignette !== 2 && vignette.etat_vignette !== 0 && vignette.etat_vignette !== 4) {
                toast.error("Vous devez identifier une sanction.", { containerId: "App" })
                redirectSaisie("sanction")
                return true
            }
            /**
             * Selections
             */
            //let materiels = new Map(JSON.parse(localStorage.liaisons))
            // ne fait pas partie si pb avec localForage
            if (interventionInfoGeneral.verification === true || interventionInfoGeneral.tarif === true) {
                let un_ok = 0
                let saisie_impulsion = false
                let saisie_afficheur = false
                // Situation
                if ((isNullOrUndefined(materiels[0].emplacement1) || materiels[0].emplacement1 === 0) &&
                    (isNullOrUndefined(materiels[0].emplacement2) || materiels[0].emplacement2 === 0) &&
                    (isNullOrUndefined(materiels[0].emplacement3) || materiels[0].emplacement3 === 0) &&
                    (isNullOrUndefined(materiels[0].emplacement4) || materiels[0].emplacement4 === 0)) {
                    toast.error("Situation non saisie pour " + getNomMateriel(1) + ".", { containerId: "App" })
                    return true
                }
                for (var i = 0; i < materiels.length; i++) {
                    // Marque
                    if (!materiels[i].modeleTousAppareils || materiels[i].modeleTousAppareils.marque_nom_appareil === "" || materiels[i].modeleTousAppareils.marque_nom_appareil === "XXXXX") {
                        toast.error("Marque non saisie pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    // Modèle
                    if (!materiels[i].modeleTousAppareils || materiels[i].modeleTousAppareils.modele_nom_appareil === "" || materiels[i].modeleTousAppareils.modele_nom_appareil === "XXXXX") {
                        toast.error("Modèle non saisie pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    // Logiciel
                    if (materiels[i].modeleTousAppareils.logiciel_obligatoire === true && verif.logiciel_afficheur === "") {
                        toast.error("Logiciel non saisi pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    // ok, int, refus
                    if (materiels[i].etat === 0) {
                        toast.error("Pas de jugement sur l'instrument : " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    // Situation
                    if ((isNullOrUndefined(materiels[i].emplacement1) || materiels[i].emplacement1 === 0) &&
                        (isNullOrUndefined(materiels[i].emplacement2) || materiels[i].emplacement2 === 0) &&
                        (isNullOrUndefined(materiels[i].emplacement3) || materiels[i].emplacement3 === 0) &&
                        (isNullOrUndefined(materiels[i].emplacement4) || materiels[i].emplacement4 === 0)) {
                        toast.error("Situation non saisie pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    if (materiels[i].numero_type_interne !== 7 && materiels[i].modeleTousAppareils.serie_obligatoire === true && materiels[i].serie_appareil === "") {
                        toast.error("Numéro de série obligatoire pour " + getNomMateriel(materiels[i].numero_type_interne) + " mais pas renseigné.", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    // CET Lumineux
                    if (interventionInfoGeneral.que_tarif === false && materiels[i].numero_type_interne === 2 && materiels[i].cet === "") {
                        toast.error("Numéro de CET du lumineux non saisi.", { containerId: "App" })
                        return true
                    }
                    // Impulsion
                    if (materiels[i].numero_type_interne === 4 || materiels[i].numero_type_interne === 9) {
                        saisie_impulsion = true
                    }
                    // Afficheur
                    if (materiels[i].numero_type_interne === 10) {
                        saisie_afficheur = true
                    }
                    // Position Lumineux
                    if (materiels[i].numero_type_interne === 2 && materiels[i].emplacement3 > 0.7) {
                        toast.error("Lumineux trop à l'avant mais pas refusé.", { containerId: "App" })
                        return true
                    }
                    un_ok += 1
                    if (materiels[i].marque_1 === "" && materiels[i].modeleTousAppareils.marque_1 !== "" && materiels[i].numero_type_interne !== 12 && materiels[i].numero_type_interne !== 9 && materiels[i].numero_type_interne !== 10 && materiels[i].numero_type_interne !== 7) {
                        toast.error("Manque de scellements pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    if (materiels[i].numero_type_interne !== 12 && materiels[i].numero_type_interne !== 9 && materiels[i].numero_type_interne !== 10 && materiels[i].numero_type_interne !== 7) {
                        if (materiels[i].marque_1 === "" && materiels[i].modeleTousAppareils.marque_1 !== "" && materiels[i].modeleTousAppareils.marque_1_optionnel !== true) {
                            toast.error("Manque le 1er scellement obligatoire pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                            redirectSaisie("autres")
                            return true
                        }
                        if (materiels[i].marque_2 === "" && materiels[i].modeleTousAppareils.marque_2 !== "" && materiels[i].modeleTousAppareils.marque_2_optionnel !== true) {
                            toast.error("Manque le 2ème scellement obligatoire pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                            redirectSaisie("autres")
                            return true
                        }
                        if (materiels[i].marque_3 === "" && materiels[i].modeleTousAppareils.marque_3 !== "" && materiels[i].modeleTousAppareils.marque_3_optionnel !== true) {
                            toast.error("Manque le 3ème scellement obligatoire pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                            redirectSaisie("autres")
                            return true
                        }
                        if (materiels[i].marque_4 === "" && materiels[i].modeleTousAppareils.marque_4 !== "" && materiels[i].modeleTousAppareils.marque_4_optionnel !== true) {
                            toast.error("Manque le 4ème scellement obligatoire pour " + getNomMateriel(materiels[i].numero_type_interne) + ".", { containerId: "App" })
                            redirectSaisie("autres")
                            return true
                        }
                    }
                }
                if (saisie_impulsion === false) {
                    toast.error("Vous n'avez pas sélectionné une prise d'impulsions.", { containerId: "App" })
                    redirectSaisie("autres")
                    return true
                }
                let afficheur = infosData && infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur
                if (saisie_afficheur === false && afficheur === true) {
                    toast.error("Vous n'avez pas sélectionné d'afficheur (obligatoire sur ce taximètre).", { containerId: "App" })
                    redirectSaisie("autres")
                    return true
                }
                if (interventionInfoGeneral.verification === true) {
                    if (verif.c_autoblocage === 0) {
                        toast.error("Vous devez identifier un résultat sur le Contrôle autoblocage du Taximètre (dans la partie Appareils/lumineux).", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                    let lumineux = materiels.find(element => element.numero_type_interne === 2)
                    if (verif.c_ampoules === 0 && lumineux && lumineux.modeleTousAppareils.ampoules !== "") {
                        toast.error("Vous devez identifier un résultat sur le Contrôle des ampoules du lumineux.", { containerId: "App" })
                        return true
                    }
                    if (verif.couleur_lumineux === 0 || verif.couleur_lumineux === -2) {
                        toast.error("Vous devez identifier une couleur de lumineux (dans la partie Appareils/lumineux.", { containerId: "App" })
                        redirectSaisie("autres")
                        return true
                    }
                }
                if (un_ok < 3) {
                    toast.error("Vous n'avez pas saisi assez d'appareils (seulement " + un_ok + " en plus du taximètre).", { containerId: "App" })
                    redirectSaisie("autres")
                    return true
                }
            }
            /**
             * Lumineux
             */
            if (interventionInfoGeneral.verification === true) {
                if (verif.c_lum_commune_lisible === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la commune sur le lumineux.", { containerId: "App" })
                    redirectSaisie("lumineux")
                    return true
                }
                if (verif.c_lum_commune_taille === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la hauteur de la commune sur le lumineux.", { containerId: "App" })
                    redirectSaisie("lumineux")
                    return true
                }
                if (verif.c_lum_couleur_autorise === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de l'autorisation de la couleur sur le lumineux.", { containerId: "App" })
                    return true
                }
                if (verif.c_lum_non_cache === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de si le lumineux est caché.", { containerId: "App" })
                    redirectSaisie("lumineux")
                    return true
                }
                if (verif.c_lum_taxi_visible === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la visibilité de 'TAXI' (non altéré).", { containerId: "App" })
                    redirectSaisie("lumineux")
                    return true
                }
                if (verif.c_lum_non_deplacable === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du lumineux non dépaçable à l'arrière.", { containerId: "App" })
                    redirectSaisie("lumineux")
                    return true
                }
            }

            /**
             * Taximètre
            */
            if (interventionInfoGeneral.tarif === true) {
                if (intervention.numero_cet_new === 0) {
                    toast.error("Taximètre > Logiciel : Vous devez sélectionner un CET avec sa version de logiciel à libération (même pour un changement de tarif).", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
            }
            if (interventionInfoGeneral.verification === true) {
                if (formVerif.c_marque_reparateur === 0) {
                    toast.error("Taximètre > Marquage : Vous devez identifier un résultat sur le contrôle de la marque bonne foi réparateur (Sans Objet si jamais réparé).", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_affichagea === 0 || verif.c_affichagea === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle Tarif A.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_affichageb === 0 || verif.c_affichageb === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle Tarif B.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_affichagec === 0 || verif.c_affichagec === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle Tarif C.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_affichagelibre === 0 || verif.c_affichagelibre === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle affichage Libre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_affichagelumineux === 0 || verif.c_affichagelumineux === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle correspondance lumineux/taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_mention1 === 0 || verif.c_mention1 === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle 'A payer' pendant la course.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_mention3 === 0 || verif.c_mention3 === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle 'Tarif' sur le taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_mention2 === 0 || verif.c_mention2 === 4) {
                    toast.error("Taximètre > Affichage : Vous devez identifier un résultat sur le contrôle 'A payer' à la fin de la course.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                let france_mid = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.france_mid
                let afficheur = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur
                let numero_serie_afficheur = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_afficheur
                let date_fin_dam = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.date_fin_dam
                let marquage_ce_afficheur = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marquage_ce_afficheur
                let marque_app = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
                let modele_app = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil
                if (france_mid === 1 && (intervention.millesime_mid === 0 || intervention.millesime_mid === "")) {
                    toast.error("Taximètre > Marquages : Vous devez identifier un Millésime MID valable.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (france_mid === 1 && (intervention.millesime_mid !== 0 && intervention.millesime_mid !== "" && (new Date(date_fin_dam)).getFullYear() > 2000)) {
                    if ((2000 + intervention.millesime_mid) > (new Date(date_fin_dam)).getFullYear()) {
                        toast.error("Ce taximètre ne peut être construit que jusqu'au " + formatDate(date_fin_dam) + " et le millésime CE est " + intervention.millesime_mid + " : impossible, rapprochez vous du siège.", { containerId: "App" })
                        redirectSaisie("taximetre")
                        return true
                    }
                }
                if (france_mid === 1 && marquage_ce_afficheur === true) {
                    if (parseInt(intervention.millesime_mid_afficheur) === 0 /*&& intervention.c_pas_marquage_mid_afficheur*/) {
                        toast.error("Vous devez identifier un Millésime MID valable pour l'afficheur ou indiquer qu'il n'y en a pas.", { containerId: "App" })
                        redirectSaisie("taximetre")
                        return true
                    }
                    if (intervention.c_pas_marquage_mid_afficheur === false) {
                        if (parseInt(intervention.millesime_mid_afficheur) !== parseInt(intervention.millesime_mid)) {
                            if (interventionInfoGeneral.a1 === true) {
                                toast.error("Pour une A1 le millésime de l'afficheur et de la centrale ne peuvent pas être différents, (OK pour une A2 : par exemple véhicule remplacé).", { containerId: "App" })
                                redirectSaisie("taximetre")
                                return true
                            }
                            if (verif.c_ANO_double_marquage_CE !== 2 && verif.c_REF_double_marquage_CE !== 2) {
                                redirectSaisie("taximetre")
                                toast.error("Vous devez vous cocher l'anomalie A130 ou le refus R130 si marquages CE différents.")
                            }
                            if (verif.c_ANO_double_marquage_CE === 2 && verif.c_REF_double_marquage_CE === 2) {
                                redirectSaisie("taximetre")
                                toast.error("Vous ne pouvez pas cocher l'anomalie A130 et le refus R130 en même temps.")
                            }
                        }
                    }
                }
                if (intervention.numero_cet_avant === 0) {
                    toast.error("Vous devez sélectionner un CET avec sa version de logiciel à réception.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (intervention.numero_cet_new === 0) {
                    toast.error("Vous devez sélectionner un CET avec sa version de logiciel à libération.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (intervention.numero_cet_new === 0) {
                    toast.error("Vous devez sélectionner un CET avec sa version de logiciel à libération.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (intervention.numero_cet_new !== intervention.numero_cet_avant) {
                    toast.error("La version de logiciel à libération et à réception est différente.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (intervention.anomalie_logiciel === true && interventionInfoGeneral.a1 === true) {
                    toast.error("L'anomalie Logiciel n'est pas possible dans le cas d'une installation neuve, le taximètre doit être remplacé", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (intervention.anomalie_logiciel === true && verif.c_ANO_maj_ml_sans_vprim === 2) {
                    toast.error("Si l'anomalie Logiciel est coché, vous devez cocher aussi A110", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                let version_logiciel_inter_precedente = 0
                if (initialFormIntervention.numero_cet_new !== 0) {
                    version_logiciel_inter_precedente = initialFormIntervention.numero_cet_new
                }
                else {
                    version_logiciel_inter_precedente = initialFormIntervention.numero_cet_avant
                }
                if (version_logiciel_inter_precedente !== 0) {
                    if (version_logiciel_inter_precedente !== intervention.numero_cet_avant) {
                        toast("La version de CET/logiciel à réception est différente de celle de l'intervention précédente, rapprochez vous du siège")
                        let show_modal_deblocage = true
                        let serie = infosData && infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_taximetre
                        while (show_modal_deblocage) {
                            let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Incohérence Version CET/Logiciel avec intervention précédente " + serie, option: 2 })
                            if (confirmCodeDeblocage === "code_bon") {
                                show_modal_deblocage = false
                            }
                            else if (confirmCodeDeblocage === "code_incorrect") {
                                toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                            }
                            else {
                                toast.error("Arrêt...", { autoClose: 5000 })
                                show_modal_deblocage = false
                                return true
                            }
                        }
                    }
                }
                if (intervention.anomalie_logiciel === true && intervention.detail_anomalie_logiciel === "") {
                    toast.error("Dans le cas d'une anomalie logiciel vous devez préciser le détail", { containerId: "App" })
                    return true
                }
                let modeleLog = await supervisionService.getModeleLog(marque_app, modele_app, intervention.numero_cet_new)
                if (france_mid === 1) {
                    if (modeleLog !== null) {
                        if (modeleLog.acceptation !== null) {
                            let year_cet = (new Date(modeleLog.acceptation)).getFullYear()
                            /* if 1 > 2 */
                            //ajout blocage SEMEL obligation anomalie
                            //09/03/2020 : pour tous pas que semel
                            if (year_cet > 2000) {
                                if (year_cet > 2000 + parseInt(intervention.millesime_mid)) {
                                    if (intervention.anomalie_logiciel === false) {
                                        toast.error("L'année du millésime MID ne peut pas être inférieure au CET utilisé (sauf cas très exceptionnels) : vous devez acter une anomalie de cohérence, la plus détaillée possible.", { containerId: "App" })
                                        redirectSaisie("taximetre")
                                        return true
                                    }
                                }
                            }
                        }
                    }
                }
                if (modeleLog.interdit === true) {
                    toast.error("Version de Logiciel Interdite, le taximètre ne peut pas être validé.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_logiciel === 0) {
                    toast.error("Taximètre > Logiciel > Sanction : Vous devez identifier un résultat sur le contrôle de version de logiciel.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_REF_maj_ml_sans_vprim === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la non modificiation de la version de logiciel Métrologie Légale après le 01/06/2021.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_ANO_maj_ml_sans_vprim === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la non modificiation de la version de logiciel Métrologie Légale avant le 31/05/2021.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_cet === 0) {
                    toast.error("Vous devez identifier la conformité au CET.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.cet === "") {
                    toast.error("Vous devez identifier la version de CET du Taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                // Indication
                if (verif.c_indication1 === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du nom et marque du taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_indication2 === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du Numéro de CET sur le taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_indication3 === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du modèle et du numéro de série du taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_indication4 === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du symbole € du taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                // Etiquette k
                if (verif.c_etiquettek1 === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du 'k'.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_etiquettek2 === 0) {
                    toast.error("Vous devez identifier le contrôle sur le département de l'étiquette tarifaire.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_etiquettek3 === 0) {
                    toast.error("Vous devez identifier un résultat sur la taille de la lettre de l'étiquette tarifaire.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                // Marquage
                if (verif.c_scelles === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle des marques réglementaires.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (france_mid === 1 && (verif.c_marque_ce === 0)) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du marquage CE.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (france_mid === 1 && (verif.c_marque_ce === 0)) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du marquage CE.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                // Position
                if (verif.detail_fixation === "") {
                    toast.error("Vous devez identifier la façon dont est fixé le taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.detail_fixation_afficheur === "" && afficheur === true) {
                    toast.error("Vous devez identifier la façon dont est fixé l'afficheur.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.fixation === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la fixation du taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.positionnement === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle du positionnement du taximètre.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.temporisation === 0) {
                    toast.error("Vous devez identifier un résultat sur le contrôle de la temporisation du taximètre pour un MID.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (dpt === 75 && (verif.conformite_gabarit === 0)) {
                    toast.error("Pour Paris vous devez préciser la conformité de l'installation au gabarit.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                // Autres
                if (verif.detail_acces_centrale === "" && afficheur === true) {
                    toast.error("Vous devez identifier l'accès à la centrale (Rappel : sans aucun outillage), cette information sera reprise dans le carnet métrologique Cercle Optima.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                //Interupteur on fait pas
                // etiquette
                if (parseInt(verif.etiquettek) === 0) {
                    toast.error("Vous devez identifier une valeur de 'k'.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (interventionInfoGeneral.installation === false && intervention.c1 === false && interventionInfoGeneral.c2 === false) {
                    if (parseInt(verif.etiquettek) !== 0 && parseInt(intervention.coefficient_k_avant) !== 0) {
                        if (parseInt(verif.etiquettek) !== parseInt(intervention.coefficient_k_avant)) {
                            toast.error("Dans le cas d'une VP ou d'un changement de tarif le coefficient k dans le taximètre doit être le même que celui affiché sur le taximètre.", { containerId: "App" })
                            redirectSaisie("taximetre")
                            return true
                        }
                    }
                }
                if (verif.c_elements_accessibles === 0) {
                    toast.error("Vous devez identifier le contrôle sur l'accessibilité des éléments.", { containerId: "App" })
                    redirectSaisie("taximetre")
                    return true
                }
                if (verif.c_elements_accessibles === 2) {
                    toast.error("Taximètre > Autres : Accessibilité refusé mais installation non refusée.", { containerId: "App" })
                    redirectSaisie("taximètre")
                    return true
                }
                if (interventionInfoGeneral.installation === true && afficheur === true && photos.length < 2) {
                    toast.error("Vous devez prendre a minima une photo lors de l'installation (Lumineux de côté pour situation sur toit et situation de la centrale). Pour l'instant un code de déblocage suffit le temps de pouvoir prendre des photos, mais il faut rapidement mettre en place une solution adaptée, cliquez sur 'annuler' pour revenir à la saisie et prendre la photo.", { containerId: "App" })
                    let show_deblocage_installation = true
                    while (show_deblocage_installation) {
                        let confirmCodeDeblocageInstallation = await isConfirmedDeblocage("Code de déblocage", { message: "Pas de photo(s) sur installation " + interventionInfoGeneral.numero_oi, option: 2 })
                        if (confirmCodeDeblocageInstallation === "code_bon") {
                            show_deblocage_installation = false
                        }
                        else if (confirmCodeDeblocageInstallation === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000 })
                            show_deblocage_installation = false
                            return true
                        }
                    }
                }
                else if (interventionInfoGeneral.installation === true && afficheur === false && photos.length === 0) {
                    toast.error("Vous devez prendre a minima une photo lors de l'installation (Lumineux de côté pour situation sur toit). Pour l'instant un code de déblocage suffit le temps de pouvoir prendre des photos, mais il faut rapidement mettre en place une solution adaptée, cliquez sur 'annuler' pour revenir à la saisie et prendre la photo.", { containerId: "App" })
                    let show_deblocage_lumineux = true
                    while (show_deblocage_lumineux) {
                        let confirmCodeDeblocageLumineux = await isConfirmedDeblocage("Code de déblocage", { message: "Pas de photo lumineux sur installation " + interventionInfoGeneral.numero_oi, option: 2 })
                        if (confirmCodeDeblocageLumineux === "code_bon") {
                            show_deblocage_lumineux = false
                        }
                        else if (confirmCodeDeblocageLumineux === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000 })
                            show_deblocage_lumineux = false
                            return true
                        }
                    }
                }
                else if (afficheur === true && photos.length === 0) {
                    toast.error("Vous devez prendre à minima une photo (Situation de la centrale sans démontages). Pour l'instant un code de déblocage suffit le temps de pouvoir prendre des photos, mais il faut rapidement mettre en place une solution adaptée, cliquez sur 'annuler' pour revenir à la saisie et prendre la photo.", { containerId: "App" })
                    let show_deblocage_centrale = true
                    while (show_deblocage_centrale) {
                        let confirmCodeDeblocageCentrale = await isConfirmedDeblocage("Code de déblocage", { message: "Pas de photo centrale sur installation " + interventionInfoGeneral.numero_oi, option: 2 })
                        if (confirmCodeDeblocageCentrale === "code_bon") {
                            show_deblocage_centrale = false
                        }
                        else if (confirmCodeDeblocageCentrale === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000 })
                            show_deblocage_centrale = false
                            return true
                        }
                    }
                }
                if (afficheur === true) {
                    if (verif.c_ANO_afficheur_depareille !== 1 && verif.c_ANO_afficheur_depareille !== 2 && verif.c_ANO_afficheur_depareille !== 3) {
                        toast.error("Vous devez identifier si une anomalie existe sur le dépareillage de l'afficheur et de la centrale.", { containerId: "App" })
                        return true
                    }
                    if (verif.c_REF_afficheur_depareille !== 1 && verif.c_REF_afficheur_depareille !== 2 && verif.c_REF_afficheur_depareille !== 3) {
                        toast.error("Vous devez identifier si un refus existe sur le dépareillage de l'afficheur et de la centrale.", { containerId: "App" })
                        return true
                    }
                    if (verif.c_REF_afficheur_depareille === 3 && refus === false) {
                        toast.error("Si vous avez coché R120 vous devez valider un refus de votre intervention.", { containerId: "App" })
                        return true
                    }
                    /**
                     * ------
                     * Historique afficheur a mettre en place ici
                     * ------
                     */
                    let anomalie_A120 = false
                    let refus_A120 = false
                    if (historiqueAfficheur.length !== 0) {
                        historiqueAfficheur.forEach((element) => {
                            if (renderHistoriqueAfficheur(element, numero_serie_afficheur) === "A120") {
                                anomalie_A120 = true
                            }
                            if (renderHistoriqueAfficheur(element, numero_serie_afficheur) === "R120") {
                                refus_A120 = true
                            }
                        })
                    }
                    if (anomalie_A120 === true && verif.c_ANO_afficheur_depareille === 2) {
                        toast.error("Vous devez cocher une A120 car un afficheur a été changé avant le 31/05/2021.", { containerId: "App" })
                        return true
                    }
                    if (refus_A120 === true && verif.c_REF_afficheur_depareille === 3) {
                        toast.error("Vous devez cocher un R120 car un afficheur a été changé après le 01/06/2021.", { containerId: "App" })
                        return true
                    }
                }
            }
        }
        /**
         * Etalonnage
         */
        if (interventionInfoGeneral.installation === true || interventionInfoGeneral.bris_scellement_etalonnage_obligatoire === true) {
            if (intervention.etalonnage_type === 0) {
                toast.error("Vous devez identifier le type d'appareil utilisé pour l'étalonnage (piste ou banc).", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
            if (intervention.instrument_etalonnage === 0) {
                toast.error("Vous devez identifier le type d'appareils utilisé pour l'étalonnage (piste ou banc).", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
            if (intervention.etalonnage_distance === 0) {
                toast.error("Vous devez identifier le type d'appareils utilisé pour l'étalonnage (piste ou banc).", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
            if (intervention.etalonnage_impulsions === 0) {
                toast.error("Vous devez identifier le nombre d'impulsions pendant l'étalonnage.", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
            if (intervention.etalonnage_k === 0) {
                toast.error("Vous devez identifier le k obtenu pendant l'étalonnage.", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
            if (intervention.etalonnage_k !== 0 && verif.etiquettek !== 0) {
                if (verif.etiquettek !== intervention.etalonnage_k) {
                    toast.error("Le 'k' déterminé durant l'étalonnage est différent de celui du taximètre.", { containerId: "App" })
                    redirectSaisie("etalonnage")
                    return true
                }
            }
            if (interventionInfoGeneral.bris_scellement_etalonnage_obligatoire === true) {
                supervisionService.getEtiquetteK(dataI)
                    .then(async response => {
                        if (response !== 0) {
                            if (intervention.etalonnage_k === response) {
                                toast.error("Valeur de k déterminée identique à la précédente ( " + response + "), appelez le siège pour valider.", { containerId: "App" })
                                let show_deblocage_etalonnage = true
                                while (show_deblocage_etalonnage) {
                                    let confirmCodeDeblocageEtalonnage = await isConfirmedDeblocage("Code de déblocage", { message: "Bris de scellement vallant réétalonnage mais k différent de précédemment " + infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_taximetre, option: 2 })
                                    if (confirmCodeDeblocageEtalonnage === "code_bon") {
                                        show_deblocage_etalonnage = false
                                    }
                                    else if (confirmCodeDeblocageEtalonnage === "code_incorrect") {
                                        toast.error("Code de déblocage erroné.", { autoClose: 5000 })
                                    }
                                    else {
                                        toast.error("Arrêt...", { autoClose: 5000 })
                                        show_deblocage_etalonnage = false
                                        redirectSaisie("etalonnage")
                                        return true
                                    }
                                }
                            }
                        }
                    })
            }
            if (intervention.etalonnage_k !== calculK) {
                toast.error("Calcul de 'k' différent de la valeur d'étalonnage.", { containerId: "App" })
                redirectSaisie("etalonnage")
                return true
            }
        }
        /**
         * Métrologie
         */
        if (refus === true) {
            if (isUniv === true && verif.c_point_lu !== "empty" && vaiVisible === true && intervention.installation === true && verif.c_r71 === true) {
                toast.error("Piste Universelle en installation : acceptée mais vous avez coché R71.", { containerId: "App" })
                return true
            }
            if (isUniv === true && verif.c_point_lu !== "empty" && vpVisible === true && intervention.que_vp === true && verif.c_r71 === true) {
                toast.error("Piste Universelle en VP : acceptée mais vous avez coché R71.", { containerId: "App" })
                return true
            }
            if (isUniv === true && verif.c_point_lu !== "empty" && vaiVisible === false && intervention.installation === true && verif.c_r71 === false) {
                toast.error("Piste Universelle en installation : refusée mais vous n'avez pas coché R71.", { containerId: "App" })
                return true
            }
            if (isUniv === true && verif.c_point_lu !== "empty" && vpVisible === false && intervention.que_vp === true && verif.c_r71 === false) {
                toast.error("Piste Universelle en VP : refusée mais vous n'avez pas coché R71.", { containerId: "App" })
                return true
            }
            if (isReelle === true && (verif.position_kilo === 1 || verif.position_kilo === 6 || verif.position_kilo === 2 || verif.position_kilo === 5) && intervention.installation === true && verif.c_r71 === false) {
                toast.error("Piste Réelle en installation : refusée mais vous n'avez pas coché R71.", { containerId: "App" })
                return true
            }
            if (isReelle === true && (verif.position_kilo === 1 || verif.position_kilo === 6) && intervention.que_vp === true && verif.c_r71 === false) {
                toast.error("Piste Réelle en VP : refusée mais vous n'avez pas coché R71.", { containerId: "App" })
                return true
            }
            if (verif.lue_secondes !== 0 && verif.lue_secondes !== "" && (verif.lue_secondes < emtMoins || verif.lue_secondes > emtPlus) && verif.c_r81 === false) {
                toast.error("Base horaire : essai faux mais vous n'avez pas coché R81.", { containerId: "App" })
                return true
            }
        }

        if (refus === true && vignette.etat_vignette === 3) {
            if (interventionInfoGeneral.vignette === true || interventionInfoGeneral.change_vignette === true) {
                if (interventionInfoGeneral.vignette === true && vignette.etat_vignette !== 1 && vignette.etat_vignette !== 2 && vignette.etat_vignette !== 3) {
                    toast.error("Vous devez identifier une sanction pose vignette ou intervention car l'intervention sélectionné présuppose une vignette.", { containerId: "App" })
                    return true
                }
                if ((vignette.etat_vignette === 1 || vignette.etat_vignette === 2) && parseInt(vignette.numero_vignette) === 0) {
                    toast.error("Vous devez identifier un numéro de vignette posée.", { containerId: "App" })
                    return true
                }
                if (vignette_unique.isUnique === false) {
                    toast.error(vignette_unique.message, { containerId: "App" })
                    return true
                }
            }
        }
        // a supprimer a la fin
        if (process.NODE_ENV !== "development") {
            if (verif.numero_intervenant !== 0 && verif.numero_intervenant !== "") {
                if (verif.numero_intervenant !== userId) {
                    toast.error("Le vérificateur sélectionné est différent de la session de l'intervenant.", { containerId: "App" })
                    let show_modal_deblocage = true
                    while (show_modal_deblocage) {
                        let confirmCodeDeblocage = await isConfirmedDeblocage("Code de déblocage", { message: "Changement de session vérificateur ", option: 2 })
                        if (confirmCodeDeblocage === "code_bon") {
                            show_modal_deblocage = false
                        }
                        else if (confirmCodeDeblocage === "code_incorrect") {
                            toast.error("Code de déblocage erroné.", { autoClose: 5000, containerId: 'App' })
                        }
                        else {
                            toast.error("Arrêt...", { autoClose: 5000, containerId: 'App' })
                            show_modal_deblocage = false
                            return true
                        }
                    }
                }
            }
        }
        // Controle des vignettes
        if (vignette.etat_vignette === 2) {
            if (isNullOrUndefined(vignette.numero_vignette) || vignette.numero_vignette === 0 || isNaN(vignette.numero_vignette)) {
                toast.error("Vous devez identifier un numéro de vignette posée", { containerId: 'App' })
                return true
            }
            else {
                return hasControlePoseError(vignette.numero_vignette, vignette.etat_vignette)
            }
        }
        if (vignette.etat_vignette === 1) {
            if (formIntervention.clef_d2 === 16) {
                var re = /^\d{4}$/
                if ((vignette.annee_vignette).toString().length !== 4 || !re.test(vignette.annee_vignette)) {
                    toast.error("Veuiller saisir une année valide de vignette de remplacement", { containerId: 'App' })
                    return true
                }
            }
        }
        return false
    }

    // Enregistrement partiel de la saisie
    const handleSubmitPartially = () => {
        if (disableSave !== true) {
            setStopAll(true)
            const saisie = {
                verif: formVerif,
                intervention: formIntervention,
                photos: formPhotos,
                materiels: formMateriel,
                vignette: formVignette,
            }

            setDisableSave(true)
            setIsSavingPartially(true)

            if (formVerif) {
                // Gestion metrologie
                if (isUniv) {
                    if (saisie.verif.c_point_lu === -1) {
                        formVerif.position_kilo = 99
                    }
                    else {
                        formVerif.position_kilo = parseInt(saisie.verif.c_point_lu) + 100
                    }
                }
                else if (isBanc) {
                    if (saisie.verif.banc === -1000) {
                        formVerif.position_kilo = -1000
                    }
                    else if (saisie.verif.banc === -1001) {
                        formVerif.position_kilo = -1001
                    }
                }
                // Réelle pas besoin car prend automatiquement la valeur de la position kilométrique
            }

            //if (!haveErrors(saisie)) {
            if (formIntervention) {
                if (formIntervention.date_effectuee) {
                    formIntervention.date_effectuee = null
                }
            }

            if (saisie?.vignette?.etat_vignette === 1) {
                if (isNullOrUndefined(saisie.vignette.numero_vignette) || isNaN(saisie.vignette.numero_vignette)) {
                    saisie.vignette.numero_vignette = 0
                }
            }

            // Vérification de l'existence des objets avant d'y accéder
            let _c_point_lu = saisie?.verif?.c_point_lu || null
            let _banc = saisie?.verif?.banc || null
            let _clef_numero_vignette = saisie?.vignette?.clef_numero_vignette || null

            // Vérification que verif et vignette existent avant de supprimer leurs propriétés
            if (saisie?.verif) {
                delete saisie.verif.c_point_lu
                delete saisie.verif.banc
            }
            if (saisie?.vignette) {
                delete saisie.vignette.clef_numero_vignette
            }

            if (saisie?.intervention) {
                saisie.intervention.quel_appli_enregistrement = 2
                saisie.intervention.appli_web_last_used = new Date()
            }

            //process.env.NODE_ENV === "development" && console.log(saisie)
            supervisionService.saveSaisiePartially(saisie)
                .then(response => {
                    // On met à jour le formulaire initial
                    formVerif.c_point_lu = _c_point_lu
                    formVerif.banc = _banc
                    formVignette.clef_numero_vignette = _clef_numero_vignette
                    setInitialFormVerif(formVerif)
                    setInitialFormIntervention(formIntervention)
                    setInitialFormPhotos(formPhotos)
                    setInitialFormMateriel(formMateriel)
                    setInitialFormVignette(formVignette)
                    // On désactive les boutons d'enregistrement
                    setDisableSave(true)
                    setIsSavingPartially(false)
                    notifySavingDone()
                })
                .catch(error => {
                    notifyError(error)
                    setDisableSave(false)
                    setIsSavingPartially(false)
                })
                .finally(() => {
                    setStopAll(false)
                })
            /*}
            else {
                setDisableSave(false)
                setIsSavingPartially(false)
            }*/
        }
        else {
            console.log("Petit Malin. Appelle au siège si tu veux savoir ce qui se cache sous le coffre !")
        }
    }

    // Enregistrement de la saisie
    // La différence est qu'on a une date effectuée
    const handleSubmit = async () => {

        if (disableSave !== true) {
            setDisableSave(true)
            setIsSaving(true)

            const saisie = {
                verif: formVerif,
                intervention: formIntervention,
                photos: formPhotos,
                materiels: formMateriel,
                vignette: formVignette,
            }

            haveErrors(saisie)
                .then(error => {
                    if (!error) {
                        //console.log("Pas d'erreur")
                        process.env.NODE_ENV === "development" && console.log(saisie)

                        if (saisie.vignette.etat_vignette === 1 && (isNullOrUndefined(saisie.vignette.numero_vignette) || isNaN(saisie.vignette.numero_vignette))) {
                            saisie.vignette.numero_vignette = 0
                        }

                        if (formVerif) {
                            // Gestion metrologie
                            if (isUniv) {
                                if (saisie.verif.c_point_lu === -1) {
                                    formVerif.position_kilo = 99
                                }
                                else {
                                    formVerif.position_kilo = parseInt(saisie.verif.c_point_lu) + 100
                                }
                            }
                            else if (isBanc) {
                                if (saisie.verif.banc === -1000) {
                                    formVerif.position_kilo = -1000
                                }
                                else if (saisie.verif.banc === -1001) {
                                    formVerif.position_kilo = -1001
                                }
                            }
                            // Réelle pas besoin car prend automatiquement la valeur de la position kilométrique
                        }

                        let _c_point_lu = saisie.verif.c_point_lu
                        let _banc = saisie.verif.banc
                        let _clef_numero_vignette = saisie.vignette.clef_numero_vignette

                        delete saisie.verif.c_point_lu
                        delete saisie.verif.banc
                        delete saisie.vignette.clef_numero_vignette
                        saisie.intervention.quel_appli_enregistrement = 2
                        saisie.intervention.appli_web_last_used = new Date()

                        supervisionService.saveSaisie(saisie)
                            .then(response => {
                                formVerif.c_point_lu = _c_point_lu
                                formVerif.banc = _banc
                                formVignette.clef_numero_vignette = _clef_numero_vignette
                                // On met à jour le formulaire initial
                                setInitialFormVerif(formVerif)
                                setInitialFormIntervention(response)
                                setFormIntervention(response)
                                setInitialFormPhotos(formPhotos)
                                setInitialFormMateriel(formMateriel)
                                setInitialFormVignette(formVignette)
                                // On désactive les boutons d'enregistrement
                                setDisableSave(true)
                                setIsSaving(false)
                                notifySavingDone()
                            })
                            .catch(error => {
                                setDisableSave(false)
                                setIsSaving(false)
                                notifyError(error)
                            })
                    }
                    else {
                        //console.log("Erreur")
                        setDisableSave(false)
                        setIsSaving(false)
                    }
                })

        } else {
            console.log("Petit Malin. Appelle au siège si tu veux savoir ce qui se cache sous le coffre !")
        }
    }

    const notifyPartialSavingLoading = () => {
        toastSavingId.current = toast("Enregistrement partiel en cours...", { type: toast.TYPE.INFO, autoClose: false, containerId: 'App' })
    }

    const notifySavingLoading = () => {
        toastSavingId.current = toast("Enregistrement en cours...", { type: toast.TYPE.INFO, autoClose: false, containerId: 'App' })
    }

    const notifySavingDone = () => {
        toast.update(toastSavingId.current, { render: "Enregistré avec succès !", type: toast.TYPE.SUCCESS, autoClose: 6000, containerId: 'App' })
    }

    const notifyError = (message) => {
        toast.error(message, { autoClose: 5000, containerId: 'App' })
    }

    const notifySavingLoadingDone = () => {
        toast.dismiss(toastSavingId.current)
    }

    useEffect(() => {
        if (shouldBlockNavigation) {
            window.onbeforeunload = () => true
        }
    }, [shouldBlockNavigation])

    const togglePopup = () => {
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    return (
        <>
            <div className={"loading-app" + (stopAll ? " loading-app-show" : "")}>
                <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={50} style={{ padding: 50 }} />
            </div>
            <Prompt
                message={(location, action) => {
                    return location.pathname.startsWith("/intervention")
                        ? true
                        : `Êtes-vous sûr de vouloir quitter l'intervention ?`
                }}
            />
            <h2 className='pt-4 px-1'>{currentSaisieTitle}</h2>
            <Router history={history}>
                <div className="section-saisie mt-3">
                    <div className="saisie-container">
                        <div className={"saisie-sidebar" + (!showMenu ? " saisie-sidebar-hide" : "")}>
                            <div className="saisie-sidebar-header">
                                <button className="btn saisie-sidebar-btn-close" onClick={toggleMenu}>
                                    <BiArrowFromRight className="saisie-sidebar-btn-icon" />
                                </button>
                            </div>
                            <div className="saisie-sidebar-body">
                                <ul>
                                    {history.location.pathname.includes("intervention/") && UserCase && UserCase.map((g, n) => {
                                        if (((interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === true && g.group !== "Installation") || (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === false) {
                                            return (
                                                <Fragment key={n}>
                                                    <div className="group-header">
                                                        <span>
                                                            {g.group}
                                                        </span>
                                                    </div>
                                                    <div className="group-body">
                                                        {g.items.map((u, m) => {
                                                            if (u.link === "/intervention/etalonnage") {
                                                                if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                                                                    return (
                                                                        <li key={m}>
                                                                            <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                                <div className="saisie-sidebar-icon">
                                                                                    {u.image}
                                                                                </div>
                                                                                <div className="saisie-outils-texte">
                                                                                    {u.name}
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            }
                                                            else {
                                                                return (
                                                                    <li key={m} >
                                                                        <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                            <div className="saisie-sidebar-icon">
                                                                                {u.image}
                                                                            </div>
                                                                            <div className="saisie-outils-texte">
                                                                                {u.name}
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    }
                                    )}
                                    {history.location.pathname.includes("intervention-v2") && UserCaseV2 && UserCaseV2.map((g, n) => {
                                        if (((interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === true && g.group !== "Installation") || (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === false) {
                                            return (
                                                <Fragment key={n}>
                                                    <div className="group-header">
                                                        <span>
                                                            {g.group}
                                                        </span>
                                                    </div>
                                                    <div className="group-body">
                                                        {g.items.map((u, m) => {
                                                            if (u.link === "/intervention-v2/etalonnage") {
                                                                if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                                                                    return (
                                                                        <li key={m} >
                                                                            <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention-v2/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                                <div className="saisie-sidebar-icon">
                                                                                    {u.image}
                                                                                </div>
                                                                                <div className="saisie-outils-texte">
                                                                                    {u.name}
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                                else {
                                                                    return null
                                                                }
                                                            }
                                                            else {
                                                                return (
                                                                    <li key={m} >
                                                                        <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention-v2/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                            <div className="saisie-sidebar-icon">
                                                                                {u.image}
                                                                            </div>
                                                                            <div className="saisie-outils-texte">
                                                                                {u.name}
                                                                            </div>
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className={"saisie-main" + (!showMenu ? " saisie-main-expand" : "")}>
                            <div className={"saisie-header" + (stickySave ? " saisie-fixed-top" : "")}>
                                <div className="saisie-actions">
                                    <div className="saisie-actions-container">
                                        <button disabled={blockSaveMetrologie || disableSave} className="btn saisie-btn" onClick={handleSubmit}>
                                            <i className="fas fa-save"></i>
                                            {isSaving ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enregistrement"}
                                        </button>
                                        <button disabled={blockSaveMetrologie || disableSave} className="btn saisie-btn" onClick={handleSubmitPartially}>
                                            <i className="fas fa-cloud"></i>
                                            {isSavingPartially ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} /> : "Enreg. partiel"}
                                        </button>
                                    </div>
                                </div>
                                <div className="saisie-numerotations mt-2">
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classes.heading}>Numérotations et Signature Clients et Vérificateur</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="form-saisie-container">
                                                <Formik
                                                    enableReinitialize
                                                    initialValues={initial}
                                                    validate={async values => {
                                                        const errors = {}
                                                        let taximetre = await storage.get('taximetre')
                                                        let intervention = await storage.get('intervention')
                                                        // Mise à jour des interventions
                                                        intervention.revision_rapport = values.revision_rapport
                                                        intervention.numero_oi_interne = values.numero_oi_interne
                                                        intervention.numero_commande = values.numero_commande
                                                        intervention.notes = values.notes
                                                        intervention.sign1 = values.sign1
                                                        // Mise à jour des taximetres
                                                        taximetre.anomalies = values.anomalies
                                                        taximetre.numero_intervenant = values.numero_intervenant
                                                        // Partage des données
                                                        setFormVerif(taximetre)
                                                        setFormIntervention(intervention)
                                                        // Mise à jour de localStorage
                                                        await storage.set('taximetre', taximetre)
                                                        await storage.set('intervention', intervention)
                                                    }}
                                                    onSubmit={(values, { setSubmitting }) => {
                                                        setTimeout(() => {
                                                            alert(JSON.stringify(values, null, 2));
                                                            setSubmitting(false);
                                                        }, 400);
                                                    }}
                                                >
                                                    {({ values, setFieldValue, isSubmitting }) => (
                                                        <Form>
                                                            {values &&
                                                                <>
                                                                    <div className="form-row m-m-0">
                                                                        <div className="modele-actions mb-3">
                                                                            <button type="button" className={"modele-btn" + (values.sign1 ? " bg-success" : " bg-danger")} onClick={togglePopup}>
                                                                                Signature client avant
                                                                                <i className="fas fa-external-link-alt ml-2"></i>
                                                                            </button>
                                                                            <span className={"details-item" + (values.sign1 ? " option-yes" : "")}>{!values.sign1 ? "Vide" : "Signé"}</span>
                                                                        </div>
                                                                    </div>
                                                                    {values.sign1 ?
                                                                        <div className="form-row m-m-0">
                                                                            <div className="signature-img-container">
                                                                                <img src={"data:image/png;base64," + values.sign1} alt="" />
                                                                            </div>
                                                                        </div> : null
                                                                    }
                                                                    {showPopup &&
                                                                        <SignaturePopup close={togglePopup} title="Signature Client avant" subtitle={"Merci de valider votre accord pour l'intervention : " + getTextInterventionDetail(selectedIntervention.data)} setFieldValue={setFieldValue} inputName="sign1" />
                                                                    }
                                                                </>
                                                            }
                                                            <div className="form-group simi-row">
                                                                <div className="col">
                                                                    <label htmlFor="revision_rapport">Révision</label>
                                                                    <Field className="form-control" type="text" name="revision_rapport" value={(values && !values.revision_rapport ? "0" : undefined) || (values && values.revision_rapport) || ""} disabled />
                                                                    <ErrorMessage name="revision_rapport" component="div" />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="numero_oi_interne">N° OI Interne</label>
                                                                    <Field className="form-control" type="text" name="numero_oi_interne" value={(values && !values.numero_oi_interne ? "" : undefined) || (values && values.numero_oi_interne) || ""} />
                                                                    <ErrorMessage name="numero_oi_interne" component="div" />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="numero_commande">N° Commande</label>
                                                                    <Field className="form-control" type="text" name="numero_commande" value={(values && !values.numero_commande ? "" : undefined) || (values && values.numero_commande) || ""} />
                                                                    <ErrorMessage name="numero_commande" component="div" />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="numero_intervenant">Vérificateur</label>
                                                                    <Field className="form-control" as="select" name="numero_intervenant" value={(values && !values.numero_intervenant ? userId : undefined) || (values && values.numero_intervenant) || ""}>
                                                                        <option value=""></option>
                                                                        {intervenants && intervenants.map((i, n) =>
                                                                            <option key={n} value={i.numero_intervenant}>{i.prenom + " " + i.nom}</option>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className="form-group simi-row">
                                                                <div className="col">
                                                                    <label htmlFor="anomalies">Anomalies pendant Verif</label>
                                                                    <Field className="form-control" as="textarea" name="anomalies" value={(values && !values.anomalies ? "" : undefined) || (values && values.anomalies) || ""} />
                                                                    <ErrorMessage name="anomalies" component="div" />
                                                                </div>
                                                                <div className="col">
                                                                    <label htmlFor="notes">Notes (pour résumé)</label>
                                                                    <Field className="form-control" as="textarea" name="notes" value={(values && !values.notes ? "" : undefined) || (values && values.notes) || ""} />
                                                                    <ErrorMessage name="notes" component="div" />
                                                                </div>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                            <div className="saisie-body mt-2">
                                <div className="content">
                                    <Switch>
                                        <Route exact path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/infos" : "/intervention/infos"} component={Infos} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/modele" : "/intervention/modele"} component={Modele} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/procedure" : "/intervention/procedure"} component={Procedure} />
                                        {/*<Route path="/intervention/conception" component={Conception} />*/}
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/sanction" : "/intervention/sanction"} component={Sanction} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/photos" : "/intervention/photos"} component={Photos} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/metrologie" : "/intervention/metrologie"} component={Metrologie} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/imprimante" : "/intervention/imprimante"} component={Imprimante} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/liaisons" : "/intervention/liaisons"} component={Liaisons} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/lumineux" : "/intervention/lumineux"} component={Lumineux} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/autres" : "/intervention/autres"} component={Autres} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/taximetre" : "/intervention/taximetre"} component={Taximetre} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/reception-pneumatique" : "/intervention/reception-pneumatique"} component={ReceptionP} />
                                        <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/reception-vehicule" : "/intervention/reception-vehicule"} component={ReceptionV} />
                                        {!(interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) && (interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement) &&
                                            <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/etalonnage" : "/intervention/etalonnage"} component={Etalonnage} />
                                        }
                                        {!(interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) &&
                                            <Route path={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/installateur" : "/intervention/installateur"} component={Installateur} />
                                        }
                                        <Redirect from="*" to={history.location.pathname.includes("intervention-v2") ? "/intervention-v2/infos" : "/intervention/infos"} />
                                    </Switch >
                                </div >
                            </div >
                        </div >
                        <div className="saisie-navigation-rapide z-3">
                            <div className="saisie-navigation-rapide-container">
                                <button id="b1" className="btn comet-btn-primary comet-btn-nr" onClick={handleChangePage} >
                                    <span>&lsaquo;</span>
                                </button>
                                <button id="b2" className="btn comet-btn-primary comet-btn-nr" onClick={handleChangePage}>
                                    <span>&rsaquo;</span>
                                </button>
                            </div>
                        </div>
                        <div className="saisie-navigation">
                            <div className="saisie-navigation-bottom">
                                <div className="saisie-navigation-bottom-container">
                                    {history.location.pathname.includes("intervention/") && UserCase && UserCase.map((g, n) => {
                                        if (((interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === true && g.group !== "Installation") || (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === false) {
                                            return (
                                                <div key={n} className="group-body">
                                                    {g.items.map((u, m) => {
                                                        if (u.link === "/intervention/etalonnage") {
                                                            if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                                                                return (
                                                                    <div key={m} >
                                                                        <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                            <div className="saisie-sidebar-icon">
                                                                                {u.image}
                                                                            </div>
                                                                            <div className="saisie-outils-texte">
                                                                                {u.name}
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }
                                                        else {
                                                            return (
                                                                <div key={m} >
                                                                    <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                        <div className="saisie-sidebar-icon">
                                                                            {u.image}
                                                                        </div>
                                                                        <div className="saisie-outils-texte">
                                                                            {u.name}
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </div>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    }
                                    )}
                                    {history.location.pathname.includes("intervention-v2") && UserCaseV2 && UserCaseV2.map((g, n) => {
                                        if (((interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === true && g.group !== "Installation") || (interventionInfoGeneral.que_vp === true && interventionInfoGeneral.d1 === false) === false) {
                                            return (
                                                <div key={n} className="group-body">
                                                    {g.items.map((u, m) => {
                                                        if (u.link === "/intervention-v2/etalonnage") {
                                                            if ((interventionInfoGeneral.installation || interventionInfoGeneral.bris_scellement)) {
                                                                return (
                                                                    <div key={m} >
                                                                        <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention-v2/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                            <div className="saisie-sidebar-icon">
                                                                                {u.image}
                                                                            </div>
                                                                            <div className="saisie-outils-texte">
                                                                                {u.name}
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )
                                                            }
                                                            else {
                                                                return null
                                                            }
                                                        }
                                                        else {
                                                            return (
                                                                <div key={m} >
                                                                    <Link to={{ pathname: u.link, state: { prevPath: history.location.pathname.replace("/intervention-v2/", "") } }} className={((u.link === history.location.pathname) ? "saisie-active" : "") + (okList.indexOf(u.name) !== -1 ? " isok" : "")}>
                                                                        <div className="saisie-sidebar-icon">
                                                                            {u.image}
                                                                        </div>
                                                                        <div className="saisie-outils-texte">
                                                                            {u.name}
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </div>
                                            )
                                        }
                                        else {
                                            return null
                                        }
                                    }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </Router >
        </>
    )
}

export default SaisieIntervention