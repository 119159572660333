import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import { Tabs } from 'antd'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { supervisionService } from '../../../../services/supervisionService'
import { formatDate } from '../../../../helpers/utils'
import Checkbox from '@material-ui/core/Checkbox'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { storage } from '../../../../helpers/storageHelper'

const { TabPane } = Tabs

const Logiciel = (props) => {

    const [logiciel, setLogiciel] = useState(null)
    const [logiciel_intervention, setLogiciel_intervention] = useState(null)
    const [infos, setInfos] = useState(null)
    const [modelesLog, setModelesLog] = useState([])
    const [infosRevisionAvant, setInfosRevisionAvant] = useState("")
    const [infosRevisionNew, setInfosRevisionNew] = useState("")
    const [fileAvant, setFileAvant] = useState(null)
    const [fileNew, setFileNew] = useState(null)
    const [loadingWork, setLoadingWork] = useState(false)
    const [disableDetailAvant, setDisableDetailAvant] = useState(false)
    const [disableDetailApres, setDisableDetailApres] = useState(false)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des données avec le storage helper
                const [taximetreData, interventionData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention'),
                    storage.get('infos')
                ])

                if (taximetreData) setLogiciel(taximetreData)
                if (interventionData) setLogiciel_intervention(interventionData)
                if (infosData) setInfos(infosData)

                if (infosData && interventionData) {
                    const marque = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_appareil
                    const modele = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_appareil

                    await loadModelesLog(interventionData, marque, modele)
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            }
        }

        loadInitialData()
    }, [])

    const loadModelesLog = async (intervention, marque, modele) => {
        try {
            const response = await supervisionService.getModelesLog(marque, modele)
            setModelesLog(response)

            if (intervention?.numero_cet_avant) {
                const myModele = response.filter(modele => modele.clef_logiciel === intervention.numero_cet_avant)[0]
                setInfosRevisionAvant("Log : " + myModele.version_logiciel + " Signature " + myModele.signature + " (" + formatDate(myModele.acceptation) + " N° : " + myModele.dam_cet + ") \n" + myModele.autres)
                await loadFile(myModele.numero_document_cet, "avant")
                if (myModele.version_logiciel.indexOf("?") === -1) {
                    setDisableDetailAvant(true)
                }
            }

            if (intervention?.numero_cet_new) {
                const myModele2 = response.filter(modele => modele.clef_logiciel === intervention.numero_cet_new)[0]
                setInfosRevisionNew("Log : " + myModele2.version_logiciel + " Signature " + myModele2.signature + " (" + formatDate(myModele2.acceptation) + " N° : " + myModele2.dam_cet + ") \n" + myModele2.autres)
                await loadFile(myModele2.numero_document_cet, "new")
                if (myModele2.version_logiciel.indexOf("?") === -1) {
                    setDisableDetailApres(true)
                }
            }
        } catch (error) {
            console.error('Erreur lors du chargement des modèles:', error)
        }
    }

    // Changement des signatures cet et chargement des infos Révision
    const handleChange = async (e, setFieldValue, values, id) => {
        try {
            if (id === "avant") {
                setFieldValue("numero_cet_avant", e.target.value)
                let modele = modelesLog.filter(m => m.clef_logiciel === parseInt(e.target.value))[0]
                if (modele && modele.version_logiciel.indexOf("?") === -1) {
                    setDisableDetailAvant(true)
                }
            } else {
                setFieldValue("numero_cet_new", e.target.value)
                let modele = modelesLog.filter(m => m.clef_logiciel === parseInt(e.target.value))[0]
                if (modele && modele.version_logiciel.indexOf("?") === -1) {
                    setDisableDetailApres(true)
                }
            }

            let texteRevision = ""
            let detail = ""
            var index = e.nativeEvent.target.selectedIndex
            if (index !== 0) {
                texteRevision = e.nativeEvent.target[index].text + "\n" + modelesLog[index - 1].autres
                detail = e.nativeEvent.target[index].getAttribute('data-log')
            }

            id === "avant" ? setInfosRevisionAvant(texteRevision) : setInfosRevisionNew(texteRevision)
            if (id === "avant" && !values.numero_cet_avant_detail) {
                setFieldValue("numero_cet_avant_detail", detail)
            } else if (id !== "avant" && !values.numero_cet_new_detail) {
                setFieldValue("numero_cet_new_detail", detail)
            }

            await loadFile(parseInt(e.nativeEvent.target[index].getAttribute('data-doc')), id)
        } catch (error) {
            console.error('Erreur lors du changement:', error)
        }
    }

    // Chargement du document de la signature
    const loadFile = async (numero, id) => {
        setLoadingWork(true)
        try {
            if (!isNaN(numero)) {
                const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                id === "avant" ? setFileAvant(response) : setFileNew(response)
            } else {
                id === "avant" ? setFileAvant(null) : setFileNew(null)
            }
        } catch (error) {
            id === "avant" ? setFileAvant(null) : setFileNew(null)
        }
        setLoadingWork(false)
    }

    // Ouvre un document 
    const openPDF = (event, afile) => {
        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)
    }

    const similaireLogicielReception = async (setFieldValue, values) => {
        try {
            setFieldValue("numero_cet_new", values.numero_cet_avant)
            setInfosRevisionNew(infosRevisionAvant)
            setFieldValue("numero_cet_new_detail", values.numero_cet_avant_detail)

            const modele = modelesLog?.filter(m => m.clef_logiciel === parseInt(values.numero_cet_avant))[0]
            if (modele) {
                await loadFile(modele.numero_document_cet, "new")
            }
        } catch (error) {
            console.error('Erreur lors de la copie des données:', error)
        }
    }

    if (!logiciel || !logiciel_intervention || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                validate={async (values) => {
                    const errors = {}
                    try {
                        const [taximetre, intervention] = await Promise.all([
                            storage.get('taximetre'),
                            storage.get('intervention')
                        ])

                        // Mise à jour des interventions
                        intervention.anomalie_logiciel = values.anomalie_logiciel
                        intervention.detail_anomalie_logiciel = values.detail_anomalie_logiciel
                        intervention.numero_cet_avant = parseInt(values.numero_cet_avant)
                        intervention.numero_cet_avant_detail = values.numero_cet_avant_detail
                        intervention.numero_cet_new = parseInt(values.numero_cet_new)
                        intervention.numero_cet_new_detail = values.numero_cet_new_detail

                        // Mise à jour des taximetres
                        taximetre.c_ANO_maj_ml_sans_vprim = values.c_ANO_maj_ml_sans_vprim
                        taximetre.c_REF_maj_ml_sans_vprim = values.c_REF_maj_ml_sans_vprim
                        taximetre.reF_maj_ml_sans_vprim_detail = values.reF_maj_ml_sans_vprim_detail
                        taximetre.anO_maj_ml_sans_vprim_detail = values.anO_maj_ml_sans_vprim_detail
                        taximetre.c_cet = values.c_cet
                        taximetre.c_logiciel = values.c_logiciel

                        // Mise à jour du storage
                        await Promise.all([
                            storage.set('taximetre', taximetre),
                            storage.set('intervention', intervention)
                        ])

                        // Partage des données
                        props.passFormToParent(taximetre, intervention, errors)
                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                        errors.submit = "Erreur lors de la sauvegarde"
                    }
                    return errors
                }}
                initialValues={{
                    anomalie_logiciel: logiciel_intervention.anomalie_logiciel,
                    detail_anomalie_logiciel: logiciel_intervention.detail_anomalie_logiciel,
                    numero_cet_avant: logiciel_intervention.numero_cet_avant,
                    numero_cet_avant_detail: logiciel_intervention.numero_cet_avant_detail,
                    numero_cet_new: logiciel_intervention.numero_cet_new,
                    numero_cet_new_detail: logiciel_intervention.numero_cet_new_detail,
                    c_ANO_maj_ml_sans_vprim: logiciel.c_ANO_maj_ml_sans_vprim,
                    anO_maj_ml_sans_vprim_detail: logiciel.anO_maj_ml_sans_vprim_detail,
                    c_REF_maj_ml_sans_vprim: logiciel.c_REF_maj_ml_sans_vprim,
                    reF_maj_ml_sans_vprim_detail: logiciel.reF_maj_ml_sans_vprim_detail,
                    c_cet: logiciel.c_cet,
                    c_logiciel: logiciel.c_logiciel,
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues }) => (
                    <Form>
                        <div className="affichage-form-container logiciel">
                            {/* Accès */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Accès
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="manip_logiciel" className="form-control form-infos" disabled as="textarea" value={infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_logiciel} />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-logiciel-container">
                                <Tabs>
                                    <TabPane tab="A réception" key="1">
                                        {/* Logiciel à réception */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        C.E.T
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field className="form-control" as="select" name="numero_cet_avant" onChange={e => handleChange(e, setFieldValue, values, "avant")}>
                                                        <option value="0"></option>
                                                        {modelesLog && modelesLog.map((m, i) =>
                                                            <option value={m.clef_logiciel} key={i} data-doc={m.numero_document_cet} data-log={m.version_logiciel}>{"Log : " + m.version_logiciel + " Signature " + m.signature + " (" + formatDate(m.acceptation) + " N° : " + m.dam_cet + ")"}</option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {fileAvant && fileAvant.fichier_contenu &&
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <div className="popup-action-open">
                                                            <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, fileAvant)}>
                                                                <span>Ouvrir</span>
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Détail
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field className="form-control" type="text" name="numero_cet_avant_detail" disabled={disableDetailAvant} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Info Révision
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="infosRevisionAvant" className="form-control form-infos" disabled as="textarea" value={infosRevisionAvant} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="A libération" key="2">
                                        {/* Logiciel à libération */}
                                        <div className="button-similaire">
                                            <button type="button" className="cbtn cbtn-success" onClick={() => similaireLogicielReception(setFieldValue, values)}>
                                                {loadingWork ? <UseAnimations strokeColor={'var(--comet-white)'} animation={loading} size={20} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                                    : "= A reception"}
                                            </button>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        C.E.T
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <div className="form-input">
                                                    <Field className="form-control" as="select" name="numero_cet_new" onChange={e => handleChange(e, setFieldValue, values, "new")}>
                                                        <option value="0"></option>
                                                        {modelesLog && modelesLog.map((m, i) =>
                                                            <option value={m.clef_logiciel} key={i} data-doc={m.numero_document_cet} data-log={m.version_logiciel}>{"Log : " + m.version_logiciel + " Signature " + m.signature + " (" + formatDate(m.acceptation) + " N° : " + m.dam_cet + ")"}</option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            {fileNew && fileNew.fichier_contenu &&
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <div className="popup-action-open">
                                                            <button type="button" className={"upload-file-btn upload-file-btn-open"} onClick={e => openPDF(e, fileNew)}>
                                                                <span>Ouvrir</span>
                                                                <i className="fas fa-eye"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Détail
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field className="form-control" type="text" name="numero_cet_new_detail" disabled={disableDetailApres} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Info Révision
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="infosRevisionNew" className="form-control form-infos" disabled as="textarea" value={infosRevisionNew} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Sanction" key="3">
                                        {/* Conformité au CET */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Conformité au CET
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_cet"
                                                                id="c_cet-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_cet', 1)}
                                                                checked={values.c_cet === 1 ? true : false} />
                                                            <label htmlFor="c_cet-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                OK
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_cet"
                                                                id="c_cet-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_cet', 2)}
                                                                checked={values.c_cet === 2 ? true : false} />
                                                            <label htmlFor="c_cet-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R61
                                                            </label>
                                                        </div>
                                                    </li>
                                                    {props.interventionInfoGeneral.que_vp === false &&
                                                        <li>
                                                            <div className="input-radio-item">
                                                                <Field
                                                                    type="radio"
                                                                    name="c_cet"
                                                                    id="c_cet-4"
                                                                    className="sr-only"
                                                                    onChange={() => setFieldValue('c_cet', 3)}
                                                                    checked={values.c_cet === 3 ? true : false} />
                                                                <label htmlFor="c_cet-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                    <Intervention />
                                                                    I61
                                                                </label>
                                                            </div>
                                                        </li>
                                                    }
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_cet"
                                                                id="c_cet-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_cet', 0)}
                                                                checked={values.c_cet === 0 ? true : false} />
                                                            <label htmlFor="c_cet-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Logiciel conforme */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Logiciel conforme
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_logiciel"
                                                                id="c_logiciel-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_logiciel', 1)}
                                                                checked={values.c_logiciel === 1 ? true : false} />
                                                            <label htmlFor="c_logiciel-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                OK
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_logiciel"
                                                                id="c_logiciel-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_logiciel', 2)}
                                                                checked={values.c_logiciel === 2 ? true : false} />
                                                            <label htmlFor="c_logiciel-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R06
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_logiciel"
                                                                id="c_logiciel-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_logiciel', 0)}
                                                                checked={values.c_logiciel === 0 ? true : false} />
                                                            <label htmlFor="c_logiciel-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* Anomalie incohérence */}
                                        <div className="form-row">
                                            <div className="form-cell-input checkbox-container">
                                                <div className="form-input input-anomalie">
                                                    <Checkbox
                                                        checked={values.anomalie_logiciel}
                                                        name="anomalie_logiciel"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        onChange={(e) => {
                                                            setValues(prev => ({
                                                                ...prev,
                                                                detail_anomalie_logiciel: "",
                                                                anomalie_logiciel: !values.anomalie_logiciel
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Anomalie incohérence (Millésime / Logiciel) :
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field className="form-control" type="text" name="detail_anomalie_logiciel" disabled={values.anomalie_logiciel ? undefined : true} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Jusqu'au 31/05/2021 : MAJ Logiciel Métrologique sans Vérification Primitive */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Jusqu'au 31/05/2021 : MAJ Logiciel Métrologique sans Vérification Primitive
                                                        <i className="fas fa-question-circle ctooltip">
                                                            <span className="tooltiptext">
                                                                Selon carnet
                                                            </span>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ANO_maj_ml_sans_vprim"
                                                                id="c_ANO_maj_ml_sans_vprim-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_ANO_maj_ml_sans_vprim', 1)}
                                                                checked={values.c_ANO_maj_ml_sans_vprim === 1 ? true : false} />
                                                            <label htmlFor="c_ANO_maj_ml_sans_vprim-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Non
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ANO_maj_ml_sans_vprim"
                                                                id="c_ANO_maj_ml_sans_vprim-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_ANO_maj_ml_sans_vprim', 4)}
                                                                checked={values.c_ANO_maj_ml_sans_vprim === 4 ? true : false} />
                                                            <label htmlFor="c_ANO_maj_ml_sans_vprim-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                Pas d'info
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_ANO_maj_ml_sans_vprim"
                                                                id="c_ANO_maj_ml_sans_vprim-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_ANO_maj_ml_sans_vprim', 2)}
                                                                checked={values.c_ANO_maj_ml_sans_vprim === 2 ? true : false} />
                                                            <label htmlFor="c_ANO_maj_ml_sans_vprim-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Intervention />
                                                                A110
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field className="form-control" type="text" name="anO_maj_ml_sans_vprim_detail" value={values.anO_maj_ml_sans_vprim_detail} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Jusqu'au 01/06/2021 : MAJ Logiciel Métrologique sans Vérification Primitive */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Jusqu'au 01/06/2021 : MAJ Logiciel Métrologique sans Vérification Primitive
                                                        <i className="fas fa-question-circle ctooltip">
                                                            <span className="tooltiptext">
                                                                Selon carnet
                                                            </span>
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_REF_maj_ml_sans_vprim"
                                                                id="c_REF_maj_ml_sans_vprim-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_REF_maj_ml_sans_vprim', 1)}
                                                                checked={values.c_REF_maj_ml_sans_vprim === 1 ? true : false} />
                                                            <label htmlFor="c_REF_maj_ml_sans_vprim-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Non
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_REF_maj_ml_sans_vprim"
                                                                id="c_REF_maj_ml_sans_vprim-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_REF_maj_ml_sans_vprim', 4)}
                                                                checked={values.c_REF_maj_ml_sans_vprim === 4 ? true : false} />
                                                            <label htmlFor="c_REF_maj_ml_sans_vprim-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                Pas d'info
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_REF_maj_ml_sans_vprim"
                                                                id="c_REF_maj_ml_sans_vprim-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_REF_maj_ml_sans_vprim', 3)}
                                                                checked={values.c_REF_maj_ml_sans_vprim === 3 ? true : false} />
                                                            <label htmlFor="c_REF_maj_ml_sans_vprim-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R110
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field className="form-control" type="text" name="reF_maj_ml_sans_vprim_detail" value={values.reF_maj_ml_sans_vprim_detail} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Logiciel