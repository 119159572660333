import { useState, useEffect, useRef } from "react"
import Timer from "./Timer"
import ControlButtons from "./ControlButtons"
import { supervisionService } from "../../../../services/supervisionService"
import { toast } from "react-toastify"
import { isNullOrUndefined, logger, loggerDev } from "../../../../helpers/utils"
import { useSaisieContext } from "../SaisieContext"

const StopWatch = (props) => {
    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const intervalRef = useRef(null)
    const [elapsedTime, setElapsedTime] = useState(0)
    const [remainingTime, setRemainingTime] = useState(0);
    const countdownRef = useRef(null);
    const { setBlockSaveMetrologie } = useSaisieContext()

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current)
            clearInterval(countdownRef.current)
        }
    }, [])

    const start = () => {
        if (!isRunning) {
            if (props.tempo > 0) {
                const startCountdown = Date.now()
                const totalWaitTime = props.tempo
                setRemainingTime(totalWaitTime)

                // Notify initial waiting time
                if (props.returnTimeToWait) {
                    props.returnTimeToWait("Démarrage dans " + Math.ceil(totalWaitTime) + " s")
                }

                // Update remaining time every second
                countdownRef.current = setInterval(() => {
                    const elapsed = (Date.now() - startCountdown) / 1000
                    const remaining = Math.max(0, totalWaitTime - elapsed)
                    setRemainingTime(remaining)

                    if (props.returnTimeToWait) {
                        const roundedRemaining = Math.ceil(remaining)
                        if (roundedRemaining > 0) {
                            props.returnTimeToWait("Démarrage dans " + roundedRemaining + " s")
                        } else {
                            props.returnTimeToWait("Démarré")
                        }
                    }

                    if (remaining <= 0) {
                        clearInterval(countdownRef.current)
                    }
                }, 1000)

                // Start timer after tempo
                setTimeout(() => {
                    clearInterval(countdownRef.current)
                    startTimer()
                }, props.tempo * 1000)
            } else {
                startTimer()
            }
        }
    }

    const startTimer = () => {
        setIsRunning(true)
        supervisionService.stopwatchStart()
            .then(response => {
                const startTime = Date.now()
                intervalRef.current = setInterval(() => {
                    let _time = Date.now() - startTime;
                    setTime(_time)
                }, 10)
            })
            .catch(error => {
                toast.error(error, { containerId: 'App' })
                console.log(error)
            })
    }

    const stop = () => {
        if (isRunning) {
            clearInterval(intervalRef.current)
            setIsRunning(false)
            const startTime = performance.now() // Début de la mesure du temps
            supervisionService.stopwatchStop()
                .then(response => {
                    const endTime = performance.now(); // Fin de la mesure du temps
                    const requestDuration = endTime - startTime; // Durée de la requête en millisecondes
                    supervisionService.stopwatchElapsed()
                        .then(response => {
                            setElapsedTime(convertElapsedTimeToSeconds(response.elapsedTime))
                            let value = (convertElapsedTimeToSeconds(response.elapsedTime) - requestDuration / 1000)
                            controleServeur(value)
                        })
                        .catch(error => {
                            toast.error(error, { containerId: 'App' });
                            console.log(error)
                        })
                })
                .catch(error => {
                    toast.error(error, { containerId: 'App' });
                    console.log(error)
                })
        }
        props.parentCallback(time / 1000)
    }

    const reset = () => {
        clearInterval(intervalRef.current)
        if (isRunning === true) {
            supervisionService.stopwatchStop()
        }
        setIsRunning(false)
        setTime(0)
    }

    const convertElapsedTimeToSeconds = (elapsedTimeString) => {
        const timeParts = elapsedTimeString.split(':')
        const hours = parseInt(timeParts[0], 10)
        const minutes = parseInt(timeParts[1], 10)
        const secondsAndMilliseconds = timeParts[2].split('.')
        const seconds = parseInt(secondsAndMilliseconds[0], 10)
        const milliseconds = parseInt(secondsAndMilliseconds[1], 10)
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds + (milliseconds / 1000)
        return totalSeconds
    }

    const isValidEssai = (value) => {
        if (!isNullOrUndefined(value)) {
            let _time = time
            if (time === 0 && localStorage.time) {
                _time = parseInt(JSON.parse(localStorage.getItem("time")))
            }
            let result = Math.round((value - (_time / 1000)) * 1000) / 1000
            if (result < 0.2) {
                toast.success("Essai valable au niveau du serveur : " + Math.round(value * 100) / 100 + " avec " + result + " secondes de différence. (Tolérance : 200ms)")
                return true
            }
            else {
                toast.info("Essai au niveau du serveur supérieur à 200ms. Enregistrement impossible : " + value + " avec " + result + " secondes de différence. (Tolérance : 200ms)")
                return false
            }
        }
    }

    const controleServeur = (elsapedTime) => {
        if (!isValidEssai(elsapedTime)) {
            setBlockSaveMetrologie(true)
        }
        else {
            setBlockSaveMetrologie(false)
        }
    }

    return (
        <div className="stop-watch c-center">
            <Timer time={time} />
            <div className="Control-Buttons">
                {!isRunning &&
                    <div className="btn btn-one btn-start comet-btn-primary"
                        onClick={start}>
                        Démarrer
                    </div>
                }
                {isRunning &&
                    <div className="btn btn-one comet-btn-danger"
                        onClick={stop}>
                        Arrêter
                    </div>
                }
                <div className="btn btn-two btn-info"
                    onClick={reset}>
                    Effacer
                </div>
            </div>
        </div>
    )
}

export default StopWatch