import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { supervisionService } from '../../../../services/supervisionService'
import Checkbox from '@material-ui/core/Checkbox'
import { Link } from 'react-router-dom'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { Table } from 'react-bootstrap'
import { formatDate, renderHistoriqueAfficheur } from '../../../../helpers/utils'
import { useSaisieContext } from '../SaisieContext'
import { storage } from '../../../../helpers/storageHelper'

const Autres = (props) => {

    const [autres, setAutres] = useState(null)
    const { historiqueAfficheur, setHistoriqueAfficheur } = useSaisieContext()
    const [infos, setInfos] = useState(null)
    const [numeroSerieAfficheur, setNumeroSerieAfficheur] = useState(null)
    const [afficheur, setAfficheur] = useState(false)
    const [afficheurText, setAfficheurText] = useState("")

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des données avec le storage helper
                const [taximetreData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('infos')
                ])

                if (taximetreData) setAutres(taximetreData)
                if (infosData) setInfos(infosData)

                if (infosData) {
                    setNumeroSerieAfficheur(infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_afficheur)

                    const afficheur = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur
                    if (afficheur) {
                        setAfficheurText("Actuel : " + infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_afficheur)
                        setAfficheur(true)
                        getHistoriqueAfficheur(infosData)
                    } else {
                        setAfficheurText("Pas d'info sur l'actuel")
                        setAfficheur(false)
                        setNumeroSerieAfficheur(null)
                    }
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            }
        }

        loadInitialData()
    }, [])

    const getHistoriqueAfficheur = async (infosData) => {
        try {
            const data = {
                numero_adherent: infosData.numero_adherent,
                numero_taximetre: infosData.stationnements[0].vehiculeTaximetres[0].taximetre.numero_taximetre,
            }
            const response = await supervisionService.getHistoriqueAfficheur(data)
            setHistoriqueAfficheur(response)
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'historique:', error)
        }
    }

    if (!autres || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    c_r99: autres.c_r99,
                    r99: autres.r99,
                    c_r10: autres.c_r10,
                    r10: autres.r10,
                    c_r19: autres.c_r19,
                    r19: autres.r19,
                    c_elements_accessibles: autres.c_elements_accessibles,
                    detail_acces_centrale: autres.detail_acces_centrale,
                    c_ANO_afficheur_depareille: autres.c_ANO_afficheur_depareille,
                    c_REF_afficheur_depareille: autres.c_REF_afficheur_depareille,
                    anO_afficheur_depareille_detail: autres.anO_afficheur_depareille_detail,
                    reF_afficheur_depareille_detail: autres.reF_afficheur_depareille_detail,
                }}
                validate={async (values) => {
                    const errors = {}

                    try {
                        const taximetre = await storage.get('taximetre')
                        // Mise à jour des taximetres
                        taximetre.c_r99 = values.c_r99
                        taximetre.r99 = values.r99
                        taximetre.c_r10 = values.c_r10
                        taximetre.r10 = values.r10
                        taximetre.c_r19 = values.c_r19
                        taximetre.r19 = values.r19
                        taximetre.c_elements_accessibles = values.c_elements_accessibles
                        taximetre.detail_acces_centrale = values.detail_acces_centrale
                        taximetre.c_ANO_afficheur_depareille = values.c_ANO_afficheur_depareille
                        taximetre.c_REF_afficheur_depareille = values.c_REF_afficheur_depareille
                        taximetre.anO_afficheur_depareille_detail = values.anO_afficheur_depareille_detail
                        taximetre.reF_afficheur_depareille_detail = values.reF_afficheur_depareille_detail

                        // Partage des données
                        props.passFormToParent(taximetre, errors)
                        // Mise à jour du storage
                        await storage.set('taximetre', taximetre)

                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }

                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues }) => (
                    <Form autoComplete="off">
                        <div className="affichage-form-container etiquette">
                            {/* R99 */}
                            <div className="form-row m-m-0">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            checked={values.c_r99}
                                            onChange={() => {
                                                setValues(prev => ({
                                                    ...prev,
                                                    r99: "",
                                                    c_r99: !values.c_r99
                                                }))
                                            }}
                                            name="c_r99"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            R99 - Refus pour fraude :
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control" type="text" name="r99" disabled={values.c_r99 ? undefined : true} />
                                    </div>
                                </div>
                            </div>
                            {/* R10 */}
                            <div className="form-row m-m-0">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            checked={values.c_r10}
                                            onChange={() => {
                                                setValues(prev => ({
                                                    ...prev,
                                                    r10: "",
                                                    c_r10: !values.c_r10
                                                }))
                                            }}
                                            name="c_r10"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            R10 - Autre motif de refus :
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control" type="text" name="r10" disabled={values.c_r10 ? undefined : true} />
                                    </div>
                                </div>
                            </div>
                            {/* R19 */}
                            <div className="form-row">
                                <div className="form-cell-input checkbox-container">
                                    <div className="form-input">
                                        <Checkbox
                                            checked={values.c_r19}
                                            onChange={() => {
                                                setValues(prev => ({
                                                    ...prev,
                                                    r19: "",
                                                    c_r19: !values.c_r19
                                                }))
                                            }}
                                            name="c_r19"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            R19 - Comportement anormal :
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control" type="text" name="r19" disabled={values.c_r19 ? undefined : true} />
                                    </div>
                                </div>
                            </div>
                            {/* Equipements spéciaux */}
                            <div className="form-row m-m-0">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Tous équipements spéciaux (y compris marquages) accessibles sans outillage
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_elements_accessibles"
                                                    id="c_elements_accessibles-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_elements_accessibles', 1)}
                                                    checked={values.c_elements_accessibles === 1 ? true : false}
                                                />
                                                <label htmlFor="c_elements_accessibles-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_elements_accessibles"
                                                    id="c_elements_accessibles-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_elements_accessibles', 2)}
                                                    checked={values.c_elements_accessibles === 2 ? true : false} />
                                                <label htmlFor="c_elements_accessibles-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R100
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_elements_accessibles"
                                                        id="c_elements_accessibles-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_elements_accessibles', 3)}
                                                        checked={values.c_elements_accessibles === 3 ? true : false} />
                                                    <label htmlFor="c_elements_accessibles-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I100
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_elements_accessibles"
                                                    id="c_elements_accessibles-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_elements_accessibles', 0)}
                                                    checked={values.c_elements_accessibles === 0 ? true : false} />
                                                <label htmlFor="c_elements_accessibles-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    ?
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Afficheur si y a */}
                            {afficheur &&
                                <>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Situation centrale (à reprendre dans Carnet)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field className="form-control" type="text" name="detail_acces_centrale" list="details_acces_centrale" id="detail_acces_centrale" />
                                                <datalist id="details_acces_centrale">
                                                    <option value="-1">
                                                        test
                                                    </option>
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="indication-details">
                                            <span className="details-item c-bg-danger c-text-white">
                                                Photo obligatoire de l'accès à la centrale (Installation, bris scellement ET VP)
                                            </span>
                                            <div className="modele-actions mt-2">
                                                <Link to="photos" className="modele-btn">
                                                    Photos
                                                    <i className="fas fa-external-link-alt ml-2"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Jusqu'au 31/05/21 : dépareillage Centrale/Afficheur (selon preuve)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_ANO_afficheur_depareille"
                                                            id="c_ANO_afficheur_depareille-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_ANO_afficheur_depareille', 1)}
                                                            checked={values.c_ANO_afficheur_depareille === 1 ? true : false}
                                                        />
                                                        <label htmlFor="c_ANO_afficheur_depareille-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_ANO_afficheur_depareille"
                                                            id="c_ANO_afficheur_depareille-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_ANO_afficheur_depareille', 4)}
                                                            checked={values.c_ANO_afficheur_depareille === 4 ? true : false} />
                                                        <label htmlFor="c_ANO_afficheur_depareille-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            Pas d'infos
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_ANO_afficheur_depareille"
                                                            id="c_ANO_afficheur_depareille-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_ANO_afficheur_depareille', 2)}
                                                            checked={values.c_ANO_afficheur_depareille === 2 ? true : false} />
                                                        <label htmlFor="c_ANO_afficheur_depareille-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            A120
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field className="form-control" type="text" name="anO_afficheur_depareille_detail" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    A partir du 01/06/21 : dépareillage Centrale/Afficheur (selon preuve)
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_REF_afficheur_depareille"
                                                            id="c_REF_afficheur_depareille-1"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_REF_afficheur_depareille', 1)}
                                                            checked={values.c_REF_afficheur_depareille === 1 ? true : false}
                                                        />
                                                        <label htmlFor="c_REF_afficheur_depareille-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <OK />
                                                            Non
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_REF_afficheur_depareille"
                                                            id="c_REF_afficheur_depareille-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_REF_afficheur_depareille', 4)}
                                                            checked={values.c_REF_afficheur_depareille === 4 ? true : false} />
                                                        <label htmlFor="c_REF_afficheur_depareille-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            Pas d'infos
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_REF_afficheur_depareille"
                                                            id="c_REF_afficheur_depareille-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_REF_afficheur_depareille', 3)}
                                                            checked={values.c_REF_afficheur_depareille === 3 ? true : false} />
                                                        <label htmlFor="c_REF_afficheur_depareille-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Erreur />
                                                            R120
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-cell-input m-w-100">
                                            <div className="form-input">
                                                <Field className="form-control" type="text" name="reF_afficheur_depareille_detail" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Historique Afficheurs</th>
                                                </tr>
                                                <tr>
                                                    <th className="text-center">{afficheurText}</th>
                                                </tr>
                                            </thead>
                                        </Table>
                                        <Table striped hover size="sm">
                                            <thead>
                                                <tr>
                                                    <th>N° Afficheur</th>
                                                    <th>Intervention</th>
                                                    <th>Résultat</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {historiqueAfficheur && historiqueAfficheur.map((element, n) =>
                                                    <tr key={n}>
                                                        <td>{element.serie_appareil}</td>
                                                        <td>{formatDate(element.date_effectuee)}</td>
                                                        {numeroSerieAfficheur !== "" &&
                                                            <td
                                                                className={renderHistoriqueAfficheur(element, numeroSerieAfficheur) === "A120" ? "bg-warning" : renderHistoriqueAfficheur(element, numeroSerieAfficheur) === "R120" ? "bg-danger" : ""}
                                                            >
                                                                {renderHistoriqueAfficheur(element, numeroSerieAfficheur)}
                                                            </td>
                                                        }
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </>
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Autres