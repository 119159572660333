import React, { useCallback, useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { storage } from '../../../../helpers/storageHelper'
import { debounce } from 'lodash'

const Indication = (props) => {

    const [indication, setIndication] = useState({
        c_indication1: "",
        c_indication2: "",
        c_indication3: "",
        c_indication4: "",
    })
    const [infos, setInfos] = useState(null)

    // Charger les données initiales
    useEffect(() => {
        loadInitialData()
    }, [])


    const loadInitialData = async () => {
        try {
            const [taximetreData, infosData] = await Promise.all([
                storage.get('taximetre'),
                storage.get('infos')
            ])

            if (taximetreData) setIndication(taximetreData)
            if (infosData) setInfos(infosData)
        } catch (error) {
            console.error('Erreur lors du chargement des données:', error)
        }
    }

    // Debounce la sauvegarde dans le storage
    const debouncedSaveToStorage = useCallback(
        debounce(async (data) => {
            try {
                await storage.set('taximetre', data)
            } catch (error) {
                console.error('Erreur lors de la sauvegarde:', error)
            }
        }, 500),
        []
    )

    if (!indication || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    c_indication1: indication.c_indication1,
                    c_indication2: indication.c_indication2,
                    c_indication3: indication.c_indication3,
                    c_indication4: indication.c_indication4,
                }}
                validate={(values) => {
                    const errors = {}

                    // Mettre à jour les données localement
                    const updatedTaximetre = {
                        ...indication,
                        ...values
                    }

                    // Partager les données avec le parent
                    props.passFormToParent(updatedTaximetre, errors)

                    // Sauvegarder dans le storage de manière debounced
                    debouncedSaveToStorage(updatedTaximetre)

                    return errors
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container indication section-indication">
                            {/* Indication Marque et Appareil */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Indication Nom et Marque
                                            <i className="fas fa-question-circle ctooltip">
                                                <span className="tooltiptext tooltiptext-bottom">
                                                    + Adresse si nouvelle MID
                                                </span>
                                            </i>
                                        </div>
                                        <div className="indication-details">
                                            <span className="details-item">
                                                {infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication1"
                                                    id="c_indication1-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication1', 1)}
                                                    checked={values.c_indication1 === 1 ? true : false} />
                                                <label htmlFor="c_indication1-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication1"
                                                    id="c_indication1-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication1', 2)}
                                                    checked={values.c_indication1 === 2 ? true : false} />
                                                <label htmlFor="c_indication1-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R11
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_indication1"
                                                        id="c_indication1-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_indication1', 3)}
                                                        checked={values.c_indication1 === 3 ? true : false} />
                                                    <label htmlFor="c_indication1-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I11
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication1"
                                                    id="c_indication1-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication1', 0)}
                                                    checked={(values.c_indication1 === 0 || values.c_indication1 === 4) ? true : false} />
                                                <label htmlFor="c_indication1-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Indication N°CET/CEC */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Indication N° CET/CEC
                                        </div>
                                        <div className="indication-details">
                                            <span className="details-item">
                                                {infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.ref_dam}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication2"
                                                    id="c_indication2-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication2', 1)}
                                                    checked={values.c_indication2 === 1 ? true : false} />
                                                <label htmlFor="c_indication2-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication2"
                                                    id="c_indication2-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication2', 2)}
                                                    checked={values.c_indication2 === 2 ? true : false} />
                                                <label htmlFor="c_indication2-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R11
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_indication2"
                                                        id="c_indication2-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_indication2', 3)}
                                                        checked={values.c_indication2 === 3 ? true : false} />
                                                    <label htmlFor="c_indication2-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I11
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication2"
                                                    id="c_indication2-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication2', 0)}
                                                    checked={(values.c_indication2 === 0 || values.c_indication2 === 4) ? true : false} />
                                                <label htmlFor="c_indication2-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Indication Modèle / N° Série */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Indication Modèle / N° Série
                                        </div>
                                        <div className="indication-details">
                                            <span className="details-item">
                                                {infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil + " " +
                                                    infos.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_taximetre
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication3"
                                                    id="c_indication3-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication3', 1)}
                                                    checked={values.c_indication3 === 1 ? true : false} />
                                                <label htmlFor="c_indication3-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication3"
                                                    id="c_indication3-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication3', 2)}
                                                    checked={values.c_indication3 === 2 ? true : false} />
                                                <label htmlFor="c_indication3-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R11
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_indication3"
                                                        id="c_indication3-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_indication3', 3)}
                                                        checked={values.c_indication3 === 3 ? true : false} />
                                                    <label htmlFor="c_indication3-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I11
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication3"
                                                    id="c_indication3-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication3', 0)}
                                                    checked={(values.c_indication3 === 0 || values.c_indication3 === 4) ? true : false} />
                                                <label htmlFor="c_indication3-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Indication 'Euros' ou '€' */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Indication 'Euros' ou '€'
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication4"
                                                    id="c_indication4-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication4', 1)}
                                                    checked={values.c_indication4 === 1 ? true : false} />
                                                <label htmlFor="c_indication4-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication4"
                                                    id="c_indication4-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication4', 2)}
                                                    checked={values.c_indication4 === 2 ? true : false} />
                                                <label htmlFor="c_indication4-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R11
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_indication4"
                                                        id="c_indication4-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_indication4', 3)}
                                                        checked={values.c_indication4 === 3 ? true : false} />
                                                    <label htmlFor="c_indication4-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I11
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_indication4"
                                                    id="c_indication4-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_indication4', 0)}
                                                    checked={(values.c_indication4 === 0 || values.c_indication4 === 4) ? true : false} />
                                                <label htmlFor="c_indication4-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Indication