import React, { useEffect, useRef, useState } from "react"
import { Formik, Field, Form } from 'formik'
import { supervisionService } from "../../../../services/supervisionService"
import './metrologie.scss'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { Table } from 'react-bootstrap'
import { Tabs } from 'antd'
import StopWatch from "./StopWatch"
import Switch from '@material-ui/core/Switch'
import { useLovelySwitchStyles } from '@mui-treasury/styles/switch/lovely'
import Checkbox from '@material-ui/core/Checkbox'
import { jsPDF } from "jspdf"
import logo from './../../../../res/logo.png'
import { afficheCouleur, afficheSupplement, formatDate, hexToRGB, isNullOrUndefined, isValidDate } from './../../../../helpers/utils'
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { useSaisieContext } from "../SaisieContext"
import { useAuth } from "../../../../providers/authProvider"
import { useDataContext } from "../../../../providers/DataContextProvider"
import { isChronoGood } from "./hooks"
import Skeleton from "react-loading-skeleton"
import { storage } from "../../../../helpers/storageHelper"

const { TabPane } = Tabs

const Metrologie = (props) => {

    const [metrologie, setMetrologie] = useState(null)
    const [metrologie_intervention, setMetrologie_intervention] = useState(null)
    const [infos, setInfos] = useState(null)
    const [arretes, setArretes] = useState(null)
    const [docNational, setDocNational] = useState(null)
    const [docDpt, setDocDpt] = useState(null)
    const [selectedArrete, setSelectedArrete] = useState(null)
    const [infosArrete, setInfosArrete] = useState("")
    const { setFormVerif, setFormIntervention, formVerif,
        setIsReelle, setIsUniv,
        setIsBanc, vpVisible, setVpVisible,
        isUniv, isBanc, isReelle,
        vaiVisible, setVaiVisible,
        nbChutes, setNbChutes, emtMoins, emtPlus, setEmtMoins, setEmtPlus, setStopAll,
        startDate, endDate } = useSaisieContext()
    const { userGroup } = useAuth()
    const [instrumentsDisposPiste, setInstrumentDisposPiste] = useState([])
    const [instrumentsDisposChrono, setInstrumentDisposChrono] = useState([])
    const [affichageDepart, setAffichageDepart] = useState("")
    const [affichageFin, setAffichageFin] = useState("")
    const [chute1, setChute1] = useState("")
    const [chute2, setChute2] = useState("")
    const [chute3, setChute3] = useState("")
    const [chute4, setChute4] = useState("")
    const [chute5, setChute5] = useState("")
    const [point1, setPoint1] = useState("")
    const [point2, setPoint2] = useState("")
    const [point3, setPoint3] = useState("")
    const [point4, setPoint4] = useState("")
    const [point5, setPoint5] = useState("")
    const [autre1, setAutre1] = useState("")
    const [autre2, setAutre2] = useState("")
    const [autre3, setAutre3] = useState("")
    const [autre4, setAutre4] = useState("")
    const [pointDepart, setPointDepart] = useState("")
    const [resultats, setResultats] = useState("")
    const [tarifsHoraire, setTarifsHoraire] = useState([])
    const [lueSecondesColor, setLueSecondesColor] = useState(-1)
    const { interventionInfoGeneral } = useDataContext()
    const [tempo, setTempo] = useState(0)
    const [timeToWait, setTimeToWait] = useState("")
    /* Layout de chargement */
    const [loadingArretes, setLoadingArretes] = useState(false)
    const [loadingFicheTarif, setLoadingFicheTarif] = useState(false)
    const [loadingNational, setLoadingNational] = useState(false)
    const [loadingDpt, setLoadingDpt] = useState(false)
    /* Formulaire */
    const formikRef = useRef()
    // Style de switch
    const lovelyStyles = useLovelySwitchStyles()

    // Initialisation des données
    useEffect(() => {
        const initializeData = async () => {
            try {
                // Chargement parallèle des données initiales
                const [metrologieData, interventionData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention'),
                    storage.get('infos')
                ])

                if (metrologieData) {
                    setMetrologie(metrologieData)
                }
                if (interventionData) {
                    setMetrologie_intervention(interventionData)
                }
                if (infosData) {
                    setInfos(infosData)
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données initiales:', error)
            }
        }

        initializeData()
    }, [])

    // Appli general et récupération des instruments
    useEffect(() => {
        if (!metrologie || !metrologie_intervention || !infos) {
            return // Attendre que toutes les données soient chargées
        }

        const loadInstruments = async () => {
            try {
                const activite = await storage.get('activite')

                const data = {
                    numero_adherent: userGroup !== 0 ? userGroup : infos.numero_adherent,
                    type_appareil: 6,
                    activite: JSON.parse(activite),
                    y_compris_inactif: (isValidDate(interventionInfoGeneral.date_effectuee) &&
                        (new Date(interventionInfoGeneral.date_effectuee)).getFullYear() > 1970) ? true : false,
                    technicien_attitre: 0,
                }

                setStopAll(true)

                // Chargement parallèle des instruments
                const [instrumentsPiste, instrumentsChrono] = await Promise.all([
                    supervisionService.getInstrumentsDispos(data),
                    supervisionService.getInstrumentsDispos({ ...data, type_appareil: 3 })
                ])

                setInstrumentDisposPiste(instrumentsPiste)
                setInstrumentDisposChrono(instrumentsChrono)

            } catch (error) {
                console.error('Erreur lors du chargement des instruments:', error)
                setInstrumentDisposPiste([])
                setInstrumentDisposChrono([])
            } finally {
                setStopAll(false)
            }
        }

        const getDocument = async (numero, setDocument, option) => {
            if (option === 2) {
                try {
                    const docArrete = await storage.get('doc_arrete')
                    if (docArrete) {
                        setDocument(docArrete)
                    } else {
                        setLoadingNational(true)
                        const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                        setDocument(response)
                        await storage.set('doc_arrete', response)
                        setLoadingNational(false)
                    }
                } catch (error) {
                    setDocument(null)
                    await storage.set('doc_arrete', null)
                    setLoadingNational(false)
                }
            }
            if (option === 3) {
                try {
                    const docDpt = await storage.get('doc_dpt')
                    if (docDpt) {
                        setDocument(docDpt)
                    } else {
                        setLoadingDpt(true)
                        const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                        setDocument(response)
                        await storage.set('doc_dpt', response)
                        setLoadingDpt(false)
                    }
                } catch (error) {
                    setDocument(null)
                    await storage.set('doc_dpt', null)
                    setLoadingDpt(false)
                }
            }
        }

        const loadItemsForArrete = async (arretes) => {
            if (metrologie.clef_arrete_national > 0) {
                var arrete = arretes.find(_arrete => _arrete.clef_arrete_national === metrologie.clef_arrete_national)
                setSelectedArrete(arrete)
                // Mise a jour de l'affichage depart dans Univ
                setAffichageDepart(arrete.arretePrefectoral[0].prise_en_charge_a + " €")
                // Mise a jour document arrete national
                if (!isNaN(parseInt(arrete.numero_doc_arrete_national))) {
                    await getDocument(arrete.numero_doc_arrete_national, setDocNational, 2)
                }
                // Mise a jour document arrete prefectoral
                if (!isNaN(parseInt(arrete.arretePrefectoral[0].numero_doc_arrete_prefectoral))) {
                    await getDocument(arrete.arretePrefectoral[0].numero_doc_arrete_prefectoral, setDocDpt, 3)
                }
                let arretePrefectoral = arrete.arretePrefectoral[0]
                let nom_dept = arretePrefectoral.departement.nom_dpt
                let numero_dpt = arretePrefectoral.departement.numero_dpt
                loadUniv(arretePrefectoral)
                initTarifsHoraire(arretePrefectoral)
                chargeChutesHoraires(numero_dpt, arretePrefectoral, 1, metrologie.chutes_horaire, metrologie.sans_premiere_chute)
                setInfosArrete(nom_dept + "(" + numero_dpt + ") -- Date : " + formatDate(arretePrefectoral.date_arrete_prefectoral) + " N° : " + arretePrefectoral.numero_arrete_prefectoral)
            }
        }

        const getArretes = async (dept) => {
            try {
                setLoadingArretes(true)
                const response = await supervisionService.getAllArreteNationalDpt(dept)
                setArretes(response)
                await storage.set('arretes', response)
                setLoadingArretes(false)
                await loadItemsForArrete(response)
            } catch (error) {
                console.error('Erreur lors du chargement des arrêtés:', error)
                setLoadingArretes(false)
            }
        }

        const initData = async () => {
            try {
                const storedArretes = await storage.get('arretes')
                if (storedArretes) {
                    setLoadingArretes(true)
                    setArretes(storedArretes)
                    setLoadingArretes(false)
                    await loadItemsForArrete(storedArretes)
                    initResultat()
                } else {
                    await getArretes(infos.stationnements[0].clef_dpt)
                    initResultat()
                }

                // Chargement des instruments après l'initialisation des données
                await loadInstruments()
            } catch (error) {
                console.error('Erreur lors de l\'initialisation des données:', error)
            }
        }

        setStopAll(true)
        initData()

    }, [metrologie, metrologie_intervention, infos, userGroup, interventionInfoGeneral.date_effectuee])

    // Gestion de l'affichage VP et VAI
    useEffect(() => {
        let value = 0
        if (formVerif && isNullOrUndefined(formVerif.c_point_lu)) {
            value = formVerif.c_point_lu
            if (formVerif.position_kilo >= 99) {
                if (formVerif.position_kilo === 99) {
                    value = "empty"
                }
                else {
                    value = (formVerif.position_kilo - 100)
                }
            }
        }

        if (autre1 !== 0 && autre1 !== "") {
            if (value > autre1 && value < (autre4 + 1)) {
                setVpVisible(true)
            }
            else {
                setVpVisible(false)
            }
            if (value > autre2 && value < (autre3 + 1)) {
                setVaiVisible(true)
            }
            else {
                setVaiVisible(false)
            }
        }
    }, [autre1, autre4, autre3, autre2])

    const openFicheTarif = (e, a) => {

        // Génération du document en cours
        setLoadingFicheTarif(true)

        // Default export is a4 paper, portrait, using millimeters for units
        const doc = new jsPDF()

        // PDF dimensions
        const pageWidth = doc.internal.pageSize.getWidth()
        const pageHeight = doc.internal.pageSize.getHeight()
        const marginLeft = 20
        const marginTop = 10
        const espacementExtraLarge = 15
        const espacementLarge = 10
        const espacementSmall = 5
        const espacementExtraSmall = 1
        const defaultFontSize = 12

        /* Header de la page */
        // Logo
        const logoSize = { w: 10, h: 10 }
        doc.cell(marginLeft, marginTop, logoSize.w, logoSize.h, " ", 1, "center")
        doc.addImage(logo, 'PNG', marginLeft + 1, marginTop + 1, 8, 8)
        // Titre
        doc.setFontSize(defaultFontSize)
        const titre = "FICHE PROGRAMMATION ARRETE TARIFAIRE"
        const titreDimension = doc.getTextDimensions(titre)
        doc.cell(marginLeft, marginTop, titreDimension.w + espacementLarge, 10, titre, 1, "center")
        // Infos Tax et App
        doc.setFontSize(8)
        doc.cell(marginLeft, marginTop, 20, 10, " ", 1, "center")
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2) - (20) - (titreDimension.w + espacementLarge) - logoSize.w, 10, "N° : Tax-F240\nCoMet App", 1, "center")

        /* SubHeader de la page */
        doc.setFontSize(defaultFontSize)
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2), logoSize.h * 2, " ", 2, "center")
        // Lettre couleur
        const FMargins = { l: marginLeft + 5, t: (marginTop * 3) + 5 }
        doc.setFontSize(32)
        const couleur = afficheCouleur(a.couleur_lettre).color
        doc.setTextColor(hexToRGB(couleur).r, hexToRGB(couleur).g, hexToRGB(couleur).b)
        doc.text(a.lettre, FMargins.l, FMargins.t)
        // Arretes Titre
        doc.setFontSize(10)
        doc.setTextColor(0, 0, 0)
        const arreteTexte = "Arrêté National : "
        const arreteTexteDimension = doc.getTextDimensions(arreteTexte)
        const arreteTexteMargins = { l: FMargins.l + 15, t: FMargins.t - 10 }
        doc.text(arreteTexte, arreteTexteMargins.l, arreteTexteMargins.t)
        // Arretes Infos 
        const arreteInfos = a.nom_arrete_national
        const arreteInfosDimension = doc.getTextDimensions(arreteInfos)
        const arreteInfosMargins = { l: arreteTexteMargins.l + arreteTexteDimension.w + espacementSmall, t: arreteTexteMargins.t }
        doc.text(arreteInfos, arreteTexteMargins.l + arreteTexteDimension.w + espacementSmall, arreteTexteMargins.t)
        // Arretes Date Titre
        const arreteDateTexte = "Du : "
        const arreteDateTexteDimension = doc.getTextDimensions(arreteDateTexte)
        const arreteDateTexteMargins = { l: arreteTexteMargins.l, t: arreteTexteMargins.t + arreteInfosDimension.h + espacementExtraSmall }
        doc.text(arreteDateTexte, arreteDateTexteMargins.l, arreteDateTexteMargins.t)
        // Arretes Date
        const arreteDate = formatDate(a.date_arrete_national)
        const arreteDateDimension = doc.getTextDimensions(arreteDate)
        const arreteDateMargins = { l: arreteInfosMargins.l, t: arreteTexteMargins.t + arreteInfosDimension.h + espacementExtraSmall }
        doc.text(arreteDate, arreteDateMargins.l, arreteDateMargins.t)
        // Chute
        const chute = "Chute (€) : "
        const chuteDimension = doc.getTextDimensions(chute)
        const chuteMargins = { l: arreteTexteMargins.l, t: arreteDateMargins.t + arreteDateDimension.h + espacementExtraSmall }
        doc.text(chute, chuteMargins.l, chuteMargins.t)
        // prixChute
        const prixChute = a.arretePrefectoral[0].chute + "0 €"
        const prixChuteMargins = { l: arreteDateMargins.l, t: chuteMargins.t }
        doc.text(prixChute, prixChuteMargins.l, prixChuteMargins.t)

        /* Corps de la page */
        // Header
        doc.setFontSize(defaultFontSize)
        doc.cell(marginLeft, marginTop, pageWidth - (marginLeft * 2), pageHeight - (marginTop * 2) - (logoSize.h * 2) - 10, " ", 3, "center")
        // Dept
        const deptMargins = { l: marginLeft + 5, t: FMargins.t + 20 }
        doc.setFontSize(32)
        doc.setTextColor(0, 0, 0)
        doc.text((a.arretePrefectoral[0].clef_dept).toString(), deptMargins.l, deptMargins.t)
        // Prefectoral Titre
        doc.setFontSize(10)
        const prefectoralTitre = "Arrêté Préfectoral : "
        const prefectoralTitreDimension = doc.getTextDimensions(prefectoralTitre)
        const prefectoralTitreMargins = { l: deptMargins.l + 20, t: deptMargins.t - 10 }
        doc.text(prefectoralTitre, prefectoralTitreMargins.l, prefectoralTitreMargins.t)
        // Prefectoral Infos 
        const prefectoralInfos = a.arretePrefectoral[0].numero_arrete_prefectoral
        const prefectoralInfosDimension = doc.getTextDimensions(prefectoralInfos)
        const prefectoralInfosMargins = { l: prefectoralTitreMargins.l + prefectoralTitreDimension.w + espacementExtraLarge, t: prefectoralTitreMargins.t }
        doc.text(prefectoralInfos, prefectoralInfosMargins.l, prefectoralInfosMargins.t)
        // Prefectoral Date Titre
        const prefectoralDateTitre = "Du : "
        const prefectoralDateTitreDimension = doc.getTextDimensions(prefectoralDateTitre)
        const prefectoralDateTitreMargins = { l: prefectoralTitreMargins.l, t: prefectoralTitreMargins.t + prefectoralInfosDimension.h + espacementExtraSmall }
        doc.text(prefectoralDateTitre, prefectoralDateTitreMargins.l, prefectoralDateTitreMargins.t)
        // Prefectoral Date
        const prefectoralDate = formatDate(a.date_arrete_prefectoral)
        const prefectoralDateDimension = doc.getTextDimensions(prefectoralDate)
        const prefectoralDateMargins = { l: prefectoralInfosMargins.l, t: prefectoralTitreMargins.t + prefectoralInfosDimension.h + espacementExtraSmall }
        doc.text(arreteDate, prefectoralDateMargins.l, prefectoralDateMargins.t)
        // Minimum perception (€)
        const perception = "Minimum perception (€) : "
        const perceptionDimension = doc.getTextDimensions(perception)
        const perceptionMargins = { l: prefectoralTitreMargins.l, t: prefectoralDateTitreMargins.t + prefectoralDateDimension.h + espacementExtraSmall }
        doc.text(perception, perceptionMargins.l, perceptionMargins.t)
        // prix Perception
        const prixPerception = a.arretePrefectoral[0].mini_perception + "0 €"
        const prixPerceptionMargins = { l: prefectoralDateMargins.l, t: perceptionMargins.t }
        doc.text(prixPerception, prixPerceptionMargins.l, prixPerceptionMargins.t)

        // Tableau 1
        doc.setFontSize(8)
        // Rectangle 1
        doc.setFillColor(244, 244, 244);
        doc.rect(marginLeft + 2, deptMargins.t + espacementSmall, pageWidth - (marginLeft * 2) - 5, pageHeight - (marginTop * 2) - (logoSize.h * 4) - 15, 'FD')
        // Champ 1
        const tarifA = "TARIF A (€)"
        const tarifAPosition = { x: (pageWidth / 2) - marginLeft, y: deptMargins.t + espacementLarge }
        doc.text(tarifA, tarifAPosition.x, tarifAPosition.y)
        // Champ 2
        const tarifB = "TARIF B (€)"
        const tarifBPosition = { x: (pageWidth / 2) + espacementSmall, y: tarifAPosition.y }
        doc.text(tarifB, tarifBPosition.x, tarifBPosition.y)
        // Champ 3
        const tarifC = "TARIF C (€)"
        const tarifCPosition = { x: (pageWidth / 2) + marginLeft + (espacementSmall * 2), y: tarifAPosition.y }
        doc.text(tarifC, tarifCPosition.x, tarifCPosition.y)
        // Champ 4
        const tarifD = "TARIF D (€)"
        const tarifDPosition = { x: (pageWidth / 2) + (marginLeft * 2) + (espacementSmall * 3), y: tarifAPosition.y }
        doc.text(tarifD, tarifDPosition.x, tarifDPosition.y)

        /* ---- Pris en charge ---- */
        const priseEnCharge = "PRISE EN CHARGE (-)"
        const priseEnChargePosition = { x: marginLeft + 5, y: tarifAPosition.y + espacementSmall }
        doc.text(priseEnCharge, priseEnChargePosition.x, priseEnChargePosition.y)
        // valeur 1
        const valeur1 = a.arretePrefectoral[0].prise_en_charge_a + "0 €"
        const valeur1Position = { x: tarifAPosition.x + 15, y: priseEnChargePosition.y }
        doc.text(valeur1, valeur1Position.x, valeur1Position.y, "right")
        // valeur 2
        const valeur2 = a.arretePrefectoral[0].prise_en_charge_b + "0 €"
        const valeur2Position = { x: tarifBPosition.x + 15, y: priseEnChargePosition.y }
        doc.text(valeur2, valeur2Position.x, valeur2Position.y, "right")
        // valeur 3
        const valeur3 = a.arretePrefectoral[0].prise_en_charge_c + "0 €"
        const valeur3Position = { x: tarifCPosition.x + 15, y: priseEnChargePosition.y }
        doc.text(valeur3, valeur3Position.x, valeur3Position.y, "right")
        // valeur 4
        const valeur4 = a.arretePrefectoral[0].prise_en_charge_d + "0 €"
        const valeur4Position = { x: tarifDPosition.x + 15, y: priseEnChargePosition.y }
        doc.text(valeur4, valeur4Position.x, valeur4Position.y, "right")

        /* ---- TARIF KILOMETRIQUE (-) ---- */
        const tarifKilometrique = "TARIF KILOMETRIQUE (-)"
        const tarifKilometriquePosition = { x: marginLeft + 5, y: priseEnChargePosition.y + espacementSmall }
        doc.text(tarifKilometrique, tarifKilometriquePosition.x, tarifKilometriquePosition.y)
        // valeur 1
        const tkvaleur1 = a.arretePrefectoral[0].tarif_kilometrique_a + "0 €"
        const tkvaleur1Position = { x: tarifAPosition.x + 15, y: tarifKilometriquePosition.y }
        doc.text(tkvaleur1, tkvaleur1Position.x, tkvaleur1Position.y, "right")
        // valeur 2
        const tkvaleur2 = a.arretePrefectoral[0].tarif_kilometrique_b + "0 €"
        const tkvaleur2Position = { x: tarifBPosition.x + 15, y: tarifKilometriquePosition.y }
        doc.text(tkvaleur2, tkvaleur2Position.x, tkvaleur2Position.y, "right")
        // valeur 3
        const tkvaleur3 = a.arretePrefectoral[0].tarif_kilometrique_c + "0 €"
        const tkvaleur3Position = { x: tarifCPosition.x + 15, y: tarifKilometriquePosition.y }
        doc.text(tkvaleur3, tkvaleur3Position.x, tkvaleur3Position.y, "right")
        // valeur 4
        const tkvaleur4 = a.arretePrefectoral[0].tarif_kilometrique_d + "0 €"
        const tkvaleur4Position = { x: tarifDPosition.x + 15, y: tarifKilometriquePosition.y }
        doc.text(tkvaleur4, tkvaleur4Position.x, tkvaleur4Position.y, "right")

        /* ---- 1ERE CHUTE KILOMETRIQUE (+) ---- */
        const chuteKilometrique = "1ERE CHUTE KILOMETRIQUE (+)"
        const chuteKilometriquePosition = { x: marginLeft + 5, y: tarifKilometriquePosition.y + espacementSmall }
        doc.text(chuteKilometrique, chuteKilometriquePosition.x, chuteKilometriquePosition.y)
        // valeur 1
        const ckvaleur1 = a.arretePrefectoral[0].distance_chute1_a + " m"
        const ckvaleur1Position = { x: tarifAPosition.x + 15, y: chuteKilometriquePosition.y }
        doc.text(ckvaleur1, ckvaleur1Position.x, ckvaleur1Position.y, "right")
        // valeur 2
        const ckvaleur2 = a.arretePrefectoral[0].distance_chute1_b + " m"
        const ckvaleur2Position = { x: tarifBPosition.x + 15, y: chuteKilometriquePosition.y }
        doc.text(ckvaleur2, ckvaleur2Position.x, ckvaleur2Position.y, "right")
        // valeur 3
        const ckvaleur3 = a.arretePrefectoral[0].distance_chute1_c + " m"
        const ckvaleur3Position = { x: tarifCPosition.x + 15, y: chuteKilometriquePosition.y }
        doc.text(ckvaleur3, ckvaleur3Position.x, ckvaleur3Position.y, "right")
        // valeur 4
        const ckvaleur4 = a.arretePrefectoral[0].distance_chute1_d + " m"
        const ckvaleur4Position = { x: tarifDPosition.x + 15, y: chuteKilometriquePosition.y }
        doc.text(ckvaleur4, ckvaleur4Position.x, ckvaleur4Position.y, "right")

        /* ---- TARIF HORAIRE (-) ---- */
        const tarifHoraire = "TARIF HORAIRE (-)"
        const tarifHorairePosition = { x: marginLeft + 5, y: chuteKilometriquePosition.y + espacementSmall }
        doc.text(tarifHoraire, tarifHorairePosition.x, tarifHorairePosition.y)
        // valeur 1
        const thvaleur1 = a.arretePrefectoral[0].tarif_horaire_a + " €"
        const thvaleur1Position = { x: tarifAPosition.x + 15, y: tarifHorairePosition.y }
        doc.text(thvaleur1, thvaleur1Position.x, thvaleur1Position.y, "right")
        // valeur 2
        const thvaleur2 = a.arretePrefectoral[0].tarif_horaire_b + " €"
        const thvaleur2Position = { x: tarifBPosition.x + 15, y: tarifHorairePosition.y }
        doc.text(thvaleur2, thvaleur2Position.x, thvaleur2Position.y, "right")
        // valeur 3
        const thvaleur3 = a.arretePrefectoral[0].tarif_horaire_c + " €"
        const thvaleur3Position = { x: tarifCPosition.x + 15, y: tarifHorairePosition.y }
        doc.text(thvaleur3, thvaleur3Position.x, thvaleur3Position.y, "right")
        // valeur 4
        const thvaleur4 = a.arretePrefectoral[0].tarif_horaire_d + " €"
        const thvaleur4Position = { x: tarifDPosition.x + 15, y: tarifHorairePosition.y }
        doc.text(thvaleur4, thvaleur4Position.x, thvaleur4Position.y, "right")

        /* ---- 1ERE CHUTE HORAIRE (+) ---- */
        const chuteHoraire = "1ERE CHUTE HORAIRE (+)"
        const chuteHorairePosition = { x: marginLeft + 5, y: tarifHorairePosition.y + espacementSmall }
        doc.text(chuteHoraire, chuteHorairePosition.x, chuteHorairePosition.y)
        // valeur 1
        const chvaleur1 = (3600 * a.arretePrefectoral[0].chute / a.arretePrefectoral[0].tarif_horaire_a).toFixed(2) + " s"
        const chvaleur1Position = { x: tarifAPosition.x + 15, y: chuteHorairePosition.y }
        doc.text(chvaleur1, chvaleur1Position.x, chvaleur1Position.y, "right")
        // valeur 2
        const chvaleur2 = (3600 * a.arretePrefectoral[0].chute / a.arretePrefectoral[0].tarif_horaire_b).toFixed(2) + " s"
        const chvaleur2Position = { x: tarifBPosition.x + 15, y: chuteHorairePosition.y }
        doc.text(chvaleur2, chvaleur2Position.x, chvaleur2Position.y, "right")
        // valeur 3
        const chvaleur3 = (3600 * a.arretePrefectoral[0].chute / a.arretePrefectoral[0].tarif_horaire_c).toFixed(2) + " s"
        const chvaleur3Position = { x: tarifCPosition.x + 15, y: chuteHorairePosition.y }
        doc.text(chvaleur3, chvaleur3Position.x, chvaleur3Position.y, "right")
        // valeur 4
        const chvaleur4 = (3600 * a.arretePrefectoral[0].chute / a.arretePrefectoral[0].tarif_horaire_d).toFixed(2) + " s"
        const chvaleur4Position = { x: tarifDPosition.x + 15, y: chuteHorairePosition.y }
        doc.text(chvaleur4, chvaleur4Position.x, chvaleur4Position.y, "right")

        // Tableau 2
        doc.setFontSize(8)
        // Champ 1
        const typeSupplement = "TYPE DE SUPPELMENT"
        const typeSupplementPosition = { x: (pageWidth / 2) - (marginLeft + espacementLarge), y: chuteHorairePosition.y + 10 }
        doc.text(typeSupplement, typeSupplementPosition.x, typeSupplementPosition.y)
        // Champ 2
        const montant = "MONTANT"
        const montantPosition = { x: (pageWidth / 2) + marginLeft + espacementSmall, y: chuteHorairePosition.y + 10 }
        doc.text(montant, montantPosition.x, montantPosition.y)
        // Champ 3
        const max = "MAX"
        const maxPosition = { x: (pageWidth / 2) + (marginLeft + (espacementExtraLarge * 3)), y: chuteHorairePosition.y + 10 }
        doc.text(max, maxPosition.x, maxPosition.y)

        /* ---- Supplément 1 ---- */
        const supplement1 = "SUPPLEMENT 1"
        const supplement1Position = { x: marginLeft + 5, y: typeSupplementPosition.y + espacementSmall }
        doc.text(supplement1, supplement1Position.x, supplement1Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s1valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_0)
        const s1valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement1Position.y }
        doc.text(s1valeur1, s1valeur1Position.x, s1valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s1valeur2 = a.arretePrefectoral[0].type_supplement_0 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_0).toString() + " €"
        const s1valeur2Position = { x: montantPosition.x + 15, y: supplement1Position.y }
        doc.text(s1valeur2, s1valeur2Position.x, s1valeur2Position.y, "right")
        // valeur 3
        const s1valeur3 = (a.arretePrefectoral[0].nbe_maxi_0).toString()
        const s1valeur3Position = { x: maxPosition.x + 5, y: supplement1Position.y }
        doc.text(s1valeur3, s1valeur3Position.x, s1valeur3Position.y, "right")

        /* ---- Supplément 2 ---- */
        const supplement2 = "SUPPLEMENT 2"
        const supplement2Position = { x: marginLeft + 5, y: supplement1Position.y + espacementSmall }
        doc.text(supplement2, supplement2Position.x, supplement2Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s2valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_1)
        const s2valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement2Position.y }
        doc.text(s2valeur1, s2valeur1Position.x, s2valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s2valeur2 = (a.arretePrefectoral[0].type_supplement_1 === 0 ? "0.00 €" : a.arretePrefectoral[0].type_supplement_1).toString() + " €"
        const s2valeur2Position = { x: montantPosition.x + 15, y: supplement2Position.y }
        doc.text(s2valeur2, s2valeur2Position.x, s2valeur2Position.y, "right")
        // valeur 3
        const s2valeur3 = (a.arretePrefectoral[0].nbe_maxi_1).toString()
        const s2valeur3Position = { x: maxPosition.x + 5, y: supplement2Position.y }
        doc.text(s2valeur3, s2valeur3Position.x, s2valeur3Position.y, "right")

        /* ---- Supplément 3 ---- */
        const supplement3 = "SUPPLEMENT 3"
        const supplement3Position = { x: marginLeft + 5, y: supplement2Position.y + espacementSmall }
        doc.text(supplement3, supplement3Position.x, supplement3Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s3valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_2)
        const s3valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement3Position.y }
        doc.text(s3valeur1, s3valeur1Position.x, s3valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s3valeur2 = a.arretePrefectoral[0].type_supplement_2 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_2).toString() + " €"
        const s3valeur2Position = { x: montantPosition.x + 15, y: supplement3Position.y }
        doc.text(s3valeur2, s3valeur2Position.x, s3valeur2Position.y, "right")
        // valeur 3
        const s3valeur3 = (a.arretePrefectoral[0].nbe_maxi_2).toString()
        const s3valeur3Position = { x: maxPosition.x + 5, y: supplement3Position.y }
        doc.text(s3valeur3, s3valeur3Position.x, s3valeur3Position.y, "right")

        /* ---- Supplément 4 ---- */
        const supplement4 = "SUPPLEMENT 4"
        const supplement4Position = { x: marginLeft + 5, y: supplement3Position.y + espacementSmall }
        doc.text(supplement4, supplement4Position.x, supplement4Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s4valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_3)
        const s4valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement4Position.y }
        doc.text(s4valeur1, s4valeur1Position.x, s4valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s4valeur2 = a.arretePrefectoral[0].type_supplement_3 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_3).toString() + " €"
        const s4valeur2Position = { x: montantPosition.x + 15, y: supplement4Position.y }
        doc.text(s4valeur2, s4valeur2Position.x, s4valeur2Position.y, "right")
        // valeur 3
        const s4valeur3 = (a.arretePrefectoral[0].nbe_maxi_3).toString()
        const s4valeur3Position = { x: maxPosition.x + 5, y: supplement4Position.y }
        doc.text(s4valeur3, s4valeur3Position.x, s4valeur3Position.y, "right")

        /* ---- Supplément 5 ---- */
        const supplement5 = "SUPPLEMENT 5"
        const supplement5Position = { x: marginLeft + 5, y: supplement4Position.y + espacementSmall }
        doc.text(supplement5, supplement5Position.x, supplement5Position.y)
        // valeur 1 
        doc.setFontSize(6)
        const s5valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_4)
        const s5valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement5Position.y }
        doc.text(s5valeur1, s5valeur1Position.x, s5valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s5valeur2 = a.arretePrefectoral[0].type_supplement_4 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_4).toString() + " €"
        const s5valeur2Position = { x: montantPosition.x + 15, y: supplement5Position.y }
        doc.text(s5valeur2, s5valeur2Position.x, s5valeur2Position.y, "right")
        // valeur 3
        const s5valeur3 = (a.arretePrefectoral[0].nbe_maxi_4).toString()
        const s5valeur3Position = { x: maxPosition.x + 5, y: supplement5Position.y }
        doc.text(s5valeur3, s5valeur3Position.x, s5valeur3Position.y, "right")

        /* ---- Supplément 6 ---- */
        const supplement6 = "SUPPLEMENT 6"
        const supplement6Position = { x: marginLeft + 5, y: supplement5Position.y + espacementSmall }
        doc.text(supplement6, supplement6Position.x, supplement6Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s6valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_5)
        const s6valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement6Position.y }
        doc.text(s6valeur1, s6valeur1Position.x, s6valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s6valeur2 = a.arretePrefectoral[0].type_supplement_5 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_5).toString() + " €"
        const s6valeur2Position = { x: montantPosition.x + 15, y: supplement6Position.y }
        doc.text(s6valeur2, s6valeur2Position.x, s6valeur2Position.y, "right")
        // valeur 3
        const s6valeur3 = (a.arretePrefectoral[0].nbe_maxi_5).toString()
        const s6valeur3Position = { x: maxPosition.x + 5, y: supplement6Position.y }
        doc.text(s6valeur3, s6valeur3Position.x, s6valeur3Position.y, "right")

        /* ---- Supplément 7 ---- */
        const supplement7 = "SUPPLEMENT 7"
        const supplement7Position = { x: marginLeft + 5, y: supplement6Position.y + espacementSmall }
        doc.text(supplement7, supplement7Position.x, supplement7Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s7valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_6)
        const s7valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement7Position.y }
        doc.text(s7valeur1, s7valeur1Position.x, s7valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s7valeur2 = a.arretePrefectoral[0].type_supplement_6 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_6).toString() + " €"
        const s7valeur2Position = { x: montantPosition.x + 15, y: supplement7Position.y }
        doc.text(s7valeur2, s7valeur2Position.x, s7valeur2Position.y, "right")
        // valeur 3
        const s7valeur3 = (a.arretePrefectoral[0].nbe_maxi_6).toString()
        const s7valeur3Position = { x: maxPosition.x + 5, y: supplement7Position.y }
        doc.text(s7valeur3, s7valeur3Position.x, s7valeur3Position.y, "right")

        /* ---- Supplément 8 ---- */
        const supplement8 = "SUPPLEMENT 8"
        const supplement8Position = { x: marginLeft + 5, y: supplement7Position.y + espacementSmall }
        doc.text(supplement8, supplement8Position.x, supplement8Position.y)
        // valeur 1
        doc.setFontSize(6)
        const s8valeur1 = afficheSupplement(a.arretePrefectoral[0].type_supplement_7)
        const s8valeur1Position = { x: typeSupplementPosition.x + 15, y: supplement8Position.y }
        doc.text(s8valeur1, s8valeur1Position.x, s8valeur1Position.y, "center")
        doc.setFontSize(8)
        // valeur 2
        const s8valeur2 = a.arretePrefectoral[0].type_supplement_7 === 0 ? "0.00 €" : (a.arretePrefectoral[0].type_supplement_7).toString() + " €"
        const s8valeur2Position = { x: montantPosition.x + 15, y: supplement8Position.y }
        doc.text(s8valeur2, s8valeur2Position.x, s8valeur2Position.y, "right")
        // valeur 3
        const s8valeur3 = (a.arretePrefectoral[0].nbe_maxi_7).toString()
        const s8valeur3Position = { x: maxPosition.x + 5, y: supplement8Position.y }
        doc.text(s8valeur3, s8valeur3Position.x, s8valeur3Position.y, "right")

        // Tableau 3
        doc.setFontSize(8)
        // Champ 1
        const typeForfait = "TYPE DE FORFAIT"
        const typeForfaitPosition = { x: (pageWidth / 2), y: supplement8Position.y + 10 }
        doc.text(typeForfait, typeForfaitPosition.x, typeForfaitPosition.y, "center")
        // Champ 2
        const montant2 = "MONTANT"
        const montant2Position = { x: (pageWidth / 2) + (marginLeft + (espacementExtraLarge * 2) + espacementLarge), y: supplement8Position.y + 10 }
        doc.text(montant2, montant2Position.x, montant2Position.y)

        /* ---- Forfait 1 ---- */
        const forfait1 = "FORFAIT 1"
        const forfait1Position = { x: marginLeft + 5, y: typeForfaitPosition.y + espacementSmall }
        doc.text(forfait1, forfait1Position.x, forfait1Position.y)
        // valeur 1
        const f1valeur1 = a.arretePrefectoral[0].forfait_nom_1 !== undefined ? (a.arretePrefectoral[0].forfait_nom_1).toString() : ""
        const f1valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait1Position.y }
        doc.text(f1valeur1, f1valeur1Position.x, f1valeur1Position.y, "right")
        // valeur 2
        const f1valeur2 = a.arretePrefectoral[0].forfait_prix_1 !== undefined ? (a.arretePrefectoral[0].forfait_prix_1).toString() : ""
        const f1valeur2Position = { x: montant2Position.x + 15, y: forfait1Position.y }
        doc.text(f1valeur2, f1valeur2Position.x, f1valeur2Position.y, "right")

        /* ---- Forfait 2 ---- */
        const forfait2 = "FORFAIT 2"
        const forfait2Position = { x: marginLeft + 5, y: forfait1Position.y + espacementSmall }
        doc.text(forfait2, forfait2Position.x, forfait2Position.y)
        // valeur 1
        const f2valeur1 = a.arretePrefectoral[0].forfait_nom_2 !== undefined ? (a.arretePrefectoral[0].forfait_nom_2).toString() : ""
        const f2valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait2Position.y }
        doc.text(f2valeur1, f2valeur1Position.x, f2valeur1Position.y, "right")
        // valeur 2
        const f2valeur2 = a.arretePrefectoral[0].forfait_prix_2 !== undefined ? (a.arretePrefectoral[0].forfait_prix_2).toString() : ""
        const f2valeur2Position = { x: montant2Position.x + 15, y: forfait2Position.y }
        doc.text(f2valeur2, f2valeur2Position.x, f2valeur2Position.y, "right")

        /* ---- Forfait 3 ---- */
        const forfait3 = "FORFAIT 3"
        const forfait3Position = { x: marginLeft + 5, y: forfait2Position.y + espacementSmall }
        doc.text(forfait3, forfait3Position.x, forfait3Position.y)
        // valeur 1
        const f3valeur1 = a.arretePrefectoral[0].forfait_nom_3 !== undefined ? (a.arretePrefectoral[0].forfait_nom_3).toString() : ""
        const f3valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait3Position.y }
        doc.text(f3valeur1, f3valeur1Position.x, f3valeur1Position.y, "right")
        // valeur 2
        const f3valeur2 = a.arretePrefectoral[0].forfait_prix_3 !== undefined ? (a.arretePrefectoral[0].forfait_prix_3).toString() : ""
        const f3valeur2Position = { x: montant2Position.x + 15, y: forfait3Position.y }
        doc.text(f3valeur2, f3valeur2Position.x, f3valeur2Position.y, "right")

        /* ---- Forfait 4 ---- */
        const forfait4 = "FORFAIT 4"
        const forfait4Position = { x: marginLeft + 5, y: forfait3Position.y + espacementSmall }
        doc.text(forfait4, forfait4Position.x, forfait4Position.y)
        // valeur 1
        const f4valeur1 = a.arretePrefectoral[0].forfait_nom_4 !== undefined ? (a.arretePrefectoral[0].forfait_nom_4).toString() : ""
        const f4valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait4Position.y }
        doc.text(f4valeur1, f4valeur1Position.x, f4valeur1Position.y, "right")
        // valeur 2
        const f4valeur2 = a.arretePrefectoral[0].forfait_prix_4 !== undefined ? (a.arretePrefectoral[0].forfait_prix_4).toString() : ""
        const f4valeur2Position = { x: montant2Position.x + 15, y: forfait4Position.y }
        doc.text(f4valeur2, f4valeur2Position.x, f4valeur2Position.y, "right")

        /* ---- Forfait 5 ---- */
        const forfait5 = "FORFAIT 5"
        const forfait5Position = { x: marginLeft + 5, y: forfait4Position.y + espacementSmall }
        doc.text(forfait5, forfait5Position.x, forfait5Position.y)
        // valeur 1
        const f5valeur1 = a.arretePrefectoral[0].forfait_nom_5 !== undefined ? (a.arretePrefectoral[0].forfait_nom_5).toString() : ""
        const f5valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait5Position.y }
        doc.text(f5valeur1, f5valeur1Position.x, f5valeur1Position.y, "right")
        // valeur 2
        const f5valeur2 = a.arretePrefectoral[0].forfait_prix_5 !== undefined ? (a.arretePrefectoral[0].forfait_prix_5).toString() : ""
        const f5valeur2Position = { x: montant2Position.x + 15, y: forfait5Position.y }
        doc.text(f5valeur2, f5valeur2Position.x, f5valeur2Position.y, "right")

        /* ---- Forfait 6 ---- */
        const forfait6 = "FORFAIT 6"
        const forfait6Position = { x: marginLeft + 5, y: forfait5Position.y + espacementSmall }
        doc.text(forfait6, forfait6Position.x, forfait6Position.y)
        // valeur 1
        const f6valeur1 = a.arretePrefectoral[0].forfait_nom_6 !== undefined ? (a.arretePrefectoral[0].forfait_nom_6).toString() : ""
        const f6valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait6Position.y }
        doc.text(f6valeur1, f6valeur1Position.x, f6valeur1Position.y, "right")
        // valeur 2
        const f6valeur2 = a.arretePrefectoral[0].forfait_prix_6 !== undefined ? (a.arretePrefectoral[0].forfait_prix_6).toString() : ""
        const f6valeur2Position = { x: montant2Position.x + 15, y: forfait6Position.y }
        doc.text(f6valeur2, f6valeur2Position.x, f6valeur2Position.y, "right")

        /* ---- Forfait 7 ---- */
        const forfait7 = "FORFAIT 7"
        const forfait7Position = { x: marginLeft + 5, y: forfait6Position.y + espacementSmall }
        doc.text(forfait7, forfait7Position.x, forfait7Position.y)
        // valeur 1
        const f7valeur1 = a.arretePrefectoral[0].forfait_nom_7 !== undefined ? (a.arretePrefectoral[0].forfait_nom_7).toString() : ""
        const f7valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait7Position.y }
        doc.text(f7valeur1, f7valeur1Position.x, f7valeur1Position.y, "right")
        // valeur 2
        const f7valeur2 = a.arretePrefectoral[0].forfait_prix_7 !== undefined ? (a.arretePrefectoral[0].forfait_prix_7).toString() : ""
        const f7valeur2Position = { x: montant2Position.x + 15, y: forfait7Position.y }
        doc.text(f7valeur2, f7valeur2Position.x, f7valeur2Position.y, "right")

        /* ---- Forfait 8 ---- */
        const forfait8 = "FORFAIT 8"
        const forfait8Position = { x: marginLeft + 5, y: forfait7Position.y + espacementSmall }
        doc.text(forfait8, forfait8Position.x, forfait8Position.y)
        // valeur 1
        const f8valeur1 = a.arretePrefectoral[0].forfait_nom_8 !== undefined ? (a.arretePrefectoral[0].forfait_nom_8).toString() : ""
        const f8valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait8Position.y }
        doc.text(f8valeur1, f8valeur1Position.x, f8valeur1Position.y, "right")
        // valeur 2
        const f8valeur2 = a.arretePrefectoral[0].forfait_prix_8 !== undefined ? (a.arretePrefectoral[0].forfait_prix_8).toString() : ""
        const f8valeur2Position = { x: montant2Position.x + 15, y: forfait8Position.y }
        doc.text(f8valeur2, f8valeur2Position.x, f8valeur2Position.y, "right")

        /* ---- Forfait 9 ---- */
        const forfait9 = "FORFAIT 9"
        const forfait9Position = { x: marginLeft + 5, y: forfait8Position.y + espacementSmall }
        doc.text(forfait9, forfait9Position.x, forfait9Position.y)
        // valeur 1
        const f9valeur1 = a.arretePrefectoral[0].forfait_nom_9 !== undefined ? (a.arretePrefectoral[0].forfait_nom_9).toString() : ""
        const f9valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait9Position.y }
        doc.text(f9valeur1, f9valeur1Position.x, f9valeur1Position.y, "right")
        // valeur 2
        const f9valeur2 = a.arretePrefectoral[0].forfait_prix_9 !== undefined ? (a.arretePrefectoral[0].forfait_prix_9).toString() : ""
        const f9valeur2Position = { x: montant2Position.x + 15, y: forfait9Position.y }
        doc.text(f9valeur2, f9valeur2Position.x, f9valeur2Position.y, "right")

        /* ---- Forfait 10 ---- */
        const forfait10 = "FORFAIT 10"
        const forfait10Position = { x: marginLeft + 5, y: forfait9Position.y + espacementSmall }
        doc.text(forfait10, forfait10Position.x, forfait10Position.y)
        // valeur 1
        const f1valeur10 = a.arretePrefectoral[0].forfait_nom_10 !== undefined ? (a.arretePrefectoral[0].forfait_nom_10).toString() : ""
        const f1valeur10Position = { x: typeForfaitPosition.x + 15, y: forfait10Position.y }
        doc.text(f1valeur10, f1valeur10Position.x, f1valeur10Position.y, "right")
        // valeur 2
        const f10valeur2 = a.arretePrefectoral[0].forfait_prix_10 !== undefined ? (a.arretePrefectoral[0].forfait_prix_10).toString() : ""
        const f10valeur2Position = { x: montant2Position.x + 15, y: forfait10Position.y }
        doc.text(f10valeur2, f10valeur2Position.x, f10valeur2Position.y, "right")

        /* ---- Forfait 11 ---- */
        const forfait11 = "FORFAIT 11"
        const forfait11Position = { x: marginLeft + 5, y: forfait10Position.y + espacementSmall }
        doc.text(forfait11, forfait11Position.x, forfait11Position.y)
        // valeur 1
        const f11valeur1 = a.arretePrefectoral[0].forfait_nom_11 !== undefined ? (a.arretePrefectoral[0].forfait_nom_11).toString() : ""
        const f11valeur1Position = { x: typeForfaitPosition.x + 15, y: forfait11Position.y }
        doc.text(f11valeur1, f11valeur1Position.x, f11valeur1Position.y, "right")
        // valeur 2
        const f11valeur2 = a.arretePrefectoral[0].forfait_prix_11 !== undefined ? (a.arretePrefectoral[0].forfait_prix_11).toString() : ""
        const f11valeur2Position = { x: montant2Position.x + 15, y: forfait11Position.y }
        doc.text(f11valeur2, f11valeur2Position.x, f11valeur2Position.y, "right")

        // Tableau 4
        doc.setFontSize(8)
        // Champ 1
        const reclamation = "RECLAMATIONS"
        const reclamationPosition = { x: (pageWidth / 2), y: forfait11Position.y + 10 }
        doc.text(reclamation, reclamationPosition.x, reclamationPosition.y, "center")

        /* ---- Dans ville ---- */
        doc.setFontSize(4)
        const ville1Titre = a.arretePrefectoral[0].adresse_reclamation !== undefined ? a.arretePrefectoral[0].adresse_reclamation : ""
        const ville1TitrePosition = { x: marginLeft + 5, y: reclamationPosition.y + espacementSmall }
        doc.text(ville1Titre, ville1TitrePosition.x, ville1TitrePosition.y)

        // Tableau 5
        doc.setFontSize(8)
        // Champ 1
        const observations = "OBSERVATIONS"
        const observationsPosition = { x: (pageWidth / 2), y: ville1TitrePosition.y + (6 * doc.getTextDimensions(ville1Titre).h) + 10 }
        doc.text(observations, observationsPosition.x, observationsPosition.y, "center")

        /* ---- Observations Details ---- */
        doc.setFontSize(4)
        const observationsDetails = a.arretePrefectoral[0].observations !== undefined ? a.arretePrefectoral[0].observations : ""
        const observationsDetailsPosition = { x: marginLeft + 5, y: observationsPosition.y + espacementSmall }
        doc.text(observationsDetails, observationsDetailsPosition.x, observationsDetailsPosition.y)

        // Tableau 6
        doc.setFontSize(8)
        // Champ 1
        const piste = "PISTE UNIVERSELLE"
        const pistePosition = { x: marginLeft + 5, y: observationsDetailsPosition.y + 10 }
        doc.text(piste, pistePosition.x, pistePosition.y)

        /* ---- Point de Départ ---- */
        const pointDepart = "Point Départ : "
        const pointDepartPosition = { x: pistePosition.x + doc.getTextDimensions(piste).w + espacementLarge, y: pistePosition.y }
        doc.text(pointDepart, pointDepartPosition.x, pointDepartPosition.y)
        // valeur 1
        const pdvaleur1 = "B"
        const pdvaleur1Position = { x: pointDepartPosition.x + doc.getTextDimensions(pointDepart).w + espacementExtraSmall, y: pointDepartPosition.y }
        doc.text(pdvaleur1, pdvaleur1Position.x, pdvaleur1Position.y)

        /* ---- Nbe Chutes ---- */
        const nbeChutes = "Nbe Chutes : "
        const nbeChutesPosition = { x: pointDepartPosition.x, y: pointDepartPosition.y + espacementSmall }
        doc.text(nbeChutes, nbeChutesPosition.x, nbeChutesPosition.y)
        // valeur 1
        const ncvaleur1 = "8"
        const ncvaleur1Position = { x: pdvaleur1Position.x, y: pdvaleur1Position.y + espacementSmall }
        doc.text(ncvaleur1, ncvaleur1Position.x, ncvaleur1Position.y)

        /* ---- Affichage de Départ ---- */
        const affichageDepart = "Affichage Départ : "
        const affichageDepartPosition = { x: pdvaleur1Position.x + doc.getTextDimensions(pdvaleur1).w + espacementLarge, y: pointDepartPosition.y }
        doc.text(affichageDepart, affichageDepartPosition.x, affichageDepartPosition.y)
        // valeur 1
        const advaleur1 = "2.1"
        const advaleur1Position = { x: affichageDepartPosition.x + doc.getTextDimensions(affichageDepart).w + espacementExtraSmall, y: affichageDepartPosition.y }
        doc.text(advaleur1, advaleur1Position.x, pdvaleur1Position.y)

        /* ---- Tolérance VAI ---- */
        const toleranceVAI = "Tolérance VAI : "
        const toleranceVAIPosition = { x: affichageDepartPosition.x, y: nbeChutesPosition.y }
        doc.text(toleranceVAI, toleranceVAIPosition.x, toleranceVAIPosition.y)
        // valeur 1
        const tvvaleur1 = "[14;20]"
        const tvvaleur1Position = { x: advaleur1Position.x, y: advaleur1Position.y + espacementSmall }
        doc.text(tvvaleur1, tvvaleur1Position.x, tvvaleur1Position.y)

        /* ---- Affichage de Fin ---- */
        const affichageFin = "Affichage Fin : "
        const affichageFinPosition = { x: advaleur1Position.x + doc.getTextDimensions(advaleur1).w + espacementLarge, y: pointDepartPosition.y }
        doc.text(affichageFin, affichageFinPosition.x, affichageFinPosition.y)
        // valeur 1
        const afvaleur1 = "2.9"
        const afvaleur1Position = { x: affichageFinPosition.x + doc.getTextDimensions(affichageFin).w + espacementExtraSmall, y: affichageFinPosition.y }
        doc.text(afvaleur1, afvaleur1Position.x, afvaleur1Position.y)

        /* ---- Tolérance VP ---- */
        const toleranceVP = "Tolérance VP : "
        const toleranceVPPosition = { x: affichageFinPosition.x, y: affichageFinPosition.y + espacementSmall }
        doc.text(toleranceVP, toleranceVPPosition.x, toleranceVPPosition.y)
        // valeur 1
        const tpvaleur1 = "[11;23]"
        const tpvaleur1Position = { x: afvaleur1Position.x, y: toleranceVPPosition.y }
        doc.text(tpvaleur1, tpvaleur1Position.x, tpvaleur1Position.y)

        doc.save(a.nom_arrete_national + ".pdf")
        setLoadingFicheTarif(false)
    }

    // Changement l'arrêté dans la liste déroulante
    const handleChange = async (e, setFieldValue) => {
        try {
            setStopAll(true)
            // Numéro document du clef
            let numeroNat = e.target.options[e.nativeEvent.target.selectedIndex].getAttribute("data-one")
            let numeroDpt = e.target.options[e.nativeEvent.target.selectedIndex].getAttribute("data-two")
            setFieldValue("clef_arrete_national", e.target.value)
            setFieldValue("tarif", 1)
            let index = e.nativeEvent.target.selectedIndex - 1

            if (index >= 0) {
                let _arrete = arretes[index]
                let _arretePrefectoral = _arrete.arretePrefectoral[0]
                let nom_dept = _arretePrefectoral.departement.nom_dpt
                let numero_dpt = _arretePrefectoral.departement.numero_dpt
                let sans_premiere_chute = formikRef.current.values.sans_premiere_chute
                let tarif = formikRef.current.values.tarif
                if (tarif === 0) {
                    initTarifsHoraire(_arretePrefectoral)
                    formikRef.current.setFieldValue("tarif", 1)
                    tarif = 1
                }
                setSelectedArrete(_arrete)
                loadUniv(_arretePrefectoral)
                chargeChutesHoraires(numero_dpt, _arretePrefectoral, tarif, 3, sans_premiere_chute)
                //setAffichageDepart(_arretePrefectoral.prise_en_charge_a + " €")
                setInfosArrete(nom_dept + "(" + numero_dpt + ") -- Date : " + formatDate(_arretePrefectoral.date_arrete_prefectoral) + " N° : " + _arretePrefectoral.numero_arrete_prefectoral)
            }
            else {
                setSelectedArrete(null)
                setAffichageDepart("")
                setInfosArrete("")
                setStopAll(false)
            }

            // Si numero document existe pour cet arrete
            if (!isNaN(parseInt(numeroNat))) {
                await loadFile(parseInt(numeroNat), 2)
            } else {
                setDocNational(null)
            }
            // Si numero document existe pour cet arrete
            if (!isNaN(parseInt(numeroDpt))) {
                await loadFile(parseInt(numeroDpt), 3)
            } else {
                setDocDpt(null)
            }
        } catch (error) {
            console.error('Erreur lors du changement d\'arrêté:', error)
        }
    }

    // Chargement du document de la signature
    const loadFile = async (numero, option) => {
        setStopAll(true)
        try {
            if (option === 2) {
                setLoadingNational(true)
                const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                setDocNational(response)
                await storage.set('doc_arrete', response)
            }
            if (option === 3) {
                setLoadingDpt(true)
                const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                setDocDpt(response)
                await storage.set('doc_dpt', response)
            }
        } catch (error) {
            console.error('Erreur lors du chargement du fichier:', error)
            if (option === 2) {
                setDocNational(null)
                await storage.set('doc_arrete', null)
            }
            if (option === 3) {
                setDocDpt(null)
                await storage.set('doc_dpt', null)
            }
        } finally {
            setLoadingNational(false)
            setLoadingDpt(false)
            setStopAll(false)
        }
    }

    const getTimeFromWatchAndSetLueSeconde = (watchData) => {
        // Mise à jour du champ de texte
        if (formikRef.current) {
            formikRef.current.setFieldValue("lue_secondes", watchData)
        }
        // Contrôle du temps
        isChronoGood(watchData, emtPlus, emtMoins, setLueSecondesColor)
    }

    const getTimeToWait = (watchData) => {
        setTimeToWait(watchData)
    }

    // Ouvre un document 
    const openPDF = (event, afile) => {

        var byteCharacters = atob(afile.fichier_contenu);
        var byteNumbers = new Array(byteCharacters.length)
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers)
        var file = new Blob([byteArray], { type: 'application/' + afile.extension_fichier.split[1] + ';base64' })
        var fileURL = URL.createObjectURL(file)

        var element = document.createElement('a')
        element.setAttribute('href', fileURL)
        element.setAttribute('download', afile.fichier_original)
        document.body.appendChild(element)
        element.click()
        setTimeout(function () {
            // Necessaire pour Firefox
            window.URL.revokeObjectURL(fileURL)
        }, 100)
        document.body.removeChild(element)

    }

    const loadUniv = (arrete) => {
        setStopAll(true)
        let chutes_etalon = 5
        let etalon_mini = 0
        let etalon_maxi = 0
        let val_theo_etalon = 0
        let decalage = 0
        let value1 = 0
        let value2 = 0
        let value3 = 0
        let value4 = 0
        let value5 = 0
        let _affichageD = 0
        let _affichageF = 0

        while (true) {
            value1 = (parseInt((0.96 * (arrete.distance_chute1_a + ((chutes_etalon - 1) * ((arrete.chute * 1000) / arrete.tarif_kilometrique_a))) / 5)) + 1) * 5
            value2 = (parseInt((0.98 * (arrete.distance_chute1_a + ((chutes_etalon - 1) * ((arrete.chute * 1000) / arrete.tarif_kilometrique_a))) / 5)) + 1) * 5
            value3 = parseInt((1.02 * (arrete.distance_chute1_a + ((chutes_etalon - 1) * ((arrete.chute * 1000) / arrete.tarif_kilometrique_a))) / 5)) * 5
            value4 = parseInt((1.04 * (arrete.distance_chute1_a + ((chutes_etalon - 1) * ((arrete.chute * 1000) / arrete.tarif_kilometrique_a))) / 5)) * 5

            setChute1(value1)
            setChute2(value2)
            setChute3(value3)
            setChute4(value4)

            etalon_mini = value1
            etalon_maxi = value4

            setNbChutes(chutes_etalon)
            _affichageD = (Math.round(arrete.prise_en_charge_a * 100) / 100) + " €"
            setAffichageDepart(_affichageD)
            _affichageF = (Math.round((arrete.prise_en_charge_a + chutes_etalon * arrete.chute) * 100) / 100) + " €"
            setAffichageFin(_affichageF)

            value5 = (Math.round((arrete.distance_chute1_a + (chutes_etalon - 1) * (arrete.chute * 1000 / arrete.tarif_kilometrique_a)) * 100) / 100)
            setChute5(value5)

            if (etalon_mini > 775 && etalon_maxi < 930) {
                setPointDepart("B")
                decalage = 775
            }

            if (etalon_mini > 855 && etalon_maxi < 1010) {
                setPointDepart("A")
                decalage = 855
            }

            setPoint1("Pt " + (value1 - decalage) / 5)
            setPoint2("Pt " + (value2 - decalage) / 5)
            setPoint3("Pt " + (value3 - decalage) / 5)
            setPoint4("Pt " + (value4 - decalage) / 5)
            setAutre1((value1 - decalage) / 5)
            setAutre2((value2 - decalage) / 5)
            setAutre3((value3 - decalage) / 5)
            setAutre4((value4 - decalage) / 5)
            setPoint5("Pts : [" + (parseInt(value5 / 5) * 5 - decalage) / 5 + ";" + ((parseInt(value5 / 5) + 1) * 5 - decalage) / 5 + "]")

            chutes_etalon = chutes_etalon + 1

            if ((etalon_mini > 775 && etalon_maxi < 930) || (etalon_mini > 855 && etalon_maxi < 1010) || val_theo_etalon > 2000) {
                break;
            }
        }
        setStopAll(false)
    }

    const initResultat = () => {
        setStopAll(true)
        let _resultats = ["Avant le Pt 0"]
        for (var i = 0; i < 31; i++) {
            _resultats.push("Entre le Pt " + i + " et le Pt " + (i + 1))
        }
        _resultats.push("Après le Pt 31")
        setResultats(_resultats)
        setStopAll(false)
    }

    const initTarifsHoraire = (arrete) => {
        setStopAll(true)
        let _tarifs = []
        if (arrete.tarif_horaire_a !== 0) {
            _tarifs.push("A")
        }
        if (arrete.tarif_horaire_b !== 0) {
            _tarifs.push("B")
        }
        if (arrete.tarif_horaire_c !== 0) {
            _tarifs.push("C")
        }
        if (arrete.tarif_horaire_d !== 0) {
            _tarifs.push("D")
        }
        setTarifsHoraire(_tarifs)
        setStopAll(false)
    }

    const handleChangeResultat = (e, setFieldValue) => {
        let value = parseInt(e.target.value)
        setFieldValue('c_point_lu', value)
        initialiseVAIVP(value)
    }

    const initialiseVAIVP = (c_point_lu) => {
        if (autre1 !== 0 && autre1 !== "") {
            if (c_point_lu > autre1 && c_point_lu < (autre4 + 1)) {
                setVpVisible(true)
            }
            else {
                setVpVisible(false)
            }
            if (c_point_lu > autre2 && c_point_lu < (autre3 + 1)) {
                setVaiVisible(true)
            }
            else {
                setVaiVisible(false)
            }
        }
    }

    const handleChangeNbeChutes = e => {
        let value = parseInt(e.target.value)
        let sans_premiere_chute = formikRef.current.values.sans_premiere_chute
        formikRef.current.setFieldValue("chutes_horaire", value)
        let arretePrefectoral = selectedArrete.arretePrefectoral[0]
        let numero_dpt = arretePrefectoral.departement.numero_dpt
        chargeChutesHoraires(numero_dpt, arretePrefectoral, 1, value, sans_premiere_chute)
    }

    const handleChangeSansPremiereChute = e => {
        let value = e.target.checked
        let chutes_horaire = formikRef.current.values.chutes_horaire
        formikRef.current.setFieldValue("sans_premiere_chute", value)
        let arretePrefectoral = selectedArrete.arretePrefectoral[0]
        let numero_dpt = arretePrefectoral.departement.numero_dpt
        chargeChutesHoraires(numero_dpt, arretePrefectoral, 1, chutes_horaire, value)
    }

    // Verifier dans grand Comet si on change tarif on
    // appelle la fonction
    const chargeChutesHoraires = (departement, arrete, tarif, chutes_horaire, sans_premiere_chute) => {
        setStopAll(true)
        let essai_chute_horaire_n = 0
        let essai_premiere_chute_horaire = 0
        let nombre_chutes_mini_horaire = 3
        let nombre_chutes_metrologique_mini = 4
        let valeur_theorique = 0

        essai_chute_horaire_n = chute_horaire_n(departement, arrete, tarif)
        essai_premiere_chute_horaire = chute_horaire_premiere(departement, arrete, tarif)

        if (metrologie.sans_premiere_chute === true) {
            nombre_chutes_metrologique_mini = myRound(60 / essai_chute_horaire_n, 1, 0)
        }
        else {
            nombre_chutes_metrologique_mini = myRound((60 - essai_premiere_chute_horaire) / essai_chute_horaire_n, 1, 0) + 1
        }

        if (nombre_chutes_metrologique_mini > nombre_chutes_mini_horaire) {
            nombre_chutes_mini_horaire = nombre_chutes_metrologique_mini
        }

        if (chutes_horaire < nombre_chutes_mini_horaire) {
            if (formikRef.current) {
                formikRef.current.setFieldValue("chutes_horaire", nombre_chutes_mini_horaire)
                chutes_horaire = nombre_chutes_mini_horaire
            }
            //setNbChutes(nombre_chutes_mini_horaire)
        }
        // Pour regler le soucis du 3 avec l'affichage et mise à jour du champ
        else if (chutes_horaire === 3 && (chutes_horaire < nombre_chutes_mini_horaire) === false) {
            formikRef.current.setFieldValue("chutes_horaire", 3)
            chutes_horaire = 3
        }

        if (sans_premiere_chute === true) {
            let vp_ou_vai = interventionInfoGeneral.que_vp === true ? 2 : 1
            valeur_theorique = chutes_horaire * essai_chute_horaire_n
            let _emtMoins = emt_tax_horaire(vp_ou_vai, 1, valeur_theorique)
            let _emtPlus = emt_tax_horaire(vp_ou_vai, 2, valeur_theorique)
            setEmtMoins(_emtMoins)
            setEmtPlus(_emtPlus)

            // Vérification de lue en secondes
            isChronoGood(metrologie.lue_secondes, _emtPlus, _emtMoins, setLueSecondesColor)
            setStopAll(false)
        }
        else {
            let vp_ou_vai = interventionInfoGeneral.que_vp === true ? 2 : 1
            valeur_theorique = (chutes_horaire - 1) * essai_chute_horaire_n + essai_premiere_chute_horaire
            let _emtMoins = emt_tax_horaire(vp_ou_vai, 1, valeur_theorique)
            let _emtPlus = emt_tax_horaire(vp_ou_vai, 2, valeur_theorique)
            setEmtMoins(_emtMoins)
            setEmtPlus(_emtPlus)

            // Vérification de lue en secondes
            isChronoGood(metrologie.lue_secondes, _emtPlus, _emtMoins, setLueSecondesColor)
            setStopAll(false)
        }
    }

    const chute_horaire_n = (departement, arrete, tarif) => {
        let chute_horaire_n = 0
        switch (tarif) {
            case 1:
                if (arrete.tarif_horaire_a !== 0) {
                    chute_horaire_n = 3600 * arrete.chute / arrete.tarif_horaire_a
                }
                break;
            case 2:
                if (arrete.tarif_horaire_b !== 0) {
                    chute_horaire_n = 3600 * arrete.chute / arrete.tarif_horaire_b
                }
                break;
            case 3:
                if (arrete.tarif_horaire_c !== 0) {
                    chute_horaire_n = 3600 * arrete.chute / arrete.tarif_horaire_c
                }
                break;
            case 4:
                if (arrete.tarif_horaire_d !== 0) {
                    chute_horaire_n = 3600 * arrete.chute / arrete.tarif_horaire_d
                }
                break;
            default:
                break;
        }
        return Math.round(chute_horaire_n * 100) / 100
    }

    const chute_horaire_premiere = (departement, arrete, tarif) => {
        let chute_horaire_premiere = 0
        switch (tarif) {
            case 1:
                if (arrete.tarif_horaire_a !== 0) {
                    chute_horaire_premiere = 3.6 * arrete.distance_chute1_a * arrete.tarif_kilometrique_a / arrete.tarif_horaire_a
                }
                break;
            case 2:
                if (arrete.tarif_horaire_b !== 0) {
                    chute_horaire_premiere = 3.6 * arrete.distance_chute1_b * arrete.tarif_kilometrique_b / arrete.tarif_horaire_b
                }
                break;
            case 3:
                if (arrete.tarif_horaire_c !== 0) {
                    chute_horaire_premiere = 3.6 * arrete.distance_chute1_c * arrete.tarif_kilometrique_c / arrete.tarif_horaire_c
                }
                break;
            case 4:
                if (arrete.tarif_horaire_d !== 0) {
                    chute_horaire_premiere = 3.6 * arrete.distance_chute1_d * arrete.tarif_kilometrique_d / arrete.tarif_horaire_d
                }
                break;
            default:
                break;
        }
        return Math.round(chute_horaire_premiere * 100) / 100
    }

    const myRound = (nombre, sens, nbDec) => {
        let roundedNumber = 0
        switch (sens) {
            case 1:
                roundedNumber = Math.ceil(nombre * (10 ** -nbDec)) / (10 ** -nbDec)
                break;
            case -1:
                roundedNumber = Math.floor(nombre * (10 ** -nbDec)) / (10 ** -nbDec)
                break;
            default:
                break;
        }

        return roundedNumber
    }

    const emt_tax_horaire = (vai_1_vp_2, moins_1_plus_2, theorique) => {
        let _emt_tax_horaire = 0

        switch (moins_1_plus_2) {
            case 1: // negatif
                if (vai_1_vp_2 === 1) {
                    _emt_tax_horaire = theorique * 0.985
                }
                else {
                    _emt_tax_horaire = theorique * 0.97
                }
                break;
            case 2:
                if (vai_1_vp_2 === 1) {
                    _emt_tax_horaire = theorique * 1.015
                }
                else {
                    _emt_tax_horaire = theorique * 1.03
                }
                break;
            default:
                break;
        }

        return Math.round(_emt_tax_horaire * 100) / 100
    }

    const handleChangeTempo = (e) => {
        let value = parseInt(e.target.value)
        if (value === 5) {
            if (tempo === 0 || tempo === 10) {
                setTempo(value)
            }
            else {
                setTempo(0)
            }
        }
        if (value === 10) {
            if (tempo === 0 || tempo === 5) {
                setTempo(value)
            }
            else {
                setTempo(0)
            }
        }
    }

    const handleChangeTarifHoraire = e => {
        let value = parseInt(e.target.value)
        let chutes_horaire = formikRef.current.values.chutes_horaire
        let sans_premiere_chute = formikRef.current.values.sans_premiere_chute
        formikRef.current.setFieldValue("tarif", value)
        let arretePrefectoral = selectedArrete.arretePrefectoral[0]
        let numero_dpt = arretePrefectoral.departement.numero_dpt
        chargeChutesHoraires(numero_dpt, arretePrefectoral, value, chutes_horaire, sans_premiere_chute)
    }

    const handleShowRelle = () => {
        setIsReelle(true)
        setIsUniv(false)
        setIsBanc(false)
    }

    const handleShowUniv = () => {
        setIsUniv(true)
        setIsReelle(false)
        setIsBanc(false)
    }

    const handleShowBanc = () => {
        setIsBanc(true)
        setIsReelle(false)
        setIsUniv(false)
    }

    const initialiseResultat = (verif) => {
        if (!isNullOrUndefined(verif.position_kilo)) {
            if (verif.position_kilo >= 99) {
                if (verif.position_kilo === 99) {
                    return -1
                }
                else {
                    return (verif.position_kilo - 100)
                }
            }
            else {
                return -1
            }
        }
    }

    const initialiseBanc = (verif) => {
        if (verif.position_kilo <= -1000) {
            if (verif.position_kilo === -1000) {
                return -1000
            }
            else if (verif.position_kilo === -1001) {
                return -1001
            }
        }
    }

    if (!metrologie || !metrologie_intervention || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="metrologie-section">
            <Formik
                innerRef={formikRef}
                initialValues={{
                    clef_arrete_national: metrologie.clef_arrete_national,
                    c_correspondance: metrologie.c_correspondance,
                    c_conformite_lettre: metrologie.c_conformite_lettre,
                    position_kilo: metrologie.position_kilo,
                    c_r71: metrologie.c_r71,
                    c_r81: metrologie.c_r81,
                    sans_premiere_chute: metrologie.sans_premiere_chute,
                    chutes_horaire: metrologie.chutes_horaire,
                    lue_secondes: metrologie.lue_secondes,
                    util_piste: (metrologie_intervention.util_piste === 0 || isNullOrUndefined(metrologie_intervention.util_piste)) && instrumentsDisposPiste && instrumentsDisposPiste.length === 1 ? instrumentsDisposPiste[0].numero_appareil : metrologie_intervention.util_piste,
                    util_palm: (metrologie_intervention.util_palm === 0 || isNullOrUndefined(metrologie_intervention.util_palm)) && instrumentsDisposChrono && instrumentsDisposChrono.length === 1 ? instrumentsDisposChrono[0].numero_appareil : metrologie_intervention.util_palm,
                    c_point_lu: initialiseResultat(metrologie),
                    banc: initialiseBanc(metrologie),
                    // A l'initialisation on utilise les valeurs int
                    // Et on changera lors de l'enregistrement
                    tarif: metrologie.tarif === "" || isNullOrUndefined(metrologie.tarif) ? 0 : ((metrologie.tarif).charCodeAt(0) - 64),
                }}
                enableReinitialize={true}
                validate={async (values) => {
                    try {
                        const intervention = await storage.get('intervention')
                        const taximetre = await storage.get('taximetre')

                        // Mise à jour des taximetres
                        taximetre.clef_arrete_national = parseInt(values.clef_arrete_national) // ""
                        taximetre.c_correspondance = values.c_correspondance
                        taximetre.c_conformite_lettre = values.c_conformite_lettre
                        taximetre.c_r71 = values.c_r71
                        taximetre.c_r81 = values.c_r81
                        taximetre.sans_premiere_chute = values.sans_premiere_chute
                        taximetre.chutes_horaire = values.chutes_horaire
                        taximetre.position_kilo = values.position_kilo
                        taximetre.lue_secondes = values.lue_secondes
                        taximetre.tarif = values.tarif !== 0 ? String.fromCharCode(values.tarif + 64) : ""
                        intervention.util_piste = parseInt(values.util_piste) // empty
                        intervention.util_palm = parseInt(values.util_palm) // empty
                        // C'est normal dans intervention et verif il y figure
                        intervention.clef_arrete_national = parseInt(values.clef_arrete_national)
                        // Partage des données
                        let test = taximetre
                        test.c_point_lu = parseInt(values.c_point_lu)
                        test.banc = parseInt(values.banc)
                        setFormVerif(test)
                        setFormIntervention(intervention)
                        // Mise à jour de localStorage

                        await storage.set('intervention', intervention)
                        await storage.set('taximetre', taximetre)
                    } catch (error) {
                        console.error('Erreur lors de la sauvegarde:', error)
                    }
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            <div className="reception-accordion">
                                {/* National */}
                                <div className="form-row m-w-100 m-m-0">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                National
                                                <span className="details-item">
                                                    {infosArrete === "" ? "Pas d'infos" : infosArrete}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            {(!loadingArretes &&
                                                <Field name="clef_arrete_national" className="form-control input-infos" as="select" onChange={e => handleChange(e, setFieldValue)}>
                                                    <option value=""></option>
                                                    {arretes && arretes.map((arrete, index) =>
                                                        <option key={index} value={arrete.clef_arrete_national} data-one={arrete.numero_doc_arrete_national} data-two={arrete.arretePrefectoral[0] ? arrete.arretePrefectoral[0].numero_doc_arrete_prefectoral : undefined}>
                                                            {arrete.lettre}
                                                            {arrete.couleur_lettre === 4 ? " rouge " :
                                                                arrete.couleur_lettre === 2 ? " vert " :
                                                                    arrete.couleur_lettre === 1 ? " bleu " :
                                                                        null}
                                                            {arrete.nom_arrete_national}</option>
                                                    )}
                                                </Field>) ||
                                                <Skeleton height={34} width={320} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* Details*/}
                                <div className="form-row m-m-0">
                                    <div className="form-col">
                                        <div className="button-document-container online">
                                            <div className="national-button">
                                                <button type="button" className="btn btn-document" disabled={selectedArrete === null ? true : false} onClick={e => openFicheTarif(e, selectedArrete)}>
                                                    <span>{loadingFicheTarif ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /> : "Fiche Tarif"}</span>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                            </div>
                                            <div className="national-button">
                                                <button type="button" className="btn btn-document" onClick={e => openPDF(e, docNational)} disabled={!docNational ? true : false}>
                                                    <span>{loadingNational ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /> : "National"}</span>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                            </div>
                                            <div className="national-button">
                                                <button type="button" className="btn btn-document" onClick={e => openPDF(e, docDpt)} disabled={!docDpt ? true : false}>
                                                    <span>{loadingDpt ? <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={24} style={{ padding: 20 }} wrapperStyle={{ marginLeft: 'auto', marginRight: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }} /> : "Dpt"}</span>
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Arrêté et Lettre */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Arrêté et lettre
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_correspondance"
                                                        id="c_correspondance-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_correspondance', 1)}
                                                        checked={values.c_correspondance === 1 ? true : false} />
                                                    <label htmlFor="c_correspondance-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_correspondance"
                                                        id="c_correspondance-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_correspondance', 2)}
                                                        checked={values.c_correspondance === 2 ? true : false} />
                                                    <label htmlFor="c_correspondance-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R51
                                                    </label>
                                                </div>
                                            </li>
                                            {interventionInfoGeneral.que_vp === false &&
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_correspondance"
                                                            id="c_correspondance-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_correspondance', 3)}
                                                            checked={values.c_correspondance === 3 ? true : false} />
                                                        <label htmlFor="c_correspondance-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            I51
                                                        </label>
                                                    </div>
                                                </li>
                                            }
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_correspondance"
                                                        id="c_correspondance-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_correspondance', 0)}
                                                        checked={(values.c_correspondance === 0 || values.c_correspondance === 4) ? true : false} />
                                                    <label htmlFor="c_correspondance-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Contrôle Tarif */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Contrôle Tarif
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <ul>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_conformite_lettre"
                                                        id="c_conformite_lettre-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_conformite_lettre', 1)}
                                                        checked={values.c_conformite_lettre === 1 ? true : false} />
                                                    <label htmlFor="c_conformite_lettre-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <OK />
                                                        OK
                                                    </label>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_conformite_lettre"
                                                        id="c_conformite_lettre-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_conformite_lettre', 2)}
                                                        checked={values.c_conformite_lettre === 2 ? true : false} />
                                                    <label htmlFor="c_conformite_lettre-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        R52
                                                    </label>
                                                </div>
                                            </li>
                                            {interventionInfoGeneral.que_vp === false &&
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="c_conformite_lettre"
                                                            id="c_conformite_lettre-4"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('c_conformite_lettre', 3)}
                                                            checked={values.c_conformite_lettre === 3 ? true : false} />
                                                        <label htmlFor="c_conformite_lettre-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <Intervention />
                                                            I52
                                                        </label>
                                                    </div>
                                                </li>
                                            }
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_conformite_lettre"
                                                        id="c_conformite_lettre-3"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_conformite_lettre', 0)}
                                                        checked={(values.c_conformite_lettre === 0 || values.c_conformite_lettre === 4) ? true : false} />
                                                    <label htmlFor="c_conformite_lettre-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {/* Accès Tarifs */}
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Accès Tarifs
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field name="manip_tarifs" className="form-control form-infos" disabled as="textarea" value={infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.manip_tarifs} />
                                        </div>
                                    </div>
                                </div>
                                {/* Piste et Chrono */}
                                <Tabs defaultActiveKey="1" tabPosition={'top'} style={{ height: 220, width: '100%' }}>
                                    <TabPane tab={'Piste (VAI +-2%, VP +-4%)'} key={1}>
                                        {/* Accès Tarifs */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Instru.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_piste" className="form-control form-infos" as="select">
                                                        <option value="empty"></option>
                                                        {instrumentsDisposPiste && instrumentsDisposPiste.map((instrument, n) =>
                                                            <option key={n} value={instrument.numero_appareil}>{instrument.marque + " " + instrument.modele + " " + instrument.identification_interne}</option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="piste-tabs">
                                            <ul className="nav nav-pills">
                                                <li className="nav-item">
                                                    <div className={"nav-link" + (isReelle ? " saisie-active" : "")} onClick={handleShowRelle}>Réelle</div>
                                                </li>
                                                <li className="nav-item">
                                                    <div className={"nav-link" + (isUniv ? " saisie-active" : "")} onClick={handleShowUniv}>Univ</div>
                                                </li>
                                                <li className="nav-item">
                                                    <div className={"nav-link" + (isBanc ? " saisie-active" : "")} onClick={handleShowBanc}>Banc</div>
                                                </li>
                                            </ul>
                                        </div>
                                        {isReelle &&
                                            <>
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <span>
                                                                Réelle
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row reelle-step">
                                                    <div className="form-cell-input">
                                                        <ul className="reelle-step-container">
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-1"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 1)}
                                                                        checked={values.position_kilo === 1 ? true : false} />
                                                                    <label htmlFor="position_kilo-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <Erreur />
                                                                        <span>&#60; -4%</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-2"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 2)}
                                                                        checked={values.position_kilo === 2 ? true : false} />
                                                                    <label htmlFor="position_kilo-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        {interventionInfoGeneral.que_vp ? <OK /> : <Erreur />}
                                                                        <span>-4% et -2%</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-3"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 3)}
                                                                        checked={(values.position_kilo === 3) ? true : false} />
                                                                    <label htmlFor="position_kilo-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <OK />
                                                                        <span>-2% et Top</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-4"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 4)}
                                                                        checked={(values.position_kilo === 4) ? true : false} />
                                                                    <label htmlFor="position_kilo-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <OK />
                                                                        <span>Top et +2%</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-5"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 5)}
                                                                        checked={(values.position_kilo === 5) ? true : false} />
                                                                    <label htmlFor="position_kilo-5" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        {interventionInfoGeneral.que_vp ? <OK /> : <Erreur />}
                                                                        <span>+2% et +4%</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="position_kilo"
                                                                        id="position_kilo-6"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('position_kilo', 6)}
                                                                        checked={(values.position_kilo === 6) ? true : false} />
                                                                    <label htmlFor="position_kilo-6" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <Erreur />
                                                                        <span>&#62; +4%</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            isUniv &&
                                            <>

                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <span>
                                                                Univ
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">
                                                                    Nb Chutes
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="nb_chutes" className="form-control input-small" type="text" disabled value={nbChutes} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">
                                                                    Affichage départ
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="affichage_depart" className="form-control input-small" type="text" disabled value={affichageDepart} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">
                                                                    Départ
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="depart" className="form-control input-small" type="text" disabled value={pointDepart} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">
                                                                    Affichage fin
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="affichage_fin" className="form-control input-small" type="text" disabled value={affichageFin} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-col">
                                                        <div className="form-cell-label">
                                                            <div className="form-text">
                                                                <div className="indication-title">
                                                                    Résultat
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-cell-input">
                                                            <div className="form-input">
                                                                <Field name="c_point_lu" className="form-control input-infos" as="select" onChange={e => handleChangeResultat(e, setFieldValue)}>
                                                                    <option value="-1"></option>
                                                                    {resultats && resultats.map((resultat, n) =>
                                                                        <option value={n} key={n}>{resultat}</option>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="vpvai-container">
                                                        {vaiVisible === true &&
                                                            <div className="vai resultat-item">
                                                                OK VAI
                                                            </div>
                                                        }
                                                        {vpVisible === true &&
                                                            <div className="vp resultat-item">
                                                                OK VP
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <Table striped hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Point</th>
                                                            <th>Mètres</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{point1 !== "" ? point1 : "-4%"}</td>
                                                            <td>{chute1}</td>
                                                            <td>{autre1}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{point2 !== "" ? point2 : "-2%"}</td>
                                                            <td>{chute2}</td>
                                                            <td>{autre2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{point5 !== "" ? point5 : "0%"}</td>
                                                            <td>{chute5}</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>{point3 !== "" ? point3 : "+2%"}</td>
                                                            <td>{chute3}</td>
                                                            <td>{autre3}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>{point4 !== "" ? point4 : "+4%"}</td>
                                                            <td>{chute4}</td>
                                                            <td>{autre4}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </>
                                        }
                                        {
                                            isBanc &&
                                            <>
                                                <div className="form-row">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <span>
                                                                Banc
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-cell-input">
                                                        <ul>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="banc"
                                                                        id="banc-1"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('banc', -1000)}
                                                                        checked={values.banc === -1000 ? true : false} />
                                                                    <label htmlFor="banc-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <OK />
                                                                        <span>Conforme</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="input-radio-item">
                                                                    <Field
                                                                        type="radio"
                                                                        name="banc"
                                                                        id="banc-2"
                                                                        className="sr-only"
                                                                        onChange={() => setFieldValue('banc', -1001)}
                                                                        checked={values.banc === -1001 ? true : false} />
                                                                    <label htmlFor="banc-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                        <Erreur />
                                                                        <span>Non Conforme</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="form-row">
                                            <div className="form-cell-input checkbox-container">
                                                <div className="form-input">
                                                    <Checkbox
                                                        onChange={e => { setFieldValue('c_r71', e.target.checked) }}
                                                        name="c_r71"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">R71 : Refus Base kilométrique</div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab={'Chrono (VAI +-1.5%, VP +-3%)'} key={2}>
                                        {/* Accès Tarifs */}
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Instrument
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="util_palm" className="form-control form-infos" as="select">
                                                        <option value="empty"></option>
                                                        <option value="1000000">CHRONO APPLI WEB COMET</option>
                                                        {instrumentsDisposChrono && instrumentsDisposChrono.map((instrument, n) =>
                                                            <option key={n} value={instrument.numero_appareil}>{instrument.marque + " " + instrument.modele + " " + instrument.identification_interne}</option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-input checkbox-container">
                                                <div className="form-input">
                                                    <Checkbox
                                                        onChange={e => { setFieldValue('c_r81', e.target.checked) }}
                                                        name="c_r81"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">R81 : Refus hors</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Tarif de contrôle
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="tarif" className="form-control" as="select" onChange={handleChangeTarifHoraire} >
                                                        <option value="0"></option>
                                                        {tarifsHoraire && tarifsHoraire.map((tarif, n) =>
                                                            <option value={(n + 1)} key={n}>{tarif}</option>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Nbe de Chutes
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="chutes_horaire" className="form-control input-small m-w-100" type="number" max={100} onChange={handleChangeNbeChutes} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">EMT (en s.)</div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="emtMoins" className="form-control" type="text" value={emtMoins} />
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="emtPlus" className="form-control" type="text" value={emtPlus} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timer-container checkbox-group">
                                            {/* <StopWatch
                                                parentCallback={getTimeFromWatchAndSetLueSeconde}
                                                tempo={tempo}
                                                returnTimeToWait={getTimeToWait}
                                            /> */}
                                            <StopWatch
                                                parentCallback={getTimeFromWatchAndSetLueSeconde}
                                                tempo={tempo}
                                                returnTimeToWait={getTimeToWait}
                                            />
                                            <div className="form-row">
                                                <div className="ml-4 form-group">
                                                    <label className="options-label">Sans première chute</label>
                                                    <Switch
                                                        name="sans_premiere_chute"
                                                        classes={lovelyStyles}
                                                        checked={values.sans_premiere_chute}
                                                        value={values.sans_premiere_chute}
                                                        onChange={handleChangeSansPremiereChute}
                                                    />
                                                </div>
                                                <div className="form-cell-input">
                                                    <div className="form-input">
                                                        <Field name="tempo_info" className="form-control" type="text" disabled value={timeToWait} />
                                                    </div>
                                                </div>
                                                <div className="ml-4 form-group">
                                                    <label className="options-label">Tempo 10s.</label>
                                                    <Switch
                                                        name="tempo"
                                                        classes={lovelyStyles}
                                                        checked={tempo === 10 ? true : false}
                                                        value={10}
                                                        onChange={handleChangeTempo}
                                                    />
                                                </div>
                                                <div className="ml-4 form-group">
                                                    <label className="options-label">Tempo 5s.</label>
                                                    <Switch
                                                        name="tempo"
                                                        classes={lovelyStyles}
                                                        checked={tempo === 5 ? true : false}
                                                        value={5}
                                                        onChange={handleChangeTempo}
                                                    />
                                                </div>
                                            </div>
                                            {startDate && endDate &&
                                                <div className="form-row">
                                                    <div className="form-group">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Heure de début serveur
                                                            </div>
                                                        </div>
                                                        <div className="form-text">
                                                            <label className="options-label">{`${new Date(startDate).toLocaleTimeString()} - ${new Date(startDate).getMilliseconds()}`}</label>
                                                        </div>
                                                    </div>
                                                    <div className="ml-4 form-group">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                Heure de fin serveur
                                                            </div>
                                                        </div>
                                                        <div className="form-text">
                                                            <label className="options-label">{`${new Date(endDate).toLocaleTimeString()} - ${new Date(endDate).getMilliseconds()}`}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="form-row">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        lue (en s.)
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input m-w-100">
                                                <div className="form-input">
                                                    <Field name="lue_secondes" className={"form-control" + (lueSecondesColor === 1 || (values.lue_secondes >= emtMoins && values.lue_secondes <= emtPlus) ? " c-bg-success" : lueSecondesColor === 0 || (values.lue_secondes !== 0 && values.lue_secondes !== "" && (values.lue_secondes < emtMoins || values.lue_secondes > emtPlus)) ? " c-bg-danger" : "")} type="text" />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Metrologie