import { useEffect, useRef, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Refus } from './../../../../res/refuse.svg'
import { supervisionService } from '../../../../services/supervisionService'
import vignetteRV from './../../../../assets/vignette rouge verte.png'
import SignaturePopup from '../../../Popup/SignaturePopup'
import { useSaisieContext } from '../SaisieContext'
import 'react-toastify/dist/ReactToastify.css'
import { formatDate, showVignetteStatus, getDegatVignette, isNullOrUndefined, unlockBody, lockBody } from './../../../../helpers/utils'
import { openPDF } from './../../../../helpers/utils.jsx'
import { useDataContext } from '../../../../providers/DataContextProvider'
import BasicButton from '../../../Buttons/BasicButton'
import { storage } from '../../../../helpers/storageHelper'

const Sanction = (props) => {

    const [sanction, setSanction] = useState(null)
    const [sanction_intervention, setSanction_intervention] = useState(null)
    const [sanction_vignette, setSanction_vignette] = useState(null)
    const [infos, setInfos] = useState(null)
    const formikRef = useRef()
    const [docUE, setDocUE] = useState(null)
    const [docNotice, setDocNotice] = useState(null)
    const [showPopup, setShowPopup] = useState(false)
    const { setFormVerif, setFormIntervention, setFormVignette, scellementTarifTaximetre, scellementTarifAfficheur } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const { interventionInfoGeneral } = useDataContext()
    const [clefColor, setClefColor] = useState(0) // -1 -> red / 0 -> neutre / 1 -> green

    useEffect(() => {

        const loadInitialData = async () => {
            try {
                // Chargement des données avec le storage helper
                const [taximetreData, interventionData, taxvignetteData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention'),
                    storage.get('taxvignette'),
                    storage.get('infos')
                ])

                setSanction(taximetreData)
                setSanction_intervention(interventionData)
                setSanction_vignette(taxvignetteData)
                setInfos(infosData)

                // Chargement des documents
                if (infosData?.stationnements?.[0]?.vehiculeTaximetres?.[0]?.taximetre?.modeleTousAppareils) {
                    const model = infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils
                    await Promise.all([
                        loadDocument(model.numero_document_attestation_UE, setDocUE),
                        loadDocument(model.numero_document_utilisateur, setDocNotice)
                    ])
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
                setErrors(error)
            }
        }

        loadInitialData()
    }, [])

    const loadDocument = async (numero, setterFunction) => {
        if (numero !== null) {
            try {
                const response = await supervisionService.getDocumentListeByNumeroDocument(numero)
                setterFunction(response)
            } catch (error) {
                process.env.NODE_ENV === "development" && console.log(error)
            }
        }
    }

    const togglePopup = () => {
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    const handleControlePose = async (numero_vignette, etat_vignette) => {
        try {
            const data = {
                numero_vignette: parseInt(numero_vignette),
                numero_adherent: sanction_intervention.numero_adherent,
                validite_vignette: (new Date()).getFullYear() + 1,
                numero_client: sanction_intervention.numero_client,
                clef_stationnement: sanction_intervention.clef_stationnement,
                clef_lien: sanction_intervention.clef_lien,
                numero_intervention: sanction_intervention.numero_intervention
            }

            let type = etat_vignette === 1 ? "verte" : etat_vignette === 2 ? "rouge" : undefined

            const response = await supervisionService.controlePose(data, type)
            const cp_message = response.message +
                (response.degat ? getDegatVignette(response.degat) : "") +
                (response.infos ? formatInfosMessage(response.infos) : "")

            showVignetteStatus(response.type, cp_message)
        } catch (error) {
            console.error('Erreur lors du contrôle pose:', error)
        }
    }

    const formatInfosMessage = (infos) => {
        return ` ${infos.nom_client} ${infos.cp_client} ${infos.ville_client}\n` +
            `Stationnement : ${infos.stationnements[0].numero_stationnement}\n` +
            `${infos.stationnements[0].cp} ${infos.stationnements[0].ville}\n` +
            `Date : ${formatDate(infos.date)}`
    }

    const limitValueLength = (e, setFieldValue) => {
        let value = e.target.value
        let name = e.target.name
        if (value.length <= 4) {
            setFieldValue(name, value)
        } else {
            return
        }
    }

    /**
     * @param {Number} numero_vignette 
     */
    const calcul_clef_verif_vignette = (numero_vignette) => {
        let numero_verif = ((numero_vignette - 1) % 26) + 65
        return String.fromCharCode(numero_verif).toUpperCase()
    }

    const verifie_vignette_taxi_lettre = (numero_vignette, clef_numero_vignette) => {
        if (clef_numero_vignette === "" || numero_vignette === "" || numero_vignette === 0) {
            setClefColor(0)
        }
        else {
            if (calcul_clef_verif_vignette(numero_vignette) === clef_numero_vignette) {
                setClefColor(1)
            }
            else {
                setClefColor(-1)
            }
        }
    }

    const handleChangeNumeroVignette = (e) => {
        let numero_vignette = e.target.value
        formikRef.current.setFieldValue("numero_vignette", numero_vignette)
        if (formikRef.current.values.clef_numero_vignette !== "") {
            verifie_vignette_taxi_lettre(parseInt(numero_vignette), formikRef.current.values.clef_numero_vignette)
        }
    }

    const handleChangeClefNumeroVignette = (e) => {
        let clef_numero_vignette = e.target.value
        verifie_vignette_taxi_lettre(parseInt(formikRef.current.values.numero_vignette), clef_numero_vignette)
        formikRef.current.setFieldValue("clef_numero_vignette", clef_numero_vignette)
    }

    if (!sanction || !sanction_intervention || !sanction_vignette || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={{
                    c_taximetre_mid_neuf: sanction.c_taximetre_mid_neuf,
                    c_taximetre_mid_notice: sanction.c_taximetre_mid_notice,
                    c_taximetre_mid_attestation: sanction.c_taximetre_mid_attestation,
                    commentaires: sanction.commentaires,
                    sign3: sanction_intervention.sign3,
                    scellement_tarif: sanction_intervention.scellement_tarif,
                    scellement_tarif_quel_appareil: sanction_intervention.scellement_tarif_quel_appareil,
                    info_complementaire_carnet: sanction_intervention.info_complementaire_carnet,
                    etat_vignette: sanction_vignette.etat_vignette,/*(sanction_vignette.numero_vignette === 0 && sanction_vignette.validite_vignette === -1) ? 3 :
                        (sanction_vignette.numero_vignette !== 0 && sanction_vignette.validite_vignette === 0) ? 2 :
                            (sanction_vignette.numero_vignette !== 0 && sanction_vignette.validite_vignette !== 0 && sanction_vignette.validite_vignette !== 0 && sanction_vignette.validite_vignette !== -1) ? 1 :
                                (sanction_vignette.numero_vignette === 0 && sanction_vignette.validite_vignette === 0) ? 0 : -1,*/
                    numero_vignette: sanction_vignette.numero_vignette,
                    validite_vignette: sanction_vignette.validite_vignette,
                    annee_vignette: sanction_vignette.annee_vignette,
                    clef_numero_vignette: sanction_vignette.validite_vignette === -1 ? "" : calcul_clef_verif_vignette(sanction_vignette.numero_vignette),
                }}
                validate={async values => {
                    const errors = {}
                    try {
                        const taximetre = await storage.get('taximetre')
                        const intervention = await storage.get('intervention')
                        const taxvignette = await storage.get('taxvignette')

                        // Mise à jour des interventions
                        intervention.sign3 = values.sign3
                        intervention.scellement_tarif = values.scellement_tarif
                        intervention.scellement_tarif_quel_appareil = values.scellement_tarif_quel_appareil
                        intervention.info_complementaire_carnet = values.info_complementaire_carnet
                        // Mise à jour des taximetres
                        taximetre.c_taximetre_mid_neuf = values.c_taximetre_mid_neuf
                        taximetre.c_taximetre_mid_notice = values.c_taximetre_mid_notice
                        taximetre.c_taximetre_mid_attestation = values.c_taximetre_mid_attestation
                        taximetre.commentaires = values.commentaires
                        // Mise à jour des vignettes
                        taxvignette.numero_vignette = parseInt(values.numero_vignette)
                        taxvignette.validite_vignette = parseInt(values.validite_vignette)
                        taxvignette.etat_vignette = parseInt(values.etat_vignette)
                        taxvignette.annee_vignette = parseInt(values.annee_vignette)
                        taxvignette.clef_numero_vignette = values.clef_numero_vignette

                        // Partage des données
                        setFormVerif(taximetre)
                        setFormIntervention(intervention)
                        setFormVignette(taxvignette)
                        setErrors({ ...errors })
                        // Mise à jour du storage
                        await Promise.all([
                            storage.set('intervention', intervention),
                            storage.set('taximetre', taximetre),
                            storage.set('taxvignette', taxvignette)
                        ])

                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            {/* Proximité Plaque */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Taximètre MID Neuf
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_taximetre_mid_neuf"
                                                    id="c_taximetre_mid_neuf-1"
                                                    className="sr-only"
                                                    onChange={() => {
                                                        setValues(prev => ({
                                                            ...prev,
                                                            c_taximetre_mid_neuf: 1,
                                                            c_taximetre_mid_notice: 4,
                                                            c_taximetre_mid_attestation: 4
                                                        }))
                                                    }}
                                                    checked={values.c_taximetre_mid_neuf === 1 ? true : false} />
                                                <label htmlFor="c_taximetre_mid_neuf-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    Oui
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_taximetre_mid_neuf"
                                                    id="c_taximetre_mid_neuf-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_taximetre_mid_neuf', 3)}
                                                    checked={values.c_taximetre_mid_neuf === 3 ? true : false} />
                                                <label htmlFor="c_taximetre_mid_neuf-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    Non
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Scellements */}
                            {values.c_taximetre_mid_neuf === 1 &&
                                <>
                                    <div className="form-row">
                                        <div className="form-col">
                                            <div className="form-cell-label">
                                                <div className="form-text">
                                                    <div className="indication-title">
                                                        Notice Utilisateur
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_notice"
                                                                id="c_taximetre_mid_notice-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_notice', 1)}
                                                                checked={values.c_taximetre_mid_notice === 1 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_notice-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Remise/présence
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_notice"
                                                                id="c_taximetre_mid_notice-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_notice', 3)}
                                                                checked={values.c_taximetre_mid_notice === 3 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_notice-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R55
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_notice"
                                                                id="c_taximetre_mid_notice-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_notice', 4)}
                                                                checked={values.c_taximetre_mid_notice === 4 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_notice-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="button-document-container">
                                                <div className="rapport-sup-button">
                                                    <button type="button" className="btn btn-document" onClick={e => openPDF(e, docNotice)} disabled={isNullOrUndefined(docNotice) ? true : false}>
                                                        <span>Notice</span>
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Infos Scellements */}
                                    <div className="form-row">
                                        <div className="form-col"><div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Attestation UE
                                                </div>
                                            </div>
                                        </div>
                                            <div className="form-cell-input">
                                                <ul>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_attestation"
                                                                id="c_taximetre_mid_attestation-1"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_attestation', 1)}
                                                                checked={values.c_taximetre_mid_attestation === 1 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_attestation-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <OK />
                                                                Remise/présence
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_attestation"
                                                                id="c_taximetre_mid_attestation-2"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_attestation', 3)}
                                                                checked={values.c_taximetre_mid_attestation === 3 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_attestation-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <Erreur />
                                                                R56
                                                            </label>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="input-radio-item">
                                                            <Field
                                                                type="radio"
                                                                name="c_taximetre_mid_attestation"
                                                                id="c_taximetre_mid_attestation-3"
                                                                className="sr-only"
                                                                onChange={() => setFieldValue('c_taximetre_mid_attestation', 4)}
                                                                checked={values.c_taximetre_mid_attestation === 4 ? true : false} />
                                                            <label htmlFor="c_taximetre_mid_attestation-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                                <SO />
                                                                SO
                                                            </label>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-col">
                                            <div className="button-document-container">
                                                <div className="rapport-sup-button">
                                                    <button type="button" className="btn btn-document" onClick={e => openPDF(e, docUE)} disabled={isNullOrUndefined(docUE) ? true : false}>
                                                        <span>Attestation UE</span>
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {/* Taximètre / Centrale */}
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Sanction Vignette
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <ul>
                                            <li className='m-w-100'>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="etat_vignette"
                                                        id="etat_vignette-1"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('etat_vignette', 1)}
                                                        checked={values.etat_vignette === 1 ? true : false} />
                                                    <label htmlFor="etat_vignette-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow m-w-100">
                                                        <OK />
                                                        Acceptation : Vignette Verte
                                                    </label>
                                                </div>
                                            </li>
                                            <li className='m-w-100'>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="etat_vignette"
                                                        id="etat_vignette-2"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('etat_vignette', 2)}
                                                        checked={values.etat_vignette === 2 ? true : false} />
                                                    <label htmlFor="etat_vignette-2" className="m-w-100 h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Erreur />
                                                        Refus : Vignette Rouge
                                                    </label>
                                                </div>
                                            </li>
                                            <li className='m-w-100'>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="etat_vignette"
                                                        id="etat_vignette-4"
                                                        className="sr-only"
                                                        onChange={() => {
                                                            setValues(prev => ({
                                                                ...prev,
                                                                etat_vignette: 3,
                                                                numero_vignette: 0,
                                                                clef_numero_vignette: ""
                                                            }))
                                                            setClefColor(0)
                                                        }}
                                                        checked={(values.etat_vignette === 3) ? true : false} />
                                                    <label htmlFor="etat_vignette-4" className="m-w-100 h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Refus />
                                                        Refus : Remise en confomité sans vignette rouge
                                                    </label>
                                                </div>
                                            </li>
                                            <li className='m-w-100'>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="etat_vignette"
                                                        id="etat_vignette-3"
                                                        className="sr-only"
                                                        onChange={() => {
                                                            setValues(prev => ({
                                                                ...prev,
                                                                etat_vignette: 0,
                                                                numero_vignette: 0,
                                                                clef_numero_vignette: ""
                                                            }))
                                                            setClefColor(0)
                                                        }}
                                                        checked={(values.etat_vignette === 0) ? true : false} />
                                                    <label htmlFor="etat_vignette-3" className="m-w-100 h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <SO />
                                                        SO
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {(values.etat_vignette === 1 || values.etat_vignette === 2) &&
                                    <>
                                        <div className="form-col">
                                            <div className="form-row pt-4">
                                                <div className="form-col">
                                                    <div className="form-cell-label">
                                                        <div className="form-text">
                                                            <div className="indication-title">
                                                                N° Vignette
                                                                <span className="details-item">
                                                                    Position : {infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.situation_vignette}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-cell-input">
                                                        <div className="form-input d-flex">
                                                            <Field className="form-control input-small" type="number" name="numero_vignette" onChange={handleChangeNumeroVignette} />
                                                            <Field className={"form-control input-extra-small" + (clefColor === 1 ? " c-bg-success" : clefColor === 0 ? "" : " c-bg-danger")} type="text" name="clef_numero_vignette" onChange={handleChangeClefNumeroVignette} />
                                                        </div>
                                                    </div>
                                                    {sanction_intervention.clef_d2 === 16 &&
                                                        <>
                                                            <div className="form-cell-label">
                                                                <div className="form-text">
                                                                    <div className="indication-title">
                                                                        Année vignette remplacement
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-cell-input">
                                                                <div className="form-input">
                                                                    <Field className="form-control input-small" type="number" name="annee_vignette" onChange={e => limitValueLength(e, setFieldValue)} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className="form-col pl-2">
                                                    <button type="button" className="btn controle-vignette" onClick={e => handleControlePose(values.numero_vignette, values.etat_vignette)}>
                                                        <div className="vignette-img-container">
                                                            <img src={vignetteRV} alt="Vignette Rouge et Verte" />
                                                        </div>
                                                        Contrôle pose
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            {interventionInfoGeneral.tarif === true && interventionInfoGeneral.installation === false &&
                                <>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Scellement Tarif :
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <Field name="scellement_tarif" className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-input">
                                            <ul>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="scellement_tarif_quel_appareil"
                                                            id="scellement_tarif_quel_appareil-2"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('scellement_tarif_quel_appareil', 1)}
                                                            checked={values.scellement_tarif_quel_appareil === 1 ? true : false} />
                                                        <label htmlFor="scellement_tarif_quel_appareil-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            Sur Taximètre
                                                        </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="input-radio-item">
                                                        <Field
                                                            type="radio"
                                                            name="scellement_tarif_quel_appareil"
                                                            id="scellement_tarif_quel_appareil-3"
                                                            className="sr-only"
                                                            onChange={() => setFieldValue('scellement_tarif_quel_appareil', 10)}
                                                            checked={values.scellement_tarif_quel_appareil === 10 ? true : false} />
                                                        <label htmlFor="scellement_tarif_quel_appareil-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                            <SO />
                                                            Sur Afficheur
                                                        </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="form-row m-m-0">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Si Taximètre* :
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <BasicButton
                                                    color="success"
                                                    title={scellementTarifTaximetre === "" ? "Vide" : scellementTarifTaximetre}
                                                    onClick={e => { setFieldValue("scellement_tarif", e.target.textContent); setFieldValue('scellement_tarif_quel_appareil', 1); }}
                                                />
                                                {/*<Field className="form-control" type="text" name="scellement_tarif_taximetre" disabled onClick={e => { setFieldValue("scellement_tarif", e.target.value); setFieldValue('scellement_tarif_quel_appareil', 1); }} />*/}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-cell-label">
                                            <div className="form-text">
                                                <div className="indication-title">
                                                    Si Afficheur* :
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-cell-input">
                                            <div className="form-input">
                                                <BasicButton
                                                    color="success"
                                                    title={scellementTarifAfficheur === "" ? "Vide" : scellementTarifAfficheur}
                                                    onClick={e => { setFieldValue("scellement_tarif", e.target.textContent); setFieldValue('scellement_tarif_quel_appareil', 10); }}
                                                />
                                                {/*<Field className="form-control" type="text" name="scellement_tarif_afficheur" disabled />*/}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {/* Commentaires */}
                            <div className="form-row">
                                <div className="form-col m-w-100">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Commentaires
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field className="form-control form-infos" as="textarea" name="commentaires" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Infos complémentaires dans carnet */}
                            <div className="form-row">
                                <div className="form-col m-w-100">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Info complémentaire dans carnet
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field className="form-control form-infos" as="textarea" name="info_complementaire_carnet" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*interventionInfoGeneral.que_vp === false && interventionInfoGeneral.d1 === true &&*/
                                <>
                                    <div className="form-row">
                                        <div className="modele-actions mb-3">
                                            <button type="button" className={"modele-btn" + (values.sign3 ? " bg-success" : " bg-danger")} onClick={togglePopup}>
                                                Signature vérificateur
                                                <i className="fas fa-external-link-alt ml-2"></i>
                                            </button>
                                            <span className={"details-item" + (values.sign3 ? " option-yes" : "")}>{!values.sign3 ? "Vide" : "Signé"}</span>
                                        </div>
                                    </div>
                                    {values.sign3 ?
                                        <div className="form-row">
                                            <div className="signature-img-container">
                                                <img src={"data:image/png;base64," + values.sign3} alt="" />
                                            </div>
                                        </div> : null
                                    }
                                </>
                            }
                            {showPopup &&
                                <SignaturePopup close={togglePopup} title="Signature Vérificateur" setFieldValue={setFieldValue} inputName="sign3" />
                            }
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Sanction