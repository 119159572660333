import React, { useEffect, useState } from "react"
import { dataService } from "../../../../helpers/dataService"
import { supervisionService } from "../../../../services/supervisionService"
import UseAnimations from "react-useanimations"
import loading from "react-useanimations/lib/loading"
import { isEmptyObject } from "jquery"
import { getTextInterventionDetail } from "../../../../helpers/utils"
import { storage } from "../../../../helpers/storageHelper"

const Infos = (props) => {

    const [infos, setinfos] = useState(null)
    const [intervention, setintervention] = useState("")
    const [loadingData, setloadingData] = useState(true)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        async function getInfos() {
            try {
                // Récupérer les infos depuis localforage au chargement
                const storedInfos = await storage.get("infos")
                if (storedInfos) {
                    setinfos(storedInfos)
                }

                let data = {}
                dataService.getIntervention().subscribe(async store => {
                    if (store.currentInt) {
                        data = {
                            numero_adherent: store.currentInt.numero_adherent,
                            numero_client: store.currentInt.numero_client,
                            clef_stationnement: store.currentInt.clef_stationnement,
                            clef_lien: store.currentInt.clef_lien
                        }
                        setintervention(getTextInterventionDetail(store.currentInt))
                    }
                })

                // Récupérer les nouvelles infos depuis l'API
                const response = await supervisionService.getInfos(data)
                // Sauvegarder dans localforage
                await storage.set("infos", response)
                setinfos(response)
            } catch (error) {
                if (error) {
                    setErrors({ infos: error })
                }
                console.log(error)
            } finally {
                setloadingData(false)
            }
        }

        getInfos()
    }, [])

    return (
        <div className="infos-section">
            <div className="infos-container">
                {loadingData === true &&
                    <div className="loading-section">
                        <UseAnimations strokeColor={'var(--comet-primary)'} animation={loading} size={70} style={{ padding: 20 }} wrapperStyle={{ color: 'red', marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                }
                {loadingData === false && isEmptyObject(errors) &&
                    <>
                        <div className="infos-body">
                            <div className="infos-client mb-3">
                                <div className="infos-body-title mb-1">
                                    <span className="infos-body-title-texte">Client</span>
                                </div>
                                <span className="infos-body-details">
                                    {infos && infos.nom_client + " - " + infos.cp_client + " " + infos.ville_client + " " + infos.tel_client}
                                </span>
                            </div>
                            <div className="infos-stationnement mb-3">
                                <div className="infos-body-title mb-1">
                                    <span className="infos-body-title-texte">Stationnement</span>
                                </div>
                                <span className="infos-body-details">
                                    {infos && infos.stationnements[0] && infos.stationnements[0].numero_stationnement + " " + infos.stationnements[0].ville + " " + infos.stationnements[0].cp}
                                </span>
                            </div>
                            <div className="infos-vehicule mb-3">
                                <div className="infos-body-title mb-1">
                                    <span className="infos-body-title-texte">Vehicule</span>
                                </div>
                                <span className="infos-body-details mb-1">
                                    {infos && infos.stationnements[0] && infos.stationnements[0].vehiculeTaximetres[0].vehicule.modeleVehicule.marqueVehicule.marque_nom_vehicule + " " +
                                        infos.stationnements[0].vehiculeTaximetres[0].vehicule.modeleVehicule.modele_nom_vehicule + " " +
                                        infos.stationnements[0].vehiculeTaximetres[0].vehicule.immatriculation_vehicule
                                    }
                                </span>
                            </div>
                            <div className="infos-taximetre mb-3">
                                <div className="infos-body-title mb-1">
                                    <span className="infos-body-title-texte">Taximetre</span>
                                </div>
                                <span className="infos-body-details">
                                    {infos && infos.stationnements[0] && infos.stationnements[0].vehiculeTaximetres[0] && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.marque_nom_appareil + " " +
                                        infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil + " " +
                                        infos.stationnements[0].vehiculeTaximetres[0].taximetre.numero_serie_taximetre
                                    }
                                </span>
                            </div>
                            <div className="infos-intervention mb-3">
                                <div className="infos-body-title mb-1">
                                    <span className="infos-body-title-texte">Intervention</span>
                                </div>
                                <span className="infos-body-details">
                                    {intervention && intervention}
                                </span>
                            </div>
                        </div>
                    </>
                }
                {loadingData === false && !isEmptyObject(errors) &&
                    <>
                        <div className="infos-header">
                            <h3 className="saisie-tools-section-title">Infos</h3>
                        </div>
                        <div className="infos-body">
                            <div className="infos-body-title errors-infos">
                                <span className="infos-body-title-texte">{errors.infos}</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div >
    )

}

export default Infos