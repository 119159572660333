import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { supervisionService } from '../../../../services/supervisionService'
import Skeleton from 'react-loading-skeleton'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { storage } from '../../../../helpers/storageHelper'

const Position = (props) => {

    const [position, setPosition] = useState(null)
    const [position_intervention, setPosition_intervention] = useState(null)
    const [infos, setInfos] = useState(null)
    const [details, setDetails] = useState([])
    const [detailsAfficheur, setDetailsAfficheur] = useState([])
    const [loadingDetails, setLoadingDetails] = useState(false)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des données avec le storage helper
                const [taximetreData, interventionData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention'),
                    storage.get('infos')
                ])

                if (taximetreData) setPosition(taximetreData)
                if (interventionData) setPosition_intervention(interventionData)
                if (infosData) setInfos(infosData)

                if (infosData) {
                    setLoadingDetails(true)
                    try {
                        const [detailsResponse, detailsAfficheurResponse] = await Promise.all([
                            supervisionService.getDetailFixation(infosData.numero_adherent),
                            supervisionService.getDetailFixationAfficheur(infosData.numero_adherent)
                        ])
                        setDetails(detailsResponse)
                        setDetailsAfficheur(detailsAfficheurResponse)
                    } catch (error) {
                        console.error('Erreur lors du chargement des détails:', error)
                    } finally {
                        setLoadingDetails(false)
                    }
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            }
        }

        loadInitialData()
    }, [])

    if (!position || !position_intervention || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    detail_fixation: position.detail_fixation,
                    detail_fixation_afficheur: position.detail_fixation_afficheur,
                    fixation: position.fixation,
                    positionnement: position.positionnement,
                    conformite_gabarit: position_intervention.conformite_gabarit,
                    temporisation: position.temporisation,
                }}
                validate={async (values) => {
                    const errors = {}
                    try {
                        const [taximetre, intervention] = await Promise.all([
                            storage.get('taximetre'),
                            storage.get('intervention')
                        ])

                        // Mise à jour des interventions
                        intervention.conformite_gabarit = parseInt(values.conformite_gabarit)

                        // Mise à jour des taximetres
                        taximetre.detail_fixation = values.detail_fixation
                        taximetre.detail_fixation_afficheur = values.detail_fixation_afficheur
                        taximetre.fixation = values.fixation
                        taximetre.positionnement = values.positionnement
                        taximetre.temporisation = values.temporisation

                        // Mise à jour du storage
                        await Promise.all([
                            storage.set('taximetre', taximetre),
                            storage.set('intervention', intervention)
                        ])

                        // Partage des données
                        props.passFormToParent(taximetre, intervention, errors)

                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            {/* Moyen de fixation taximètre */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Moyen de fixation taximètre
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        {(!loadingDetails &&
                                            <>
                                                <Field className="form-control" type="text" name="detail_fixation" list="detail_fixations" id="detail_fixation" placeholder="Valeur" />
                                                <datalist id="detail_fixations">
                                                    {details.map((item, key) =>
                                                        <option key={key} value={item}>
                                                            {item}
                                                        </option>
                                                    )}
                                                </datalist>
                                            </>) ||
                                            <Skeleton height={30} width={200} />
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Moyen de fixation afficheur */}
                            {infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.afficheur === true &&
                                <div className="form-row">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Moyen de fixation afficheur
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input m-w-100">
                                        <div className="form-input">
                                            <Field className="form-control" type="text" name="detail_fixation_afficheur" list="detail_fixation_afficheurs" id="detail_fixation_afficheur" placeholder="Valeur" />
                                            <datalist id="detail_fixation_afficheurs">
                                                {detailsAfficheur && detailsAfficheur.map((item, key) =>
                                                    <option key={key} value={item}>
                                                        {item}
                                                    </option>
                                                )}
                                            </datalist>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Essais d'arrachements */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Essais d'arrachements partiel
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="fixation"
                                                    id="fixation-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('fixation', 1)}
                                                    checked={values.fixation === 1 ? true : false} />
                                                <label htmlFor="fixation-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="fixation"
                                                    id="fixation-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('fixation', 2)}
                                                    checked={values.fixation === 2 ? true : false} />
                                                <label htmlFor="fixation-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R16
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="fixation"
                                                        id="fixation-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('fixation', 3)}
                                                        checked={values.fixation === 3 ? true : false} />
                                                    <label htmlFor="fixation-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I16
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="fixation"
                                                    id="fixation-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('fixation', 0)}
                                                    checked={(values.fixation === 0 || values.fixation === 4) ? true : false} />
                                                <label htmlFor="fixation-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Posiionnement Taximètre */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Positionnement Taximètre
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="positionnement"
                                                    id="positionnement-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('positionnement', 1)}
                                                    checked={values.positionnement === 1 ? true : false} />
                                                <label htmlFor="positionnement-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="positionnement"
                                                    id="positionnement-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('positionnement', 2)}
                                                    checked={values.positionnement === 2 ? true : false} />
                                                <label htmlFor="positionnement-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R15
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="positionnement"
                                                        id="positionnement-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('positionnement', 3)}
                                                        checked={values.positionnement === 3 ? true : false} />
                                                    <label htmlFor="positionnement-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I15
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="positionnement"
                                                    id="positionnement-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('positionnement', 0)}
                                                    checked={(values.positionnement === 0 || values.positionnement === 4) ? true : false} />
                                                <label htmlFor="positionnement-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* PARIS : Conformité Gabarit */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            PARIS : Conformité Gabarit
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="conformite_gabarit"
                                                    id="conformite_gabarit-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('conformite_gabarit', 1)}
                                                    checked={values.conformite_gabarit === 1 ? true : false} />
                                                <label htmlFor="conformite_gabarit-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow disabled">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="conformite_gabarit"
                                                    id="conformite_gabarit-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('conformite_gabarit', 2)}
                                                    checked={values.conformite_gabarit === 2 ? true : false} />
                                                <label htmlFor="conformite_gabarit-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow disabled">
                                                    <Erreur />
                                                    R53
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="conformite_gabarit"
                                                        id="conformite_gabarit-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('conformite_gabarit', 3)}
                                                        checked={values.conformite_gabarit === 3 ? true : false} />
                                                    <label htmlFor="conformite_gabarit-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow disabled">
                                                        <Intervention />
                                                        I53
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="conformite_gabarit"
                                                    id="conformite_gabarit-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('conformite_gabarit', 0)}
                                                    checked={(values.conformite_gabarit === 0 || values.conformite_gabarit === 4) ? true : false} />
                                                <label htmlFor="conformite_gabarit-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow disabled">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Temporisation extinction Tax */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Temporisation extinction Tax
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="temporisation"
                                                    id="temporisation-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('temporisation', 1)}
                                                    checked={values.temporisation === 1 ? true : false} />
                                                <label htmlFor="temporisation-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="temporisation"
                                                    id="temporisation-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('temporisation', 2)}
                                                    checked={values.temporisation === 2 ? true : false} />
                                                <label htmlFor="temporisation-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R24
                                                </label>
                                            </div>
                                        </li>
                                        {props.interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="temporisation"
                                                        id="temporisation-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('temporisation', 3)}
                                                        checked={values.temporisation === 3 ? true : false} />
                                                    <label htmlFor="temporisation-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I24
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="temporisation"
                                                    id="temporisation-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('temporisation', 0)}
                                                    checked={(values.temporisation === 0 || values.temporisation === 4) ? true : false} />
                                                <label htmlFor="temporisation-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Contrôle */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Contrôle
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field className="form-control form-infos" disabled as="textarea" name="manip_logiciel" value={infos && infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.taximetre_extinction} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Position