import { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { useSaisieContext } from '../SaisieContext'
import { Link } from 'react-router-dom'
import AutorisationCouleurPopup from '../../../Popup/AutorisationCouleurPopup'
import { lockBody, unlockBody } from '../../../../helpers/utils'
import { useDataContext } from '../../../../providers/DataContextProvider'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { storage } from '../../../../helpers/storageHelper'

const Lumineux = (props) => {

    const [lumineux, setLumineux] = useState(null)
    const [infos, setInfos] = useState(null)
    const [showAut, setShowAut] = useState(false)
    const { setFormVerif } = useSaisieContext()
    const [errors, setErrors] = useState(null)
    const { interventionInfoGeneral } = useDataContext()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const [lumineuxData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('infos')
                ])

                if (lumineuxData) {
                    setLumineux(lumineuxData)
                }
                if (infosData) {
                    setInfos(infosData)
                }
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            } finally {
                setLoading(false)
            }
        }

        loadInitialData()
    }, [])

    const handleAutorisationCouleur = () => {
        setShowAut(!showAut)
        showAut ? unlockBody() : lockBody()
    }

    if (loading || !lumineux || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="tabpanel-content">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    c_lum_non_deplacable: lumineux.c_lum_non_deplacable,
                    c_lum_taxi_visible: lumineux.c_lum_taxi_visible,
                    c_lum_commune_lisible: lumineux.c_lum_commune_lisible,
                    c_lum_commune_taille: lumineux.c_lum_commune_taille,
                    c_lum_non_cache: lumineux.c_lum_non_cache,
                    c_lum_couleur_autorise: lumineux.c_lum_couleur_autorise,
                    lumineux_toit_ouvrant: lumineux.lumineux_toit_ouvrant,
                    lumineux_kit_rehausse: lumineux.lumineux_kit_rehausse,
                    lumineux_amovible: lumineux.lumineux_amovible,
                    lumineux_barre_de_toit: lumineux.lumineux_barre_de_toit
                }}
                validate={async (values) => {
                    const errors = {}
                    try {
                        const taximetre = await storage.get('taximetre')
                        // Mise à jour des taximetres
                        taximetre.c_lum_non_deplacable = values.c_lum_non_deplacable
                        taximetre.c_lum_taxi_visible = values.c_lum_taxi_visible
                        taximetre.c_lum_commune_lisible = values.c_lum_commune_lisible
                        taximetre.c_lum_commune_taille = values.c_lum_commune_taille
                        taximetre.c_lum_non_cache = values.c_lum_non_cache
                        taximetre.c_lum_couleur_autorise = values.c_lum_couleur_autorise
                        taximetre.lumineux_toit_ouvrant = values.lumineux_toit_ouvrant
                        taximetre.lumineux_kit_rehausse = values.lumineux_kit_rehausse
                        taximetre.lumineux_amovible = values.lumineux_amovible
                        taximetre.lumineux_barre_de_toit = values.lumineux_barre_de_toit
                        // Partage des données
                        setFormVerif(taximetre)
                        setErrors({ ...errors })

                        // Mise à jour de storage
                        await storage.set('taximetre', taximetre)
                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        <div className="affichage-form-container etiquette">
                            {/* Toit ouvrant */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Toit ouvrant
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_toit_ouvrant"
                                                    id="lumineux_toit_ouvrant-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_toit_ouvrant', 1)}
                                                    checked={values.lumineux_toit_ouvrant === 1 ? true : false} />
                                                <label htmlFor="lumineux_toit_ouvrant-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OUI
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_toit_ouvrant"
                                                    id="lumineux_toit_ouvrant-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_toit_ouvrant', 3)}
                                                    checked={values.lumineux_toit_ouvrant === 3 ? true : false} />
                                                <label htmlFor="lumineux_toit_ouvrant-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    NON
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Kit réhausse */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Kit réhausse
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_kit_rehausse"
                                                    id="lumineux_kit_rehausse-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_kit_rehausse', 1)}
                                                    checked={values.lumineux_kit_rehausse === 1 ? true : false} />
                                                <label htmlFor="lumineux_kit_rehausse-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OUI
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_kit_rehausse"
                                                    id="lumineux_kit_rehausse-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_kit_rehausse', 3)}
                                                    checked={values.lumineux_kit_rehausse === 3 ? true : false} />
                                                <label htmlFor="lumineux_kit_rehausse-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    NON
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Lumineux amovible */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Lumineux amovible
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_amovible"
                                                    id="lumineux_amovible-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_amovible', 1)}
                                                    checked={values.lumineux_amovible === 1 ? true : false} />
                                                <label htmlFor="lumineux_amovible-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OUI
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_amovible"
                                                    id="lumineux_amovible-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_amovible', 3)}
                                                    checked={values.lumineux_amovible === 3 ? true : false} />
                                                <label htmlFor="lumineux_amovible-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    NON
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Lumineux Barre de Toit */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Barre pour support lumineux
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_barre_de_toit"
                                                    id="lumineux_barre_de_toit-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_barre_de_toit', 1)}
                                                    checked={values.lumineux_barre_de_toit === 1 ? true : false} />
                                                <label htmlFor="lumineux_barre_de_toit-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OUI
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="lumineux_barre_de_toit"
                                                    id="lumineux_barre_de_toit-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('lumineux_barre_de_toit', 3)}
                                                    checked={values.lumineux_barre_de_toit === 3 ? true : false} />
                                                <label htmlFor="lumineux_barre_de_toit-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    NON
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Lumineux deplacable */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Lumineux non déplaçable à l'arrière
                                            <i className="fas fa-question-circle ctooltip">
                                                <span className="tooltiptext">
                                                    Photo obligatoire de côté à minima pour une installation (conseillée pour VP)
                                                </span>
                                            </i>
                                            <div className="modele-actions mt-2">
                                                <Link to="photos" className="modele-btn">
                                                    Photos
                                                    <i className="fas fa-external-link-alt ml-2"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_deplacable"
                                                    id="c_lum_non_deplacable-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_deplacable', 1)}
                                                    checked={values.c_lum_non_deplacable === 1 ? true : false} />
                                                <label htmlFor="c_lum_non_deplacable-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_non_deplacable"
                                                        id="c_lum_non_deplacable-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_non_deplacable', 3)}
                                                        checked={values.c_lum_non_deplacable === 3 ? true : false} />
                                                    <label htmlFor="c_lum_non_deplacable-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I401
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_deplacable"
                                                    id="c_lum_non_deplacable-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_deplacable', 2)}
                                                    checked={values.c_lum_non_deplacable === 2 ? true : false} />
                                                <label htmlFor="c_lum_non_deplacable-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R401
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_deplacable"
                                                    id="c_lum_non_deplacable-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_deplacable', 4)}
                                                    checked={values.c_lum_non_deplacable === 4 ? true : false} />
                                                <label htmlFor="c_lum_non_deplacable-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Scellements */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            'TAXI' non abîmée / Lisible
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_taxi_visible"
                                                    id="c_lum_taxi_visible-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_taxi_visible', 1)}
                                                    checked={values.c_lum_taxi_visible === 1 ? true : false} />
                                                <label htmlFor="c_lum_taxi_visible-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_taxi_visible"
                                                        id="c_lum_taxi_visible-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_taxi_visible', 3)}
                                                        checked={values.c_lum_taxi_visible === 3 ? true : false} />
                                                    <label htmlFor="c_lum_taxi_visible-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I402
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_taxi_visible"
                                                    id="c_lum_taxi_visible-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_taxi_visible', 2)}
                                                    checked={values.c_lum_taxi_visible === 2 ? true : false} />
                                                <label htmlFor="c_lum_taxi_visible-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R402
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_taxi_visible"
                                                    id="c_lum_taxi_visible-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_taxi_visible', 4)}
                                                    checked={values.c_lum_taxi_visible === 4 ? true : false} />
                                                <label htmlFor="c_lum_taxi_visible-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Infos Scellements */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Commune MAJUSCULE
                                            <span className="details-item">
                                                {infos.stationnements[0].ville}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_lisible"
                                                    id="c_lum_commune_lisible-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_lisible', 1)}
                                                    checked={values.c_lum_commune_lisible === 1 ? true : false} />
                                                <label htmlFor="c_lum_commune_lisible-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    Non abîmée
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_commune_lisible"
                                                        id="c_lum_commune_lisible-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_commune_lisible', 3)}
                                                        checked={values.c_lum_commune_lisible === 3 ? true : false} />
                                                    <label htmlFor="c_lum_commune_lisible-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I403
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_lisible"
                                                    id="c_lum_commune_lisible-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_lisible', 2)}
                                                    checked={values.c_lum_commune_lisible === 2 ? true : false} />
                                                <label htmlFor="c_lum_commune_lisible-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R403
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_lisible"
                                                    id="c_lum_commune_lisible-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_lisible', 4)}
                                                    checked={values.c_lum_commune_lisible === 4 ? true : false} />
                                                <label htmlFor="c_lum_commune_lisible-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Taximètre / Centrale */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Taille Commune [20;50 mm]
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_taille"
                                                    id="c_lum_commune_taille-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_taille', 1)}
                                                    checked={values.c_lum_commune_taille === 1 ? true : false} />
                                                <label htmlFor="c_lum_commune_taille-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_commune_taille"
                                                        id="c_lum_commune_taille-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_commune_taille', 3)}
                                                        checked={values.c_lum_commune_taille === 3 ? true : false} />
                                                    <label htmlFor="c_lum_commune_taille-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I404
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_taille"
                                                    id="c_lum_commune_taille-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_taille', 2)}
                                                    checked={values.c_lum_commune_taille === 2 ? true : false} />
                                                <label htmlFor="c_lum_commune_taille-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R404
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_commune_taille"
                                                    id="c_lum_commune_taille-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_commune_taille', 4)}
                                                    checked={values.c_lum_commune_taille === 4 ? true : false} />
                                                <label htmlFor="c_lum_commune_taille-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Afficheur */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Lumineux non caché par élément extérieur
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_cache"
                                                    id="c_lum_non_cache-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_cache', 1)}
                                                    checked={values.c_lum_non_cache === 1 ? true : false} />
                                                <label htmlFor="c_lum_non_cache-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_non_cache"
                                                        id="c_lum_non_cache-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_non_cache', 3)}
                                                        checked={values.c_lum_non_cache === 3 ? true : false} />
                                                    <label htmlFor="c_lum_non_cache-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I405
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_cache"
                                                    id="c_lum_non_cache-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_cache', 2)}
                                                    checked={values.c_lum_non_cache === 2 ? true : false} />
                                                <label htmlFor="c_lum_non_cache-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R405
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_non_cache"
                                                    id="c_lum_non_cache-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_non_cache', 4)}
                                                    checked={values.c_lum_non_cache === 4 ? true : false} />
                                                <label htmlFor="c_lum_non_cache-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* Organisme notifie */}
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Couleur Globe autorisée
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_couleur_autorise"
                                                    id="c_lum_couleur_autorise-1"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_couleur_autorise', 1)}
                                                    checked={values.c_lum_couleur_autorise === 1 ? true : false} />
                                                <label htmlFor="c_lum_couleur_autorise-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <OK />
                                                    OK
                                                </label>
                                            </div>
                                        </li>
                                        {interventionInfoGeneral.que_vp === false &&
                                            <li>
                                                <div className="input-radio-item">
                                                    <Field
                                                        type="radio"
                                                        name="c_lum_couleur_autorise"
                                                        id="c_lum_couleur_autorise-4"
                                                        className="sr-only"
                                                        onChange={() => setFieldValue('c_lum_couleur_autorise', 3)}
                                                        checked={values.c_lum_couleur_autorise === 3 ? true : false} />
                                                    <label htmlFor="c_lum_couleur_autorise-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                        <Intervention />
                                                        I406
                                                    </label>
                                                </div>
                                            </li>
                                        }
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_couleur_autorise"
                                                    id="c_lum_couleur_autorise-2"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_couleur_autorise', 2)}
                                                    checked={values.c_lum_couleur_autorise === 2 ? true : false} />
                                                <label htmlFor="c_lum_couleur_autorise-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Erreur />
                                                    R406
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_lum_couleur_autorise"
                                                    id="c_lum_couleur_autorise-3"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_lum_couleur_autorise', 4)}
                                                    checked={values.c_lum_couleur_autorise === 4 ? true : false} />
                                                <label htmlFor="c_lum_couleur_autorise-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <SO />
                                                    SO
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="modele-actions mt-2">
                                    <button type="button" className="modele-btn" onClick={handleAutorisationCouleur}>
                                        Autorisation couleur
                                        <i className="fas fa-external-link-alt ml-2"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            {showAut &&
                <AutorisationCouleurPopup onClose={handleAutorisationCouleur} infos={infos} />
            }
        </div>
    )

}

export default Lumineux