import React, { useEffect, useState } from "react"
import './procedure.scss'
import { supervisionService } from "../../../../services/supervisionService"
import activiteList from "../../../../constants/activiteList"
import { Table } from 'react-bootstrap'
import { formatDate, isSiege, lockBody, unlockBody } from "../../../../helpers/utils"
import PopUpDoc from "../../../PopUpDoc"
import Skeleton from 'react-loading-skeleton'
import { useAuth } from "./../../../../providers/authProvider"
import { storage } from "../../../../helpers/storageHelper"

const Procedure = (props) => {

    const [infos, setInfos] = useState(null)
    const [adherents, setAdherents] = useState(null)
    const [intervenants, setIntervenants] = useState(null)
    const [documentsQualites, setDocumentsQualites] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showPopup, setShowPopup] = useState(false)
    const [modifications, setModifications] = useState("")
    const [numero, setNumero] = useState(null)
    const [activites, setActivites] = useState(activiteList)
    const [showLecture, setShowLecture] = useState(false)
    const [selectedActivite, setSelectedActivite] = useState("all")
    const [currentDoc, setCurrentDoc] = useState(null)
    const { userRole, userGroup } = useAuth()
    const [selectedAdherent, setSelectedAdherent] = useState(parseInt(userGroup))
    const [selectedIntervenant, setSelectedIntervenant] = useState(-1)

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement des infos depuis localforage
                const infosData = await storage.get('infos')
                if (infosData) {
                    setInfos(infosData)
                    if (!isSiege(userRole)) {
                        setSelectedAdherent(userGroup)
                    } else {
                        setSelectedAdherent(infosData.numero_adherent)
                    }
                }

                // Chargement des données initiales
                const loadAdherents = async () => {
                    const response = await supervisionService.getAllAdherent()
                    setAdherents(response)
                }

                const loadIntervenants = async () => {
                    if (selectedAdherent !== "all") {
                        const response = await supervisionService.getAllIntervenant(infosData.numero_adherent)
                        setIntervenants(response)
                    } else {
                        setIntervenants([])
                    }
                }

                const loadDocumentsQualites = async () => {
                    if (parseInt(userGroup) === 0) {
                        const response = await supervisionService.getAllDocumentsQualites()
                        setDocumentsQualites(response)
                    } else {
                        await loadDocumentsQualitesOnChange(userGroup, -1, "all")
                    }
                }

                // Exécution des chargements
                setTimeout(async () => {
                    await Promise.all([
                        loadAdherents(),
                        loadIntervenants(),
                        loadDocumentsQualites()
                    ])
                    setLoading(false)
                }, 1500)

            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
                setLoading(false)
            }
        }

        loadInitialData()
    }, [userRole, userGroup])

    const loadDocumentsQualitesOnChange = async (adh, interv, activ) => {
        try {
            const response = await supervisionService.getAllDocumentsQualites(adh, interv, activ)
            setDocumentsQualites(response)
        } catch (error) {
            process.env.NODE_ENV === "development" && console.log(error)
        }
    }

    const handleChangeAdherent = (event) => {
        var optionValue = parseInt(event.target.value)
        // Mise à jour du champ adherent et intervenant avec la valeur selectionnee
        setSelectedAdherent(optionValue)
        setSelectedIntervenant("")
        // Mise à jour des intervenants
        updateIntervenants(optionValue)
        // Initialise la liste des des documents qualités
        setDocumentsQualites([])
        updateActivites(optionValue)
        setSelectedActivite("all")
    }

    const handleChangeIntervenant = (event) => {
        var optionValue = parseInt(event.target.value)
        // Mise à jour du champ intervenant avec la valeur selectionnee
        setSelectedIntervenant(optionValue)
        // Mise à jour des intervenants
        if (optionValue !== "") {
            if (optionValue === -1) {
                setShowLecture(false)
                loadDocumentsQualitesOnChange(selectedAdherent, optionValue, "all")
            } else {
                setShowLecture(true)
                // Charger les infos lues
                loadDocumentsQualitesOnChange(selectedAdherent, optionValue, "all")
            }
        }
    }

    const updateActivites = (adherent) => {
        if (adherent === "all") {
            setActivites(activiteList)
        } else {
            // On récupère les données de l'adhérent saisies
            let l_adherent = adherents.filter(adh => adh.numero_adherent === parseInt(adherent))[0]
            let l_activites = [{ nom: "Gen", numero: 0 }]
            if (l_adherent.tax === true) {
                l_activites.push({ nom: "Tax", numero: 1 })
            }
            if (l_adherent.tco === true) {
                l_activites.push({ nom: "Tco", numero: 4 })
            }
            if (l_adherent.gaz === true && l_adherent.opa === true) {
                l_activites.push({ nom: "Gzo", numero: 2 })
            }
            if (l_adherent.ead === true) {
                l_activites.push({ nom: "Ead", numero: 5 })
            }
            if (l_adherent.aut === true) {
                l_activites.push({ nom: "Aut", numero: 6 })
            }
            if (l_adherent.numero_adherent === 0) {
                l_activites.push({ nom: "Mtn", numero: 400 })
            }
            setActivites(l_activites)
        }
    }

    const updateIntervenants = async (adherent) => {
        if (adherent === -1) {
            setIntervenants([])
        } else {
            try {
                const response = await supervisionService.getAllIntervenant(adherent)
                setIntervenants(response)
            } catch (error) {
                process.env.NODE_ENV === "development" && console.log(error)
            }
        }
    }

    const handleChangeActivite = (event) => {
        let value = event.target.value
        setSelectedActivite(value)
        loadDocumentsQualitesOnChange(selectedAdherent, selectedIntervenant, value.toString())
    }

    const togglePopup = () => {
        setShowPopup(!showPopup)
        showPopup ? unlockBody() : lockBody()
    }

    const resetListeDocuments = () => {
        if (showLecture === true) {
            loadDocumentsQualitesOnChange(selectedAdherent, selectedIntervenant, selectedActivite)
        }
    }

    if (loading) {
        return <div>Chargement...</div>
    }

    return (
        <div className="infos-section procedure">
            <div className="infos-section-container">
                <div className="section-header">
                    <div className="section-general">
                        {(!loading && adherents && intervenants && activiteList &&
                            <div className="intervenant input-container">
                                <label>Intervenant : </label>
                                <select className="form-control col-sm-2" name="adherent" value={selectedAdherent} onChange={handleChangeAdherent} disabled={!isSiege(userRole)}>
                                    {isSiege(userRole) && <option value="-1">Tous</option>}
                                    {adherents.map((adherent, index) =>
                                        <option key={index} value={adherent.numero_adherent}>
                                            {adherent.identification_adherent}
                                        </option>
                                    )}
                                </select>
                                <select className="form-control col-sm-4" name="intervenant" value={selectedIntervenant} onChange={handleChangeIntervenant}>
                                    <option value=""></option>
                                    <option value="-1">Tous</option>
                                    {intervenants.map((intervenant, index) =>
                                        <option key={index} value={intervenant.numero_intervenant}>
                                            {intervenant.prenom + " " + intervenant.nom}
                                        </option>
                                    )}
                                </select>
                                <label>Activité : </label>
                                <select className="form-control col-sm-2" name="activite" value={selectedActivite} onChange={handleChangeActivite}>
                                    <option value="all">Toutes</option>
                                    {activites && activites.map((activite, index) =>
                                        <option key={index} value={activite.numero}>{activite.nom}</option>
                                    )}
                                </select>
                            </div>) ||
                            <>
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </>
                        }
                    </div>
                </div>
                <div className="section-body mt-4">
                    {(!loading && documentsQualites &&
                        <Table striped hover size="sm">
                            <thead>
                                <tr>
                                    <th>Activité</th>
                                    <th>Codification</th>
                                    <th>V</th>
                                    <th>Publication</th>
                                    <th>Titre</th>
                                    {showLecture && <th>Lecture</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {documentsQualites.map((doc, index) =>
                                    <tr key={index} onClick={() => { setCurrentDoc(doc); togglePopup(); setModifications(doc.modifications_version); setNumero(doc.numero_document_version); }}>
                                        <td>{doc.numero_activite === 0 ? "GEN" :
                                            doc.numero_activite === 1 ? "TAX" :
                                                doc.numero_activite === 2 ? "GZO" :
                                                    doc.numero_activite === 4 ? "TCO" :
                                                        doc.numero_activite === 5 ? "EAD" :
                                                            doc.numero_activite === 6 ? "AUT" :
                                                                doc.numero_activite === 400 ? "MTN" : null}
                                        </td>
                                        <td>{doc.codification_doc}</td>
                                        <td>{doc.numero_version}</td>
                                        <td>{formatDate(doc.date_publication)}</td>
                                        <td>{doc.nom_doc}</td>

                                        {showLecture && <td>{doc.date_lecture ? formatDate(doc.date_lecture) : ""}</td>}
                                    </tr>
                                )}
                            </tbody>
                        </Table>) ||
                        <>
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                            <Skeleton height={25} />
                        </>
                    }
                    {showPopup ?
                        <PopUpDoc close={togglePopup} modifications={modifications} numero={numero} document={currentDoc} resetListeDocuments={resetListeDocuments} adherent={selectedAdherent} intervenant={selectedIntervenant} /> :
                        null
                    }
                </div>
            </div>
        </div>
    )
}

export default Procedure