import React, { useState, useEffect, useRef } from 'react';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import html2pdf from 'html2pdf.js';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar from '../../components/NavBar/NavBar'
import { supervisionService } from '../../services/supervisionService';
import { formatDate } from '../../helpers/utils';

const IndicateurPage = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState('all');
  const [data, setData] = useState(null);
  const [indicateur, setIndicateur] = useState(null);
  const [totalActivite, setTotalActivite] = useState(null);
  const [totalActivitePrecedente, setTotalActivitePrecedente] = useState(null);
  const [pourcentageEvolution, setPourcentageEvolution] = useState(null);
  const [searchType, setSearchType] = useState('year'); // 'year' ou 'date'
  const [startYear, setStartYear] = useState(new Date().getFullYear() - 2);
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [startDate, setStartDate] = useState(new Date('2023-01-01'));
  const [endDate, setEndDate] = useState(new Date('2023-05-31'));
  const [isLoading, setIsLoading] = useState(false);
  const reportRef = useRef(null);

  console.log("indicateur", indicateur)

  // Tableau des noms des mois
  const mois = [
    "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
    "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  useEffect(() => {
    const params = searchType === 'year'
      ? { type: 'year', startYear, endYear }
      : { type: 'date', startDate, endDate };
    supervisionService.testIndicateur2(params)
      .then(response => {
        setIndicateur(response)
        setTotalActivite(getTotals(response, 'current'))
        setTotalActivitePrecedente(getTotals(response, 'previous'))
        setPourcentageEvolution(getEvolution(response))
      })
  }, []);

  const getTotals = (_indicateur, periode = 'current') => {
    if (_indicateur) {
      if (searchType === 'year') {
        if (periode === 'current') {
          return _indicateur.item1[endYear].total
        }
        if (periode === 'previous') {
          return _indicateur.item1[endYear - 1].total
        }
      }
      else {
        if (periode === 'current') {
          return Object.values(_indicateur.item1).map(item => item.tax).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item1).map(item => item.tco).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item1).map(item => item.ead).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item1).map(item => item.gzo).reduce((sum, value) => sum + value, 0)
        }
        if (periode === 'previous') {
          return Object.values(_indicateur.item2).map(item => item.tax).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item2).map(item => item.tco).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item2).map(item => item.ead).reduce((sum, value) => sum + value, 0) +
            Object.values(_indicateur.item2).map(item => item.gzo).reduce((sum, value) => sum + value, 0)
        }
      }
    }
    return 0
  }

  const getEvolution = (_indicateur) => {
    if (_indicateur) {
      if (searchType === 'year') {
        return (((getTotals(_indicateur, 'current') - getTotals(_indicateur, 'previous')) / getTotals(_indicateur, 'previous')) * 100).toFixed(2)
      }
      else {
        return (((getTotals(_indicateur, 'current') - getTotals(_indicateur, 'previous')) / getTotals(_indicateur, 'previous')) * 100).toFixed(2)
      }
    }
    return 0
  }

  const handleSubmit = () => {
    setIsLoading(true);

    // Préparer les paramètres selon le type de recherche
    const params = searchType === 'year'
      ? { type: 'year', startYear, endYear }
      : { type: 'date', startDate, endDate };

    // Appeler le service avec les paramètres
    supervisionService.testIndicateur2(params)
      .then(response => {
        setIndicateur(response);
        setTotalActivite(getTotals(response, 'current'))
        setTotalActivitePrecedente(getTotals(response, 'previous'))
        setPourcentageEvolution(getEvolution(response))
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
        // Vous pouvez ajouter ici une notification d'erreur pour l'utilisateur
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Options pour les graphiques
  const lineChartData = {
    labels: indicateur && (searchType === 'date' ? Object.keys(indicateur.item1).map(key => {
      const [month] = key.split("-"); // Récupère le mois (01, 02, etc.)
      return mois[parseInt(month, 10) - 1]; // Convertit en texte
    }) : searchType === 'year' ? Object.keys(indicateur.item1) : null),
    datasets:
      searchType === 'date' ?
        [
          {
            label: 'Période en cours',
            data: indicateur && Object.values(indicateur.item1).map(item => item.total),
            fill: true,
            backgroundColor: 'rgba(99, 0, 0, 0.1)',
            borderColor: 'rgb(99, 0, 0)',
            tension: 0.4,
            pointRadius: 4,
            pointBackgroundColor: 'rgb(99, 0, 0)',
            pointBorderColor: '#fff',
            pointHoverRadius: 6
          },
          {
            label: 'Période précédente',
            data: indicateur && Object.values(indicateur.item2).map(item => item.total),
            fill: true,
            backgroundColor: 'rgba(99, 102, 241, 0.1)',
            borderColor: 'rgb(99, 102, 241)',
            tension: 0.4,
            pointRadius: 4,
            pointBackgroundColor: 'rgb(99, 102, 241)',
            pointBorderColor: '#fff',
            pointHoverRadius: 6
          },
        ] : searchType === 'year' ?
          [
            {
              label: 'Toutes les stations',
              data: indicateur && Object.values(indicateur.item1).map(item => item.total),
              fill: true,
              backgroundColor: 'rgba(99, 0, 0, 0.1)',
              borderColor: 'rgb(99, 0, 0)',
              tension: 0.4,
              pointRadius: 4,
              pointBackgroundColor: 'rgb(99, 0, 0)',
              pointBorderColor: '#fff',
              pointHoverRadius: 6,
              // Mettre à jour les données win et lost
              winData: indicateur && Object.values(indicateur.item1).map(item => item.win || 0),
              lostData: indicateur && Object.values(indicateur.item1).map(item => item.lost || 0)
            },
          ] : []
  };

  const lineChartActiviteData = {
    labels: indicateur && (searchType === 'date' ? Object.keys(indicateur.item1).map(key => {
      const [month] = key.split("-"); // Récupère le mois (01, 02, etc.)
      return mois[parseInt(month, 10) - 1]; // Convertit en texte
    }) : searchType === 'year' ? Object.keys(indicateur.item1) : null),
    datasets: [
      {
        label: 'TAX',
        data: indicateur && Object.values(indicateur.item1).map(item => item.tax),
        fill: true,
        backgroundColor: 'rgba(99, 102, 241, 0.1)',
        borderColor: 'rgb(99, 102, 241)',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: 'rgb(99, 102, 241)',
        pointBorderColor: '#fff',
        pointHoverRadius: 6
      },
      {
        label: 'TCO',
        data: indicateur && Object.values(indicateur.item1).map(item => item.tco),
        fill: true,
        backgroundColor: 'rgba(16, 185, 129, 0.1)',
        borderColor: 'rgb(16, 185, 129)',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: 'rgb(16, 185, 129)',
        pointBorderColor: '#fff',
        pointHoverRadius: 6
      },
      {
        label: 'EAD',
        data: indicateur && Object.values(indicateur.item1).map(item => item.ead),
        fill: true,
        backgroundColor: 'rgba(245, 158, 11, 0.1)',
        borderColor: 'rgb(245, 158, 11)',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: 'rgb(245, 158, 11)',
        pointBorderColor: '#fff',
        pointHoverRadius: 6
      },
      {
        label: 'GZO',
        data: indicateur && Object.values(indicateur.item1).map(item => item.gzo),
        fill: true,
        backgroundColor: 'rgba(239, 68, 68, 0.1)',
        borderColor: 'rgb(239, 68, 68)',
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: 'rgb(239, 68, 68)',
        pointBorderColor: '#fff',
        pointHoverRadius: 6
      },
    ]
  };

  // const doughnutData = {
  //   labels: ['TAX', 'TCO', 'EAD', 'GZO'],
  //   datasets: [{
  //     data: indicateur && [
  //       Object.values(indicateur.item1).map(item => item.tax).reduce((sum, value) => sum + value, 0),
  //       Object.values(indicateur.item1).map(item => item.tco).reduce((sum, value) => sum + value, 0),
  //       Object.values(indicateur.item1).map(item => item.ead).reduce((sum, value) => sum + value, 0),
  //       Object.values(indicateur.item1).map(item => item.gzo).reduce((sum, value) => sum + value, 0)
  //     ],
  //     backgroundColor: [
  //       'rgba(99, 102, 241, 0.8)',
  //       'rgba(16, 185, 129, 0.8)',
  //       'rgba(245, 158, 11, 0.8)',
  //       'rgba(239, 68, 68, 0.8)',
  //     ],
  //     borderWidth: 0,
  //     hoverOffset: 4
  //   }]
  // };

  const barData = {
    labels: indicateur && Object.keys(indicateur.item1).map(key => {
      const [month] = key.split("-"); // Récupère le mois (01, 02, etc.)
      return mois[parseInt(month, 10) - 1]; // Convertit en texte
    }),
    datasets: [
      {
        label: 'Période en cours',
        data: indicateur && Object.values(indicateur.item1).map(item => item.total),
        backgroundColor: 'rgba(99, 102, 241, 0.8)',
        borderColor: 'rgb(99, 102, 241)',
        borderWidth: 1
      },
      {
        label: 'Période precedente',
        data: indicateur && Object.values(indicateur.item2).map(item => item.total),
        backgroundColor: 'rgba(16, 185, 129, 0.8)',
        borderColor: 'rgb(16, 185, 129)',
        borderWidth: 1
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 10,
          padding: 20,
          font: {
            size: 12
          }
        }
      },
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      backgroundColor: 'rgba(255, 255, 255, 0.95)',
      titleFontColor: '#374151',
      bodyFontColor: '#374151',
      borderColor: '#e5e7eb',
      zIndex: 99,
      borderWidth: 1,
      caretSize: 6,
      caretPadding: 10,
      cornerRadius: 6,
      xPadding: 15,
      position: 'nearest',
      yPadding: 15,
      displayColors: true,
      boxWidth: 8,
      titleFontStyle: 'bold',
      titleFontSize: 14,
      bodyFontSize: 13,
      bodySpacing: 8,
      callbacks: {
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          const label = dataset.label || '';
          const chartId = this._chart.canvas.id;

          let tooltipLines = [];

          if (chartId === 'lineChartActivite') {
            let variation = '';
            let evolution = '';

            if (tooltipItem.index > 0) {
              const previousValue = dataset.data[tooltipItem.index - 1];
              const variationValue = currentValue - previousValue;
              const percentage = ((variationValue / previousValue) * 100).toFixed(1);
              variation = `Variation: ${variationValue > 0 ? '+' : ''}${variationValue}`;
              evolution = `Évolution: ${percentage > 0 ? '+' : ''}${percentage}%`;
            }

            // Retourner tout sur une seule ligne avec la couleur du dataset
            const color = dataset.borderColor;
            return [`${label}: ${currentValue} | ${variation} | ${evolution}`];
          } else {
            tooltipLines.push(`📊 ${label}: ${currentValue} stations`);

            const win = dataset.winData ? dataset.winData[tooltipItem.index] || 0 : 0;
            const lost = dataset.lostData ? dataset.lostData[tooltipItem.index] || 0 : 0;
            tooltipLines.push(`📈 Gains: +${win} stations`);
            tooltipLines.push(`📉 Pertes: -${lost} stations`);

            if (tooltipItem.index > 0) {
              const previousValue = dataset.data[tooltipItem.index - 1];
              const variation = currentValue - previousValue;
              const percentage = ((variation / previousValue) * 100).toFixed(1);
              tooltipLines.push('──────────────────');
              tooltipLines.push(`🔄 Variation: ${variation > 0 ? '+' : ''}${variation} stations`);
              tooltipLines.push(`📊 Évolution: ${percentage > 0 ? '+' : ''}${percentage}%`);
            }

            return tooltipLines;
          }
        },
        title: function (tooltipItems, data) {
          const item = tooltipItems[0];
          const month = data.labels[item.index];
          const chartId = this._chart.canvas.id;

          if (chartId === 'lineChartActivite') {
            const year = new Date().getFullYear();
            return `${year}`;
          }

          return `📅  ${month}  `;
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: 'rgba(0, 0, 0, 0.05)'
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  useEffect(() => {
    const mockData = {
      kpis: {
        totalInterventions: 650,
        avgDuration: "2.5h",
        satisfactionRate: "92%",
        completionRate: "95%"
      }
    };
    setData(mockData);
  }, [selectedYear, selectedMonth]);

  const exportToPDF = () => {
    const element = reportRef.current;
    const opt = {
      margin: 1,
      filename: `rapport-indicateurs-${selectedYear}-${selectedMonth}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().set(opt).from(element).save();
  };

  if (!data) return (
    <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '60vh' }}>
      <div className="spinner-border text-primary" role="status">
      </div>
    </div>
  );

  return (
    <>
      <NavBar />
      <div className='c-mt-5'>
        <Container fluid className="px-4 py-5 bg-light">
          <Container>
            {/* Header */}
            <Row className="mb-4">
              <Col md={12}>
                <h1 className="h2 mb-2">Tableau de bord des entrées / sorties de stations</h1>
                <p className="text-muted mb-4">Visualisez et analysez les données des entrées et sorties</p>

                <div className="d-flex gap-2 align-items-center">
                  <select
                    disabled
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                    className="form-select w-auto px-3 py-2 bg-white border-0 shadow-sm rounded-3 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary"
                    style={{
                      fontSize: '0.95rem',
                      minWidth: '180px',
                      appearance: 'none',
                      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 0.75rem center',
                      backgroundSize: '1rem'
                    }}
                  >
                    <option value="year">Recherche par année</option>
                    <option value="date">Recherche par date</option>
                  </select>

                  {searchType === 'year' ? (
                    <>
                      <select
                        value={startYear}
                        onChange={(e) => setStartYear(parseInt(e.target.value))}
                        className="form-select w-auto px-3 py-2 bg-white border-0 shadow-sm rounded-3 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary"
                        style={{
                          fontSize: '0.95rem',
                          minWidth: '100px',
                          appearance: 'none',
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 0.75rem center',
                          backgroundSize: '1rem'
                        }}
                      >
                        {[2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025].map(year => (
                          <option key={year} value={year}>{year}</option>
                        ))}
                      </select>

                      <select
                        value={endYear}
                        onChange={(e) => setEndYear(parseInt(e.target.value))}
                        className="form-select w-auto px-3 py-2 bg-white border-0 shadow-sm rounded-3 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary"
                        style={{
                          fontSize: '0.95rem',
                          minWidth: '100px',
                          appearance: 'none',
                          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%236b7280' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right 0.75rem center',
                          backgroundSize: '1rem'
                        }}
                      >
                        {[2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025]
                          .filter(year => year >= startYear)
                          .map(year => (
                            <option key={year} value={year}>{year}</option>
                          ))}
                      </select>
                    </>
                  ) : (
                    <>
                      <input
                        type="date"
                        value={formatDate(startDate, 2)}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="form-control w-auto px-3 py-2 bg-white border-0 shadow-sm rounded-3"
                        style={{ fontSize: '0.95rem' }}
                      />

                      <input
                        type="date"
                        value={formatDate(endDate, 2)}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="form-control w-auto px-3 py-2 bg-white border-0 shadow-sm rounded-3"
                        style={{ fontSize: '0.95rem' }}
                        min={startDate}
                      />
                    </>
                  )}

                  <button
                    onClick={handleSubmit}
                    className="btn btn-success d-flex align-items-center gap-2 px-3 py-2 rounded-3 border-0 shadow-sm"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <div className="spinner-border spinner-border-sm" role="status">
                        </div>
                        <span>Chargement...</span>
                      </>
                    ) : (
                      'Valider'
                    )}
                  </button>

                  {/* <button
                    onClick={exportToPDF}
                    className="btn btn-primary d-flex align-items-center gap-2 px-3 py-2 rounded-3 border-0 shadow-sm"
                  >
                    <i className="fas fa-download"></i>
                    Exporter
                  </button> */}
                </div>
              </Col>
            </Row>

            {/* Contenu du rapport */}
            <div ref={reportRef}>
              {/* KPIs */}
              <Row className="g-4 mb-4">
                <Col sm={6} xl={3}>
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                          <h6 className="text-muted mb-0">Total des activités ({searchType === 'year' ? ('en ' + endYear) : ('au ' + formatDate(endDate))})</h6>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="fas fa-bolt text-primary fa-lg"></i>
                        </div>
                      </div>
                      <h3 className="mb-2">{indicateur && totalActivite}</h3>
                      <div className={"small" + (pourcentageEvolution > 0 ? " text-success" : " text-danger")}>
                        {pourcentageEvolution > 0 ? (
                          <i className="fas fa-arrow-up me-1 mr-1"></i>
                        ) : (
                          <i className="fas fa-arrow-down me-1 mr-1"></i>
                        )}
                        {indicateur && pourcentageEvolution}
                        % par rapport à la période précedente
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={6} xl={3}>
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                          <h6 className="text-muted mb-0">Total des activités (période précedente)</h6>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="fas fa-clock text-success fa-lg"></i>
                        </div>
                      </div>
                      <h3 className="mb-2">{totalActivitePrecedente}</h3>
                      <div className="small text-success">
                        {totalActivite - totalActivitePrecedente > 0 ? (
                          <i className="fas fa-arrow-up me-1 mr-1"></i>
                        ) : (
                          <i className="fas fa-arrow-down me-1 mr-1"></i>
                        )}
                        {totalActivite - totalActivitePrecedente > 0 ? (
                          (totalActivite - totalActivitePrecedente) + " station(s) par rapport à la période en cours"
                        ) : (
                          (totalActivite - totalActivitePrecedente) + " station(s) par rapport à la période en cours"
                        )}
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col sm={6} xl={3}>
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                          <h6 className="text-muted mb-0">Taux de Satisfaction</h6>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="fas fa-smile text-warning fa-lg"></i>
                        </div>
                      </div>
                      <h3 className="mb-2">{data.kpis.satisfactionRate}</h3>
                      <div className="small text-success">
                        <i className="fas fa-arrow-up me-1"></i>
                        +2% par rapport au mois dernier
                      </div>
                    </div>
                  </div>
                </Col>

                <Col sm={6} xl={3}>
                  <div className="card h-100 border-0 shadow-sm">
                    <div className="card-body">
                      <div className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                          <h6 className="text-muted mb-0">Taux de Réalisation</h6>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="fas fa-check-circle text-info fa-lg"></i>
                        </div>
                      </div>
                      <h3 className="mb-2">{data.kpis.completionRate}</h3>
                      <div className="small text-muted">
                        <i className="fas fa-minus me-1"></i>
                        Stable par rapport au mois dernier
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>

              {/* Graphiques */}
              <Row className="g-4">
                <Col lg={12}>
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <h5 className="card-title">Évolution des stations</h5>
                      <div style={{ height: '350px' }}>
                        <Line data={lineChartData} options={chartOptions} />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="card border-0 shadow-sm test">
                    <div className="card-body">
                      <h5 className="card-title">Évolution par activité</h5>
                      <div style={{ height: '350px' }}>
                        <Line id="lineChartActivite" data={lineChartActiviteData} options={chartOptions} />
                      </div>
                    </div>
                  </div>
                </Col>

                {/* <Col lg={6}>
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <h5 className="card-title">Répartition par activité</h5>
                      <div style={{ height: '350px' }}>
                        <Doughnut data={doughnutData} options={{ ...chartOptions, cutout: '70%' }} />
                      </div>
                    </div>
                  </div>
                </Col> */}

                {/* <Col lg={12}>
                  <div className="card border-0 shadow-sm">
                    <div className="card-body">
                      <h5 className="card-title">Comparaison annuelle des interventions</h5>
                      <div style={{ height: '350px' }}>
                        <Bar
                          data={barData}
                          options={{
                            ...chartOptions,
                            scales: {
                              y: {
                                beginAtZero: true,
                                grid: {
                                  display: true,
                                  color: 'rgba(0, 0, 0, 0.05)'
                                }
                              },
                              x: {
                                grid: {
                                  display: false
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </div>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default IndicateurPage;