import React, { useState, useEffect } from 'react'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Tabs, Tab } from 'react-bootstrap'
import { supervisionService } from '../../services/supervisionService'
import Checkbox from '@material-ui/core/Checkbox'
import { Accordion, Card } from 'react-bootstrap'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PopUpDocument from './PopUpDocument'
import { useAuth } from '../../providers/authProvider'
import { useRef } from 'react'
import { toast } from 'react-toastify'

const LienRapport = (props) => {

    /* Nouvelle déclearation */
    const [initial, setInitial] = useState({ documentliste: [], lienrapports: [] })
    const [lienrapport] = useState(JSON.parse(localStorage.getItem("lienrapport")))
    const [adherents] = useState(JSON.parse(localStorage.getItem('adherents')) || [])
    const [intervenantsAdherents, setIntervenantsAdherents] = useState([])
    // A voir si cela peut servir lors de la creation d'une nouvelle supervision
    const [initNewLienRapport] = useState(JSON.parse(localStorage.getItem('presaisie')))
    const [rapportsNumber, setRapportsNumber] = useState(0)
    const [rapports, setRapports] = useState([])
    const regex = /[0-9]+/;
    const [showPopUp, setShowPopUp] = useState(false)
    const [index, setIndex] = useState(0)
    const body = document.getElementsByTagName("body")[0]
    const [nomV, setNomV] = useState(null)
    const [nomA, setNomA] = useState(() => {
        const adherentsData = JSON.parse(localStorage.getItem('adherents')) || []
        const presaisieData = JSON.parse(localStorage.getItem('presaisie'))
        return adherentsData.find(adherent => adherent.numero_adherent === presaisieData?.adherent)?.identification_adherent || null
    })
    const [defaultIntervenants, setDefaultIntervenants] = useState([])
    const [defaultRapports, setDefaultRapports] = useState([])
    const [years, setYears] = useState([])
    let auth = useAuth()
    const user = auth.getLocalUser()
    const formikRef = useRef()

    // Style des tables 
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })
    const classes = useStyles()

    const getAllIntervenant = async (numero_adherent, qualif, table) => {
        await supervisionService.getAllIntervenant(numero_adherent, qualif)
            .then(response => table.push(response))
    }

    const getAllNumeroRapport = async (numero_adherent, verificateur, activite, annee, table) => {
        try {
            await supervisionService.getAllNumeroRapport(numero_adherent, verificateur, activite, annee)
                .then(response => table.push(response))
        } catch (error) {
            toast.error(error, { autoClose: 5000, containerId: 'App' })
        }
    }

    const getDefaultIntervenants = async (numero_adherent) => {
        try {
            await supervisionService.getAllIntervenant(numero_adherent)
                .then(response => setDefaultIntervenants(response))
        } catch (error) {
            console.log(error)
            toast.error(error, { autoClose: 5000, containerId: 'App' })
        }
    }

    const getDefaultRapports = async (numero_adherent, verificateur, activite, annee) => {
        try {
            await supervisionService.getAllNumeroRapport(numero_adherent, verificateur, activite, annee)
                .then(response => setDefaultRapports(response))
        } catch (error) {
            console.log(error)
            toast.error(error, { autoClose: 5000, containerId: 'App' })
        }
    }

    // Initialisation des années
    useEffect(() => {
        let currentYear = new Date().getFullYear()
        let _years = [currentYear]
        while (currentYear !== 2010) {
            currentYear -= 1
            _years.push(currentYear)
        }
        setYears(_years)
    }, [])

    /* Fin nouvelle déclaration */
    useEffect(
        // Initialisation des etats
        () => {
            /**
             * Liste contenant deux objets : un lienrapport et un document
             * - lienrapport => liste contenant un objet
             * - document => à venir
             */
            // Chargement d'une supervision
            //process.env.NODE_ENV === "development" && console.log(lienrapport)
            if (lienrapport) {
                let loadedLienRapport = null
                let loadedDocument = null

                //console.log("Il y a des liens rapports")

                // Gestion de la récupération des liens en local et dans la base 
                if (Array.isArray(lienrapport)) {
                    loadedLienRapport = lienrapport[0].lienrapports
                    loadedDocument = lienrapport[0].documentliste
                } else {
                    loadedLienRapport = lienrapport.lienrapports
                    loadedDocument = lienrapport.documentliste
                }

                // Chargement des documents /
                // Chargement de lien rapport d'une supervision
                if (loadedLienRapport && loadedDocument) {
                    setInitial((recent) => {
                        let data = {
                            ...recent,
                            lienrapports: loadedLienRapport,
                            documentliste: loadedDocument
                        }
                        props.passFormToParent(data, {})
                        return data
                    })
                    // Sauvegarde du nombre de rapports chargés
                    setRapportsNumber(loadedLienRapport.length)
                }
                /**
                 * Récupération de tous les intervenants par rapport
                 * à l'adhérent du rapport chargé pour toutes les lignes
                 * intervenants => intervenants de chaque adhérent
                 * qui sera ensuite stockée dans intervenantsAdhérents
                 * qui comprend tous les adhérents avec tous leurs intervenants
                 */
                let intervenants = []
                let l_rapports = []
                loadedLienRapport.forEach((element) => {
                    getAllIntervenant(element.numero_adherent, false, intervenants)
                    getAllNumeroRapport(element.numero_adherent, element.clef_verificateur, initNewLienRapport?.activite, element.numero_rapport_annee, l_rapports)
                })
                setIntervenantsAdherents(intervenants)
                setRapports(l_rapports)
            } else {
                // Aucun rapport au démarrage
                // Aucun document au démarrage
                setInitial((recent) => {
                    let data = {
                        ...recent,
                        lienrapports: [],
                        documentliste: []
                    }
                    props.passFormToParent(data, {})
                    return data
                })
            }
            async function execute() {
                await supervisionService.getIntervenant(initNewLienRapport?.adherent, initNewLienRapport?.intervenant)
                    .then(response => setNomV(response.prenom + " " + response.nom))
            }
            execute()
            getDefaultIntervenants(initNewLienRapport?.adherent)
            getDefaultRapports(initNewLienRapport?.adherent, initNewLienRapport?.intervenant, initNewLienRapport?.activite, (new Date(Date.now())).getFullYear())
        },
        // Dépendances des états
        []
    )

    /**
     * Mettre à jour les intervenants d'un adhérent
     * sélectionné dans le formulaire
     * @param {number} ligne_rapport
     * @param {*} setFieldValue
     */
    const updateVerificateur = (event, ligne_rapport, setFieldValue) => {
        let intervenants = intervenantsAdherents
        // La valeur est un nombre
        if (event.target.value.match(regex) !== null
            && event.target.value.match(regex).length !== 0) {
            (async function execute() {
                try {
                    await supervisionService.getAllIntervenant(event.target.value, false)
                        .then(response => intervenants[ligne_rapport] = response)
                } catch (error) {
                    console.error(error)
                }
            })()
            // Met à jour la liste des intervenants des adhérents
            // du formualaire
            setIntervenantsAdherents(intervenants)
        }
        if (event.target.value === "") {
            intervenants[ligne_rapport] = null
            setIntervenantsAdherents(intervenants)
        }
        // Initialise le champ vérificateur
        setFieldValue("lienrapports[" + ligne_rapport + "].clef_verificateur", "")
    }

    /**
     * Mettre à jour le nom de l'adhérent
     * sélectionné dans le formulaire
     * @param {number} ligne_rapport
     * @param {*} setFieldValue
     */
    const updateNomAdherent = (event, ligne_rapport, setFieldValue) => {
        let index = event.nativeEvent.target.selectedIndex
        setFieldValue("lienrapports[" + ligne_rapport + "].identification_adherent_rapport", event.nativeEvent.target[index].text)
    }

    /**
     * Mettre à jour le nom et prenom 
     * de l'intervenant sélectionné dans le formulaire
     * @param {number} ligne_rapport
     * @param {*} setFieldValue
     */
    const updateNomVerificateur = (event, ligne_rapport, setFieldValue) => {
        let index = event.nativeEvent.target.selectedIndex
        setFieldValue("lienrapports[" + ligne_rapport + "].nom_verificateur_rapport", event.nativeEvent.target[index].text)
    }

    /**
     * Créer une nouvelle ligne de rapport
     * @param {*} values
     * @param {*} setFieldValue
     */
    const createNewRapport = (event, values, setFieldValue) => {
        //process.env.NODE_ENV === 'development' && console.log("Taille de lien rapports " + values.lienrapports.length)
        let intervenants = intervenantsAdherents
        intervenants.push(defaultIntervenants)
        setIntervenantsAdherents(intervenants)
        // Initialisation des champs          
        setInitial((recent) => {
            let newlienrapports = recent.lienrapports
            newlienrapports.push({
                numero_adherent: initNewLienRapport?.adherent,
                numero_intervenant: initNewLienRapport?.intervenant,
                numero_activite: initNewLienRapport?.activite,
                numero_supervision: -1,
                numero_enregistrement: 0,
                clef_adherent: initNewLienRapport?.adherent,
                clef_verificateur: initNewLienRapport?.intervenant,
                identification_adherent_rapport: nomA,
                nom_verificateur_rapport: nomV,
                numero_rapport_annee: (new Date(Date.now())).getFullYear(),
                numero_rapport: -1,
                deblocage_rapport: false
            })
            return {
                ...recent,
                lienrapports: newlienrapports
            }
        })
        //process.env.NODE_ENV === 'development' && console.log(initial)
        // Initialise le champ vérificateur
        setFieldValue("lienrapports[" + (initial.lienrapports.length - 1) + "].clef_verificateur", initNewLienRapport?.intervenant)
        const previousRapports = rapports
        previousRapports.push(defaultRapports)
        setRapports(previousRapports)
    }

    /**
     * Supprimer un rapport des liens rapports
     * de la supervision
     */
    const deleteRapport = (event, values) => {
        if (values.lienrapports.length !== 0) {
            if (window.confirm('Êtes-vous sûr de vouloir supprimer la dernière ligne de rapport' /*: N°' + values.lienrapports[values.lienrapports.length - 1].numero_rapport + " ?"*/)) {
                removeLine(values)
            } else {
                // On fait rien !
            }
        }
    }

    // Supprime une ligne de rapport
    const removeLine = (values) => {
        let tableauFiltre = values.lienrapports.filter((value, index, arr) => { return index < (values.lienrapports.length - 1) })
        // Si on supprime les rapports de depart
        if (tableauFiltre.length < rapportsNumber) {
            // Si tout est supprime
            // initialisation a zero
            // Sinon on enleve un rapport 
            rapportsNumber === 0 ? setRapportsNumber(0) : setRapportsNumber(rapportsNumber - 1)
        }
        // On supprime la liste des intervenants de la dernière indice du tableau
        let tableauIntervenants = intervenantsAdherents
        tableauIntervenants.pop()
        setIntervenantsAdherents(tableauIntervenants)
        setInitial((recent) => {
            return {
                ...recent,
                lienrapports: tableauFiltre
            }
        })
        let rapportsFiltre = rapports.filter((value, index, arr) => { return index < (rapports.length - 1) })
        setRapports(rapportsFiltre)
    }

    const togglePopUp = () => {
        setShowPopUp(!showPopUp)
    }

    const onChangeAdherent = (e, field, ligne_rapport, values, setFieldValue) => {
        const _rapports = [...rapports]
        updateNomAdherent(e, ligne_rapport, setFieldValue)
        updateVerificateur(e, ligne_rapport, setFieldValue)

        if (String(e.target.value).match(regex) !== null &&
            String(e.target.value).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex).length !== 0) {
            let adherent = parseInt(e.target.value)
            let verificateur = parseInt(values.lienrapports[ligne_rapport].clef_verificateur)
            let activite = initNewLienRapport?.activite
            let annee = parseInt(values.lienrapports[ligne_rapport].numero_rapport_annee)
            execute(e, adherent, verificateur, activite, annee, ligne_rapport, _rapports, setFieldValue)
            field.onChange(e)
        }
        setFieldValue(`lienrapports[${ligne_rapport}].numero_adherent`, parseInt(e.target.value))
        setFieldValue(`lienrapports[${ligne_rapport}].clef_adherent`, parseInt(e.target.value))
    }

    const onChangeVerificateur = (e, field, ligne_rapport, values, setFieldValue) => {
        updateNomVerificateur(e, ligne_rapport, setFieldValue)
        const _rapports = [...rapports]

        if (String(values.lienrapports[ligne_rapport].numero_adherent).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_adherent).match(regex).length !== 0 &&
            String(e.target.value).match(regex) !== null &&
            String(e.target.value).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex).length !== 0) {
            let adherent = parseInt(values.lienrapports[ligne_rapport].numero_adherent)
            let verificateur = parseInt(e.target.value)
            let activite = initNewLienRapport?.activite
            let annee = parseInt(values.lienrapports[ligne_rapport].numero_rapport_annee)
            execute(e, adherent, verificateur, activite, annee, ligne_rapport, _rapports, setFieldValue)
            field.onChange(e)
        }
        setFieldValue(`lienrapports[${ligne_rapport}].clef_verificateur`, !isNaN(e.target.value) ? parseInt(e.target.value) : null)
    }

    const onChangeRapportAnnee = (e, field, ligne_rapport, values, setFieldValue) => {
        const _rapports = [...rapports]
        if (String(values.lienrapports[ligne_rapport].numero_adherent).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_adherent).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex).length !== 0 &&
            String(e.target.value).match(regex) !== null &&
            String(e.target.value).match(regex).length !== 0) {
            let adherent = parseInt(values.lienrapports[ligne_rapport].numero_adherent)
            let verificateur = parseInt(values.lienrapports[ligne_rapport].clef_verificateur)
            let activite = initNewLienRapport?.activite
            let annee = parseInt(e.target.value)
            execute(e, adherent, verificateur, activite, annee, ligne_rapport, _rapports, setFieldValue)
            field.onChange(e)
        }
    }

    const execute = async (event, adherent, verificateur, activite, annee, ligne_rapport, table, setFieldValue) => {
        try {
            const response = await supervisionService.getAllNumeroRapport(adherent, verificateur, activite, annee)
            //console.log(response)
            if (table[ligne_rapport]) {
                table.splice(ligne_rapport, 1, response)
                //console.log(table)
            }
            else {
                table.splice(ligne_rapport, 0, response)
                //console.log(table)
            }
            setRapports(table)
            setFieldValue("lienrapports[" + ligne_rapport + "].numero_rapport", "-1")
        } catch (error) {
            console.log(error)
            toast.error(error, { autoClose: 5000, containerId: 'App' })
        }
    }

    if (showPopUp) {
        body.classList.add("no-scroll")
    } else {
        body.classList.remove("no-scroll")
        body.removeAttribute("class")
    }

    const reloadRapport = async (values, ligne_rapport, table) => {
        let adherent = parseInt(values.lienrapports[ligne_rapport].numero_adherent)
        let verificateur = parseInt(values.lienrapports[ligne_rapport].clef_verificateur)
        let activite = initNewLienRapport?.activite
        let annee = parseInt(values.lienrapports[ligne_rapport].numero_rapport_annee)
        //execute(e, adherent, verificateur, activite, annee, ligne_rapport, _rapports, setFieldValue)
        try {
            const response = await supervisionService.getAllNumeroRapport(adherent, verificateur, activite, annee)
            //console.log(response)
            if (table[ligne_rapport]) {
                table.splice(ligne_rapport, 1, response)
                //console.log(table)
            }
            else {
                table.splice(ligne_rapport, 0, response)
                //console.log(table)
            }
            setRapports(table)
        } catch (error) {
            console.log(error)
            toast.error(error, { autoClose: 5000, containerId: 'App' })
        }
    }

    const handleClickReload = (e, values, ligne_rapport, field) => {
        let _rapports = [...rapports]
        if (String(values.lienrapports[ligne_rapport].numero_adherent).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_adherent).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].clef_verificateur).match(regex).length !== 0 &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex) !== null &&
            String(values.lienrapports[ligne_rapport].numero_rapport_annee).match(regex).length !== 0) {
            reloadRapport(values, ligne_rapport, _rapports)
            formikRef.current.setFieldValue("lienrapports[" + ligne_rapport + "].numero_rapport_annee", values.lienrapports[ligne_rapport].numero_rapport_annee)
        }
    }

    return (
        <div className="lienrapport">
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={initial}
                validate={(values) => {
                    const errors = {}
                    props.passFormToParent(values, errors)
                    //process.env.NODE_ENV === "development" && console.log(values)
                    localStorage.setItem("lienrapport", JSON.stringify(values))
                }}
            >
                {({ values, setValues, setFieldValue, handleChange, handleBlur }) => (
                    <Form>
                        <Tabs defaultActiveKey="bonjour" id="uncontrolled-tab-example">
                            <Tab tabClassName="tab-supervision" eventKey="bonjour" title={<div className="tab-container" ><p>Rapport</p></div>}>
                                <FieldArray name='lienrapports'>
                                    {({ push, remove }) => (
                                        <div>
                                            {values.lienrapports.map((lienrapport, i) => {
                                                // On peut mettre des variables ici
                                                return (
                                                    <Accordion key={i}>
                                                        <Card className="lienrapport-card">
                                                            <Accordion.Toggle as={Card.Header} className={"lienrapport-card-header " + ((i >= rapportsNumber) ? "card-new" : "")} eventKey={"" + i + ""}>
                                                                <label>Rapport : {lienrapport.numero_rapport === -1 || lienrapport.numero_rapport === "-1" ? "Nouveau" : lienrapport.numero_rapport}</label>
                                                                <label>{lienrapport.nom_verificateur_rapport + " - " + lienrapport.identification_adherent_rapport}</label>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={"" + i + ""}>
                                                                <Card.Body>
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                {/* ------------------ */}
                                                                                {/* Tous les adhérents */}
                                                                                <label htmlFor={`lienrapports[${i}].numero_adherent`}>Adherent</label>
                                                                                <Field name={`lienrapports[${i}].numero_adherent`} >
                                                                                    {({ field }) => (
                                                                                        <select {...field} className="form-control" onChange={e => onChangeAdherent(e, field, i, values, setFieldValue)}>
                                                                                            <option value="">------</option>
                                                                                            {adherents && adherents.map((adherent, i) =>
                                                                                                <option key={i} value={adherent.numero_adherent}>
                                                                                                    {adherent.identification_adherent}
                                                                                                </option>
                                                                                            )}
                                                                                        </select>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            <div className="col">
                                                                                {/* ------------------ */}
                                                                                {/* Vérificateur*/}
                                                                                <label htmlFor={`lienrapports[${i}].clef_verificateur`}>Vérificateur</label>
                                                                                <Field name={`lienrapports[${i}].clef_verificateur`}>
                                                                                    {({ field }) => (
                                                                                        <select {...field} className="form-control" onChange={e => onChangeVerificateur(e, field, i, values, setFieldValue)}>
                                                                                            <option value="empty"> ------ </option>
                                                                                            {intervenantsAdherents[i] && intervenantsAdherents[i].map((intervenant, j) =>
                                                                                                <option key={j} value={intervenant.numero_intervenant}>
                                                                                                    {intervenant.prenom + ' ' + intervenant.nom}
                                                                                                </option>
                                                                                            )}
                                                                                        </select>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                {/* ------------------ */}
                                                                                {/* Toutes les années */}
                                                                                <label htmlFor={`lienrapports[${i}].numero_rapport_annee`}>Année</label>
                                                                                <Field name={`lienrapports[${i}].numero_rapport_annee`}>
                                                                                    {({ field }) => (
                                                                                        <select {...field} className="form-control" onChange={e => onChangeRapportAnnee(e, field, i, values, setFieldValue)} >
                                                                                            <option value=""> ------ </option>
                                                                                            {years && years.map((year, i) =>
                                                                                                <option key={i} value={year}>{year}</option>
                                                                                            )}
                                                                                        </select>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            <div className="col">
                                                                                {/* ------------------ */}
                                                                                {/* Numéro rapport*/}
                                                                                <label htmlFor={`lienrapports[${i}].numero_rapport`}>N° Rapport</label>
                                                                                <Field name={`lienrapports[${i}].numero_rapport`} >
                                                                                    {({ field }) => (
                                                                                        <>
                                                                                            <select {...field} className="form-control">
                                                                                                <option value="-1"> ------ </option>
                                                                                                {rapports[i] && rapports[i].map((rapport, j) =>
                                                                                                    <option key={j} value={rapport.numero_rapport}>
                                                                                                        {rapport.numero_rapport}
                                                                                                    </option>
                                                                                                )}
                                                                                            </select>
                                                                                            <button className="reload-button" type='button' onClick={e => handleClickReload(e, values, i, field)} >
                                                                                                <i className="fa fa-repeat"></i>
                                                                                            </button>
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            {/* ------------------ */}
                                                                            {/* Validation des checkbox */}
                                                                            <div className="col text-center">
                                                                                <label htmlFor={`lienrapports[${i}].deblocage_rapport`}>Validation</label>
                                                                                <Checkbox
                                                                                    name={"lienrapports[" + i + "].deblocage_rapport"}
                                                                                    value={lienrapport.deblocage_rapport}
                                                                                    checked={lienrapport.deblocage_rapport}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    inputProps={{
                                                                                        'aria-label': 'primary checkbox'
                                                                                    }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                )
                                            })}
                                        </div>
                                    )}
                                </FieldArray>
                                <div className="lienrapport-button-container">
                                    <div className="action-button-container">
                                        <button type="button" className="btn action-button add-button overflow-hidden" onClick={e => createNewRapport(e, values, setFieldValue)}>
                                            <i className="fas fa-plus"></i>
                                        </button>
                                        <button type="button" className="btn action-button delete-button overflow-hidden" onClick={e => deleteRapport(e, values)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </Tab>

                            {/* Section Document */}
                            <Tab tabClassName="tab-supervision" eventKey="hello" title={<div className="tab-container" ><p>Documents</p></div>}>

                                <div className="table-container">
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Documents <span className="details-item">(Scan Supervision et PJs)</span></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <div className="button-document-container">
                                                            {/* Premier fichier PDF */}
                                                            <div className="rapport-sup-button">
                                                                <button type="button" className="btn btn-document" onClick={e => { togglePopUp(); setIndex(0) }}>
                                                                    <span>{values && values.documentliste[0] && values.documentliste[0].fichier_contenu ? values.documentliste[0].nom_fichier : "Document 1 (vide)"}</span>
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </button>
                                                                {showPopUp === true &&
                                                                    <PopUpDocument id={index} close={togglePopUp} initial={values} setInitial={setInitial} setFieldValue={setFieldValue} handleBlur={handleBlur} activite={initNewLienRapport?.activite} superviseur={user} passFormToParent={props.passFormToParent} />
                                                                }
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell component="th" scope="row">
                                                        <div className="button-document-container">
                                                            {/* Deuxième fichier PDF */}
                                                            <div className="rapport-sup-button">
                                                                <button type="button" className="btn btn-document" onClick={e => { togglePopUp(); setIndex(1) }}>
                                                                    <span>{values && values.documentliste[1] && values.documentliste[1].fichier_contenu ? values.documentliste[1].nom_fichier : "Document 2 (vide)"}</span>
                                                                    <i className="fas fa-external-link-alt"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </Tab>
                        </Tabs>
                    </Form>
                )}
            </Formik>
        </div >
    )
}

export default LienRapport