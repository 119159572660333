import localforage from 'localforage'

export const storage = {
    async get(key) {
        try {
            return await localforage.getItem(key)
        } catch (error) {
            console.error(`Erreur lors de la récupération de ${key}:`, error)
            return null
        }
    },

    async set(key, value) {
        try {
            await localforage.setItem(key, value)
        } catch (error) {
            console.error(`Erreur lors de la sauvegarde de ${key}:`, error)
            throw error
        }
    },

    async remove(key) {
        try {
            await localforage.removeItem(key)
        } catch (error) {
            console.error(`Erreur lors de la suppression de ${key}:`, error)
            throw error
        }
    },

    async clear() {
        try {
            await localforage.clear()
        } catch (error) {
            console.error('Erreur lors du nettoyage du storage:', error)
            throw error
        }
    }
}