import { Table } from 'react-bootstrap'
import { Formik, Form, Field } from 'formik'
import { useSaisieContext } from '../SaisieContext'
import { useEffect, useState } from 'react'
import { ReactComponent as OK } from './../../../../res/checked.svg'
import { ReactComponent as Erreur } from './../../../../res/siren.svg'
import { ReactComponent as SO } from './../../../../res/erreur.svg'
import { ReactComponent as Intervention } from './../../../../res/alerte.svg'
import { makeStyles } from '@material-ui/core/styles'
import { Table as Table2 } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import imageCompression from 'browser-image-compression'
import localForage from 'localforage'
import { isNullOrUndefined } from '../../../../helpers/utils'
import { dataService } from '../../../../helpers/dataService'
import { CSSTransition } from 'react-transition-group'
import { IoMdCloseCircle } from "react-icons/io"
import { supervisionService } from '../../../../services/supervisionService'
import { toast } from 'react-toastify'
import { useDataContext } from '../../../../providers/DataContextProvider'
import { storage } from '../../../../helpers/storageHelper'

const Imprimante = () => {

    const [imprimante, setImprimante] = useState(null)
    const [imprimante_intervention, setImprimante_intervention] = useState(null)
    const [infos, setInfos] = useState(null)
    const { setFormPhotos, setFormVerif, setFormIntervention } = useSaisieContext()
    const { interventionInfoGeneral } = useDataContext()
    const [errors, setErrors] = useState(null)
    const [photo, setPhoto] = useState({ open: false, photo_20: null })
    const [loading, setLoading] = useState(true)

    // Initialisation de la photo
    useEffect(() => {
        const loadInitialData = async () => {
            try {
                // Chargement parallèle des données
                const [taximetreData, interventionData, infosData] = await Promise.all([
                    storage.get('taximetre'),
                    storage.get('intervention'),
                    storage.get('infos'),
                ])

                if (taximetreData) {
                    setImprimante(taximetreData)
                }
                if (interventionData) {
                    setImprimante_intervention(interventionData)
                }
                if (infosData) {
                    setInfos(infosData)
                }

                localForage.getItem('photos', (err, value) => {
                    if (value !== null || value.length !== 0) {
                        const foundedPhoto = value.find(photo => photo.numero_photo === 20)
                        if (!isNullOrUndefined(foundedPhoto)) {
                            setPhoto({ ...photo, photo_20: foundedPhoto.photo_detail })
                        }
                        else {
                            setPhoto({ ...photo, photo_20: null })
                        }
                    }
                })
            } catch (error) {
                console.error('Erreur lors du chargement des données:', error)
            } finally {
                setLoading(false)
            }
        }

        loadInitialData()
    }, [])

    // Gestion changement des résultats imprimantes
    const handleChangeRst = (e, setFieldValue) => {
        let name = e.target.name
        let value = parseInt(e.target.value)
        setFieldValue(name, value)
    }

    // Ouvrir image sur PopUp
    const openImage = (event, index) => {
        setPhoto({
            ...photo,
            open: true,
        })
    }

    // Fermer image sur PopUp
    const closeImage = () => {
        setPhoto({
            ...photo,
            open: false,
        })
    }

    // Gestion de l'ajout d'une image
    const uploadPhoto = async (event, setFieldValue, index) => {
        const file = event.currentTarget.files[0]
        if (file) {
            await compressImage(file, setFieldValue, index)
        }
    }

    // Compression ou réduction qualité image
    const compressImage = async (file, setFieldValue, index) => {
        try {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                initialQuality: 0.8,
            }

            const compressedFile = await imageCompression(file, options)
            const reader = new FileReader()

            reader.readAsDataURL(compressedFile)
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1]
                setPhoto({
                    ...photo,
                    photo_20: base64String,
                })
                setFieldValue("photo_20", base64String)

                let photosArray = []
                localForage.getItem('photos', (err, value) => {
                    photosArray = value
                    let photoEdited = photosArray.find(photo => photo.numero_photo === 20)

                    if (!isNullOrUndefined(photoEdited)) {
                        photoEdited.photo_detail = base64String
                        let newPhotosArray = photosArray.filter(photo => photo.numero_photo !== 20)
                        newPhotosArray.push(photoEdited)
                        photosArray = newPhotosArray
                    } else {
                        dataService.getIntervention().subscribe(store => {
                            if (store.currentInt) {
                                photosArray.push({
                                    clef_lien: store.currentInt.clef_lien,
                                    clef_stationnement: store.currentInt.clef_stationnement,
                                    numero_adherent: store.currentInt.numero_adherent,
                                    numero_client: store.currentInt.numero_client,
                                    numero_intervention: store.currentInt.numero_intervention,
                                    numero_photo: 20,
                                    photo_detail: base64String
                                })
                            }
                        })
                    }
                    localForage.setItem('photos', photosArray)
                    setFormPhotos(photosArray)
                })
            }
        } catch (error) {
            console.error('Erreur lors de la compression de l\'image:', error)
            toast.error('Erreur lors de la compression de l\'image')
        }
    }

    // Gestion suppression de la photo
    const handleDelete = (event, setFieldValue) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer la photo ?')) {
            setPhoto({
                ...photo,
                photo_20: null
            })
            setFieldValue("photo_20", null)
            let photosArray = []
            localForage.getItem('photos', (err, value) => {
                photosArray = value
                photosArray = photosArray.filter(photo => photo.numero_photo !== 20)
                setFormPhotos(photosArray)
                localForage.setItem('photos', photosArray)
            })
        }
    }

    const handleEgalPrecedent = (e, setValues) => {
        const data = {
            numero_adherent: imprimante_intervention.numero_adherent,
            numero_client: imprimante_intervention.numero_client,
            clef_stationnement: imprimante_intervention.clef_stationnement,
            clef_lien: imprimante_intervention.clef_lien,
            numero_intervention: imprimante_intervention.numero_intervention,
            date_intervention: imprimante_intervention.date_intervention,
        }

        supervisionService.getIntervention(data)
            .then(response => {
                setValues(prev => ({
                    ...prev,
                    imprimante1: parseInt(Array.from(response.controle_imprimante)[0]),
                    imprimante2: parseInt(Array.from(response.controle_imprimante)[1]),
                    imprimante3: parseInt(Array.from(response.controle_imprimante)[2]),
                    imprimante4: parseInt(Array.from(response.controle_imprimante)[3]),
                    imprimante5: parseInt(Array.from(response.controle_imprimante)[4]),
                    imprimante6: parseInt(Array.from(response.controle_imprimante)[5]),
                    imprimante7: parseInt(Array.from(response.controle_imprimante)[6]),
                    imprimante8: parseInt(Array.from(response.controle_imprimante)[7]),
                    imprimante9: parseInt(Array.from(response.controle_imprimante)[8]),
                    imprimante10: parseInt(Array.from(response.controle_imprimante)[9]),
                    imprimante11: parseInt(Array.from(response.controle_imprimante)[10]),
                }))
                //console.log(response)
                toast.success("Dernière intervention trouvée. Modification effectuée.", { containerId: 'PRE' })
            })
            .catch(error => toast.info(error, { containerId: 'PRE' }))
    }

    // Style de la table
    const useStyles = makeStyles({
        table: {
            width: "100%",
        },
    })

    const classes = useStyles()

    if (loading || !imprimante || !imprimante_intervention || !infos) {
        return <div>Chargement...</div>
    }

    return (
        <div className="imprimante-section">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={{
                    c_r91: imprimante.c_r91,
                    imprimante1: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[0]),
                    imprimante2: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[1]),
                    imprimante3: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[2]),
                    imprimante4: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[3]),
                    imprimante5: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[4]),
                    imprimante6: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[5]),
                    imprimante7: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[6]),
                    imprimante8: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[7]),
                    imprimante9: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[8]),
                    imprimante10: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[9]),
                    imprimante11: imprimante_intervention.controle_imprimante === "" || imprimante_intervention.controle_imprimante === null ? "" : parseInt(Array.from(imprimante_intervention.controle_imprimante)[10]),
                    photo_20: photo.photo_20
                }}
                validate={async (values) => {
                    // Validation pour voir les changements sur le formulaire
                    // et activer ou non les boutons d'enregistrement
                    // Utilise pour la partie Autres
                    const errors = {}
                    const taximetre = await storage.get('taximetre')
                    const intervention = await storage.get('intervention')
                    // Mise à jour des taximetres
                    taximetre.c_r91 = values.c_r91
                    let text = ""
                    for (var i = 1; i < 12; i++) {
                        text += values["imprimante" + i]
                    }
                    intervention.controle_imprimante = text
                    // Partage des données
                    // Mise à jour de l'etat global
                    setFormVerif(taximetre)
                    setFormIntervention(intervention)
                    setErrors({ ...errors })
                    // Mise à jour de localStorage
                    await storage.set('taximetre', taximetre)
                    await storage.set('intervention', intervention)
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        process.env.NODE_ENV === "development" && console.log(values)
                        //props.passFormToParent(values)
                        setSubmitting(false)
                    }, 400)
                }}
            >
                {({ values, setFieldValue, setValues }) => (
                    <Form>
                        {/* Egale precedent */}
                        <div className="section-precedent">
                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={e => handleEgalPrecedent(e, setValues)}>Egal précédent</button>
                        </div>
                        {/*---------- Note d'impression ---------- */}
                        <div className="form-row">
                            <div className="form-cell-label">
                                <div className="form-text">
                                    <div className="indication-title">
                                        Note d'impression
                                    </div>
                                </div>
                            </div>
                            <div className="form-cell-input">
                                <ul>
                                    <li>
                                        <div className="input-radio-item">
                                            <Field
                                                type="radio"
                                                name="c_r91"
                                                id="c_r91-1"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_r91', 1)}
                                                checked={values.c_r91 === 1 ? true : false} />
                                            <label htmlFor="c_r91-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <OK />
                                                OK
                                            </label>
                                        </div>
                                    </li>
                                    {interventionInfoGeneral.que_vp === false &&
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="c_r91"
                                                    id="c_r91-4"
                                                    className="sr-only"
                                                    onChange={() => setFieldValue('c_r91', 3)}
                                                    checked={values.c_r91 === 3 ? true : false} />
                                                <label htmlFor="c_r91-4" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    <Intervention />
                                                    I91
                                                </label>
                                            </div>
                                        </li>
                                    }
                                    <li>
                                        <div className="input-radio-item">
                                            <Field
                                                type="radio"
                                                name="c_r91"
                                                id="c_r91-2"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_r91', 2)}
                                                checked={values.c_r91 === 2 ? true : false} />
                                            <label htmlFor="c_r91-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <Erreur />
                                                R91
                                            </label>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="input-radio-item">
                                            <Field
                                                type="radio"
                                                name="c_r91"
                                                id="c_r91-3"
                                                className="sr-only"
                                                onChange={() => setFieldValue('c_r91', 0)}
                                                checked={(values.c_r91 === 0 || values.c_r91 === 4) ? true : false} />
                                            <label htmlFor="c_r91-3" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                <SO />
                                                ?
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <CSSTransition
                            in={photo.open}
                            timeout={300}
                            onExit={closeImage}
                            classNames="exemple"
                            unmountOnExit
                        >
                            <div className={"show-image" + (photo.open === true ? " show" : "")}>
                                <button className="btn" onClick={closeImage} type="button">
                                    <IoMdCloseCircle />
                                </button>
                                <div className="photo-saisie">
                                    <input type="checkbox" className="zoomCheck" id="zoomCheck1" />
                                    <label htmlFor="zoomCheck1">
                                        <img src={photo && photo.photo_20 ? "data:image/jpeg;base64," + photo.photo_20 : ""} alt="Pas de photo1 pour cette saisie" />
                                    </label>
                                </div>
                            </div>
                        </CSSTransition>
                        {/*---------- Photo Ticket ---------- */}
                        <TableContainer component={Paper} className="mb-5">
                            <Table2 className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Photo Ticket</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            <div className="button-document-container">
                                                {values && values.photo_20 ?
                                                    <button id="photo_20" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                                        Photo 1
                                                        <i className="fas fa-eye"></i>
                                                    </button>
                                                    :
                                                    "Pas encore de photo de ticket"
                                                }
                                            </div>
                                            <div className="action-button-container">
                                                <input
                                                    type="file"
                                                    name="photo_20"
                                                    id="photo_20"
                                                    className="photo-input"
                                                    accept="image/x-png,image/gif,image/jpeg"
                                                    onChange={e => uploadPhoto(e, setFieldValue, 1)}
                                                    key={Date.now() + 1}
                                                />
                                                <label htmlFor="photo_20">Ajouter ou modifier</label>
                                                <button id="del1" className="photo-action-button" type="button" onClick={e => handleDelete(e, setFieldValue)}>
                                                    <i className="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table2>
                        </TableContainer>
                        {/*---------- Tableau ---------- */}
                        <div className="form-row">
                            <Table hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Mention</th>
                                        <th>Type</th>
                                        <th>Résultat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Date de Rédaction de la note</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante1-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name="imprimante1"
                                                            type="radio"
                                                            checked={values.imprimante1 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante1-1">Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante1-2"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name="imprimante1"
                                                            type="radio"
                                                            checked={values.imprimante1 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante1-2">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Heures Début et Fin de course</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante2-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante2"}
                                                            type="radio"
                                                            checked={values.imprimante2 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante2-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante2-2"}
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante2"}
                                                            type="radio"
                                                            checked={values.imprimante2 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante2-2"}>Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Prestataire/société : {infos.nom_client}</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante3-1"}
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante3"}
                                                            type="radio"
                                                            checked={values.imprimante3 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante3-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante3-2"}
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante3"}
                                                            type="radio"
                                                            checked={values.imprimante3 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante3-2"}>Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Adresse Réclamation (voir ci-dessous)</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante4-1"}
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante4"}
                                                            type="radio"
                                                            checked={values.imprimante4 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante4-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante4-2"}
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante4"}
                                                            type="radio"
                                                            checked={values.imprimante4 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante4-2"}>Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Montant course minimum</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante5-1"}
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante5"}
                                                            type="radio"
                                                            checked={values.imprimante5 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante5-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante5-2"}
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante5"}
                                                            type="radio"
                                                            checked={values.imprimante5 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante5-2"}>Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Px Course TTC (hors suppléments)</td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id={"imprimante6-1"}
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante6"}
                                                            type="radio"
                                                            checked={values.imprimante6 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante6-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante6-2"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante6"}
                                                            type="radio"
                                                            checked={values.imprimante6 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante6-2"}>Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Somme Totale TTC (avec suppléments)</td>
                                        <td>Imprimé/Manuscrit</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante7-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name={"imprimante7"}
                                                            type="radio"
                                                            checked={values.imprimante7 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante7-1"}>Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante7-2"
                                                            className="checkbox-rst checkbox-rst-2"
                                                            name={"imprimante7"}
                                                            type="radio"
                                                            checked={values.imprimante7 === 1 ? true : false}
                                                            value={1}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor={"imprimante7-2"}>Manuscrit</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante7-3"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name={"imprimante7"}
                                                            type="radio"
                                                            checked={values.imprimante7 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante7-3">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Détail chaque majoration</td>
                                        <td>Imprimé/Manuscrit</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante8-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name="imprimante8"
                                                            type="radio"
                                                            checked={values.imprimante8 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante8-1">Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante8-2"
                                                            className="checkbox-rst checkbox-rst-2"
                                                            name={"imprimante8"}
                                                            type="radio"
                                                            checked={values.imprimante8 === 1 ? true : false}
                                                            value={1}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante8-2">Manuscrit</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante8-3"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name="imprimante8"
                                                            type="radio"
                                                            checked={values.imprimante8 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante8-3">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Nom du Client</td>
                                        <td>Imprimé/Manuscrit</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante9-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name="imprimante9"
                                                            type="radio"
                                                            checked={values.imprimante9 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante9-1">Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante9-2"
                                                            className="checkbox-rst checkbox-rst-2"
                                                            name="imprimante9"
                                                            type="radio"
                                                            checked={values.imprimante9 === 1 ? true : false}
                                                            value={1}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante9-2">Manuscrit</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante9-3"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name="imprimante9"
                                                            type="radio"
                                                            checked={values.imprimante9 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante9-3">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Lieux départ et arrivée</td>
                                        <td>Imprimé/Manuscrit</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante10-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name="imprimante10"
                                                            type="radio"
                                                            checked={values.imprimante10 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante10-1">Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante10-2"
                                                            className="checkbox-rst checkbox-rst-2"
                                                            name="imprimante10"
                                                            type="radio"
                                                            checked={values.imprimante10 === 1 ? true : false}
                                                            value={1}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante10-2">Manuscrit</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante10-3"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name="imprimante10"
                                                            type="radio"
                                                            checked={values.imprimante10 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante10-3">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Immatriculation : {infos.stationnements[0].vehiculeTaximetres[0].vehicule.immatriculation_vehicule} </td>
                                        <td>Imprimée</td>
                                        <td>
                                            <div className="form-group rst">
                                                <div className="rst-inputs">
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante11-1"
                                                            className="checkbox-rst checkbox-rst-1"
                                                            name="imprimante11"
                                                            type="radio"
                                                            checked={values.imprimante11 === 2 ? true : false}
                                                            value={2}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante11-1">Imprimé</label>
                                                    </div>
                                                    <div className="inputGroup">
                                                        <Field
                                                            id="imprimante11-2"
                                                            className="checkbox-rst checkbox-rst-3"
                                                            name="imprimante11"
                                                            type="radio"
                                                            checked={values.imprimante11 === 3 ? true : false}
                                                            value={3}
                                                            onChange={e => handleChangeRst(e, setFieldValue)}
                                                        />
                                                        <label htmlFor="imprimante11-2">Non conforme</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )

}

export default Imprimante