import { useState, useEffect } from "react"
import { Field } from 'formik'
import { supervisionService } from "../../../../services/supervisionService"
import { afficheCouleur } from "../../../../helpers/utils"
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { IoMdClose } from "react-icons/io"

const useStyles = makeStyles({
    table: {
        minWidth: 'auto',
    },
})

const Etiquettek = ({ infos, title, close }) => {

    const classes = useStyles()
    const [arretes, setArretes] = useState(null)
    const [departements, setDepartements] = useState(null)
    const [selectedValue, setSelectedValue] = useState("F42020")
    const [selectedLettre, setSelectedLettre] = useState("F")
    const [selectedCouleur, setSelectedCouleur] = useState("4")
    const [selectedDepartement, setSelectedDepartement] = useState("")

    const getArretes = async (dept) => {
        await supervisionService.getAllArreteNationalDpt(dept)
            .then(response => {
                setArretes(response)
            })
    }

    const getAllDepartement = async () => {
        await supervisionService.getAllDepartement()
            .then(response => {
                setDepartements(response)
            })
    }

    useEffect(() => {
        getArretes(parseInt((infos.stationnements[0].cp).substring(0, 2)))
        getAllDepartement()
    }, [])

    const handleChangeLettre = (e) => {
        let value = e.target.value
        setSelectedValue(value)
        setSelectedLettre(value.substring(0, 1))
        setSelectedCouleur(value.substring(1, 2))
    }

    const handleChangeDepartement = (e) => {
        let value = e.target.value
        if (value !== "0") {
            setSelectedDepartement(value)
        } else {
            setSelectedDepartement("")
        }
    }

    return (
        <div className="popup-cover">
            <div className="popup-area">
                <div className="popup-header">
                    <h3 className="title">
                        {title}
                    </h3>
                    <div className="popup-close-button" onClick={close}>
                        <IoMdClose className="Comet-icon-close-menu" />
                    </div>
                </div>
                <div className="popup-main">
                    <div className="document-infos c-mt-2 c-mb-2">
                        <>
                            <div className="modele-table mb-3">
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Positions</TableCell>
                                                <TableCell align="right">X (%)</TableCell>
                                                <TableCell align="right">Y (%)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Position k</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_k_1}</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_k_2}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Position Dépt</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_dpt_1}</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_dpt_2}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Position Lettre</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_lettre_1}</TableCell>
                                                <TableCell align="right">{infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.position_lettre_2}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div className="etiquette-image">
                                <span className="dpt">{selectedDepartement}</span>
                                {
                                    infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logo_k !== undefined &&
                                    <img src={`data:image/jpeg;base64,${infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.logo_k}`} alt="Etiquette" />
                                }
                                <h1 className="lettre" style={{ color: afficheCouleur(parseInt(selectedCouleur)).color }}>{selectedLettre}</h1>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="form-col">
                                        <label htmlFor="dam_cet">Lettre</label>
                                        <Field name="lettre" as="select" className="form-control" autoComplete="off" onChange={handleChangeLettre} value={selectedValue}>
                                            {arretes && arretes.map((arrete, n) =>
                                                <option key={n} value={arrete.lettre + arrete.couleur_lettre + (new Date(arrete.date_arrete_national)).getFullYear()}>{arrete.lettre + " " + afficheCouleur(arrete.couleur_lettre).nom + " " + (new Date(arrete.date_arrete_national)).getFullYear()}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="form-col">
                                        <label htmlFor="dam_cet">Dépt</label>
                                        <Field name="lettre" as="select" className="form-control" autoComplete="off" onChange={handleChangeDepartement} value={selectedDepartement}>
                                            <option value="">TOUS</option>
                                            {departements && departements.map((dpt, n) =>
                                                <option key={n} value={dpt.numero_dpt}>{dpt.numero_dpt}</option>
                                            )}
                                        </Field>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-row">
                                    <div className="form-col">
                                        <label htmlFor="dam_cet">Décalage image</label>
                                        <Field name="lettre" type="text" className="form-control" autoComplete="off" disabled />
                                    </div>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Etiquettek