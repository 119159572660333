import { Formik, Field, Form } from 'formik'
import { useEffect, useRef } from 'react'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { CSSTransition } from 'react-transition-group'
import { isValidDate, lockBody, unlockBody } from '../../../../helpers/utils'
import { useAuth } from '../../../../providers/authProvider'
import { useDataContext } from '../../../../providers/DataContextProvider'
import { supervisionService } from '../../../../services/supervisionService'
import { useSaisieContext } from '../SaisieContext'
import { IoMdCloseCircle } from "react-icons/io"
import { storage } from '../../../../helpers/storageHelper'

const Etalonnage = () => {

    const [etalonnage, setEtalonnage] = useState(null)
    const [infos, setInfos] = useState(null)
    const [tax, setTax] = useState(null)
    const [instruments, setInstruments] = useState([])
    const [afficheurs, setAfficheurs] = useState(null)
    const [loadingInstrument, setLoadingInstrument] = useState(false)
    const [loadingAfficheur, setLoadingAfficheur] = useState(false)
    const [currentAfficheur, setCurrentAfficheur] = useState("")
    const [determinationK, setDeterminationK] = useState("")
    const [determinationKColor, setDeterminationKColor] = useState("")
    const [etalonnageManipulation, setEtalonnageManipulation] = useState(null)
    const [photo, setPhoto] = useState({ open: false })
    const [k_mini, setK_mini] = useState(null)
    const [k_maxi, setK_maxi] = useState(null)
    const [calculKColor, setCalculKColor] = useState("")
    const [precautions] = useState("Si au point 0 les impulsions ne démarrent pas immédiatement :" +
        "\n- Déclencher le comptage d'impulsions avant le point 0" +
        "\n- Rouler au pas jusqu'au point 0. Au point 0 : Remise à 0 du comptage d'impulsions" +
        "\n- Lancement de l'essai. Arrêt à fin de piste (xxx m)")
    const { setFormIntervention, calculK, setCalculK } = useSaisieContext()
    const { interventionInfoGeneral } = useDataContext()
    const auth = useAuth()
    const userGroup = parseInt(auth.userGroup)
    const formikRef = useRef()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const initializeData = async () => {
            try {
                // Chargement des données principales
                const [interventionData, infosData, activiteData] = await Promise.all([
                    storage.get('intervention'),
                    storage.get('infos'),
                    storage.get('activite')
                ])

                if (!interventionData || !infosData || !activiteData) {
                    console.error('Données manquantes')
                    return
                }

                setEtalonnage(interventionData)
                setInfos(infosData)

                const taxData = infosData.stationnements[0].vehiculeTaximetres[0].taximetre
                setTax(taxData)

                // Initialisation k_mini et k_maxi
                if (taxData.modeleTousAppareils.etendue_debut > 0) {
                    setK_mini(taxData.modeleTousAppareils.etendue_debut)
                }
                if (taxData.modeleTousAppareils.etendue_fin > 0) {
                    setK_maxi(taxData.modeleTousAppareils.etendue_fin)
                }

                // Initialisation de calculK
                if (interventionData.etalonnage_distance && interventionData.etalonnage_impulsions) {
                    const result = Math.round(1000 * interventionData.etalonnage_impulsions / interventionData.etalonnage_distance)
                    setCalculK(result)
                }

                // Chargement des instruments si nécessaire
                if (interventionData.etalonnage_type !== 0) {
                    await loadInstruments(interventionData.etalonnage_type, infosData, activiteData)
                }

                await getAfficheurs(infosData)
            } catch (error) {
                console.error('Erreur lors de l\'initialisation:', error)
            } finally {
                setLoading(false)
            }
        }

        initializeData()
    }, [])

    // Ajout d'un useEffect pour calcul_k_ideal
    useEffect(() => {
        if (formikRef.current && formikRef.current.values) {
            calcul_k_ideal()
        }
    }, [formikRef.current?.values])

    const loadInstruments = async (etalonnageType, infosData, activiteData) => {
        try {
            // Vérifier d'abord le cache
            const cachedInstruments = await storage.get('instruments_etalonnage')
            if (cachedInstruments) {
                setInstruments(cachedInstruments)
                return
            }

            setLoadingInstrument(true)
            const data = {
                numero_adherent: userGroup !== 0 ? userGroup : infosData.numero_adherent,
                type_appareil: etalonnageType === 1 ? 16 : 6,
                activite: activiteData,
                y_compris_inactif: (isValidDate(interventionInfoGeneral.date_effectuee) &&
                    (new Date(interventionInfoGeneral.date_effectuee)).getFullYear() > 1970),
                technicien_attitre: 0,
            }

            const response = await supervisionService.getInstrumentsDispos(data)
            setInstruments(response)
            await storage.set('instruments_etalonnage', response)
        } catch (error) {
            console.error('Erreur lors du chargement des instruments:', error)
            setInstruments([])
        } finally {
            setLoadingInstrument(false)
        }
    }

    const handleClickKEgaleCalcul = () => {
        formikRef.current.setFieldValue("etalonnage_k", calculK)
        checkColor(calculK)
    }

    const checkColor = (value = null) => {
        const k = value ?? formikRef.current.values.etalonnage_k
        if (!k || k === "0") {
            setCalculKColor("")
            return
        }

        let ok = true
        if (k_mini && k_mini > k) ok = false
        if (k_maxi && k_maxi < k) ok = false
        setCalculKColor(ok ? "green" : "red")
    }

    const getAfficheurs = async (infosData) => {
        setLoadingAfficheur(true)
        try {
            const tax = infosData.stationnements[0].vehiculeTaximetres[0].taximetre
            const afficheurRequest = {
                type: 10,
                marque: tax.marque_taximetre,
                modele: tax.modele_taximetre,
            }

            const response = await supervisionService.getModeleTypeTax(afficheurRequest)
            if (response) {
                const newArray = response.map((afficheur) => ({
                    modele_appareil: afficheur.modele_appareil,
                    modele_nom_appareil: afficheur.modele_nom_appareil
                }))
                newArray.push({
                    modele_appareil: tax.modele_taximetre,
                    modele_nom_appareil: tax.modeleTousAppareils.modele_nom_appareil
                })
                setAfficheurs(newArray)
            }
        } catch (error) {
            setCurrentAfficheur(infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modele_taximetre)
            setAfficheurs([{
                modele_appareil: infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modele_taximetre,
                modele_nom_appareil: infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils.modele_nom_appareil
            }])
            updateDeterminationK(infosData.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils)
        } finally {
            setLoadingAfficheur(false)
        }
    }

    const updateDeterminationK = (modeleTousAppareils) => {
        const texte = modeleTousAppareils.determination_k === ""
            ? "Pas d'information, appelez le siège si vous avez des infos sur ce thème"
            : modeleTousAppareils.determination_k
        const color = modeleTousAppareils.determination_k === "" ? "orange" : "blue"
        setDeterminationK(texte)
        setEtalonnageManipulation(modeleTousAppareils.etalonnage_manipulation)
        setDeterminationKColor(color)
    }

    const handleChangeAfficheur = async (e, setFieldValue) => {
        const value = e.target.value
        if (!value) return

        setFieldValue("modele_taximetre", value)
        setCurrentAfficheur(parseInt(value))

        if (value === "50") {
            updateDeterminationK(infos.stationnements[0].vehiculeTaximetres[0].taximetre.modeleTousAppareils)
        } else {
            try {
                const response = await supervisionService.getModeleTousAppareils(tax.marque_taximetre, value)
                updateDeterminationK(response)
            } catch (error) {
                console.error('Erreur lors du changement d\'afficheur:', error)
            }
        }
    }

    const handleChangeImpulsions = (e) => {
        const value = parseInt(e.target.value)
        formikRef.current.setFieldValue("etalonnage_impulsions", e.target.value)
        if (!isNaN(value)) {
            calcul_k_ideal(undefined, value)
        } else {
            setCalculK(0)
        }
    }

    const openImage = (event) => {
        setPhoto({
            open: true,
            image: etalonnageManipulation
        })
        lockBody()
    }

    const closeImage = () => {
        setPhoto({
            open: false
        })
        unlockBody()
    }

    const calcul_k_ideal = (distance = null, etalonnage_impulsions = null) => {
        if (!formikRef.current?.values) return

        let valeur = distance ?? formikRef.current.values.etalonnage_distance
        let etalonnage = etalonnage_impulsions ?? formikRef.current.values.etalonnage_impulsions

        if (valeur !== 0 && etalonnage !== 0) {
            const result = Math.round(1000 * (etalonnage) / valeur)
            setCalculK(result)
        }
    }

    const handleChangeK = (e) => {
        formikRef.current.setFieldValue("etalonnage_k", e.target.value)
        checkColor(e.target.value)
    }

    if (loading || !etalonnage || !infos || !tax) {
        return <div>Chargement...</div>
    }

    return (
        <div className="etalonnage-section receptionp-section">
            <CSSTransition
                in={photo.open}
                timeout={300}
                onExit={closeImage}
                classNames="exemple"
                unmountOnExit
            >
                <div className={"show-image" + (photo.open === true ? " show" : "")}>
                    <button className="btn" onClick={closeImage}>
                        <IoMdCloseCircle />
                    </button>
                    <div className="photo-saisie">
                        <input type="checkbox" className="zoomCheck" id="zoomCheck1" />
                        <label htmlFor="zoomCheck1">
                            <img src={photo && photo.image ? "data:image/jpeg;base64," + photo.image : ""} alt="Pas de photo1 pour cet étalonnage" />
                        </label>
                    </div>
                </div>
            </CSSTransition>
            {interventionInfoGeneral.bris_scellement &&
                <div className="focus focus-danger">
                    Rappel  : dans le cas d'un bris de scellement qui ne touche pas l'étalonnage :
                    pas d'obligation de redétermination du 'k'
                </div>
            }
            <Formik
                enableReinitialize={true}
                innerRef={formikRef}
                initialValues={{
                    etalonnage_type: etalonnage.etalonnage_type,
                    instrument_etalonnage: etalonnage.instrument_etalonnage,
                    modele_taximetre: etalonnage.modele_taximetre,
                    etalonnage_distance: etalonnage.etalonnage_distance,
                    etalonnage_impulsions: etalonnage.etalonnage_impulsions,
                    etalonnage_k: etalonnage.etalonnage_k,
                    etalonnage_commentaire: etalonnage.etalonnage_commentaire,
                }}
                validate={async (values) => {
                    try {
                        let intervention = await storage.get('intervention')
                        // Mise à jour des interventions
                        intervention.etalonnage_type = values.etalonnage_type
                        intervention.instrument_etalonnage = parseInt(values.instrument_etalonnage)
                        intervention.etalonnage_distance = values.etalonnage_distance
                        intervention.etalonnage_impulsions = parseInt(values.etalonnage_impulsions)
                        intervention.etalonnage_k = parseInt(values.etalonnage_k)
                        intervention.etalonnage_commentaire = values.etalonnage_commentaire
                        // Partage des données
                        setFormIntervention(intervention)
                        // Mise à jour de localStorage
                        await storage.set('intervention', intervention)
                    } catch (error) {
                        console.error('Erreur lors de la validation:', error)
                    }
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500))
                    alert(JSON.stringify(values, null, 2))
                }}
            >
                {({ values, setFieldValue, setValues, handleChange }) => (
                    <Form autoComplete="off">
                        <div className="saisie-form-container">
                            <div className="form-row">
                                <div className="form-cell-input">
                                    <ul>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="etalonnage_type"
                                                    id="etalonnage_type-1"
                                                    className="sr-only"
                                                    onChange={async () => {
                                                        setFieldValue('etalonnage_type', 1)
                                                        await loadInstruments(1, infos)
                                                    }}
                                                    checked={values.etalonnage_type === 1} />
                                                <label htmlFor="etalonnage_type-1" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    Piste
                                                </label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="input-radio-item">
                                                <Field
                                                    type="radio"
                                                    name="etalonnage_type"
                                                    id="etalonnage_type-2"
                                                    className="sr-only"
                                                    onChange={async () => {
                                                        setFieldValue('etalonnage_type', 2);
                                                        await loadInstruments(2, infos)
                                                    }}
                                                    checked={values.etalonnage_type === 2} />
                                                <label htmlFor="etalonnage_type-2" className="h-8 py-1 px-2 text-sm leading-6 text-gray-600 hover:text-gray-800 bg-white rounded shadow">
                                                    Banc/Piste vérif
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="form-row m-w-100 m-m-0">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Instrument
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        {(!loadingInstrument &&
                                            <Field name="instrument_etalonnage" className="form-control input-infos" as="select" onChange={e => handleChange(e, setFieldValue)}>
                                                <option value=""></option>
                                                {instruments && instruments.map((instrument, index) =>
                                                    <option key={index} value={instrument.numero_appareil}>{instrument.marque + " " + instrument.modele + " " + instrument.identification_interne}</option>
                                                )}
                                            </Field>) ||
                                            <Skeleton height={34} width={320} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Précautions
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="precautions" className="form-control form-infos" disabled as="textarea" value={precautions} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row m-w-100 m-m-0">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Afficheur
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        {(!loadingAfficheur &&
                                            <Field name="modele_taximetre" className="form-control input-infos" as="select" onChange={e => handleChangeAfficheur(e, setFieldValue)} value={currentAfficheur}>
                                                <option value=""></option>
                                                {afficheurs && afficheurs.map((afficheur, index) =>
                                                    <option key={index} value={afficheur.modele_appareil}>{afficheur.modele_nom_appareil}</option>
                                                )}
                                            </Field>) ||
                                            <Skeleton height={34} width={320} />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Détermination k
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="determination_k" className={"form-control form-infos" + (determinationKColor === "orange" ? " bg-warning" : determinationKColor === "blue" ? " bg-info" : "")} disabled as="textarea" value={determinationK} />
                                    </div>
                                </div>
                            </div>
                            <div className="button-document-container">
                                {etalonnageManipulation ?
                                    <button id="photo1" type="button" className="btn btn-document" onClick={e => openImage(e, 1)}>
                                        Photo manipulation
                                        <i className="fas fa-eye"></i>
                                    </button>
                                    :
                                    "Pas de photo1 pour cet étalonnage"
                                }
                            </div>
                            <div className="form-row">
                                <div className="form-col d-flex">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Distance parcourue (m) :
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="etalonnage_distance" className="form-control input-small" type="text" />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" disabled={values.pas_vignette_presente} onClick={e => { setFieldValue("etalonnage_distance", 200); calcul_k_ideal(200); }}>
                                                200 m
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" disabled={values.pas_vignette_presente} onClick={e => { setFieldValue("etalonnage_distance", 1000); calcul_k_ideal(1000); }}>
                                                1000 m
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col d-flex">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Nombre d'impulsions :
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="etalonnage_impulsions" className="form-control input-small" type="text" onChange={handleChangeImpulsions} />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                =&gt;
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="calcul" className="form-control input-small" type="text" value={calculK} />
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <button type="button" className="cbtn cbtn-success mt-0 mb-2" onClick={handleClickKEgaleCalcul}>
                                                k = calcul
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col d-flex">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Calcul du k (imp/km)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-col">
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="etalonnage_k" className={"form-control input-small" + (calculKColor === "green" ? " bg-success" : calculKColor === "red" ? " bg-danger" : "")} type="text" onChange={handleChangeK} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-col">
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                min
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="k_mini" className="form-control input-small" type="text" value={k_mini} disabled />
                                        </div>
                                    </div>
                                    <div className="form-cell-label">
                                        <div className="form-text">
                                            <div className="indication-title">
                                                Max
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-cell-input">
                                        <div className="form-input">
                                            <Field name="k_maxi" className="form-control input-small" type="text" value={k_maxi} disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-cell-label">
                                    <div className="form-text">
                                        <div className="indication-title">
                                            Commentaires
                                        </div>
                                    </div>
                                </div>
                                <div className="form-cell-input m-w-100">
                                    <div className="form-input">
                                        <Field name="etalonnage_commentaire" className="form-control form-infos" as="textarea" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}

            </Formik>

        </div>
    )

}

export default Etalonnage